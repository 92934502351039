import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import HTTP from "../../services/http";

import { required, ValidateOnlyAlpha } from '../common/fieldValidations';

/**COMPONENT */
import RenderFiled from "../common/renderField";
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multiselect";
import Loader from "../common/loader";
import DropdownComp from "../common/DropdownList";
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
import infoOf from '../common/tooltip-text';
import Autosuggest from 'react-autosuggest';
import { defaultTheme } from 'react-autosuggest/dist/theme';
import MaterialMultiV1 from '../common/material_multi_v1';

/**CONSTANT DATA */
import { OPTIONS } from "../common/options"

class AddLink extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            formAction: "ADD",
            status: true,
            suggestions:[],
            footerLinks:[],
            statePages:[],
            value:"",
            linkValid:true,
            selectedPage:{},
        }
        /**event binding  */
        this.addEditLink = this.addEditLink.bind(this);
        this.getALink = this.getALink.bind(this);
    }

    componentWillMount() {
        this.getALink()
        this.getFooterLinks();
    }

    getFooterLinks(){
        // HTTP.Request('get', window.admin.getCMS,{type:"page",pageType:"StatePage"})
        HTTP.Request('get',window._env.appPath+"v1/get-cms",{type:"page",pageType:"StatePage"})
            .then(data => {
              if (data.Error) {
                this.setState({
                  footerLinks: []
                });
              } else {
                this.setState({
                  footerLinks: data.data.result.map(e=>{
                    return e.title
                  }),
                  suggestions: data.data.result.map(e=>{
                    return e.title
                  }),
                });
                data.data.result.forEach(state => {
                    HTTP.Request('get',window._env.appPath+"v1/get-cms" + `?type=page&pageType=CityPage&state=${state.state}`)
                    .then(city=>{
                    let tempState = state
                      tempState['cities'] = city.data.result.map(e=>{
                        return{title:e.title,_id:e}
                      })
                      this.state.statePages.push(tempState)
                      this.setState({
                        statePages: this.state.statePages
                      });
                    })
                  });              
                }
            })
      }

    render() {
        const { handleSubmit } = this.props;
        const { selectedpermissions,selectedPage, suggestions,isLoading, value,formAction, status } = this.state
        const renderSuggestion = suggestion => (
            <div>
              {suggestion}
            </div>
          );
        var inputProps = {
            placeholder: 'Url',
            value,
            onChange: this.onSugChange,
            onBlur:this.onBlurEvent
         };
        return (
            <div>
                {isLoading ? <Loader /> : <div><PageHeader route={formAction === "ADD" ? "Add New Footer Link" : "Edit Footer Link"} parent="Footer Link Management" parentRoute="/footer-pages" />

                    <div className="tab-pane active" >
                        <form onSubmit={handleSubmit(this.addEditLink)}  >
                            {/* <Field name="title" style={{display:"none"}} readOnly={true} fieldName="Title*" type="text" component={RenderFiled} validate={[required, ValidateOnlyAlpha]} /> */}
                            <p>State Page</p>
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                                id="link"
                                name="link"
                                value = {value}
                                theme={defaultTheme}
                            />
                           
                            {/* <Field name="position" fieldName="Position*" type="text" component={RenderFiled} validate={[required]} /> */}
                            <Field 
                                name="status"
                                id='status'
                                options={[{ label: "Active", value: true }, { label: "Inactive", value: false }]}
                                label="Status"
                                defaultValue={status ? "Active" : "Inactive"}
                                textField="label"
                                valueField="value"
                                component={DropdownComp}
                            /><br />
                            <div className="form-actions">
                                <button type="submit" className="btn green uppercase" disabled={this.props.invalid || this.props.submitting}>{formAction == 'ADD'?('ADD'):("Update")}</button>
                            </div>
                        </form>
                    </div>
                </div>
                }

            </div>
        );
    }
    onSugChange = (event, { newValue }) => {
        this.setState({
            value: newValue,
        });
        if(this.state.footerLinks.includes(newValue)){
            this.setState({
              linkValid:true
            })
          }
          else{
            this.setState({
                linkValid:false
              })
          }
        };

    onSuggestionsFetchRequested = ({ value }) => {
        let sug = this.state.footerLinks.filter((s)=>{
          return !!s ? s.toLowerCase().includes(value.toLowerCase()) :  false
        })
        this.setState({
          suggestions:sug
        })
      };

    onSuggestionsClearRequested = () => {
    this.setState({
        suggestions: this.state.footerLinks
    });
    };

    getSuggestionValue = suggestion => {
        this.setState({
          linkValid:true,
          selectedPage: this.state.statePages.find(e=>e.title == suggestion)

        })
        return suggestion
    }

    onBlurEvent=ss=>{
        if(this.state.footerLinks.includes(ss.currentTarget.value)){
            this.setState({
              linkValid:true
            })
          }
          else{
            this.setState({
                linkValid:false
              })
          }
      }

    addEditRole(data) {
        data.type = "admin";
        HTTP.Request("post", window.admin.addEditRole, data)
            .then(result => {
                this.props.dispatch(push("/footer-pages"));
                toast(result.message, { type: "success" });

                /*log audits for user*/
                this.props.dispatch({
                    type: ADMIN_TRACK_AUDIT_LOGS,
                    action: {
                        comment: "Modified the content of Footer Link - " + result.data.title,
                        type: "audit"
                    }
                });
            })
            .catch(err => {
                if (err && err.errors.length > 0) err.errors.map(message => toast(message, { type: "error" }))
                else toast(err.message, { type: 'error' })
            });
    }

    getARole() {
        const { match, initialize } = this.props;
        /*extract plant id from request*/
        let roleID = (match.params.id) ? match.params.id : null;

        if (roleID) {
            this.setState({ isLoading: true, formAction: "EDIT" })
            HTTP.Request("get", window.admin.getARole, { id: roleID })
                .then(result => {
                    this.setState({ isLoading: false, selectedpermissions: result.data.permissions, status: result.data.status })

                    /*set data to form*/
                    initialize(result.data);
                })
        }
    }

    getALink(){
        const { match, initialize } = this.props;
        if (match.params.id) {
          this.setState({ isLoading: true, formAction: "EDIT" })
        HTTP.Request("get", window.admin.getFooterLinksById, {id:match.params.id})
            .then(result => {
                this.setState({
                   value:result.data.title,
                   status:result.data.status,
                   isLoading: false,
                   selectedPage:{_id:result.data.statePage}
                })
                initialize({title:result.data.title,status:result.data.status})
                // toast(result.message, { type: "success" });
            })
            .catch(err => {
                if (err && err.errors.length > 0) err.errors.map(message => toast(message, { type: "error" }))
                else toast(err.message, { type: 'error' })
            });
          }
    }

    addEditLink(data){
      const { match, initialize } = this.props;
        data["statePage"] = this.state.selectedPage._id;
        data["title"] = this.state.value
        if(data.status == undefined){
          data["status"]=true
        }
        data["_id"]= match.params.id
        if(!this.state.value || !this.state.selectedPage._id){
          toast('Please select a valid state page', { type: "error" });
          return false
        }
        HTTP.Request("post", window.admin.upsertFooterLinks, data)
            .then(result => {
                this.props.dispatch(push("/footer-pages"));
                toast(result.message, { type: "success" });
            })
            .catch(err => {
                if (err && err.errors.length > 0) err.errors.map(message => toast(message, { type: "error" }))
                else toast(err.message, { type: 'error' })
            });
        
    }
}

//decorate form component
let AddLink_Form = reduxForm({
    form: "roleMang_Form",
})(AddLink);


export default AddLink_Form;

