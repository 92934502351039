import React,{ Component } from 'react';


class SortableItem extends Component {
    constructor(props) {
        super(props);

    }
    render() {
        console.log("this.pors-->",this.props);
      return (
          <div>
          </div>
      );
    }
  };

export default SortableItem;