import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { HelpBlock,Table,Alert } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { ValidateOnlyAlpha } from "../../common/fieldValidations";
import HTTP from '../../../services/http';
import { DropdownList } from 'react-widgets';

/**COMPONENTS */
import Loader from "../../common/loader";
import ROW from "./row";

var timer;
class TableComp extends Component{
    constructor(props){
        super(props);
        this.state = {
            array:[],
            statusFilter:"active",
            isLoading:false,
            isSyncing: false,
            searchQuery :'',
            activePage :1,
            totalItemsCount:1,
            statusOptions:[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "in-active" },
              ],
        };
        this.getProperty=this.getProperty.bind(this);
        this.syncUsers = this.syncUsers.bind(this);
        this.search = this.search.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.delete = this.delete.bind(this);
        this.filterByDate = this.filterByDate.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    componentWillMount(){
        this.filterByDate({value:"active"})
    }
    
    render(){
        const {array ,isLoading,seracherror,statusOptions} = this.state;
        return(
            <div>
                 {isLoading &&<Loader />}
                <div className="portlet light bordered">
                <div className="portlet-body">
                    {/* actions search addnew  */}
                    <div className="table-toolbar">
                            <div className="row">

                                <div className="col-md-3">
                                    <div className="btn-group pull-right">
                                        <input type="text" className="form-control" id="search_field" placeholder="Search Disp Consultant(s)" onChange={this.search} />
                                        <HelpBlock style={{ color: '#e73d4a' }}>
                                            {seracherror ? seracherror : null}
                                        </HelpBlock>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                            <DropdownList
                                                data={statusOptions}
                                                defaultValue={undefined}
                                                textField="label"
                                                valueField="value"
                                                name={"fileter"}
                                                placeholder={'---Select----'}
                                                value={this.state.statusFilter ? this.state.statusFilter : undefined}
                                                onChange={this.filterByDate}
                                            />
                                        </div>
                                        <div className="col-md-3">
                                            <button className="btn sbold red" onClick={this.handleReset}>Reset</button>
                                        </div>

                                {/* <!-- add new --> */}
                                <div className="col-md-5 col-sm-offset-4">
                                    <div className="btn-group pull-right">
                                        <Link to="/new-disposition-consultant"><button id="sample_editable_1_new" className="btn sbold green"> Add New
                                        </button></Link>
                                    </div>
                                    <div className="btn-group pull-right mr-10">
                                        <button id="sample_editable_1_new" className="btn sbold green" onClick={this.syncUsers}>
                                            { this.state.isSyncing ? 'Loading...' : 'Sync to InvestorLift' }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                           {/* if list is empty */}
                           {!isLoading && !array.length ? <Alert bsStyle="warning">
                            <strong>No Data Found !</strong>
                        </Alert>:
                            <div style={{ display: "flow-root"}}>                                
                            <Table responsive striped bordered condensed hover> 
                                <thead>
                                    <tr>
                                        <th> Name </th>
                                        <th> Email </th>
                                        <th> Phone No </th>
                                        <th> Status </th> 
                                        <th> Created On </th> 
                                        <th> Modified On </th>                                   
                                        {/* <th width="15%"> Actions </th> */}
                                    </tr>
                                </thead>
 
                                <tbody>
                                    {array.map(cms => {
                                        return (
                                            <ROW key={cms._id} cms={cms} delete={this.delete} />
                                        )
                                    })}
                                </tbody>                               
                            </Table>
                                <div style={{float:"right"}}>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={window.limit}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={3}
                                    onChange={this.handlePageChange}
                                />
                             </div>                         
                        </div>
                    }
                </div>
                </div>
            </div>
        )
    }

    /**PAGINATION */
    handlePageChange(eventKey){
        this.setState({ activePage: eventKey });
        this.getProperty({
            page: eventKey ? eventKey : 1,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
        });
        /**to set query in route */
        this.props.history.push({
            search: '?page=' + eventKey
        })
    }

    // ACTIVE IN ACTIVE FILTER
    filterByDate(data) {
        if(data.value == "in-active" || data.value == "active"){
        this.state.statusFilter = data.value
        }
        this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({
            activePage: 1
        },
        this.getProperty({
            page: 1,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
            statusFilter:this.state.statusFilter
        })
        );
    }

    /**SEARCH */
    search(e) {
        /**to remove Event Pooling  */
        e.persist();
        let searchedValue = e.target.value;
        let seracherror = ValidateOnlyAlpha(searchedValue)
        if (seracherror) {
            this.setState({ seracherror: seracherror });
            return;
        }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror });
        } else {
            this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '', activePage: 1 });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getProperty({
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery,
            statusFilter:this.state.statusFilter
        });
        }, 1000);
    }

    handleReset() {
        this.state.statusFilter="active"
        this.setState({statusFilter:"active",seracherror: '', searchQuery: '', activePage: 1 });
        this.props.history.push({
            search: '?page=' + 1
        });
        let searchEle = document.getElementById('search_field');
        if (searchEle) {
            searchEle.value = '';
        }
        this.getProperty({
            page: 1,
            searchQuery:'',
            statusFilter:this.state.statusFilter
        })
    }

    getProperty(params={}){
        /**to start Loader */
        this.setState({  isLoading: true }) 
        HTTP.Request("get",window.admin.getAgentList,params)
        .then((response) =>{
             this.setState({
                array : response.data.records,
                isLoading:false,
                totalItemsCount: response.data.total
            })     
        })
        
    }

    syncUsers() {
        this.setState({  isSyncing: true });
        HTTP.Request("get",window.admin.syncAgents)
            .then((response) =>{
                this.setState({  isSyncing: false });
            })
            .catch(error => {
                this.setState({  isSyncing: false });
            })
    }

    /* delete */
    delete(id){
       
        let page = 1;
        let data={};
        data.editID = id;
        data.trash=true;
        
        HTTP.Request("post", window.admin.trashAgent, data)
        .then(result => {
             /**to stop loader */
             this.setState({ isLoading: false});
             /**refresh list after deletion */
             this.getProperty();
        })
        .catch(error => {
            this.setState({ isLoading: false});
        });
        this.getProperty();
    }
}
export default TableComp;
