import React, {Component} from 'react';
import { required } from './fieldValidations';
import { Field ,FieldArray,reduxForm } from 'redux-form';
import renderField from "./renderField";
import TT from "./tooltip";
import infoOf from '../common/tooltip-text';
import FieldArrayfeature from "../common/FieldArrayfeature"; 
import FileInput from "../common/FileInput";
import LocationSearchInput from "../common/address";

class FieldArrayProperty extends Component {
	 constructor() {
        super();
        this.state = {
            success: '',
            reset: false,
            isLoading:false,
            address: '' ,
        }
       
    }


	render() {
		const { placeholder1, placeholder2, formGroupClassName, fields } = this.props;
		
		return (
		  <div className={formGroupClassName}>
		    <div className='m-bot-30'>
		      <button type="button" className="btn-primary ml-1 btn btn-primary" onClick={() => fields.push()}>Add More</button>
		    </div>
		    {fields.map((value, index) =>

		       <div key={index} className="form-row row">
		       <Field 
                    name={`${value}.address`}
                    type="text"
                    placeholder="Enter address"
                    component={LocationSearchInput}
                    validate={[required]}
		       />
				<Field 
				     component={FileInput} type="file"
				     label="Before Image"
				     name={`${value}.before_image`}
				     validate={[required]} />        
				<Field 
				    component={FileInput} type="file"
				    label="After Image" 
				    name={`${value}.after_image`}
				    validate={[required]}/>  	
		      
		       <Field fieldName="feature*" 
		       icon='fa fa-info-circle' 
		       placeholder={placeholder2}
		       tooltip={infoOf.default_tax} 
		       component={renderField} type="text"
		       name={`${value}.feature`} validate={[required]}/>
		       <FieldArray placeholder1="Enter feature"  formGroupClassName="" name={`${value}.features`} component={FieldArrayfeature}/>    
			    <br /> 
                        <div className="form-actions">
                    	<button
			          	type="button"
			          	className="btn btn-md red-mint"
			          	onClick={() => fields.remove(index)}>
					<i className="fa fa-trash no-pointer"  ></i>
			         </button>
                        </div>  
			    </div> 
			   
		    )}
		  </div>
		)
	}
}

export default FieldArrayProperty;