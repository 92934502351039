import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import {  toast } from 'react-toastify';
import HTTP from "../../services/http";
import {Tabs, Tab} from "react-bootstrap";
import { required, ValidateOnlyAlpha } from '../common/fieldValidations';

/**COMPONENT */
import RenderFiled from "../common/renderField";
import PageHeader from "../common/pageheader"; 
import DropdownComp  from "../common/DropdownList";
import Editor from "../common/editor";
import Loader from "../common/loader";
import Revisions from "./element/revisions";
import TT from "../common/tooltip";
import DatePicker from "../common/DateTimePicker";
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
import infoOf from '../common/tooltip-text';
import FroalaEditorComp from "../common/floalaEditor";


class AddSlide extends Component {

    constructor(props) {
        super(props);

        this.state = {
            revisions : [],
            content : "",
            isLoading:false,
            formAction :"ADD",
            status:true
        }
        
        /**event binding  */
        this.upsertCMS = this.upsertCMS.bind(this);
        this.getaCMS = this.getaCMS.bind(this);
        this.getFile = this.getFile.bind(this);

    }

    componentWillMount(){
        this.getaCMS();
    }
    
    render() {
        const { handleSubmit } = this.props;
        const { isLoading, formAction ,invalidfFile} = this.state
        return (
            <div className='relative'>
                {isLoading ? <Loader /> : <div><PageHeader route={formAction ==="ADD"?"Add New Press Release":"Edit Press Release"} parent='Press Releases' parentRoute='/slides' />
              
                <div className="tab-pane active" >
                    <Tabs defaultActiveKey={1} animation={false} id="profileTabs" >
                        <Tab eventKey={1} title={formAction ==="ADD"?"Add New Slide":"Edit Slide"}>
                            <form onSubmit={handleSubmit(this.upsertCMS)}  >
                                <div className="col-md-12">  
                                    <Field name="title" fieldName="Title*" type="text" component={RenderFiled} validate={[required, ValidateOnlyAlpha]} />
                                </div>
                                <div className="col-md-6">
                                    <label>Press Release PDF*</label>
                                    <input  type="file" id="getPdfFile" onChange={this.getFile} accept="application/pdf"  className='form-control' />                                                            
                                    {invalidfFile && <span className="invalidText" style={{ color: '#e73d4a' }}>Invalid file type</span>}
                                        {this.state.image ?<label style={{
                                            "marginRight": "10px",
                                        "paddingTop":" 15px"}}>PDF</label>:null  }                                
                                        { this.state.image ?<TT tooltip="Download">
                                            <a className="btn btn-xs grey-mint label label-info" href={ this.state.image.secure_url } target="_blank" ><i className="fa  fa-cloud-download"></i></a>
                                            </TT>  : null} 
                                        {/* <Field name="content" fieldName="Content" type="text" component={FroalaEditorComp}  content={this.state.content} />
                                        <Field name="order" icon='fa fa-info-circle' tooltip={infoOf.order} fieldName="Order" type="number" component={RenderFiled} /> */}
                                </div>
                                <div className="col-md-3">
                                        <Field
                                            name="blog_post_date"
                                            component={DatePicker}
                                            fieldName="Publish date*"
                                            disabledKeyboardNavigation
                                        /><nr/>
                                </div>
                                <div className="col-md-3">
                                    <Field name="status"
                                        options={[{ label: "Active", value: true }, { label: "Inactive", value: false }]}
                                        label="Status"
                                        defaultValue={this.state.status ? "Active" : "Inactive"}
                                        textField="label"
                                        valueField="value"
                                        component={DropdownComp}
                                    /><br />  
                                </div>
                                <div className="col-md-12">
                                    <div className="form-actions">
                                            <button type="submit" className="btn green uppercase" disabled={this.props.invalid || this.props.submitting}>{formAction === "ADD" ? "Add" : "Update"}</button>
                                    </div>
                                </div>
                            </form>
                        </Tab>
                        <Tab eventKey={2} title="Revisions">
                            <Revisions revisions={this.state.revisions} />
                        </Tab>
                    </Tabs>
                    
                </div>
                </div>
                }

            </div>
        );
    }

    upsertCMS(data) {
        const { match } = this.props;
        this.setState({isLoading: true});
         
        if(this.state.formAction=='ADD' && !this.state.file){
            this.setState({isLoading: false});
          return  toast("Please Select File", {type: "error"});
        }
        if(data.status === undefined) {
            data.status = true;
        }
        /*bind type of Post*/
        data.type = "slides";
        if(match.params._id) data.editID = match.params._id;

        let formData = new FormData();
        /*add file to request*/
        formData.append("file", this.state.file);
        formData.append("data", JSON.stringify(data));

        this.props.dispatch({
            type : "Admin-upsertSlide",
            data : formData,
            success : (r) => {
                this.props.dispatch(push("/slides"));
                toast.success(r.message);
                this.setState({isLoading: false});
                /*log audits for user*/
                this.props.dispatch({
                    type: ADMIN_TRACK_AUDIT_LOGS,
                    action: {
                        comment: "Modified the content of Slide - " + r.data.title,
                        type: "audit"
                    }
                });
            },
            error : (e) => {
                if(e.errors){
                    e.errors.map((error) => toast('The Title already exists', {type: "error"}))
                }
            }
        });
    }

    getFile(e) {
        if(['application/pdf',].includes(e.target.files[0].type)){
            this.setState({ file: e.target.files[0],invalidfFile:false });
        }else{
            this.setState({ file:"",invalidfFile:true});
            document.getElementById("getPdfFile").value = "";	
        }
    }

    getaCMS(){
        const { match, initialize } = this.props;
        /*extract plant id from request*/
        let cmsID = (match.params._id) ? match.params._id : null;

        if(cmsID){
            this.setState({ isLoading: true,  formAction:"EDIT"})
            HTTP.Request("get", window.admin.getaSlide, { _id: cmsID})
            .then(result => {
                this.setState({ isLoading: false, status: result.data.data.status, content:result.data.data.content?result.data.data.content:"", image:result.data.data.image?result.data.data.image:"", revisions: result.data.revisions?result.data.revisions:[]})
                /*set data to form*/
                initialize(result.data.data);
            })
        }
    }
}

//decorate form component
let AddSlide_Form = reduxForm({
    form: "AddSlide_Form ",
    validate:(values) => {
        const errors = {};
        if (!values.blog_post_date) {
            errors.blog_post_date = " ";
        }
        return errors;
    }

})(AddSlide);


export default AddSlide_Form;