import React from 'react';

export default class List extends React.Component {

	render(){
		const spanStyle = {
            display: 'inline-block'
        }
		return(
			<span className='label label-info mr-10' style={{...spanStyle}}>{this.props.permission}</span>
		);
	}
}