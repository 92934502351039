import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import HTTP from '../../services/http';
import { Tabs, Tab } from 'react-bootstrap';
import TT from "../common/tooltip";
import Session from '../../services/session';
import Banner from 'react-js-banner';
import { Link } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { Gallery } from "react-grid-gallery";


import {
  required,
  ValidateOnlyAlpha,
  slugValidation,
} from '../common/fieldValidations';

/**COMPONENT */
import RenderField from '../common/renderField';
import PageHeader from '../common/pageheader';
import DropdownComp from '../common/DropdownList';
/* import Editor from "../common/editor"; */
import Loader from '../common/loader';
import Revisions from './element/revisions';
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
import infoOf from '../common/tooltip-text';
import DatePicker from '../common/DateTimePicker';
import { slugify } from '../../libs/Helper';
import FroalaEditorComp from '../common/floalaEditor';
import { connect } from 'react-redux';
import BlogPreview from './blogPreview';

class AddBlog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      revisions: [],
      content: '',
      isLoading: false,
      formAction: 'ADD',
      orignalData:{},
      isPreview:false,
      previewData:{},
      status: false,
      image: '',
      isDeleting: false,
      isSlugify:true,
      selectImageModal: false,
      modalIsOpen: false,
      featuredImagefromLibraryURL: '',
      imageSelectedFromLibrary: false, 
      setImages: [],
      chosenImageData: null,
      imageRawData: null,
      allPagesForFroala: []
    };
    /**event binding  */
    this.upsertCMS = this.upsertCMS.bind(this);
    this.getaCMS = this.getaCMS.bind(this);
    this.getFile = this.getFile.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.setFeaturedImageFromLibrary = this.setFeaturedImageFromLibrary.bind(this);
    this.toBase64 = this.toBase64.bind(this);
    this.loadPages = this.loadPages.bind(this);
  }

  customStyles = {
    content: {
      top: '45%',
      left: '55%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '10px',
      transform: 'translate(-50%, -50%)',
      position: 'relative',
      maxWidth: '72%',
      height: '700px'
    },
    overlay: {
      background: 'rgb(0, 0, 0, 0.5)',
      zIndex: '2147483639',
      position: 'fixed'
    }
  };

  componentWillMount() {
    this.getaCMS();
  }

  componentDidMount() {
    this.loadPages();
  }


  closeModal() {
    let updatedImages = this.state.setImages.map(item => {
      return {...item, isSelected: false}
    });
    // const imageData = sessionStorage?.getItem('tempImage');
    // if(imageData !== ''){
      // this.setState({
      //   imageSelectedFromLibrary: false
      // })
   //  }
    // sessionStorage.removeItem('tempImage');
    this.setState({
      modalIsOpen: false,
      setImages: updatedImages,
     // imageRawData: imageData
    })
  }
  openModal(e){
    e.preventDefault();
    this.setState({
      modalIsOpen: true
    })
  }
  handleSelect = (index, item ) => {
    const nextImages = this.state.setImages.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : {...image, isSelected: false}
    );
    this.setState({
      setImages: nextImages,
      featuredImagefromLibraryURL: this.state.setImages[index]['src'],
      chosenImageData: this.state.setImages[index]
    })
  };

  setFeaturedImageFromLibrary() {
    this.setState({
     imageSelectedFromLibrary: true,
     imageRawData: null
    })
    this.closeModal();
 }
 chooseImageModalOpen() {
 }

 loadPages() {
  const user = Session.getSession('user');
  const loginId = user._id;
  const data = {
      filter: 2,
      loginId: loginId,
      order: "updated_at",
      page: 1,
      pageType: "Page",
      searchQuery: "",
      statusFilter: "active",
      type: "page",
      value: "active",
      limit: 500
  };
  const blogData = {
     type: "blog",
     order: "created_at",
     loginId: loginId,
     limit: 500,
     statusFilter: "active",
     label: "Active",
     value: "active"
  }
  HTTP.Request("get", window.admin.getPages, data)
.then(response => { 
  const createdData = response?.data?.records?.map(item => ({text: item.title, href: process?.env?.NODE_ENV === 'production' ? `https://www.housebuyersofamerica.com/${item.slug}`: `https://www.housebuyersofamerica.com/${item.slug}`}));
  const popup = [
                {
                  displayText: 'Modal Popup',
                  href: 'javascript%3Avoid(0)',
                }
          ];
          HTTP.Request("get", window.admin.getBlogs, blogData)
          .then(res => {
            const createdBlogData = res?.data?.records?.map(item => ({text: item.title, href: process?.env?.NODE_ENV === 'production' ? `https://www.housebuyersofamerica.com/blog/${item.slug}`: `https://www.housebuyersofamerica.com/blog/${item.slug}`}));
            if(createdData !== undefined && createdBlogData !== undefined){
              const totalData = [...createdData, ...createdBlogData];
              totalData.sort((a, b) => { 
              let fa = a.text.toLowerCase(),
                  fb = b.text.toLowerCase();

                  if (fa < fb) {
                      return -1;
                  }
                  if (fa > fb) {
                      return 1;
                  }
                  return 0;
              });
              const newtotalData = [...popup, ...totalData];
              this.setState({
                allPagesForFroala: newtotalData
              })
              this.forceUpdate();
            }
          })
          .catch(err => console.error(err))
      })
.catch(error => { console.error(error) });
 
}


 toBase64(file) { 
  return new Promise(resolve => {
    let fileInfo;
    let baseURL = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      console.log("Called", reader);
      baseURL = reader.result;
      console.log(baseURL);
      resolve(baseURL);
    };
    console.log(fileInfo);
  });
 }

    render() {
        const { handleSubmit , match } = this.props;
        const { isLoading, formAction, status,isSlugify ,orignalData, isPreview , previewData } = this.state;
        return (
          <div className="relative">
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <PageHeader
                  route={formAction === 'ADD' ? 'Add New Blog' : 'Edit Blog'}
                  parent="Blogs"
                  parentRoute="/blogs"
                />

                <div className="tab-pane active">
                  <Tabs defaultActiveKey={1} animation={false} id="profileTabs">
                    <Tab
                      eventKey={1}
                      title={
                        formAction === 'ADD' ? 'Add New Blog' : 'Edit Blog'
                      }
                    >
                          {isPreview &&
                          <div >
                            <BlogPreview previewData={previewData} upsertData={this.clickSubmitButton} closeParentModal={this.closePagePreviewPopup}/>
                          </div>
                        }
                     <form style={isPreview ?{display:'none'}:{}} onSubmit={handleSubmit(this.upsertCMS)}>
                     <Banner showBanner={formAction === 'EDIT' && ((!!orignalData.draft && orignalData.draft !== null && orignalData.draft !== "") || orignalData.isDrafted)} css={{ color: "#FFF", backgroundColor: "#ff4545ab", borderRadius: "4px", cursor: "pointer" }}>
                          <div >
                            <Link style={{textDecoration:'none',color:"white"}}
                            to={'/blogs/edit/' + orignalData._id + "/draft"}
                            onClick={this.forceUpdate}
                          >There is a draft content not made live
                          </Link>
                            </div>
                      </Banner>
                        <Field
                          name="title"
                          fieldName="Title*"
                          type="text"
                          component={RenderField}
                          onChange={this.onChange}
                          validate={[required, ValidateOnlyAlpha]}
                        />
                        {
                          <div className='d-flex'>
                          <div className="col-sm-9 p-0">
                          <Field
                            name="slug"
                            fieldName="Custom URL*"
                            type="text"
                            component={RenderField}
                            disabled={
                              this.props.allowCustomURLEdit ? false : true
                            }
                            validate={[required, slugValidation]}
                          />
                          </div>
                          <div className="col-sm-3">
                        <p style={{fontSize:"15px",marginTop:"0px",marginBottom:"1px",paddingLeft:"12px"}}>Auto generate slug from title</p>
                          <Switch
                            checked={isSlugify}
                            onChange={(e) => {
                              this.setState({
                                isSlugify: !isSlugify
                              })
                            }}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                          </div>
                          </div>
                         
                        }

                        <div className="row">
                          <div className="col-sm-6">
                            <label>Featured Image</label>
                            <div>
                            <button
                            //id="formSubmitBtn"
                            className="btn green uppercase"
                            // disabled={this.props.submitting}
                            onClick={(e) => {this.openModal(e)}}
                            >
                            Choose Image
                            </button>
                            </div>
                          </div>
                          <div className="col-sm-6">
                          {this.state.imageRawData && (
                          <img style={{ marginLeft: '20%'}} src={this.state.imageRawData} width={500} height={200} />
                        )}
                        {this.state.imageSelectedFromLibrary && (
                          <img style={{ marginLeft: '20%'}} src={this.state.featuredImagefromLibraryURL} width={500} height={200} />
                        )}
                        {this.state.image ? (
                          <div>
                            <img
                              src={this.state.image.secure_url}
                              alt=""
                              width={500}
                              height={200}
                              className="img-responsive img-thumbnail i-bot"
                            />
                            <button
                              type="button"
                              className="btn green uppercase"
                              onClick={this.removeImage}
                              disabled={this.state.isDeleting}
                            >
                              Remove
                            </button>
                          </div>
                        ) : null}
                          </div>
                        </div>
                        <br />

                        

                        <Field
                          name="summary"
                          textarea
                          fieldName="Summary"
                          component={RenderField}
                        />
                         {this.state.allPagesForFroala.length > 0 && (
                            <Field
                            name="content"
                            fieldName="Content"
                            type="text"
                            component={FroalaEditorComp}
                            content={this.state.content}
                            allLinksToRender={this.state.allPagesForFroala}
                            />
                         )}
                        {/* <Field name="order" icon='fa fa-info-circle' tooltip={infoOf.order} fieldName="Order" type="number" component={RenderFiled} />   */}

                        <Field
                          name="meta_title"
                          icon="fa fa-info-circle"
                          tooltip={infoOf.meta_title}
                          fieldName="Meta Title*"
                          type="text"
                          component={RenderField}
                          validate={[required]}
                        />
                        <Field
                          name="meta_description"
                          textarea
                          fieldName="Meta Description"
                          component={RenderField}
                          className="form-control"
                        />
                        <Field
                          name="metaTags"
                          icon="fa fa-info-circle"
                          tooltip={infoOf.metaTags}
                          fieldName="Meta Tags"
                          type="text"
                          component={RenderField}
                          // validate={[required]}
                        />
                        <Field
                          name="status"
                          options={[
                            {
                              label: 'Active',
                              value: true,
                            },
                            {
                              label: 'Inactive',
                              value: false,
                            },
                          ]}
                          label="Status"
                          onChange={this.onStatusChange}
                          defaultValue={status ? 'Active' : 'Inactive'}
                          textField="label"
                          valueField="value"
                          component={DropdownComp}
                        />
                        <Field
                          name="blog_post_date"
                          component={DatePicker}
                          fieldName="Blog posted date"
                        />
                        <br />

                        <div className="form-actions" style={{display:"flex"}}>
                          <button
                            type="submit"
                            className="btn green uppercase"
                            disabled={
                              this.props.invalid || this.props.submitting
                            }
                          >
                            {formAction === 'ADD' ? 'Add' : 'Update'}
                          </button>
                          {formAction === 'EDIT' && ((!!orignalData.draft && orignalData.draft !== null && orignalData.draft !== "") || orignalData.isDrafted) && <Link className='btn green uppercase' style={{marginLeft:"4px"}} 
                              to={'/blogs/edit/' + orignalData._id + "/draft"}
                              onClick={this.forceUpdate}
                            >View Draft
                          </Link>}
                          <button style={{marginLeft:"6px"}}
                        className="btn green uppercase ps-1"
                        disabled={!status || ((match.params.draft=="draft" || formAction === 'ADD') && !status)?false:true}
                        onClick={(e)=>{
                          e.preventDefault();
                          this.upsertDraft(window['pagesForm'].values)
                        }}
                      >
                        {'Save as Draft'}
                      </button>
                      <TT tooltip="Status must be inactive to save as draft"><i style={{marginLeft:"4px"}} className="mb-4 fa fa-info-circle"></i></TT>
                      <Banner showBanner={formAction === 'EDIT' && ((!!orignalData.draft && orignalData.draft !== null && orignalData.draft !== "") || orignalData.isDrafted)} css={{ color: "#FFF", backgroundColor: "#ff4545ab", borderRadius: "4px", cursor: "pointer" ,margin:"0px 6px" ,padding:"5px 10px"}}>
                            <div >
                              <Link style={{textDecoration:'none',color:"white"}}
                              to={'/blogs/edit/' + orignalData._id + "/draft"}
                              onClick={this.forceUpdate}
                            >There is a draft content not made live
                            </Link>
                              </div>
                      </Banner>
                        </div>
                      </form>
                    </Tab>
                    {/*<Tab eventKey={2} title="Revisions">
                                    <Revisions
                                        revisions={this.state.revisions}
                                    />
                                </Tab>*/}
                  </Tabs>
                </div>
              </div>
            )}


            <Modal
                isOpen={this.state.modalIsOpen}
                ariaHideApp={false}
                // onAfterOpen={afterOpenModal}
                onRequestClose={this.closeModal}
                style={this.customStyles}
                contentLabel="Example Modal"
              >
                <div>
                 <h2>Choose Image</h2>
                 <FontAwesomeIcon 
                  icon={faWindowClose} 
                  size='2x' 
                  style={{ position: 'absolute', left: '96%', top: '6%'}}
                  onClick={this.closeModal}
                />
                </div>
                <Tabs defaultActiveKey={1} animation={false} id="chooseImageTab">
                <Tab  eventKey={1}
                  title={`Select Image`}>
                <div style={{width: '100%', height:'500px', margin: '10px auto', position: 'relative'}}>
                  <div style={{ height: '100%', margin: 0, overflow: 'auto'}}>
                   <div style={{ maxHeight: '100%'}}>
                  <Gallery images={this.state.setImages} onSelect={this.handleSelect} defaultContainerWidth={200} />
                  </div>
                  {this.state.featuredImagefromLibraryURL && (
                    <div style={{width: '100%', marginTop: '5%', position: 'absolute', bottom: '-53px', left: '2px', right: '2px', height: '50px'}}>
                   <button
                            //id="formSubmitBtn"
                            className="btn green uppercase"
                            // disabled={this.props.submitting}
                            onClick={this.setFeaturedImageFromLibrary}
                            >
                            Set As Featured Image
                            </button>
                </div>
                  )}
                  </div>
                </div>    
                
                {/* <button onClick={this.closeModal}><FontAwesomeIcon icon={faWindowClose} /></button> */}
                
                  </Tab>
                  <Tab eventKey={2}
                  title={`Upload Image`}>
                    <input
                              type="file"
                              onChange={this.getFile}
                              accept="image/*"
                              className="form-control"
                            />
                        <br />
                        <Field
                              name="img_alt"
                              fieldName="Image Alternative Text"
                              type="text"
                              component={RenderField}
                              className="form-control"
                            />

                        {this.state.imageRawData && (
                          <img src={this.state.imageRawData} width={500} height={200} />
                        )}
                        {/* {imageSize && (
                          <HelpBlock style={{ color: '#e73d4a' }}>
                            {
                              'Please select image with minimum resolution of 1300 x 450 pixels'
                            }
                          </HelpBlock>
                        )} */}
                  </Tab>
                </Tabs>  
                
      </Modal>
      
          </div>
        );
    }

    onChange(event) {
        const { change } = this.props;
        if (event.target.value) {
          if(this.state.isSlugify){
            change("slug", slugify(event.target.value));
          }
            change("meta_title",event.target.value);
        }
    }

  closePagePreviewPopup = () => {
    this.setState({
      isPreview : false
    })
  }

    onStatusChange =async (event,value) =>{
      const {change}=this.props
      if(!value){
        const token = await Session.getSession('token');
        let requestOptions2 = {
          method: 'get',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        };
        let response = await fetch(window.admin.getAllRedirectLink, requestOptions2);
        response = await response.json()
        let isRedirected = response.data.find(e=>e.redirectedUrl.includes("https://stage.housebuyersofamerica.com/blog/"+this.state.orignalData.slug));
        if(isRedirected){
          event.preventDefault()
          this.setState({
            status:true
          })
          change("status","Active")
          toast.error("You cannot make this page inactive until it is used as redirect")
        }else{
          this.setState({
            status:false
          })
          change("status","Inactive")
        }
      }else{
        this.setState({
          status:true
        })
      }
    }
    clickSubmitButton = () =>{
      document.getElementById("formSubmitBtn").click() 
    }
  
    upsertDraft(data) {
      const { match } = this.props;
      this.setState({ isLoading: true });
      /*bind type of Post*/
      console.log(data);
      const metaTagas = [];
      const checkType = typeof data.metaTags;
      if (checkType == 'string') {
        data.metaTags.length &&
          data.metaTags.split(',').forEach((ele) => {
            metaTagas.push(ele);
          });
        data.metaTags = metaTagas;
      }
      data.metaTags = metaTagas;
      data.type = "blog";
      /* Bind the status default true  */
      if(data.status === undefined) {
          data.status = false;
      }
      if(data.status == "Inactive"){
        data.status = false
      }
      else if(data.status == "Active"){
        data.status = true
      }
      delete data.image;
      if (match.params._id) data.editID = match.params._id;

  let formData = new FormData();
  /*add file to request*/
  formData.append('file', this.state.file);
  if(data.status){
    data["draft"] = null
  }

  if(this.state.formAction == "ADD"){
          data["isDrafted"] = true;
          formData.append('data', JSON.stringify(data));
  }else if(!!data && data.isDrafted){
    formData.append('data', JSON.stringify(data));
  }
  else{
    formData.append('data', JSON.stringify({editID:this.state.orignalData._id,draft:JSON.stringify(data)}));
  }

  this.props.dispatch({
    type: 'Admin-upsertBlog',
    data: formData,
    success: (r) => {
      this.props.dispatch(push('/blogs'));
      toast.success(r.message);
      this.setState({ isLoading: false });
      /*log audits for user*/
      this.props.dispatch({
        type: ADMIN_TRACK_AUDIT_LOGS,
        action: {
          comment: 'Modified the content of Blog - ' + r.data.title,
          type: 'audit',
        },
      });
    },
    error: (e) => {
      if (e.errors) {
        this.setState({ isLoading: false });
        e.errors.map((error) => toast(error, { type: 'error' }));
      }
    },
  });
}

    upsertCMS(data) {
        const { match } = this.props;
        const {orignalData} = this.state
        this.setState({ isLoading: true });
        /*bind type of Post*/
        console.log(data);
        const metaTagas = [];
        const checkType = typeof data.metaTags;
        if (checkType == 'string') {
          data.metaTags.length &&
            data.metaTags.split(',').forEach((ele) => {
              metaTagas.push(ele);
            });
          data.metaTags = metaTagas;
        }
        data.metaTags = metaTagas;
        data.type = "blog";
        /* Bind the status default true  */
        if(data.status === undefined) {
            data.status = false;
        }
        if (match.params._id) data.editID = match.params._id;
        if(data.status == "Inactive"){
          data.status = false
        }
        else if(data.status == "Active"){
          data.status = true
        }
    let formData = new FormData();
    /*add file to request*/
    if(this.state.image !==""){
      formData.append('file', this.state.file);
    }else{
      data.image="";
    }
    if(data.status){
      data["draft"] = null
    }
    if(this.state.imageSelectedFromLibrary){
      data['image'] = this.state.chosenImageData;
    }
    data["isDrafted"] = false;
    formData.append('data', JSON.stringify(data));
    if(!this.state.isPreview && !!this.state.status){
      this.setState({
        previewData:{formData:data,formFile:this.state.file,image:this.state.image !== '' ? this.state.image : {secure_url: this.state.featuredImagefromLibraryURL}},
        isPreview:true,
        isLoading:false
      })
      return false
    }
    this.props.dispatch({
      type: 'Admin-upsertBlog',
      data: formData,
      success: (r) => {
        this.props.dispatch(push('/blogs'));
        toast.success(r.message);
        this.setState({ isLoading: false });
        /*log audits for user*/
        this.props.dispatch({
          type: ADMIN_TRACK_AUDIT_LOGS,
          action: {
            comment: 'Modified the content of Blog - ' + r.data.title,
            type: 'audit',
          },
        });
      },
      error: (e) => {
        if (e.errors) {
          this.setState({ isLoading: false });
          e.errors.map((error) => toast(error, { type: 'error' }));
        }
      },
    });
  }

  getFile(e) {
    this.setState({ file: e.target.files[0] });
    let file = e.target.files[0];
    this.toBase64(file)
      .then(result => {
        // file["base64"] = result;
        this.setState({
          imageRawData: result,
          imageSelectedFromLibrary: false
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  getaCMS() {
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let cmsID = match.params._id ? match.params._id : null;
    HTTP.Request('get', window.admin.getAllImages).then(
              (images) => {
                let updatedImages = images.data;
                updatedImages = updatedImages.map(image => {
                  return {...image, src : image.url}
                })
                this.setState({
                  setImages: updatedImages,
                  isLoading: false
                })
              }
            ).catch(error => console.error(error))
    if (cmsID) {
      this.setState({ isLoading: true, formAction: 'EDIT' });
      this.setState({
        isSlugify:false
      })
      HTTP.Request('get', window.admin.getaBlog, { _id: cmsID }).then(
        (result) => {
          if(match.params.draft == "draft" || result.data.data.isDrafted){
            let draftData
            if(result.data.data.isDrafted){
               draftData =result.data.data
            }
            else{
              draftData =JSON.parse(result.data.data.draft)
            }
            const { match, initialize } = this.props;
                this.setState({
                  isLoading: false,
                  status: draftData.status,
                  content: draftData.content ? draftData.content : '',
                  image: draftData.image ? draftData.image : '',
                  orignalData:draftData,
                  revisions: result.data.revisions ? result.data.revisions : [],
                });
                /*set data to form*/
                initialize(draftData);
          }
          else{
            this.setState({
              isLoading: false,
              status: result.data.data.status,
              content: result.data.data.content ? result.data.data.content : '',
              image: result.data.data.image ? result.data.data.image : '',
              orignalData:result.data.data,
              revisions: result.data.revisions ? result.data.revisions : [],
            });
            /*set data to form*/
            initialize(result.data.data);
          }
        },
      );
    }
  }
  removeImage() {
    const { match, change } = this.props;
    /*extract plant id from request*/
    let cmsID = match.params._id ? match.params._id : null,
      request = {
        cmsID,
        image: this.state.image,
      };
    this.setState({ isDeleting: true });
    HTTP.Request('post', window.admin.removeBlogImage, request)
      .then((response) => {
        this.setState({ image: '', isDeleting: false ,file:""});
      })
      .catch((e) => {
        if (e.errors) {
          this.setState({ isDeleting: false });
          e.errors.map((error) => toast(error, { type: 'error' }));
        }
      });
  }
}

//decorate form component
let AddBlog_Form = reduxForm({
  form: 'AddBlog_Form ',
})(AddBlog);
const mapStateToProp = (state) => {
  window['pagesForm']=state.form['AddBlog_Form ']
  var solvedPermissions = {};
  console.log('state.admin.permissions', state.admin.permissions);
  if (
    state.admin.permissions &&
    state.admin.permissions.indexOf('EDIT_CUSTOM_URL') !== -1
  ) {
    solvedPermissions.allowCustomURLEdit = true;
  }
  return solvedPermissions;
};
export default connect(mapStateToProp)(AddBlog_Form);
