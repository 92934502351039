import React, { Component } from 'react';
import { connect } from 'react-redux';
import RenderField from '../common/renderField';
import { Modal, Button, Alert } from 'react-bootstrap';

import { Tabs, Tab } from 'react-bootstrap';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Loader from '../common/loader';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import PageHeader from '../common/pageheader';
import Multiselect from '../common/multi';
import DropdownCompV2 from '../common/dropdown_v2';
import { STATUS } from '../common/options';
import DropdownComp from '../common/DropdownList';
import LocationSearchInput from '../common/address';
import DatePicker from '../common/DateTimePicker';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import HTTP from '../../services/http';
import SortableItem from '../common/imagesDrag';
import Session from '../../services/session';

import {
  required,
  ValidateOnlyAlpha,
  mobileValidate,
  emailValiadte,
} from '../common/fieldValidations';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
const phoneMask = createTextMask({
  pattern: '(999)999-9999',
});

class AddAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      property: '',
      records: [],
      formAction: 'ADD',
      content: '',
      isLoading: false,
      status: true,
    };
    this.upsertProp = this.upsertProp.bind(this);
    this.getProp = this.getProp.bind(this);
    this.changeagent = this.changeagent.bind(this);
    this.closeModel = this.closeModel.bind(this);
    this.updateAgent = this.updateAgent.bind(this);
  }

  async componentDidMount() {
    const {match,change } = this.props;

    this.getProp();
    if(match.params.propGuid){
      this.setState({isLoading:true})
      let token = Session.getSession('token');

      let propRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({ searchKeyword: match.params.propGuid })
        };
       let propResponse =  await fetch(window.admin.getCrmPropertyByGuid, propRequestOptions);
       let crmPropData = await propResponse.json();

      let agentRequestOptions = {
       method: 'POST',
       headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
       body: JSON.stringify({ agentGuid: crmPropData.value[0]._emn_dispositionsconsultantid_value })
       };
      let response =  await fetch(window.admin.searchAgentInCrm, agentRequestOptions);
      let crmAgentData = await response.json();
      if(crmAgentData.value && (crmAgentData.value.length !== 0)){
        change("guid",crmAgentData.value[0].emn_userid)
        change("firstname",crmAgentData.value[0].emn_name.split(" ")[0])
        change("lastname",crmAgentData.value[0].emn_name.split(" ")[1])
        change("email",crmAgentData.value[0].emn_email)
        change("phone_no",crmAgentData.value[0].emn_mobilenumber.replaceAll("-",""))
      }
      this.setState({isLoading:false})
    }
  }

  render() {
    const { handleSubmit,match } = this.props;
    const { isLoading, formAction, status } = this.state;
    return (
      <div className="relative">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {' '}
            <PageHeader
              route={formAction === 'ADD' ? 'Add Disposition Consultant' : 'View Disposition Consultant'}
              parent="Disposition Consultant"
              parentRoute="/disposition-consultant-list"
            />
            <div>
              <Tabs defaultActiveKey={1} animation={false} id="profileTabs">
                <Tab
                  eventKey={1}
                  title={formAction === 'ADD' ? 'Add New Disposition Consultant' : 'View Disposition Consultant'}
                >
                  <form onSubmit={handleSubmit(this.upsertProp)}>
                    <Field
                      name="firstname"
                      fieldName="First Name*"
                      type="text"
                      readOnly = {formAction === 'ADD' ? false : true}
                      component={RenderField}
                      validate={[required]}
                    />{' '}
                    <Field
                      name="lastname"
                      fieldName="Last Name*"
                      type="text"
                      readOnly = {formAction === 'ADD' ? false : true}
                      component={RenderField}
                      validate={[required]}
                    />{' '}
                    <Field
                      name="email"
                      fieldName="Email*"
                      type="text"
                      readOnly = {formAction === 'ADD' ? false : true}
                      component={RenderField}
                      validate={[required, emailValiadte]}
                    />{' '}
                    <Field
                      name="phone_no"
                      fieldName="Mobile Phone*"
                      readOnly = {formAction === 'ADD' ? false : true}
                      type="text"
                      {...phoneMask}
                      component={RenderField}
                      validate={[required]}
                    />{' '}
                    <Field
                      name="guid"
                      fieldName="GUID*"
                      readOnly = {formAction === 'ADD' ? false : true}
                      type="text"
                      component={RenderField}
                      validate={[required]}
                    />
                    <Field
                      name="status"
                      options={STATUS}
                      label="Status"
                      defaultValue={this.state.status ? 'Active' : 'Inactive'}
                      textField="title"
                      disabled={(formAction == "ADD" && !!match &&!!match.params &&!!match.params.guid) ? true :formAction == "EDIT"?true: false}
                      valueField="value"
                      component={DropdownComp}
                    />
                    <br />
                    {formAction === 'ADD'?(                   
                    <div className="form-actions">
                      <button
                        type="submit"
                        className="btn green uppercase"
                        disabled={this.props.invalid || this.props.submitting}
                      >
                        {' '}
                        {formAction === 'ADD' ? 'Add' : 'Update'}{' '}
                      </button>{' '}
                    </div>
                    ):""}
                  </form>{' '}
                </Tab>{' '}
              </Tabs>{' '}
            </div>{' '}
          </div>
        )}
      </div>
    );
  }

  closeModel() {
    /**to close modal */
    this.setState({ show: false });
  }

  upsertProp(data) {
    const { match } = this.props;
    /* this.setState({isLoading: true}); */
    if (match.params._id) data.editID = match.params._id;
    console.log('upsertPropertDetails', data);

    if (data.status == 'false' || data.status == false) {
      HTTP.Request('get', window.admin.getAgentCount, {
        id: data._id,
      }).then((result) => {
        console.log(result);

        if (result.count > 0) {
          console.log('records', result.agent);
          this.setState({
            isLoading: false,
            show: true,
            property: result.count,
            records: result.agent,
          });
        } else {
          this.updateAgent(data);
        }
      });
    } else {
      this.updateAgent(data);
    }
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  async changeagent(data) {
    await HTTP.Request('post', window.admin.updateAgentDetail, data)
      .then((result) => {
        console.log(result);
        // this.setState({
        //     isLoading: false
        // });
        // this.props.dispatch(push("/agent-list"));
        // toast(result.message, {
        //     type: "success"
        // });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
        toast(err.message, {
          type: 'error',
        });
      });

    this.updateAgent(data);
  }

  updateAgent(data) {
    HTTP.Request('post', window.admin.upsertAgent, data)
      .then((result) => {
        console.log(result);
        this.setState({
          isLoading: false,
        });
        this.props.dispatch(push('/disposition-consultant-list'));
        toast(result.message, {
          type: 'success',
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
        toast(err.message, {
          type: 'error',
        });
      });
  }

  getProp() {
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let roleID = match.params._id ? match.params._id : null;

    if (roleID) {
      this.setState({
        isLoading: true,
        formAction: 'EDIT',
      });
      HTTP.Request('get', window.admin.getAgentDetail, {
        id: roleID,
      }).then((result) => {
        this.setState({
          isLoading: false,

          status: result.data.status,
        });

        /*set data to form*/
        initialize(result.data);
      });
    }
  }
}

let agentForm = reduxForm({
  form: 'agentForm',
})(AddAgent);

export default connect()(agentForm);
