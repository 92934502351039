import React, { Component } from 'react';
import { Alert, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import HTTP from '../../../../services/http';
import { ValidateOnlyAlpha } from "../../../common/fieldValidations";
/**COMPONENTS */
import Loader from "../../../common/loader";
import ROW from "./row";




var timer;
class TableComp extends Component {

    constructor(props){
        super(props);
        this.state ={
            array:[],
            isLoading:false,
            searchQuery :'',
            activePage :1,
            totalItemsCount:1
        }
        /**event binding */
        this.getCredentials = this.getCredentials.bind(this);
        this.search = this.search.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

    }

    componentWillMount(){
        this.getCredentials();
    }
    render() {
        const {array ,isLoading} = this.state;
        return (
            <div >
                {isLoading &&<Loader />}

                <div className="portlet light bordered">
                    <div className="portlet-body min-heigh">

                        {/* actions search addnew  */}
                        <div className="table-toolbar">
                            <div className="row">
                                {/* <!-- add new --> */}
                                <div className="col-md-1 col-sm-offset-8">
                                    <div className="btn-group pull-right">
                                        <Link to="/third-party-services/add"><button id="sample_editable_1_new" className="btn sbold green"> Add New
                                        </button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* actions search addnew END */}


                        {/* if list is empty */}
                        {!isLoading && !array.length ? <Alert bsStyle="warning">
                            <strong>No Data Found !</strong>
                        </Alert>:
                            <div style={{ display: "flow-root"}}>
                                <Table responsive striped bordered condensed hover>
                                <thead>
                                    <tr>
                                        <th> Services </th>
                                        <th> Modified On </th>
                                        <th width='15%'> Actions </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {array.map(service => {
                                        return (
                                            <ROW key={service._id} service={service} />
                                        )
                                    })}
                                </tbody>
                            </Table>
                                <div style={{float:"right"}}>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={window.limit}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={3}
                                    onChange={this.handlePageChange}
                              />
                             </div>
                        </div>
                    }
                    </div>
                </div>
            </div>
        );
    }

    /**PAGINATION */
    handlePageChange(eventKey){
        this.setState({ activePage: eventKey });
        this.getCredentials({
            type: 'admin',
            page: eventKey ? eventKey : 1,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
        });
        /**to set query in route */
        this.props.history.push({
            search: '?page=' + eventKey
        })
    }
    /**SEARCH */
    search(e) {
        /**to remove Event Pooling  */
        e.persist();
        let searchedValue = e.target.value;
        let searchError = ValidateOnlyAlpha(searchedValue)
        if (searchError) {
            this.setState({ searchError: searchError });
            return;
        }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', searchError: '' });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({ searchQuery: searchedValue, activePage: 1, searchError: searchError });
        } else {
            this.setState({ searchError: 'Enter minimum 3 characters to search.', searchQuery: '' });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getCredentials({
            type: 'admin',
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery
        });
        }, 1000);
    }

    getCredentials(params={}){
        /**to start Loader */
        this.setState({  isLoading: true })
        HTTP.Request("get",window.admin.getThirdPartyCredentials,params)
        .then((response) =>{
                this.setState({
                    array : response.data.result ? response.data.result: [],
                    isLoading:false
                })
        }).catch((err) => {
            this.setState({
                isLoading: false
            })
            toast(err.message, { type: "error" }) ;
        })

    }
}

export default TableComp ;