import React, { Component } from 'react';
import Moment from "react-moment";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TT from "../../common/tooltip";
import moment from "moment-timezone";

class ROW extends Component {  
    
    render() {
        const {admin,adminID} = this.props;
        return (
            <tr className="odd gradeX" >
                <td> {admin.firstname + " " + admin.lastname} </td>
                <td> {admin.email} </td>
                <td> {admin.mobile ? admin.mobile:"NA"} </td>
                <td> {admin.roleTitle.title} </td>
                <td><Moment format="MM/DD/YY">{moment(admin.created_at).zone("-04:00")}</Moment></td>

                {/* <td><Moment format="MM/DD/YY">{admin.created_at}</Moment></td>             */}
                <td className="center"> <label className={admin.status ? 'label label-info' : 'label label-danger'}>{admin.status ? "Active" : "Inactive"}</label> </td> 
                {(adminID !== admin._id)?<td>                    
                    <TT tooltip="Edit"> <Link to={'/customer-management/edit/' + admin._id} className=" btn btn-xs grey-mint"><i className="fa fa-pencil-square-o" ></i></Link> </TT>
                </td>:
                <td >- </td>
            }
            </tr>
        );
    }
}
/*get props*/
const mapStatesToProps =(state) => {
    return ({
        adminID: state.admin && state.admin.user ? state.admin.user._id : null
    });
}

export default connect(mapStatesToProps)(ROW);
