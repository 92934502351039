import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

/*import component level reducers*/
import admin from "./admin";
import dashboard from "./dashboard";
import taxes from "./taxes";
import renovatedFlyer from './renovated_flyer';
import wholesaleFlyer from './wholesale_flyer';
import logsCount from './logsCount';
import crmlogsCount from './crmlogsCount';

// import users from "./users";

export const reducers = combineReducers({
    routing: routerReducer,
    form: formReducer,
    admin: admin,
    WholesaleFlyer: wholesaleFlyer,
    dashboard: dashboard,
    RenovatedFyler: renovatedFlyer,
    taxes: taxes,
    logsCount: logsCount,
    crmlogsCount: crmlogsCount
});