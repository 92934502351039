export const normalizePhone = (value, previousValue) => {
    if (!value) {
      return value
    }
    const onlyNums = value.replace(/[^\d]/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
       
    return onlyNums.slice(0,15)
  }
  
  export const normalizeBuilt = (value, previousValue) => {
      if(!value){
          return value
      }
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums.slice(0, 4) 
  }

  export const normalizeHoa = (value, previousValue) => {
      if(!value){
          return value
      }

      const onlyNums = value.replace(/[^\d]/g,'')
      return onlyNums.slice(0,5)
  }