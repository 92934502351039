import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import ImageCropper from "../common/ImageCropper";
import { required, ValidateOnlyAlpha } from "../common/fieldValidations";

/**COMPONENT */
import RenderFiled from "../common/renderField";
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multiselect";
import Loader from "../common/loader";
import DropdownComp from "../common/dropdown_v2";
import DropdownComp2 from "../common/DropdownList";
import infoOf from "../common/tooltip-text";

/**CONSTANT DATA */
import { OPTIONS, countyToMarket } from "../common/options";

class AddMarket extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            formAction: "ADD",
            countyToMarketSortByCode: []
        };
        /**event binding  */
        this.formSubmit = this.formSubmit.bind(this);
        this.getAMarket = this.getAMarket.bind(this);
        this.getAgent = this.getAgent.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
    }

    componentWillMount() {
        this.getAgent();
    }
    componentDidMount() {
        this.getAMarket();

    }

    render() {
        const { handleSubmit } = this.props;
        const { isLoading, formAction, status, state, marketImage,countyToMarketSortByCode, stateCounties } = this.state;
        return (
            <div>
                {isLoading ? (
                    <Loader />
                ) : (
                        <div>
                            <PageHeader
                                route={"Edit Market"}
                                parent="County List Management"
                                parentRoute="/market-management-list"
                            />

                            <div className="tab-pane active">
                                <form onSubmit={handleSubmit(this.formSubmit)}>
                                <Field
                                    name="state_code"
                                    textField="state_code"
                                    valueField="state_code"
                                    label="State*"
                                    component={DropdownComp}
                                    onChangeState={this.onChangeState}
                                    placeholder="-select-"
                                    validate={[required]}
                                    options={countyToMarketSortByCode}
                                />
                                    {stateCounties && <Field
                                    name="county"
                                    textField="title"
                                    valueField="title"
                                    label="County*"
                                    component={DropdownComp}
                                    placeholder="-select"
                                    validate={[required]}
                                    options={stateCounties}
                                />}
                                    <Field
                                        name="market"
                                        textField="name"
                                        valueField="name"
                                        label="Market"
                                        component={DropdownComp}
                                        placeholder="-select-"
                                        validate={required}
                                        options={marketImage}
                                    />
                                    <Field
                                        name="status"
                                        options={[
                                            { label: "Active", value: true },
                                            { label: "Inactive", value: false }
                                        ]}
                                        label="Status"
                                        defaultValue={
                                            status ? "Active" : "Inactive"
                                        }
                                        textField="label"
                                        valueField="value"
                                        component={DropdownComp2}
                                    />
                                    <br />
                                    <div className="form-actions">
                                        <button
                                            type="submit"
                                            className="btn green uppercase"
                                            disabled={
                                                this.props.invalid ||
                                                this.props.submitting
                                            }
                                        >
                                            Update
                                    </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
            </div>
        );
    }

    getAMarket(params = {}) {
        /**to start Loader */
        this.setState({ isLoading: true });
        let id = this.props.match.params;
        const { match, initialize } = this.props;
        HTTP.Request("get", window.admin.getmarketView, id)
            .then(result => {

                // console.log("result couny from db", result.data['county'])
                let splitStr = result.data['county'].toLowerCase().split(' ');
                for (let i = 0; i < splitStr.length; i++) {
                    // You do not need to check if i is larger than splitStr length, as your for does that for you
                    // Assign it back to the array
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                }

                // console.log(splitStr.join(' '))
                // result.data['county'] = splitStr.join(' ');
                result.data.county = result.data.userEnteredCounty ? result.data.userEnteredCounty : splitStr.join(' ');

                /*set data to form*/
                const countyToMarketSortByCode = countyToMarket.sort(
                    (stateA,stateB) =>  stateA.state_code > stateB.state_code ? 1 : -1
                );

                this.onChangeState(result.data.state_code, true);
                initialize(result.data);

                this.setState({
                    isLoading: false,
                    image: result.data.image ? result.data.image : "",
                    countyToMarketSortByCode: countyToMarketSortByCode,
                });
            })
            .catch(
                error => toast(error.message, { type: "error" }),
                this.setState({ isLoading: false })
            );
    }

    getAgent() {
        this.setState({ isLoading: true });
        HTTP.Request("get", window.admin.agentsDynamic, {})
            .then(result => {
                console.log("resultAgent", result)
                this.setState({
                    isLoading: false, selectedAgent: result.data ? result.data.agent : [],
                    marketImage: result.data ? result.data.market : []
                })
            })
    }

    onChangeState(event, keepCounty) {
        HTTP.Request("get", window.admin.countyListByState, { state: event })
            .then(result => {
                let countyImageArray = [];
                if (result.data && result.data.result && result.data.result.length) {
                    countyImageArray = result.data.result.map((i) => {
                    return { title: i.COUNTYNAME, _id: i._id };
                    })
                }
                const sortedCounties = countyImageArray.sort(
                    (countyA,countyB) =>  countyA.title > countyB.title ? 1 : -1
                );
                this.setState({
                    stateCounties: sortedCounties
                });
                const { change } = this.props;
                if(!keepCounty) {
                    change('county', null);
                }
            }).catch(err => {
                console.log(err);
            });
    };

    formSubmit(values) {
        this.setState({ isLoading: true });
        values.id = this.props.match.params;
        const { state_code, county, market, status, id } = values;
        const selectedState = countyToMarket.find(state => state.state_code === state_code);
        values.state = selectedState.state;
        values.state_code = state_code.toUpperCase();
        HTTP.Request("put", window.admin.EditMarket, values)
            .then(result => {

                if (result.success === true) {

                    toast(result.message, { type: "success" });
                    this.props.dispatch(push("/market-management-list"));
                } else {
                    toast(result.message, { type: "error" });

                }


                this.setState({ isLoading: false });
                // this.props.dispatch(push("/market-management-list"));
                // toast(result.message, { type: "success" });
            })
            .catch(error => {
                this.setState({ isLoading: false });
                toast(error.message, { type: "error" });
            });
    }






}



//decorate form component
let MarketMang_Form = reduxForm({
    form: "MarkMang_Form"
})(AddMarket);

export default MarketMang_Form;
