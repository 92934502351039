import React, { Component } from "react";
import { HelpBlock, Table } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import { Alert } from "react-bootstrap";
import Loader from '../../common/loader';
import HTTP from '../../../services/http';
import FilterForm from '../../common/filterForm';
import CrmlogsROW from "./crmlogsRow";
import { connect } from "react-redux";

class CrmToMongo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: [],
            isLoading: false,
            searchQuery: '',
            activePage: 1,
            totalItemsCount: 1,
            page: 1,
            noOfRecords: 10,
            logsArray: [],
            isFilter: false

        }
        /**event binding */
        // this.getmongoLogs = this.getmongoLogs.bind(this);
        // this.submitFilters = this.submitFilters.bind(this);
        // // this.exportCsv = this.exportCsv.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

    }
    // componentDidMount() {
    //     this.getmongoLogs()

    // }
    componentWillReceiveProps(nextprops) {
        console.log("nextprops", nextprops);
        this.setState({
            totalItemsCount: nextprops.crmlogsCount
        })
    }

    componentWillUpdate() {
        console.log("update state", this.props);

        if (!isNaN(this.props.crmlogsCount) && this.props.crmlogsCount !=
            this.state.totalItemsCount) {
            this.setState({
                totalItemsCount: this.props.crmlogsCount
            })
        }

    }


    onChange = (value) => {
        this.setState({
            noOfRecords: value.limit
        })

    }


    render() {
        const { logsArray, isLoading, filterData } = this.state;

        return(   
            <div>
                {isLoading && <Loader />}
                <div className="portlet light bordered">
                    <div className="portlet-body">

                        <FilterForm
                            submitFunction={this.submitFilters}
                            reset={this.reset}
                            resetFunction={() => {}}
                            onChange={this.onChange}
                            // searchPlaceholder="Search Crm to Mongo Log(s)"
                            limitComp
                        />
                        {logsArray.length == 0 && this.state.isFilter ? <Alert bsStyle="warning">
                            <strong>No Data Found !</strong>
                        </Alert> :
                            <div style={{ display: "flow-root" }}>
                                <Table responsive striped bordered condensed hover>
                                    <thead>
                                        <tr>
                                            <th width="22%"> Created At </th>
                                            {/* <th width="18%"> Password </th> */}
                                            <th> Number of Records Added </th>
                                            <th> Number of Records Updated </th>

                                        </tr>
                                    </thead>

                                    <tbody>

                                        {/* {array.map(log => { */}

                                        <CrmlogsROW activePage={this.state.activePage} sizePerPage={this.state.noOfRecords} isFilter={this.state.isFilter} filterData={this.state.logsArray} activePage={this.state.activePage} sizePerPage={this.state.noOfRecords} />

                                        {/* })} */}
                                    </tbody>
                                </Table>
                                <div style={{ float: "right" }}>
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={filterData ? filterData.limit : window.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={3}
                                        onChange={this.handlePageChange}
                                    />  </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }

    handlePageChange(eventKey) {
        this.setState({ activePage: eventKey });
        let { filterData } = this.state;
        this.getcrmLogs({
            page: eventKey ? eventKey : 1,
            ...filterData
        });
    }

    submitFilters = (filterData) => {
        this.setState({ filterData, isFilter: true, isLoading: true, activePage: 1 })
        this.getcrmLogs({
            page: 1,
            ...filterData
        });
        this.props.history.push({
            search: '?page=' + 1
        });
    }

    getcrmLogs(data) {
        try {
            HTTP.Request("get", window.admin.CrmtoMongoLog, data).then((response) => {
                this.setState({
                    logsArray: response.data.logs,
                    totalItemsCount: response.data.count,
                    isLoading: false
                })

            })
        }
        catch (e) {
            this.setState({
                isLoading: false
            });
        }
    }

}


function mapstateToProps(state) {
    /**to get value of dropdown */
    console.log("crmlogs file", state);
    const { crmlogsCount } = state
    return { crmlogsCount: crmlogsCount }
}


export default connect(mapstateToProps)(CrmToMongo)