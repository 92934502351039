import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TimePicker from 'material-ui/TimePicker';
import moment from "moment";
import { HelpBlock } from "react-bootstrap";
import './timePicker.css'


class TimePickers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showError: false,
        }
        console.log("message from setting", this.props)
        /**event bind  */
        this.handleChange = this.handleChange.bind(this);
        this._handleInputBlur = this._handleInputBlur.bind(this);
    }

    handleChange(e, date) {
        console.log('timeis ', e, date);
        console.log(moment(date).format('LT'));
        this.props.input.onChange(moment(date).format('LT'));
    }

    _handleInputBlur(e) {
        const { input: { value }, id } = this.props;
        if (!value && id == "listing_date") {
            const { showError } = this.state
            this.setState({ showError: true })
        } else {
            const { showError } = this.state
            this.setState({ showError: false })
        }
    }

    render() {
        const { input: { value }, id, meta: { invalid, error, touched }, fieldName, disabled } = this.props
        const { showError } = this.state;
        let valueParsed = null;
        if (value) {
            valueParsed = moment(value, 'LT').toDate();
        }

        return (
            <div className="section form-group form-material">
                <label>{fieldName}</label>
                <div>
                    <MuiThemeProvider>
                        <TimePicker
                            id={id}
                            disabled={disabled}
                            onChange={this.handleChange}
                            value={valueParsed}
                        />
                    </MuiThemeProvider>
                </div>

                <HelpBlock style={{ color: '#e73d4a' }}>
                    {touched && error ? error : null}
                    {showError && invalid && error && !touched ? error : null}
                    {id == 'sold_date' && !touched && invalid && error ? error : null}
                </HelpBlock>
            </div>
        )
    }
}

export default TimePickers;
