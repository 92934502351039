import React from 'react';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { HelpBlock } from 'react-bootstrap';

export default class MaterialMulti extends React.Component {

    constructor(props) {
        super(props)

        console.log('materialselectedprops', this.props);
        this.state = {
            personName: []
        }

    }

    handleChange = event => {
        this.setState({
            personName: event.target.value
        })

        const inputName =  this.props.input.name? this.props.input.name: '';
        if(this.props.updateMarket) {
            this.props.updateMarket(inputName,event.target.value);
        }
        if(this.props.updateCounty) {
            this.props.updateCounty(event.target.value);
        }
        // setPersonName(event.target.value);
    };

    componentWillMount() {

        setTimeout(() => {
            console.log('this.props', this.props)
            this.setState({
                personName: this.props.selectedValues ? this.props.selectedValues : []
            });
            if(this.props.triggerValidation) {
                this.props.triggerValidation(this.props.selectedValues);
            }
        }, 1000);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.options !== nextProps.options) {
            this.setState(
                {
                    personName: []
                }
            )
        } else {
        }

    }

    render() {
        const { label, input, meta: { error, touched }, options, updateCounty } = this.props;
        const { names, selected, personName } = this.state;

        return (
            <div className="set-error">
                <InputLabel id="demo-mutiple-checkbox-label">{label}</InputLabel>
                {/* {this.props.selectedValues?this.props.selectedValues.join(', '):''} */}
                <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    {...input}
                    value={personName}
                    onChange={this.handleChange}
                    inputProps={{
                        name: 'age',
                        id: 'age-native-simple'
                    }}
                    renderValue={selected => {
                        if(this.props.updateCounty) {
                            let selectedStrings = [];
                        selected.forEach(element => {
                            let index = options.findIndex(opt => opt.id === element);
                            if(index >= 0) {
                                selectedStrings.push(options[index].title);
                            }
                        });
                        return selectedStrings.join(', ');
                        }
                        return selected.join(',')
                    }}
                // MenuProps={MenuProps}
                >
                    {options.map((name, index) => (
                        <MenuItem key={name.id? name.id : index} value={ updateCounty? name.id : name.title}>
                            <Checkbox checked={personName.indexOf(name.id? name.id : name.title) > -1} />
                            <ListItemText primary={name.title} />
                        </MenuItem>
                    ))}
                </Select>
                <HelpBlock style={{ color: '#e73d4a' }}>
                    {touched && error ? error : null}
                </HelpBlock>
            </div>
        )
    }

}