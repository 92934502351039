import React, { Component } from 'react';
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import TT from "../../common/tooltip";
import Modal from '../../common/modal';
import { chmodSync } from 'fs';
import "./rows.css"
import Banner from 'react-js-banner';

class ROW extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    render() {
        const { element } = this.props;

        let replied = <label className='label label-success'>Replied</label>;
        let notReplied = <label className='label label-danger'>Not Replied</label>
        return (
            <tr className={`odd gradeX`} >
                <td> <Link to={'/market-view/' + element._id}>{element.state}</Link></td>
                <td> {element.state_code} </td>
                {/* <td> {this.capitalize(element.county)}</td> */}
                <td> {element.userEnteredCounty ? element.userEnteredCounty : this.capitalize(element.county)}</td>
                <td> {element.market} </td>
                <td className="center"> <label className={element.status ? 'label label-info' : 'label label-danger'}>{element.status ? "Active" : "Inactive"}</label> </td>
                <td><Moment format="MM/DD/YY">{element.updated_at}</Moment></td>
                <td><Moment format="MM/DD/YY">{element.created_at}</Moment></td>
                <td>
                    {/* <TT tooltip="view"><Link to={'/market-view/' + element._id} className=" btn btn-xs btn-outline blue-madison"><i className="fa fa-search"></i></Link></TT> */}
                    <TT tooltip="Edit"><Link to={'/market-edit/' + element._id} className=" btn btn-xs btn-outline blue-madison"><i className="fa fa-pencil"></i></Link></TT>
                    <TT tooltip="Remove"><a className=" btn btn-xs red-mint" onClick={this.showModal}><i className="fa fa-trash no-pointer"></i></a></TT>
                    {this.state.show && <Modal show={true} func={this.deleteRow} closeParentModal={this.closeModal} title={element.userEnteredCounty ? element.userEnteredCounty : element.county} />}
                </td>

            </tr>
        );
    }

    // Captililze county value 
    capitalize = (s) => {
        let splitStr = s.toLowerCase().split(' ');
        for (let i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        // Directly return the joined string
        return splitStr.join(' ');
    }

    //     if (typeof s !== 'string') return ''
    //     return s.charAt(0).toUpperCase() + s.slice(1) 
    // }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow() {
        this.setState({ isLoading: true, show: false });
        /*delete row by call prop func*/
        this.props.delete(this.props.element._id);
    }

    closeModal() {
        this.setState({ show: false });
    }

}


export default ROW;

