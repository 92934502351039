import React from 'react';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import {  HelpBlock } from 'react-bootstrap';
import "./material_multi.css"
export default class MaterialMultiV1 extends React.Component {

    constructor(props){
        super(props)
        this.updateValue = this.updateValue.bind(this);
        console.log('materialselectedprops', this.props.selectedValues)

        this.state = {
              personName : this.props.selectedValues ? this.props.selectedValues : [],
            //   personName :  [1,2],
              optValue : [],
              testValue: ['Buyer', 'Realtor']
        }


    }

      handleChange = event => {
        this.updateValue(event.target.value)     
          this.setState ({
              personName: event.target.value,
          })
      };

        updateValue(event) {
            this.setState ({
                personName: event ? event : []
            })


            let tmpValue= [] 
            this.props.options.map(async value => {
                await event.map(newValue => {
                    if (value._id === newValue) {
                        tmpValue.push(value.title);
                    }
                })
            })

            this.setState ({
                optValue : tmpValue
            })

        }

      componentWillMount(){
        console.log('this.props',this.props)
        // update the selected value when receive the props  

        if (this.props.selectedValues) {
            setTimeout(() => {
            this.updateValue(this.props.selectedValues)      
            }, 1000); 
        }
     }

      componentWillReceiveProps (nextProps) {
        console.log('this.props', this.props);

        // if (this.props.selectedValues) {
        //     setTimeout(() => {
        //     this.updateValue(this.props.selectedValues)      
        //     }, 100); 
        // }
  
      }
    

    render () {
        const {label,valueKey, input , meta: { error, touched }, options,readOnly} = this.props;
        const {names, optValue, personName} = this.state;
        let newVal = []
        console.log('optvalueeeeeee', optValue);
        return (
            <div>
                    <InputLabel id="demo-mutiple-checkbox-label">{label}</InputLabel>
                    <Select style={{margin:0}}
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        className={`${readOnly?"isDisabled":""}`}
                        readOnly={readOnly}
                        multiple
                        {...input}
                        value={personName}
                        onChange={this.handleChange}
                        inputProps={{
                            name: 'age',
                            id: 'age-native-simple'
                          }}
                        // input={<Input /> }
                        renderValue={() => this.state.optValue.join(', ') }
                        // MenuProps={MenuProps}
                        >
                        {
                            options.map((name, index) => (
                                <MenuItem key={index} value={name._id}>
                                <Checkbox checked={personName.indexOf(name._id) > -1} />
                                <ListItemText primary={name.title} />
                                </MenuItem>
                            ))
                        }
                    </Select>
                    <HelpBlock style={{color: '#e73d4a'}}>
                    {touched &&  error ? error : null}
                </HelpBlock>
            </div>
        )
    }

}