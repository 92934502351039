import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { exportData} from "../../api/cms";
import { toast } from 'react-toastify';

/**COMPONENTS */
import PageHeader from "../common/pageheader";
import DateFilter from '../common/filter';
import Switch from '@material-ui/core/Switch';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from 'material-ui/AutoComplete'

import Session from '../../services/session';
import HTTP from '../../services/http';
import { Link } from "react-router-dom";

import moment from 'moment';
// import { alpha } from '@mui/material/styles';

// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from '@mui/icons-material/FilterList';
/**PAGE LEVEL CSS */
import "../../assets/css/profile.min.css";

export default function FixPageIssues(props) {

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [age, setAge] = React.useState('30');
    const [allPagesForFroala, setAllPagesForFroala] = useState([]);
    const [allData, setAllData] = useState([]);
    const [progress, setProgress] = useState(0);
    const [totalNum, setTotalNum] = useState(0);
    const [buttonStatus, setButtonStatus] = useState(true);
    const [searchButtonStatus, setSearchButtonStatus] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [helpText, setHelpText] = useState(false);
 


    // {
    //     faultyURL: '',
    //     urlSelected: '',
    //     slug: '',
    //     status: 'deleted | inactive'
    // }
  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleChange = (event, id) => {
        const correctURL = event.target.value;
        const updatedData = allData?.map(item => item.id === id ? {...item, urlSelected: correctURL} : item);
        setAllData(updatedData);
      };
    
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = rows.map((n) => n.name);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };

    const loadPages = () => {
        const user = Session.getSession('user');
        const regex = /\bhttps?:\/\/\S+\b/g;
        const loginId = user._id;
        const data = {
            filter: 2,
            loginId: loginId,
            order: "updated_at",
            page: 1,
            pageType: "Page",
            searchQuery: "",
            statusFilter: "active",
            type: "page",
            value: "active",
            limit: 500
        };
        const blogData = {
           type: "blog",
           order: "created_at",
           loginId: loginId,
           limit: 500,
           statusFilter: "active",
           label: "Active",
           value: "active"
        }
        HTTP.Request("get", window.admin.getPages, data)
      .then(async response => { 
                const foundContent = response?.data?.records?.map(item => ({content: item.content, slug: item.slug, pageID: item._id, content: item.content}));
                const fractionToUpdate = (100 / foundContent.length).toFixed(2);
                for(let i = 1; i<= foundContent.length; i++){
                    // foundContent.length
                    const match = foundContent[i]?.content?.match(regex)?.map(item => item.split('"')[0]);
                    if(match?.length > 0){
                        for(let j=0; j<= match?.length; j++){
                            try {
                                const foundRes = await fetch(`${match[j]}`);
                                console.log('foundRes.status', foundRes);
                                if(foundRes.status === 404){
                                    const updatedData = {
                                        faultyURL: match[j],
                                        urlSelected: '',
                                        slug: foundContent[i].slug,
                                        status: 'deleted',
                                        id: `${match[j]}${foundContent[i].slug}`,
                                        pageID: foundContent[i].pageID,
                                        content: foundContent[i].content
                                    };
                                    // let foundStoreData = JSON.parse(JSON.stringify(allData));
                                    // foundStoreData = [...foundStoreData, updatedData];
                                    // const storedObj = {
                                    //   tobeStoredData: foundStoreData,
                                    //   timeStamp: moment().unix()
                                    // };
                                    // const stored = localStorage?.setItem('fixPagesObj', JSON.stringify(storedObj));
                                    setAllData(prev => {
                                      const storedObj = {
                                        tobeStoredData: [...prev, updatedData],
                                        timeStamp: moment().unix()
                                      };
                                      const stored = localStorage?.setItem('fixPagesObj', JSON.stringify(storedObj));
                                      return [...prev, updatedData]
                                    });
                                    setTotalNum(prev => prev + 1);
                                } 
                            } catch(e){
                              console.log('error', e)
                                const updatedData = {
                                    faultyURL: match[j],
                                    urlSelected: '',
                                    slug: foundContent[i].slug,
                                    status: 'deleted',
                                    id: `${match[j]}${foundContent[i].slug}`,
                                    pageID: foundContent[i].pageID,
                                    content: foundContent[i].content
                                };
                                setAllData(prev => [...prev, updatedData]);
                                setTotalNum(prev => prev + 1);
                            }
                            
                            // else if(foundRes.status === 200){
                            //   const foundInactive = fetch(process.env.NODE_ENV === 'live' ? `https://www.housebuyersofamerica.com/api/v1/get-static-page-data/testbvvnvbvbvb767676hvv-newwww` : ``);
                            // }
                        }
                        
                    }
                    
                    setProgress(prev => prev + Number(fractionToUpdate));
                }
                
                const createdData = response?.data?.records?.map(item => ({text: item.title, href: process?.env?.NODE_ENV === 'live' ? `https://www.housebuyersofamerica.com/${item.slug}`: `https://stage.housebuyersofamerica.com/${item.slug}`}));
                HTTP.Request("get", window.admin.getBlogs, blogData)
                .then(res => {
                  const createdBlogData = res?.data?.records?.map(item => ({text: item.title, href: process?.env?.NODE_ENV === 'live' ? `https://www.housebuyersofamerica.com/blog/${item.slug}`: `https://stage.housebuyersofamerica.com/blog/${item.slug}`}));
                  if(createdData !== undefined && createdBlogData !== undefined){
                    const totalData = [...createdData, ...createdBlogData];
                    totalData.sort((a, b) => { 
                      let fa = a.text.toLowerCase(),
                          fb = b.text.toLowerCase();
        
                          if (fa < fb) {
                              return -1;
                          }
                          if (fa > fb) {
                              return 1;
                          }
                          return 0;
                      });
                    localStorage.setItem('allLinksFroFroala', JSON.stringify(totalData));
                    setAllPagesForFroala(totalData);
                    setButtonStatus(false);
                    setSearchButtonStatus(false);
                    setShowProgressBar(false);
                  }
                })
                .catch(err => console.error(err))
            })
      .catch(error => { console.error(error) });
       
      }

   useEffect(() => {
    if(localStorage?.getItem('fixPagesObj') !== null) {
      // setSearchButtonStatus(true);
      const allLinksFroFroala = JSON.parse(localStorage?.getItem('allLinksFroFroala'));
      setHelpText(true);
      setAllPagesForFroala(allLinksFroFroala);
      const foundinStore = JSON.parse(localStorage?.getItem('fixPagesObj'));

      const timeStamp = foundinStore?.timeStamp;
      console.log('old', timeStamp);
      const currentTimeStamp = moment().unix();
      console.log('current', currentTimeStamp);
      const timeDifferenceInSeconds = currentTimeStamp - timeStamp;
      const duration = moment.duration(timeDifferenceInSeconds, 'seconds');
      const minutes = duration.minutes();
      console.log('minutes', duration);
      if(timeDifferenceInSeconds > 1800) {
        localStorage.removeItem('fixPagesObj');
        setSearchButtonStatus(false);
      } else {
        const foundinStoreData = foundinStore?.tobeStoredData;
        console.log('foundinStoreData', foundinStoreData)
        setAllData(foundinStoreData);
        setButtonStatus(false);
      }
    }
   }, []);
  
    const handleClick = (event, name) => {
      const selectedIndex = selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
  
      setSelected(newSelected);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };
  
    const isSelected = (name) => selected.indexOf(name) !== -1;
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  
    const visibleRows = React.useMemo(
      () =>
        stableSort(rows, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        ),
      [order, orderBy, page, rowsPerPage],
    );

    const handleFixLink = ({ faultyURL, urlSelected, pageID, content, id }) => {
        if(urlSelected !== ''){
            setButtonStatus(true);
            const updatedContent = content.replace(faultyURL, urlSelected);
            let data = {};
            let formData = new FormData();
            data.editID = pageID;
            data.content = updatedContent;
            formData.append('data', JSON.stringify(data));
            HTTP.Request('post', window.admin.upsertPage, formData)
            .then((result) => {
              let foundFixedData = JSON.parse(JSON.stringify(allData));
              foundFixedData = foundFixedData.filter(item => item.id !== id);
              const storedObj = {
                tobeStoredData: foundFixedData,
                timeStamp: moment().unix()
              };
              localStorage.setItem('fixPagesObj', JSON.stringify(storedObj));
              setAllData(prev => prev.filter(item => item.id !== id))
              setButtonStatus(false);
              toast.success('URL Fixed');
            })
            .catch((err) => {
              console.error(err);
              toast.error('There is some issue');
              setButtonStatus(false);
            });
        } else {
            toast.error('Please select a URL to Fix');
        }
        
    }

    const handleSearchButton = () => {
        setSearchButtonStatus(true);
        setShowProgressBar(true);
        setProgress(0);
        setAllData([]);
        loadPages();
    }

  return (
    
      <div>
        <PageHeader pageTitle="Fix 404 Issues" route="Fix 404 Issues" />
        <div className="profile-content min-height1000">
          <div className="">
            <div style={{ paddingLeft: "15px" }} className='row col-12'>
              {/* {isloading?"Exporting...." : ""} */}
              <div className='d-flex'>
                <h2>Find 404 Page Issues</h2>
                <div>
          <strong style={{color: 'red'}}>Page with Issues</strong> - Depict the actual Page that is broken inside the Page content<br/>
          <strong style={{color: 'red'}}>404 Links</strong> - Depict the broken link that to be replaced <br/>
          <strong style={{color: 'red'}}>Page Link</strong> - Depict the actual editable Page link where it appears <br/>
        </div>
        {helpText && (
 <div style={{color: 'red'}}>
 The following list is cached and will be visible for 30 Mins only, please click on button to refresh the list
</div>
        )}
       
                <Button variant="contained" onClick={handleSearchButton} style={{ marginTop: '10px'}} disabled={searchButtonStatus} color="primary" size='large'>Search Page Issues</Button>
                <div style={{ marginTop: '10px'}}>
                {showProgressBar && (
                    <LinearProgress variant="determinate" value={progress} style={{ height: '10px', borderRadius: '5px'}} />
                )}
                </div>
                
              </div>
              <div className='d-flex'>


              <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {allData?.map((row, index) => {
                const isItemSelected = isSelected(row.slug);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.faultyURL)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      {/* <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      /> */}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      style={{ fontSize: '16px'}}
                    >
                      <Link style={{ fontSize: '16px'}} to={`/pages/edit/${row.pageID}`}>{row.slug}</Link>
                      
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      style={{ fontSize: '16px', color: 'red'}}
                    >
                      {row.faultyURL}
                    </TableCell>
                    <TableCell align="right" style={{ width: '20%', zIndex: '999999999'}}>
                    {/* <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={top100Films}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Movie" />}
                      /> */}
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={row.urlSelected}
                        label="Age"
                        disabled={buttonStatus}
                        onChange={(e) => handleChange(e, row.id)}
                        >
                        {allPagesForFroala?.map((item, index) => (
                            <MenuItem key={index} value={item.href}>{item.text}</MenuItem>
                        ))}
                        
                        </Select>
                    </TableCell>
                    <TableCell align="right">
                    <Button variant="contained" disabled={buttonStatus} onClick={(e) => handleFixLink(row)} color="primary" size='large'>Fix Link</Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>

              </div>
            </div>
          </div>
        </div>
      </div>
   
  
  )

}

function createData(name, calories, fat, carbs, protein) {
    return {
      name,
      calories,
      fat,
      carbs,
      protein,
    };
  }
  
  const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
  ];
  
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Page with Issues',
    },
    {
      id: 'calories',
      numeric: true,
      disablePadding: false,
      label: '404 Links',
    },
    {
      id: 'calories1',
      numeric: true,
      disablePadding: false,
      label: 'Correct Link',
    },
    {
      id: 'carbs',
      numeric: true,
      disablePadding: false,
      label: 'Action',
    },
  ];
  
  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {/* <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
              style={{ fontSize: '20px'}}
            /> */}
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'left' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              // sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={false}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                style={{ fontSize: '20px'}}
              >
                {headCell.label}
                {/* {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null} */}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              //alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
              theme.palette.primary.main
          }),
        }}
      >
        {/* {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Pages with Issues
          </Typography>
        )} */}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              {/* <DeleteIcon /> */}
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              {/* <FilterListIcon /> */}
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };



