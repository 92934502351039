import React, { Component } from 'react';
import { DropdownList } from 'react-widgets';
import { FormGroup, Col, FormFeedback, FormText } from 'reactstrap';
import { HelpBlock } from "react-bootstrap";
import "react-widgets/dist/css/react-widgets.css";
import './dropdown.css';
/**
 *DropdownComp needs following props
 * label :-to show label of dropdown 
 * options:-options of dropdown must be an array
 * name :- by which it will create field
 * defaultValue :- to show default value on dropdown
 * textField :-to show text on dropdown
 * valueField :- corresponding value
 */

class DropdownComp extends Component {
    constructor(props) {
        super(props);

        /**event binding */
        this.updateValue = this.updateValue.bind(this);
        this.onSelectChnage = this.onSelectChnage.bind(this);
    }

    onSelectChnage(event) {
        const { handleImageValueChange } = this.props;
        if (handleImageValueChange) {
            handleImageValueChange(event)
        }
    }
    render() {
        const { input: { name, value }, disabled,options, placeholder, defaultValue, label, textField, valueField, hintText, meta: { touched, error, invalid }, } = this.props;
        return (<div>

            <label>{label}</label>
            <DropdownList
                data={options}
                defaultValue={defaultValue}
                name={name}
                textField={textField}
                valueField={valueField}
                placeholder={placeholder}
                readOnly={disabled}
                onChange={this.updateValue}
                // onSelect={this.onSelectChnage}    
                value={value ? value : undefined}
                className={touched && error ? 'react-select-error' : null}
            />

            <HelpBlock style={{ color: '#e73d4a' }}>
                {touched && error ? error : null}
            </HelpBlock>
            {hintText && <FormText>{hintText}</FormText>}

        </div>

        )
    }

    updateValue(newValue) {
        /** redux onchange method  */
        /**to send only value on server */
        const { valueField } = this.props;
        if (this.props.handleImageValueChange) {
            this.props.handleImageValueChange(newValue[valueField])
        }
        this.props.input.onChange(newValue[valueField]);
    }
}

export default DropdownComp;