import React, { Component } from 'react';
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import Modal from '../../common/modal';
import TT from "../../common/tooltip";
import moment from "moment-timezone";

class ROW extends Component {
    
    constructor(props){
        super(props);

        this.state={
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    
    render() {
        const {cms} = this.props;
        return (
            <tr className="odd gradeX" >
                <td> {cms.firstname + ' '+cms.lastname}</td> 
                <td> {cms.email} </td> 
                <td>{cms.agentName}</td>
                <td>{cms.property}</td>
                <td><Moment format="MM/DD/YY">{moment(cms.created_at).zone("-04:00")}</Moment></td>
{/* 
                <td><Moment format="MM/DD/YY">{cms.created_at}</Moment></td> */}
               {/*  <td>
                    <TT tooltip="Remove"><a className=" btn btn-xs red-mint" onClick={this.showModal} title="View"><i className="fa fa-trash no-pointer"  ></i></a></TT>
                    <TT tooltip="Edit"><Link to={'/property-cms-edit/' + cms._id} className=" btn btn-xs grey-mint" ><i className="fa fa-pencil-square-o"></i></Link></TT>
                    {this.state.show && <Modal show={true} func={this.deleteRow} closeParentModal={this.closeModal} title={cms.title} />}                    
                </td> */}
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow(){
        this.setState({ isLoading: true,show:false });
        /*delete row by call prop func*/
        this.props.delete(this.props.cms._id);        
    }

    closeModal() {
        this.setState({ show: false });
    }

}

export default ROW;

