import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';

/**COMPONENTS */
import Loader from '../../common/loader';
import ROW from './row';
import DateFilter from '../../common/filter';
import { ValidateOnlyAlpha } from '../../common/fieldValidations';
import { HelpBlock, Table } from 'react-bootstrap';
import { DropdownList } from 'react-widgets';

var timer;
class TableComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      array: [],
      isLoading: false,
      searchQuery: '',
      activePage: 1,
      totalItemsCount: 1,
      isCityPage: false,
      statusFilter:"active",
      statusOptions:[
        { label: "Active", value: "active" },
        { label: "Inactive", value: "in-active" },
      ],
    };
    /**event binding */
    this.getPages = this.getPages.bind(this);
    this.filterByDate = this.filterByDate.bind(this);
    this.search = this.search.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.delete = this.delete.bind(this);

  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      // this.resetFilters();
      window.location.reload();
    }
  }

  componentWillMount() {
    let prevLocation = this.props.location.pathname.includes('city-pages');

    this.getPages({ type: 'page', order: 'updated_at' });

    setInterval(() => {
      if (
        prevLocation !== this.props.location.pathname.includes('city-pages')
      ) {
        prevLocation = this.props.location.pathname.includes('city-pages');

        this.getPages({ type: 'page', order: 'updated_at' });
      }
    }, 1000);
    this.filterByDate({value:"active"})
  }
  render() {
    const { array, isLoading, seracherror, totalItemsCount,statusOptions } = this.state;
    var divStyle = {
      padding: '8px 8px 8px',
    };

    return (
      <div className="relative">
        {isLoading && <Loader />}

        <div className="portlet light bordered">
          <div className="portlet-body min-heigh-">
            {/* actions search addnew  */}
            <div className="table-toolbar">
              <div className="row">
                <div className="col-md-9">
                  <DateFilter resetFunction={this.resetFilters} filterByDate={this.filterByDate}></DateFilter>
                </div>
                {/* <div className="col-md-3">
                                    <div className="form-actions filter-actions ">
                                        <label style={divStyle} className={'label label-info bg-blue-steel bg-font-blue-steel'}><strong>Total Count  {totalItemsCount}</strong></label>
                                    </div>
                                </div> */}
              </div>
              <div className="row">
                {/* search */}
                <div className="col-md-3">
                  <div className="btn-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Page(s)"
                      id="search_field"
                      onChange={this.search}
                    />
                    <HelpBlock style={{ color: '#e73d4a' }}>
                      {seracherror ? seracherror : null}
                    </HelpBlock>
                  </div>                                    
                </div>
                <div className="col-md-3">
                                            <DropdownList
                                                data={statusOptions}
                                                defaultValue={undefined}
                                                textField="label"
                                                valueField="value"
                                                name={"fileter"}
                                                placeholder={'---Select----'}
                                                value={this.state.statusFilter ? this.state.statusFilter : undefined}
                                                onChange={this.filterByDate}
                                            />
                                        </div>
                <div className="col-md-1 col-sm-offset-2">
                  <div>
                    <label
                      style={divStyle}
                      className={
                        'label label-info bg-blue-steel bg-font-blue-steel'
                      }
                    >
                      Total Count <strong>-</strong> {totalItemsCount}
                    </label>
                  </div>
                </div>

                {/* <!-- add new --> */}
                <div className="col-md-1 col-sm-offset-4 ">
                  <div className="btn-group">
                    <Link
                      to={
                        this.state.isCityPage ? '/city-pages/add' : '/pages/add'
                      }
                    >
                      <button
                        id="sample_editable_1_new"
                        className="btn sbold green"
                      >
                        {' '}
                        Add New
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* actions search addnew END */}

            {/* if list is empty */}
            {!isLoading && !array.length ? (
              <Alert bsStyle="warning">
                <strong>No Data Found !</strong>
              </Alert>
            ) : (
              <div style={{ display: 'flow-root' }}>
                <Table responsive striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th> Title </th>
                      <th> Meta Title </th>
                      <th> Author </th>
                      <th> Modified On </th>
                      <th> Created On </th>
                      <th> Status </th>
                      <th width="15%"> Actions </th>
                    </tr>
                  </thead>

                  <tbody>
                    {array.map((cms) => {
                      return this.state.isCityPage ? (
                        <ROW
                          key={cms._id}
                          cms={cms}
                          delete={this.delete}
                          isCityPage={true}
                        />
                      ) : (
                        <ROW
                          key={cms._id}
                          cms={cms}
                          delete={this.delete}
                          isCityPage={false}
                        />
                      );
                    })}
                  </tbody>
                </Table>
                <div style={{ float: 'right' }}>
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={window.limit}
                    totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={3}
                    onChange={this.handlePageChange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  /* search data by date */

  filterByDate(data) {
    console.log(data);
    const { end_date, start_date } = data;
    if(data.value == "in-active" || data.value == "active"){
      this.state.statusFilter = data.value
      }
    this.setState({
      start_date: start_date,
      end_date: end_date,
    });
    this.getPages({
      type: 'page',
      order: 'updated_at',
      page: 1,
      searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
      filter: this.state.filter ? this.state.filter : 2,
      statusFilter:this.state.statusFilter,
      ...data,
    });
  }

  resetFilters() {
    this.state.statusFilter="active"
      this.setState({statusFilter:"active", activePage: 1, seracherror: '', searchQuery: '' });
      let searchEle = document.getElementById('search_field');
      if (searchEle) {
          searchEle.value = '';
      }
      this.getPages({
        type: 'page',
        order: 'updated_at',
        page: 1,
        searchQuery:'',
        statusFilter:this.state.statusFilter
      });
  }

  /**PAGINATION */
  handlePageChange(eventKey) {
    console.log(this.state.start_date);
    console.log(this.state.end_date);
    this.setState({ activePage: eventKey });
    this.getPages({
      type: 'page',
      order: 'updated_at',
      page: eventKey ? eventKey : 1,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
    });
    /**to set query in route */
    this.props.history.push({
      search: '?page=' + eventKey,
    });
  }

  delete(id) {
    /*const search = this.props.location.search,
        params = new URLSearchParams(search);

        let page = params.get('page');

        if (this.state.array.length == 1) {page = page - 1;}*/
    let page = 1;

    this.props.dispatch({
      type: 'Admin-deletePage',
      data: { _id: id },
      success: (r) => {
        /**to stop loader */
        this.setState({ isLoading: false });
        /**refresh list after deletion */
        this.getPages({ type: 'page', page: page ? parseInt(page) : 1 });
      },
      error: (e) => {
        this.setState({ isLoading: false });
      },
    });
  }
  /**SEARCH */
  search(e) {
    console.log(this.state.end_date ,this.state.start_date);
    /**to remove Event Pooling  */
    e.persist();
    let searchedValue = e.target.value;
    let seracherror = ValidateOnlyAlpha(searchedValue);
    if (seracherror) {
      this.setState({ seracherror: seracherror });
      return;
    }

    if (!searchedValue.length) {
        this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
            this.props.history.push({
                search: '?page=' + 1
            });
    } else if (searchedValue.length > 2) {
        this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror });
    } else {
        this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '' });
        return;
    }

    clearTimeout(timer);
    timer = setTimeout(() => {
    this.getPages({
        type: 'page',
        order: 'updated_at',
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        page: this.state.activePage ? this.state.activePage : 1,
        searchQuery: this.state.searchQuery,
        filter: 2,
    });
    }, 1000);
  }

  /**to get list of roles */
  getPages(params = {}) {
    /**to start Loader */
    this.setState({ isLoading: true });

    const isCityPage = this.props.location.pathname.includes('city-pages');

    const pageType = isCityPage ? 'CityPage' : 'Page';

    console.log({ state: this.state });

    this.props.dispatch({
      type: 'Admin-getPage',
      data: {
        ...params,
        pageType,
      },
      success: (response) => {
        if(response.data.records){
          response.data.records = response.data.records.sort((a,b)=>{
            if((!!a.draft && a.draft !== null && a.draft !== "") || a.isDrafted){
              return -1
            }
            else{
              return 1
            }
          })
        }
        this.setState({
          array: response.data.records ? response.data.records : [],
          isLoading: false,
          totalItemsCount: response.data.paging.count,
          isCityPage: isCityPage
        });
      },
      error: (e) => {
        this.setState({
          array: [],
          isLoading: false,
          totalItemsCount: 0,
          isCityPage: isCityPage
        });
      },
    });
  }
}

export default TableComp;
