import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Modal from '../../common/modal';
import TT from '../../common/tooltip';
import moment from 'moment-timezone';
import ShowPopup from '../../common/modalpagedelete';
import Session from '../../../services/session';
import { slugify } from '../../../libs/Helper';


class ROW extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      pageLinkedPopup:false,
      linkedPages:[]
    };
    /*bind this with methods*/
    this.showModal = this.showModal.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openPageLinkedPopup = this.openPageLinkedPopup.bind(this);
  }

  render() {
    const { cms } = this.props;
    const {pageLinkedPopup,linkedPages} = this.state
    return (
      <tr className="odd gradeX">
        <td><Link style={cms.isDrafted?{pointerEvents: "none",color:"gray"}:{}}
              to={
                this.props.isCityPage
                  ? '/city-pages/edit/' + cms._id
                  : '/pages/edit/' + cms._id
              }
            >
              {cms.title} 
            </Link> </td>
        <td> {cms.meta_title} </td>
        <td className="center"> {cms._Admin ? cms._Admin.firstname : ''} </td>
        <td>
          <Moment format="MM/DD/YY">
            {moment(cms.updated_at)}
          </Moment>
        </td>
        <td>
          <Moment format="MM/DD/YY">
            {moment(cms.created_at).zone('-04:00')}
          </Moment>
        </td>

        {/* <td><Moment format="MM/DD/YY">{cms.created_at}</Moment></td> */}
        <td className="center">
          {' '}
          <label
            className={cms.status ? 'label label-info' : 'label label-danger'}
          >
            {cms.status ? 'Active' : 'Inactive'}
          </label>{' '}
        </td>
        <td>
          {cms.slug !== "site-map" && <TT tooltip="Remove">
            <a className=" btn btn-xs red-mint" onClick={this.openPageLinkedPopup}>
              <i className="fa fa-trash no-pointer"></i>
            </a>
          </TT>}
           {!cms.isDrafted && (<TT tooltip="Edit">
            <Link
              to={
                this.props.isCityPage
                  ? '/city-pages/edit/' + cms._id
                  : '/pages/edit/' + cms._id
              }
              className=" btn btn-xs grey-mint"
            >
              <i className="fa fa-pencil-square-o"></i>
            </Link>
          </TT>)}
          {(!!cms.draft && cms.draft !== null && cms.draft !== "") || cms.isDrafted ? (<TT tooltip="Draft">
            <Link
              to={
                this.props.isCityPage
                  ? '/city-pages/edit/' + cms._id + "/draft"
                  : '/pages/edit/' + cms._id + "/draft"
              }
              className=" btn btn-xs orange-mint"
            >
              <i className="fa fa-floppy-o"></i>
            </Link>
          </TT>):('')}
          {this.state.show && (
            <Modal
              show={true}
              func={this.deleteRow}
              closeParentModal={this.closeModal}
              title={cms.title}
            />
          )}
        </td>
        {pageLinkedPopup && (
            <ShowPopup
              closeParentModal={this.closePageLinkedPopup}
              message={
                <div>
                  <p>Are you sure you want to Delete ?</p>
                  <p>This action cannot be undone it will delete the page permanently. </p>
                  {linkedPages.length > 0?
                    <div>This page is linked to the following pages.</div>
                    :""
                  }
                {linkedPages.length > 0?
                  linkedPages.map(e=>{
                    return <p style={{cursor:"pointer",margin:"0"}} onClick={()=>{this.openNewTab(window.location.pathname.includes("/city-pages") ? `https://admin.housebuyersofamerica.com/city-pages/edit/${e._id}`:`https://admin.housebuyersofamerica.com/pages/edit/${e._id}`)}}>{e.slug}</p>
                  }):("")
                }
                </div>
              }
              footer={
                <div>
                {linkedPages.length == 0 ?(
                  <button className='btn btn-danger' onClick={this.deleteRow}>Delete</button>
                ):("")
                }
                </div>
            }
            />
  )}
      </tr>
    );
  }

  showModal() {
    /*show popup and confirm before delete*/
    this.setState({ show: true });
  }

  deleteRow() {
    this.setState({ isLoading: true, show: false });
    /*delete row by call prop func*/
    this.props.delete(this.props.cms._id);
  }

  closeModal() {
    this.setState({ show: false });
  }

  openNewTab(link){
    window.open(link, '')
  }

 async openPageLinkedPopup(){
  this.setState({
    isLoading:true
  })
      const token = Session.getSession('token');
      const requestOptions = {
        method: 'post',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
        body: JSON.stringify({ redirectTo: "/"+slugify(this.props.cms.slug)}),
      };
      let response = await fetch(window.admin.getCMSByRedirect, requestOptions);
      this.state.linkedPages = await response.json();
      this.setState({
        linkedPages:this.state.linkedPages.data.map((e)=>{return {slug:e.slug ? "/"+e.slug:"",_id:e._id}}),
        isLoading:false
      })
      this.setState({
        pageLinkedPopup:true,
      })
  }

  closePageLinkedPopup = () => {
    this.setState({
      pageLinkedPopup : false
    })
  }
}

export default ROW;
