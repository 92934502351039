import React, { Component } from 'react';
import { connect } from 'react-redux';
import RenderField from "../common/renderField";
import { Tabs, Tab } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Loader from "../common/loader";
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multi";
import DropdownCompV2 from "../common/dropdown_v2";
import {STATUS} from "../common/options";
import DropdownComp from "../common/DropdownList";
import LocationSearchInput from "../common/address";
import DatePicker from "../common/DateTimePicker";
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import HTTP from "../../services/http";


import { required, ValidateOnlyAlpha,mobileValidate,emailValiadte } from '../common/fieldValidations';

class AddRealtorSignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formAction: "ADD",
            content: "",
            isLoading: false,
            status:false
        }
        this.upsertProp = this.upsertProp.bind(this);
        this.getProp=this.getProp.bind(this);
        this.getAgent = this.getAgent.bind(this);
        
        
    }

    componentDidMount(){
        this.getProp();
        this.getAgent();
    }

   


    

    render() {
        const { handleSubmit } = this.props;
        const { isLoading, formAction,status,marketImage } = this.state;
        return (
            <div className='relative'>

                {isLoading ? <Loader /> : <div><PageHeader route={formAction === "ADD" ? "Add New Realtor" : "Edit Realtor"} parent='Realtor Signup' parentRoute='/realtor-signup-list' />

                    <div  >
                        <Tabs defaultActiveKey={1} animation={false} id="profileTabs" >
                            <Tab eventKey={1} title={formAction === "ADD" ? "Add New Realtor" : "Edit Realtor"}>
                                <form onSubmit={handleSubmit(this.upsertProp)}  >
                                    <Field
                                       name="firstname"
                                       fieldName="First Name*"
                                       type="text"
                                       component={RenderField}
                                       validate={[required]}
                                    />
                                    <Field
                                       name="lastname"
                                       fieldName="Last Name*"
                                       type="text"
                                       component={RenderField}
                                       validate={[required]}
                                    />
                                     <Field
                                       name="email"
                                       fieldName="Email*"
                                       type="text"
                                       component={RenderField}
                                       validate={[required,emailValiadte]}
                                    />
                                    <Field
                                       name="mobile"
                                       fieldName="Phone Number*"
                                       type="text"
                                       component={RenderField}
                                       validate={[required]}
                                    />
                                    <Field
                                        name="market"
                                        textField="name"
                                        valueField="name"
                                        label="Market"
                                        component={DropdownCompV2}
                                        placeholder="-select-"
                                        validate={required}
                                        options={marketImage}
                                    />

                                     <Field name="status"
                                        options={STATUS}
                                        label="Status"
                                        defaultValue={this.state.status ? "Active" : "Inactive"}
                                        textField="title"
                                        valueField="value"
                                        component={DropdownComp}
                                    
                                    />

 
                                    <br />

                                    <div className="form-actions">
                                        <button type="submit" className="btn green uppercase" disabled={this.props.invalid || this.props.submitting}>{formAction === "ADD" ? "Add" : "Update"}</button>
                                    </div>
                                </form>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                }
            </div>

        )
    }

    upsertProp(data) {
        const { match } = this.props;
        
        this.setState({isLoading: true});
        if (match.params._id) data.editID = match.params._id;
        console.log("upsertPropertDetails", data);

        HTTP.Request("post", window.admin.RealtorSignUpupsert, data)
            .then(result => {
                console.log(result)
                this.setState({ isLoading: false });
                this.props.dispatch(push("/realtor-signup-list"));
                toast(result.message, { type: "success" });
            })
            .catch(err => {
                console.log(err)
                this.setState({ isLoading: false });
                toast(err.message, { type: 'error' })
            });

    }

    getAgent() {
        this.setState({ isLoading: true });
        HTTP.Request("get", window.admin.agentsDynamic, {}).then(result => {
          console.log("resultAgent", result);
          this.setState({
            isLoading: false,
            selectedAgent: result.data ? result.data.agent : [],
            marketImage: result.data ? result.data.market : []
          });
        });
      }

    getProp(){
        const { match, initialize } = this.props;
        /*extract plant id from request*/
        let roleID = (match.params._id) ? match.params._id : null;

        if(roleID){
            this.setState({ isLoading: true, formAction:"EDIT"})
            HTTP.Request("get", window.admin.RealtorSignUpOne, { id: roleID})
            .then(result => {
                console.log(result)
                this.setState({ isLoading: false, 
                   
                    status:result.data.status})

                /*set data to form*/
                initialize(result.data);
            })
        }
    }


}

let RealtorSignUpForm = reduxForm({
    form: "RealtorSignUpForm"
})(AddRealtorSignUp)

export default connect()(RealtorSignUpForm);