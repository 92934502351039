import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import List from '../../../common/list';
import TT from '../../../common/tooltip';

class ROW extends Component {
  render() {
    const { role } = this.props;
    const permissionsStyle = {
      maxWidth: '750px',
      wordBreak: 'break-all',
    };

    return (
      <tr className="odd gradeX">
        <td> {role.title} </td>
        <td style={{ ...permissionsStyle }} className="center">
          {' '}
          {role.permissions.map((permission, i) => (
            <List permission={permission} key={i} />
          ))}{' '}
        </td>
        <td>
          <Moment format="MM/DD/YY">
            {moment(role.created_at).zone('-04:00')}
          </Moment>
        </td>
        <td className="center">
          {' '}
          <label
            className={role.status ? 'label label-info' : 'label label-danger'}
          >
            {role.status ? 'Active' : 'Inactive'}
          </label>{' '}
        </td>
        <td>
          {!role.preBuilt ? (
            <TT tooltip="Edit">
              <Link
                to={'/role-management/edit/' + role._id}
                className="btn btn-xs grey-mint"
              >
                <i className="fa fa-pencil-square-o"></i>
              </Link>
            </TT>
          ) : (
            ''
          )}
        </td>
      </tr>
    );
  }
}

export default ROW;
