import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { required, password } from "../common/fieldValidations";
import RenderFiled from "../common/renderField";
import * as QueryString from "query-string";
import Session from "../../services/session";

/**CSS */
import "../../assets/css/login.min.css";
import HTTP from '../../services/http';
import { ADMIN_LOGOUT } from "../common/actions";

class NewUserCreatePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
        this.newUserCreatePassword = this.newUserCreatePassword.bind(this);
        this.getAUser = this.getAUser.bind(this);
    }

    componentWillMount() {
        this.getAUser();
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div className="login">
                <div className="content">
                    <form onSubmit={handleSubmit(this.newUserCreatePassword)} className="login-form">
                        <h3 className="font-green">Create Password</h3>
                        <Field name="newPassword" fieldName="New Password" type="password" component={RenderFiled} validate={[required, password]}></Field>
                        <Field name="cPassword" fieldName="Confirm Password" type="password" component={RenderFiled} validate={[required]}></Field>
                        <div className="form-actions">
                            <button type="submit" className="btn btn-success uppercase pull-right" disabled={this.props.invalid || this.props.submitting}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    getAUser() {
        const queryData = QueryString.parse(this.props.location.search);
        if (queryData && queryData.user && queryData.resetToken) {
            Session.setSession("token", queryData.resetToken);
            Session.setSession("user", { _id: queryData.user });
        }
        HTTP.Request("get", window.admin.getAUser, { id: queryData.user })
            .then(result => {
                console.log("get user result", result);
                Session.clearSession("token");
                Session.clearSession("user");
                Session.clearSession("permissions");
                if (!result.data.pwdToBeCreated) {
                    this.props.dispatch(push("/login"));
                }
            })
            .catch(err => {
                console.log("get user error", err);
                Session.clearSession("token");
                Session.clearSession("user");
                Session.clearSession("permissions");
                this.props.dispatch(push("/login"));
            });
    }

    newUserCreatePassword(data) {
        this.setState({ isLoading: true });
        const queryData = QueryString.parse(this.props.location.search);
        console.log("queryData is =>>>", queryData);
        if (queryData && queryData.user && queryData.resetToken) {
            Session.setSession("token", queryData.resetToken);
            Session.setSession("user", { _id: queryData.user });
        }
        HTTP.Request("post", window.admin.newUserCreatePassword, data)
            .then(result => {
                toast(result.message, { type: "success" });
                Session.clearSession("token");
                Session.clearSession("user");
                Session.clearSession("permissions");
                this.props.dispatch(push("/login"));
            })
            .catch(err => {
                Session.clearSession("token");
                Session.clearSession("user");
                Session.clearSession("permissions");
                console.log("err while creating password", err);
                this.setState({ isLoading: false });
                if (err.message) {
                    toast(err.message, { type: "error" });
                } else {
                    toast("Link has been expired or is invalid", { type: "error" });
                    this.props.dispatch(push("/login"));
                }
            });
    }
}

let create_password_form = reduxForm({
    form: "create_password",
    validate: function (values) {
        const errors = {};
        if (values.cPassword !== values.newPassword) errors.cPassword = "Password mismatch";
        return errors;
    }
})(NewUserCreatePassword);

export default create_password_form;