import React, { Component } from 'react';
import Moment from "react-moment";
import moment from "moment-timezone";
import HTTP from '../../../services/http';

import { connect } from "react-redux";
import { crmlogsCount } from '../../common/actions';

class PropertySynclogsROW extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            logsArray: [],
            noOfRecords: 0
        }
        /*bind this with methods*/

    }

    componentWillReceiveProps(nextProps) {
        let data = {
            page: nextProps.activePage,
            size: nextProps.sizePerPage
        }
        if (!nextProps.isFilter) {
            this.getcrmLogs(data);
        }
    }

    componentWillMount() {
        let data = {
            page: this.props.activePage,
            size: this.props.sizePerPage
        }
        if (!this.props.isFilter) {
            this.getcrmLogs(data)
        }
    }
    render() {
        console.log("crmlogsArray ", this.state.logsArray)

        let result = (this.props.filterData && this.props.filterData.length) ?
            this.props.filterData && this.props.filterData.map(log => {
                return (
                    <tr key={log._id}>
                        <td>
                            {moment(log.created_at).format("MM/DD/YY h:mm:ss a")}
                        </td>
                        <td>{log.payload}</td>
                        <td>{log.syncType}</td>
                    </tr>
                )
            }) :

            this.state.logsArray && this.state.logsArray.map(log => {
                return (
                    <tr height="12px" key={log._id}>
                        <td>
                            {moment(log.created_at).format("MM/DD/YY h:mm:ss a")}
                        </td>
                        <td style={{wordBreak:'break-word', fontSize: "12px",overflow: 'auto' , height: '50px'}}>{log.payload}</td>
                        <td>{log.syncType}</td>
                    </tr>
                )
            })
        return (
            <React.Fragment>
                {result}
            </React.Fragment>
        )
    }



    // get Mongo logs
    getcrmLogs(data) {

        console.log("this.props.dispatch ", this.props.dispatch)

        console.log("getmongoLogs", this.props.activePage)

        console.log("data", data)

        //     console.log("cron time reponse", response)
        try {
            HTTP.Request("get", window.admin.getPropertySyncLogs, data).then((response) => {
                console.log("crm to mongo logs", response)
                // this.state.logsArray.push(response.data)
                this.setState({
                    logsArray: response.data.logs,
                    noOfRecords: response.data.count,
                })

                this.props.dispatch({
                    type: crmlogsCount,
                    params: response.data.count
                });

            })
        }
        catch (e) {
            console.log("erro is here", e)
        }
    }


}

export default connect()(PropertySynclogsROW);
