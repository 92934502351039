import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import { Tabs, Tab } from "react-bootstrap";
import DropdownCompV2 from "../common/dropdown_v2";
import { required, ValidateOnlyAlpha, minLengthReview } from "../common/fieldValidations";
import * as _ from "lodash";

/**COMPONENT */
import RenderField from "../common/renderField";
import PageHeader from "../common/pageheader";
import Loader from "../common/loader";
import DropdownComp from "../common/DropdownList";
import Revisions from "./element/revisions";
/**CONSTANT DATA */
import { STATUS, RatingStars, stateList } from "../common/options";
import { ADMIN_TRACK_AUDIT_LOGS } from "../common/actions";
class upsertTestimonial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            revisions: [],
            isLoading: false,
            formAction: "ADD",
            status: true,
            rating: null,
            address: ""
        };

        /**event binding  */
        this.upsertCMS = this.upsertCMS.bind(this);
        this.getaCMS = this.getaCMS.bind(this);
    }

    componentWillMount() {
        this.getaCMS();
    }

    handleSelect(address) {
        console.log(address);
    }

    render() {
        const { handleSubmit } = this.props;
        const { isLoading, formAction } = this.state;
        console.log("stateList is =>>>", stateList);
        const orderedStateList = _.orderBy(stateList, 'title', 'asc');
        console.log("orderedStateList =>>>", orderedStateList);
        return (
            <div>
                {isLoading ? (
                    <Loader />
                ) : (
                        <div>
                            <PageHeader
                                route={
                                    formAction === "ADD"
                                        ? "Add Review"
                                        : "Edit Review"
                                }
                                parent="Reviews"
                                parentRoute="/reviews"
                            />

                            <div className="tab-pane active">
                                <Tabs
                                    defaultActiveKey={1}
                                    animation={false}
                                    id="profileTabs"
                                >
                                    <Tab
                                        eventKey={1}
                                        title={
                                            formAction === "ADD"
                                                ? "Add New "
                                                : "Edit "
                                        }
                                    >
                                        <form
                                            onSubmit={handleSubmit(this.upsertCMS)}
                                        >
                                            <Field
                                                name="title"
                                                fieldName="Title*"
                                                type="text"
                                                component={RenderField}
                                                validate={[required]}
                                            />
                                            <Field
                                                name="name"
                                                fieldName="Name*"
                                                type="text"
                                                component={RenderField}
                                                validate={[
                                                    required,
                                                    ValidateOnlyAlpha
                                                ]}
                                            />

                                            {/* <Field
                                            name="address"
                                            type="text"
                                            value={this.state.address}
                                            placeholder="Enter address"
                                            component={LocationSearchInput}
                                            onChange={this.handleSelect}
                                            validate={required}
                                        /> */}
                                            <div className="row">
                                                <div className="col-sm-6">

                                                    <Field
                                                        name="address.city"
                                                        type="text"
                                                        fieldName="City*"
                                                        placeholder="Enter City"
                                                        component={RenderField}
                                                        validate={[required]}
                                                    />

                                                </div>
                                                <div className="col-sm-6">
                                                    <Field
                                                        name="address.state"
                                                        textField="title"
                                                        valueField="value"
                                                        label="State*"
                                                        component={DropdownCompV2}
                                                        placeholder="-select-"
                                                        validate={[required]}
                                                        onChange={this.hanldeCategory}
                                                        options={orderedStateList}
                                                    />
                                                </div>
                                            </div>



                                            <Field
                                                name="content"
                                                textarea
                                                fieldName="Message*"
                                                type="text"
                                                component={RenderField}
                                                validate={[required, minLengthReview]}
                                            />
                                            <Field
                                                name="rating"
                                                placeholder = "-select-"
                                                options={RatingStars}
                                                defaultValue={
                                                    this.state.rating
                                                        ? this.state.rating
                                                        : null
                                                }
                                                label="Rating*"
                                                textField="title"
                                                valueField="value"
                                                component={DropdownComp}
                                                validate = {
                                                    [required]
                                                  }
                                            />
                                            {/* <Field
                                                name="order"
                                                icon="fa fa-info-circle"
                                                tooltip={infoOf.order}
                                                fieldName="Order"
                                                type="number"
                                                component={RenderField}
                                            /> */}
                                            <Field
                                                name="status"
                                                options={STATUS}
                                                label="Status"
                                                defaultValue={
                                                    this.state.status
                                                        ? "Active"
                                                        : "Inactive"
                                                }
                                                textField="title"
                                                valueField="value"
                                                component={DropdownComp}

                                            />
                                            <br />
                                            <div className="form-actions">
                                                <button
                                                    type="submit"
                                                    className="btn green uppercase"
                                                    disabled={
                                                        this.props.invalid ||
                                                        this.props.submitting
                                                    }
                                                >
                                                    {formAction === "ADD"
                                                        ? "Add "
                                                        : "Update"}
                                                </button>
                                            </div>
                                        </form>
                                    </Tab>
                                    <Tab eventKey={2} title="Revisions">
                                        <Revisions
                                            revisions={this.state.revisions}
                                        />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    )}
            </div>
        );
    }

    upsertCMS(data) {

        const { match } = this.props;
        this.setState({ isLoading: true });
        /*bind type of Post*/
        if(data.status === undefined) {
            data.status = true;
        }
        data.type = "reviews";
        if (match.params._id) data.editID = match.params._id;

        let formData = new FormData();
        /*add file to request*/
        formData.append("file", this.state.file);
        formData.append("data", JSON.stringify(data));

        this.props.dispatch({
            type: "Admin-upsertReview",
            data: formData,
            success: r => {
                this.props.dispatch(push("/reviews"));
                toast.success(r.message);
                this.setState({ isLoading: false });

                /*log audits for user*/
                this.props.dispatch({
                    type: ADMIN_TRACK_AUDIT_LOGS,
                    action: {
                        comment:
                            "Modified the content of Testimonial - " +
                            r.data.title,
                        type: "audit"
                    }
                });
            },
            error: e => {
                if (e.errors) {
                    e.errors.map(error => toast(error, { type: "error" }));
                }
            }
        });
    }

    getaCMS() {
        const { match, initialize } = this.props;
        /*extract plant id from request*/
        let cmsID = match.params._id ? match.params._id : null;

        if (cmsID) {
            this.setState({ isLoading: true, formAction: "EDIT" });
            HTTP.Request("get", window.admin.getaReview, { _id: cmsID }).then(
                result => {
                    this.setState({
                        isLoading: false,
                        status: result.data.data.status,
                        rating: result.data.data.rating,
                        address: result.data.data.address
                            ? result.data.data.address.address
                            : "",
                        revisions: result.data.revisions
                            ? result.data.revisions
                            : []
                    });
                    /*set data to form*/
                    initialize(result.data.data);
                }
            );
        }
    }
}

//decorate form component
let upsertTestimonial_Form = reduxForm({
    form: "testimonial_Form"
})(upsertTestimonial);

export default upsertTestimonial_Form;
