import React, { Component } from "react";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import Moment from "react-moment";
import { Panel, Table } from "react-bootstrap";
import moment from "moment-timezone";
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

/**COMPONENT */
import PageHeader from "../common/pageheader";
import Loader from "../common/loader";


class ViewGetOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            leadId: '',
            isLoading: false,
            status: true,
            market: {},
            adrs:"",
            dataToCrm:{},
            
        };
        /**event binding  */
        this.getList = this.getList.bind(this);
        this.printDataToCrmLog = this.printDataToCrmLog.bind(this);
        this.sendDataToCrm = this.sendDataToCrm.bind(this);
        this.handleCRMJSONChange = this.handleCRMJSONChange.bind(this);

    }

    componentWillMount() {
        this.getList();
    }
    render() {
        const { isLoading, market, status, adrs, formattedCrmData } = this.state;
        return (
            <div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <PageHeader
                            route={"View Lead"}
                            parent="Incoming Leads"
                            parentRoute="/get-offer-list"
                        />
                        <div className="tab-pane active">
                            {/* payment details  start */}
                            <div>
                                <Panel bsStyle="info">
                                    <Panel.Heading style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>View Lead<button type="button"  className="btn green uppercase" onClick={this.sendDataToCrm}>Submit To Crm</button></Panel.Heading>
                                    <Panel.Body>
                                        <Table striped bordered condensed hover>
                                            <tbody>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>First Name</strong>
                                                    </td>
                                                    <td>{market.firstname}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>
                                                            Last Name
                                                        </strong>
                                                    </td>
                                                    <td>{market.lastname}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Day Time Mobile</strong>
                                                    </td>
                                                    <td>{market.day_time_mobile}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Mobile</strong>
                                                    </td>
                                                    <td>{market.mobile ?market.mobile: "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Email</strong>
                                                    </td>
                                                    <td>{market.email}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Address</strong>
                                                    </td>
                                                    <td>{adrs ? adrs : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Latitude</strong>
                                                    </td>
                                                    <td>{market.address ? market.address.lat : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Longitude</strong>
                                                    </td>
                                                    <td>{market.address ? market.address.lng : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>County</strong>
                                                    </td>
                                                    <td>{market.address ? market.address.county : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Market</strong>
                                                    </td>
                                                    <td>{market.market ? market.market : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>
                                                            Date
                                                        </strong>
                                                    </td>
                                                    <td>

                                                   < Moment format="MM/DD/YY">{moment(market.created_at).zone("-04:00")}</Moment>
                                                          {' '}  {moment(market.created_at).zone("-04:00").format('h:mm:ss a')}
                                                        {/* <Moment format="MM/DD/YY hh:mm:ss A">
                                                                {market.ts ? market.ts : market.created_at}
                                                        </Moment> */}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>UTM Medium</strong>
                                                    </td>
                                                    <td>{market.medium ? market.medium : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>UTM Campaign</strong>
                                                    </td>
                                                    <td>{market.campaign ? market.campaign : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>UTM Keyword</strong>
                                                    </td>
                                                    <td>{market.keyword ? market.keyword : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>UTM Search Engine</strong>
                                                    </td>
                                                    <td>{market.searchengine ? market.searchengine : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>TCPA Agreed On</strong>
                                                    </td>
                                                    <td>
                                                            {market.tcpa_agreed_on ? <>< Moment format="MM/DD/YY">{moment(market.tcpa_agreed_on).zone("-04:00")}</Moment>
                                                          {' '}  {moment(market.tcpa_agreed_on).zone("-04:00").format('h:mm:ss a')}</> : "NA"}
                                                   
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>TCPA Checkbox</strong>
                                                    </td>
                                                    <td>{market.tcpa_checkbox ? market.tcpa_checkbox : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Data to CRM</strong>
                                                    </td>
                                                    <td><Editor
                                                        value={this.state.dataToCrm}
                                                        onChange={this.handleCRMJSONChange}
                                                    /></td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>CRM Response</strong>
                                                    </td>
                                                    <td>{market.dynamciAPIResponse ? JSON.stringify(market.dynamciAPIResponse): 'NA'}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <div style={{display:"flex", alignItems: 'center', justifyContent: 'flex-end'}}>
                                            <button type="button"  className="btn green uppercase" onClick={this.updateLead}>Save</button>
                                        </div>
                                        
                                    </Panel.Body>
                                </Panel>
                            </div>
                            {/* payment details  end */}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    printDataToCrmLog(dataToCrm) {
        let formattedCrmData = [];
        Object.keys(dataToCrm).map((key) => {
            formattedCrmData.push(<p key={key}>{key} : {dataToCrm[key]}</p>);
        });
        return formattedCrmData;
    }

    getList(params = {}) {
        /**to start Loader */
        this.setState({ isLoading: true });
        let id = this.props.match.params;
        HTTP.Request("get", window.admin.getanOfferView, id)
            .then(result => {
                let adrs = [];
                let formattedCrmData = [];
                
                if (result && result.data && result.data.address && result.data.address.route) adrs.push(result.data.address.route);
                if (result && result.data && result.data.address && result.data.address.unit_no) adrs.push(result.data.address.unit_no);
                if (result && result.data && result.data.address && result.data.address.locality) adrs.push(result.data.address.locality);

                formattedCrmData = this.printDataToCrmLog(result.data.dataToCrm);
                this.setState({
                    leadId: result.data._id,
                    isLoading: false,
                    market: result.data,
                    adrs : adrs.join(" "),
                    formattedCrmData: formattedCrmData,
                    dataToCrm: result.data.dataToCrm
                });
            })
            .catch(err => this.setState({ isLoading: false }));
    }

    sendDataToCrm = ()=>{
        this.setState({
            isLoading:true
        })
        HTTP.Request("post", `${window.admin.sendDataToCrm}?leadId=${this.state.leadId}`, this.state.dataToCrm)
            .then(result => {
                this.setState({
                    isLoading:false
                })
                if(result.status){
                    toast.success(result.message)
                }else{
                    toast.error(result.message)
                }
            })
    }

    handleCRMJSONChange = (data) => {
        this.setState({
            dataToCrm: data
        })
    }

    updateLead = () => {
        this.setState({
            isLoading:true
        })
        HTTP.Request("post", window.admin.updateLead, {
            _id: this.state.leadId,
            dataToCrm: this.state.dataToCrm
        })
            .then(result => {
                this.setState({
                    isLoading:false
                })
                if(result.success){
                    toast.success(result.message)
                }else{
                    toast.error(result.message)
                }
            })
    }
}

export default ViewGetOffer;
