import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import HTTP from '../../../services/http';
import { Link } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';

/**COMPONENTS */
import Loader from '../../common/loader';
import ROW from './row';

import { ValidateOnlyAlpha } from '../../common/fieldValidations';
import { HelpBlock, Table } from 'react-bootstrap';

var timer;
class TableComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      array: [],
      isLoading: false,
      searchQuery: '',
      activePage: 1,
      totalItemsCount: 1,
    };
    /**event binding */
    this.getRoles = this.getRoles.bind(this);
    this.search = this.search.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.moveUp = this.moveUp.bind(this);
    this.moveDown = this.moveDown.bind(this);
    this.deleteFooterLink = this.deleteFooterLink.bind(this);
  }

  componentWillMount() {
    this.getRoles({ type: 'admin' });
  }
  render() {
    const { array, isLoading, seracherror } = this.state;
    return (
      <div>
        {isLoading && <Loader />}

        <div className="portlet light bordered">
          <div className="portlet-body min-heigh">
            {/* actions search addnew  */}
            <div className="table-toolbar">
              <div className="row">
                {/* <!-- search --> */}
                <div className="col-md-3">
                  {/* <div className="btn-group pull-right">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Footer Link(s)"
                      onChange={this.search}
                      id="search_field"
                    />
                    <HelpBlock style={{ color: '#e73d4a' }}>
                      {seracherror ? seracherror : null}
                    </HelpBlock>
                  </div> */}
                </div>

                {/* <!-- add new --> */}
                <div className="col-md-1 col-sm-offset-8">
                  <div className="btn-group pull-right">
                    <Link to="/footer-pages/add">
                      <button
                        id="sample_editable_1_new"
                        className="btn sbold green"
                      >
                        {' '}
                        Add New
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* actions search addnew END */}

            {/* if list is empty */}
            {!isLoading && !array.length ? (
              <Alert bsStyle="warning">
                <strong>No Data Found !</strong>
              </Alert>
            ) : (
              <div style={{ display: 'flow-root' }}>
                <Table responsive striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th> State Page </th>
                      <th> Position </th>
                      {/* <th> Created On </th>
                      <th> Updated On </th> */}
                      <th>Status</th>
                      <th width="20%"> Actions </th>
                    </tr>
                  </thead>

                  <tbody>
                    {array.map((role) => {
                      return <ROW moveUp={this.moveUp} deleteFooterLink={this.deleteFooterLink} totalCount={array.length} moveDown={this.moveDown} key={role._id} role={role} />;
                    })}
                  </tbody>
                </Table>
                {/* <div style={{ float: 'right' }}>
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={window.limit}
                    totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={3}
                    onChange={this.handlePageChange}
                  />
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  /**PAGINATION */
  handlePageChange(eventKey) {
    this.setState({ activePage: eventKey });
    this.getRoles({
      type: 'admin',
      page: eventKey ? eventKey : 1,
      searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
    });
    /**to set query in route */
    this.props.history.push({
      search: '?page=' + eventKey,
    });
  }
  /**SEARCH */
  search(e) {
    /**to remove Event Pooling  */
    e.persist();
    let searchedValue = e.target.value;
    let seracherror = ValidateOnlyAlpha(searchedValue);
    if (seracherror) {
      this.setState({ seracherror: seracherror });
      return;
    }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
            this.props.history.push({
                search: '?page=' + 1
            });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror });
        } else {
            this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '' });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getRoles({
            type: 'admin',
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery
        });
        }, 1000);
    }

  /**to get list of roles */
  getRoles(params = {}) {
    /**to start Loader */
    this.setState({ isLoading: true });
    HTTP.Request('get', window.admin.getFooterLinks, params).then((response) => {
      this.setState({
        array: response.data.sort((a,b)=>Number(a)-Number(b)),
        isLoading: false,
        totalItemsCount: response.data.length ,
      });
    });
  }
  moveUp(data){
    this.setState({
      isLoading:true
    });
    HTTP.Request("post", window.admin.moveUpFooterLinks, data)
    .then(result => {
      this.getRoles({ type: 'admin' });
    })
    .catch(err => {
        if (err && err.errors.length > 0) err.errors.map(message => toast(message, { type: "error" }))
        else toast(err.message, { type: 'error' })
    });
  }
  
  moveDown(data){
    this.setState({
      isLoading:true
    });
    HTTP.Request("post", window.admin.moveDownFooterLinks, data)
    .then(result => {
      this.getRoles({ type: 'admin' });
    })
    .catch(err => {
        if (err && err.errors.length > 0) err.errors.map(message => toast(message, { type: "error" }))
        else toast(err.message, { type: 'error' })
    });
  }

  deleteFooterLink(id,position){
    HTTP.Request('get', window.admin.deleteFooterLinks, {id:id,position:position}).then((response) => {
      this.getRoles()
    });
  }
}

export default TableComp;
