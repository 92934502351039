import React from "react";
import { FormGroup, FormControl, HelpBlock } from "react-bootstrap";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";
import './dropdown.css';

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: "" };
        //this.fillFormFields = this.fillFormFields.bind(this);
    }

    handleChange = address => {
        this.setState({ address });
        this.props.input.onChange(address);
    };


    render() {
        const {
            input,
            id,
            placeholder,
            address,
            meta: { asyncValidating, touched, error },
            addressValidate,
            className,
            onChange,
            onSelect,
            label, meta
        } = this.props;
        const options = {
            types: ['address'],
            componentRestrictions: { country: 'usa' }
        }
        const autocompleteItem = ({suggestion}) => (<div>{suggestion.replace(", USA", "")}</div>)



        return (
            <div className={touched && error ? 'street-address-error' : null} >
                <lable>{label}</lable>
                <PlacesAutocomplete
                    clearItemsOnError={true} onSelect={onSelect}
                    classNames={{ autocompleteContainer: 'autocomplete-container', input: `form-control ${className ? className : ''} ${!meta.touched ? null : (meta.error && meta.touched ? 'street-address-error' : null)}   ${meta.asyncValidating ? 'async-validating' : ''}` }}
                    inputProps={{ ...input, placeholder: placeholder }} options={options}
                    autocompleteItem={autocompleteItem} />

                <HelpBlock>
                    <span style={{ color: "#e73d4a" }}>{touched && error ? error : null}</span>
                </HelpBlock>
            </div>

        )
    }
}

export default LocationSearchInput;
