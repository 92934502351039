import { createNumberMask } from 'redux-form-input-masks';
import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Button} from 'reactstrap';

import { required, yearBuiltValidation } from '../../common/fieldValidations';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { normalizeBuilt, normalizePhone} from './../numberValidation';
import LocationSearchInput from '../../common/address';
import RenderFieldV1 from '../../common/renderFieldV1';
import DropdownCompV2 from '../../common/dropdown_v2';
import DatePicker from '../../common/DateTimePicker';
import RenderField from '../../common/renderField';
import { slugify } from '../../../libs/Helper';
import {BED, BATH} from '../../common/options';
import HTTP from '../../../services/http';

const currencyMask = createNumberMask({
  prefix: '',
  suffix: '',
  allowEmpty: true,
  locale: 'en-US',
});

let readOnlyChecker = true,
  zipCodeChecker = true,
  cityCodeChecker = true;

class CreateNewComarables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisable: false,
    };
  }

  componentDidMount() { }

  fillFormFields(address) {
    const { change } = this.props;
    console.log('props values', this.props);
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    if (address.length > 0) {
      const obj = {};
      let address_components = address[0].address_components;
      change(
        'street_address',
        address[0].formatted_address.replace(', USA', ''),
      );
      change(
        'slug',
        slugify(address[0].formatted_address.replace(', USA', '')),
      );
      console.log(address_components);
      for (var i = 0; i < address_components.length; i++) {
        var addressType = address_components[i].types[0];
        var checkState = address_components[i].types[1];
        if (componentForm[addressType]) {
          var val = address_components[i][componentForm[addressType]];
          obj[addressType] = val;

          if (addressType === 'administrative_area_level_1') {
            change('state', val);
            if (checkState === 'political') {
              let state_value = address_components[i]['short_name'];
              change('state_code', state_value);
            }
          }
          if (addressType === 'locality') {
            cityCodeChecker = true;
            change('city', val);
          }
          if (!document.getElementsByClassName("comparableForm")[0].querySelector("input[name='city']").value) {
            cityCodeChecker = false;
          }

          if (addressType === 'country') {
            change('country', val);
          }

          if (addressType === 'postal_code') {
            zipCodeChecker = true;
            change('zipcode', val);
          } else {
            zipCodeChecker = false;
          }
        }
      }
      HTTP.Request('post', window.admin.getCounty, obj)
        .then((result) => {
          const { data } = result;
          console.log(data);
          if (data.length > 0) {
            change('county', data[0].county);
            change('market', data[0].market);
            readOnlyChecker = true;
            change('unit_no', '');
            console.log('inside County', data[0].county);
          } else {
            readOnlyChecker = false;
            change('unit_no', ' ');
            console.log('outside County', readOnlyChecker);
          }
        })
        .catch((err) => {
          if (err) {
            toast(err.message, {
              type: 'error',
            });
          }
        });
    }
  }
  handleSelect = (address) => {
    const { change } = this.props;
    geocodeByAddress(address)
      .then((result) => {
        this.fillFormFields(result);
        return getLatLng(result[0]);
      })
      .then(({ lat, lng }) => {
        let request = {lat, lng};
        change('lat', request.lat);
        change('lng', request.lng);

        this.setState({coordinates: request});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeAddComparable = () => {
    this.props.onCloseComparable();
  }
 
  render() {
      const addComprables = (data) => {
        /*Restore Revision*/
        let param = {
          ...data,
          source: 'Manual',
          property_id: this.props.property_id,
        };
        HTTP.Request('post', window.admin.addComprables, param)
          .then((r) => {
            toast.success(r.message);
            this.props.reset();
            this.props.onAddComparables();
          })
          .catch((error) => console.log(error));
      };
    const {
      showComparablesAutoAddModal,
      showComparablesAddModal,
      closeComparablesAddModal,
    } = this.props;
    return (
      <div>
        <form onSubmit={this.props.handleSubmit(addComprables)}>
          <div className="box comparableForm" style={{ marginTop: 15 }}>
            <div className="row">
              <div className="col-md-3">
                <Field
                  component={LocationSearchInput}
                  type="text"
                  formGroupClassName="col-md-3 col-lg-3"
                  name="street_address"
                  label="Street Address*"
                  autocomplete="off"
                  placesAutocomplete={true}
                  onSelect={this.handleSelect}
                  id="street_address"
                  placeholder="Enter Street Address"
                  validate={[required]}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="zipcode"
                  fieldName="Zipcode*"
                  autocomplete="off"
                  type="text"
                  max={5}
                  id="zipcode"
                  readOnly={zipCodeChecker}
                  // component={zipCodeChecker == true ? RenderFieldV1 : RenderField}
                  component={RenderField}
                  validate={[required]}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="city"
                  fieldName="City*"
                  autocomplete="off"
                  type="text"
                  id="city"
                  readOnly={cityCodeChecker}
                  // component={cityCodeChecker == true ? RenderFieldV1 : RenderField}
                  component={RenderField}
                  validate={[required]}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="state"
                  fieldName="State*"
                  autocomplete="off"
                  type="text"
                  id="state"
                  readOnly={true}
                  component={RenderField}
                  validate={[required]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Field
                  name="unit"
                  fieldName="Unit"
                  autocomplete="off"
                  type="text"
                  id="unit_no"
                  // onChange={this.onChange}
                  component={RenderField}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="year_built"
                  fieldName="Year Built*"
                  autocomplete="off"
                  type="text"
                  max={4}
                  normalize={normalizeBuilt}
                  id="year_built"
                  component={RenderField}
                  validate={[required, yearBuiltValidation]}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="lat"
                  fieldName="Latitude*"
                  autocomplete="off"
                  type="text"
                  id="lat"
                  readOnly={true}
                  // component={RenderFieldV1}
                  component={RenderField}
                  validate={[required]}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="lng"
                  fieldName="Longitude*"
                  type="text"
                  autocomplete="off"
                  id="lng"
                  readOnly={true}
                  component={RenderField}
                  validate={[required]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Field
                  name="sq_footage"
                  fieldName="Square Footage*"
                  type="number"
                  autocomplete="off"
                  id="square_footage"
                  component={RenderField}
                  validate={[required]}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="lot_size"
                  fieldName="Lot Size"
                  autocomplete="off"
                  type="number"
                  id="lot_size"
                  component={RenderField}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="bedrooms"
                  fieldName="Bedrooms"
                  id="bedrooms"
                  options={BED}
                  label="Beds*"
                  placeholder="Select"
                  textField="title"
                  valueField="value"
                  component={DropdownCompV2}
                  validate={[required]}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="bathrooms"
                  fieldName="Bathrooms"
                  id="bathrooms"
                  options={BATH}
                  label="Baths*"
                  placeholder="Select"
                  textField="title"
                  valueField="value"
                  component={DropdownCompV2}
                  validate={[required]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <Field
                  name="last_sold_price"
                  fieldName="Last Sold Price*"
                  type="text"
                  autocomplete="off"
                  {...currencyMask}
                  normalize={normalizePhone}
                  id="last_sold_price"
                  component={RenderField}
                  validate={[required]}
                />
              </div>
              <div className="col-md-3">
                <Field
                  name="last_sold_date"
                  id="last_sold_date"
                  autocomplete="off"
                  fieldName="Last Sold Date*"
                  component={DatePicker}
                  validate={[required]}
                />
              </div>
              <div className="col-md-3">
                <button
                  className="btn green uppercase w-100"
                  style={{ marginTop: 25 }}
                  type="submit"
                >
                  Save
                </button>
              </div>
              <div className="col-md-3">
                <button className="btn yellow uppercase w-100" style={{ marginTop: 25 }} type="button" onClick={() => this.closeAddComparable()}>Close</button>
              </div>
            </div>
          </div>
        </form>
        <Modal
          show={showComparablesAutoAddModal}
          onHide={() => closeComparablesAddModal()}
        >
          <Modal.Header closeButton className="theme-bg">
            <Modal.Title>CREATE NEW COMPARABLE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Field
              component={LocationSearchInput}
              type="text"
              formGroupClassName="col-md-12 col-lg-12"
              name="street_address"
              label="Street Address"
              placesAutocomplete={true}
              onSelect={this.handleSelect}
              id="Street Address"
              placeholder="Enter Street Address"
              validate={[required]}
            />
          </Modal.Body>
          <Modal.Footer>
            {/* <Button className='btn btn-default' onClick={this.closeModel}>Cancel</Button> */}
            <Button
              onClick={() => closeComparablesAddModal()}
              className="btn red-mint"
            >
              Save
            </Button>
            <button type="submit" className="btn green uppercase">
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
let AddComarables = reduxForm({
  form: 'CreateNewComarables',
})(CreateNewComarables);
const mapStateToProps = (state) => {
  return {
    state,
  };
};
export default connect(mapStateToProps)(AddComarables);
