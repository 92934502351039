import React, { Component } from 'react';
import Moment from "react-moment";
import moment from "moment-timezone";
import { Link } from 'react-router-dom';
import Modal from '../../common/modal';
import TT from "../../common/tooltip";

class ROW extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.download = this.download.bind(this);
    }

    render() {
        const { cms } = this.props;
        return (
            <tr className="odd gradeX" >

                <td> {cms.street_address} </td>
                <td className="center"> {cms.type} </td>
                <td><Moment format="MM/DD/YY">{moment(cms.created_at).zone("-04:00")}</Moment></td>
                <td>{this.wholesaleDST(cms.created_at)}</td>
                {/* <td> {moment(cms.created_at).zone("-04:00").format('h:mm:ss a')}</td> */}
                {/* <td> {moment(cms.created_at).tz('America/Phoenix').format('MMMM Do').replace(new RegExp('[^\.]?' + moment().format('YYYY') + '.?'), '').replace(/[, ]+/g, " ").trim() }</td>  */}
                <td className="center" width="10%">
                    {cms.status === 1 ? 'Sent' : ""}

                </td>
                {/* {Number(moment(new Date).format('x'))} { Number(moment(cms.created_at).format('x'))+86400 } */}
                <td> {cms.download > 0 &&
                    <i style={{ cursor: 'pointer' }} className="fa fa-download" onClick={() => this.download(cms._id)} > </i>}  </td>
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow() {
        this.setState({ isLoading: true, show: false });
        /*delete row by call prop func*/
        this.props.delete(this.props.cms._id);
    }

    closeModal() {
        this.setState({ show: false });
    }

    download(id) {
        this.props.download(id);
    }

    // DST CHEKC FOR  WHOLESALE FLYER
    wholesaleDST = time => {
        let newTime = "";
        let d = moment(time);

        if (d.isDST()) {
            console.log("this is dst time", d.isDST())
            newTime = moment(time)
                .format(" h:mm:ss a");
        } else {
            console.log("this is not dst", d.isDST())
            newTime = moment(time).format("h:mm:ss a");
        }
        return newTime;
    }
}


export default ROW;

