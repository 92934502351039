import moment from "moment-timezone";
import React, { Component } from 'react';
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import TT from "../../../common/tooltip";

class ROW extends Component {
    render() {
        const {service} = this.props;
        const permissionsStyle = {
            maxWidth: '750px',
            wordBreak: 'break-all'
        }

        return (
            <tr className="odd gradeX" >
                <td> {service.service} </td>
                <td><Moment format="DD/MM/YYYY hh:mm">{moment(service.updated_at)}</Moment></td>
                <td>
                    {
                        <TT tooltip="Edit"><Link to={'/third-party-services/edit/' + service._id} className="btn btn-xs grey-mint"><i className="fa fa-pencil-square-o"  ></i></Link></TT>
                    }
                </td>
            </tr>
        );
    }

}



export default ROW;

