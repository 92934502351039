import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import {  toast } from 'react-toastify';
import HTTP from "../../services/http";
import Socket from "../../sockets";

import { required } from '../common/fieldValidations';
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
/**COMPONENT */
import RenderFiled from "../common/renderField";
import PageHeader from "../common/pageheader"; 
import Multiselect from "../common/multiselect";
import Loader from "../common/loader";
import DropdownComp from "../common/DropdownList";
import infoOf from "../common/tooltip-text";

class AddRedirectLink extends Component {

    constructor(props) {
        super(props);

        this.state = {
            roleOptions:[],
            isLoading:false,
            formAction :"ADD",
            status:true,
            // selectedRole:{}
        }

        /**event binding  */
        this.addEditRedirectLink = this.addEditRedirectLink.bind(this);
        this.getARedirectLink = this.getARedirectLink.bind(this);

    }

    componentWillMount(){
        this.getARedirectLink();
    }
    
    render() {
        const { handleSubmit } = this.props;
        const { roleOptions, isLoading, formAction, selectedRole} = this.state;
        return (
            <div>

                {isLoading ? <Loader /> : <div><PageHeader route="Edit Redirect Link" parent="Redirect Links Management" parentRoute="/redirect-links"/>
              
                <div className="tab-pane active" >
                    <form onSubmit={handleSubmit(this.addEditRedirectLink)}  >                         
                        <Field name="sourceUrl" fieldName="Source URL*" type="text" component={RenderFiled} validate={[required]} />
                        <Field name="redirectedUrl" fieldName="Redirect URL*" type="text" component={RenderFiled} validate={[required]} />                  
                        <div className="form-actions">
                            <button type="submit" className="btn green uppercase" disabled={this.props.invalid || this.props.submitting}>{formAction === "ADD" ? "Add " : "Edit"}</button>
                        </div>
                    </form>
                </div>
                </div>
                }

            </div>
        );
    }

    addEditRedirectLink(data) {
        if(data.sourceUrl == data.redirectedUrl){
            toast.error("Both URL must be different");
            return
        }
        HTTP.Request("post", window.admin.upsertRedirectLink, data)
        .then(result => { 
            this.props.dispatch(push("/redirect-links"));
            toast(result.message,{type:"success"});
            /**to check if the role of the user is changed */
            if(result.data.role._id !== this.state.selectedRole._id ){
                /**call socket to logout the user whose role has been changed */
                Socket.callEvent("logout",{userId : result.data._id})            
            }

            /*log audits for user*/
            this.props.dispatch({
                type: ADMIN_TRACK_AUDIT_LOGS,
                action: {
                    comment: "Modified details of Customer - " + (result.data.firstname + ' ' + result.data.lastname + ' (' + result.data.email + ')'),
                    type: "audit"
                }
            });
        })
        .catch(err => {
            if(err && err.errors && err.errors.length>0)
            err.errors.map(message => toast(message,{type:"error"})  )      
        })
    }

    getARedirectLink(){
        const { match, initialize } = this.props;
        /*extract plant id from request*/
        let redirectLinkId = (match.params.id) ? match.params.id : null;

        if(redirectLinkId){
            this.setState({ isLoading: true, formAction:"EDIT"})
            HTTP.Request("get", window.admin.getRedirectLinkById, { id: redirectLinkId})
            .then(result => {
                setTimeout(() => {
                    this.setState({
                        isLoading: false,
                        selectedRole: result.data.role ? result.data.role : {},
                        status: result.data.status 
                    })
                }, 1000);
             

                /*set data to form*/
                initialize(result.data);
            })
        }
    
    }
}

//decorate form component
let AddRedirectLink_Form = reduxForm({
    form: "addRedirectLink_Form",
})(AddRedirectLink);


export default AddRedirectLink_Form;