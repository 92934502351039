import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { HelpBlock, Table, Alert } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { ValidateOnlyAlpha } from "../../common/fieldValidations";
import HTTP from '../../../services/http';
import DateFilter from "../../common/filter";
import { Field, reduxForm } from "redux-form";
import { DropdownList } from 'react-widgets';
/**COMPONENTS */
import Loader from "../../common/loader";
import ROW from "./row";
import xlsx from 'xlsx'
var timer;
class TableComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: [],
            options: [
                { label: "Renovated", value: "renovated" },
                { label: "Wholesale", value: "wholesale" }
            ],
            isLoading: false,
            searchQuery: '',
            activePage: 1,
            totalItemsCount: 1
        }
        this.getProperty = this.getProperty.bind(this);
        this.search = this.search.bind(this);
        // this.filterByDate=this.filterByDate.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.delete = this.delete.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.downloadLogs = this.downloadLogs.bind(this);
    }

    componentWillMount() {
        this.getProperty();
    }

    render() {
        const { array, isLoading, seracherror, options } = this.state;
        return (
            <div>
                {isLoading && <Loader />}
                <div className="portlet light bordered">
                    <div className="portlet-body">
                        {/* actions search addnew  */}
                        <div className="table-toolbar">
                            <div className="row">
                                <div className="col-md-9">
                                    {/* <div className="row"> */}
                                    <div className="col-md-2">
                                        {/* <label>Deal Type</label> */}
                                    </div>
                                    {/* <div className="col-md-4"> */}
                                    {/* <DropdownList
                            data={options}
                            defaultValue={undefined}
                            textField="label"
                            valueField="value"
                            name={"fileter"}
                            placeholder={'---Select----'}
                            value={this.state.value?this.state.value:undefined}
                            onChange={this.filterByDate} />  */}
                                    {/* </div> */}

                                    {/* <div  className="col-md-3"> */}
                                    {/* <button className="btn sbold green" onClick={this.handleReset}>Reset</button> */}
                                    {/* </div> */}
                                    {/* </div> */}


                                </div>


                            </div>
                            <br />
                            <div className="row">


                                <div className="col-md-3">
                                    <div className="btn-group pull-right">
                                        <input type="text" className="form-control" placeholder="Search Property(s)" onChange={this.search} />
                                        <HelpBlock style={{ color: '#e73d4a' }}>
                                            {seracherror ? seracherror : null}
                                        </HelpBlock>
                                    </div>
                                </div>

                                <div className="col-md-9">
                                    <div className="btn-group pull-right">

                                        <Link to="/renovated-flyer-management"> <button className="btn sbold green" onClick={this.handleReset}>Add New Flyer</button></Link>

                                    </div>
                                </div>

                                {/* <!-- add new --> */}
                                {/* <div className="col-md-1 col-sm-offset-8">
                                    <div className="btn-group pull-right">
                                        <Link to="/new-property"><button id="sample_editable_1_new" className="btn sbold green"> Add New
                                        </button></Link>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* if list is empty */}
                        {!isLoading && !array.length ? <Alert bsStyle="warning">
                            <strong>No Data Found !</strong>
                        </Alert> :
                            <div style={{ display: "flow-root" }}>
                                <Table responsive striped bordered condensed hover>
                                    <thead>
                                        <tr>
                                            <th> Property Address </th>
                                            <th> Flyer Type </th>
                                            <th> Sent On </th>
                                            <th> Time </th>
                                            <th> Status </th>
                                            <th> Download SMS Logs </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {array.map(cms => {
                                            console.log("CMS DATA ", cms);
                                            if(cms.type == "Relisted from"){
                                                cms.type = "Relisted"
                                            } 
                                            return (
                                                <ROW key={cms._id} cms={cms} download={this.downloadLogs} delete={this.delete} />

                                            )
                                        })}
                                    </tbody>
                                </Table>
                                <div style={{ float: "right" }}>
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={window.limit}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={3}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
    /* handle Reset */
    handleReset(event) {
        this.setState({ value: undefined, searchQuery: '' }, this.getProperty())
    }

    /**PAGINATION */
    handlePageChange(eventKey) {
        this.setState({ activePage: eventKey });
        this.getProperty({
            page: eventKey ? eventKey : 1,
            filter: this.state.value,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
            category: 'renovated',
        });
        /**to set query in route */
        this.props.history.push({
            search: '?page=' + eventKey
        })
    }
    /**SEARCH */
    search(e) {
        /**to remove Event Pooling  */
        e.persist();
        let searchedValue = e.target.value;
        let seracherror = ValidateOnlyAlpha(searchedValue);
        if (seracherror) {
            this.setState({ seracherror: seracherror });
            return;
        }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
            this.props.history.push({
                search: '?page=' + 1
            });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror });
        } else {
            this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '' });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getProperty({
            category: 'renovated',
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery,
            filter: this.state.filter ? this.state.filter : 2
        });
        }, 1000);
    }

    getProperty(params = { category: 'renovated' }) {

        /**to start Loader */
        this.setState({ isLoading: true })
        HTTP.Request("get", window.admin.renovatedFlyerLogs, params)
            .then((response) => {
                console.log("THISI SI RESPONSE", response.data)
                this.setState({
                    array: response.data.records,
                    isLoading: false,
                    totalItemsCount: response.data.total
                })
            })

    }
    /* search data by date */

    //   filterByDate(data){
    //       const {value}=data;
    //       this.setState({
    //           value
    //       },
    //       this.getProperty({
    //         page:1,
    //         searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
    //         filter: value,
    //     })
    //     )


    // }
    /* delete */
    delete(id) {
        let page = 1;
        let data = {};
        data.id = id;
        data.trash = true;

        HTTP.Request("post", window.admin.removePropertyDeatial, data)
            .then(result => {
                /**to stop loader */
                this.setState({ isLoading: false });
                /**refresh list after deletion */
                this.getProperty();
            })
            .catch(error => {
                this.setState({ isLoading: false });
            });

    }

    downloadLogs(id) {

        this.setState({ isLoading: true })
        HTTP.Request("get", window.admin.downloadMessageLog, { id: id })
            .then((response) => {
                console.log(response.data)
                this.setState({
                    isLoading: false,

                })

                let data, numbers;

                if (response.data) {
                    console.log('with in');
                    data = response.data.items

                    numbers = response.data.totalnumbers.map((number) => { return { Mobile: number } });
                } else {
                    console.log('outside');
                    data = [];
                    numbers = [];
                }
                console.log('data is', data);

                var ws = xlsx.utils.json_to_sheet(data);
                var number = xlsx.utils.json_to_sheet(numbers);
                console.log(ws);
                this.wb = xlsx.utils.book_new();
                xlsx.utils.book_append_sheet(this.wb, ws, "Message_logs");
                xlsx.utils.book_append_sheet(this.wb, number, "Request_send");
                console.log(this.wb);
                xlsx.writeFile(this.wb, "Renovated_SMS_Logs.xlsx");
            })
        console.log('download working', id);


    }
}
export default TableComp;