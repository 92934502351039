import React, { Component } from 'react';
import { Alert, Table } from "react-bootstrap";
import Pagination from "react-js-pagination";
import HTTP from '../../../../services/http';
import FilterForm from "../../../common/filterForm";
/**COMPONENTS */
import Loader from "../../../common/loader";
import ROW from "./row";
class TableComp extends Component {

    constructor(props){
        super(props);
        this.state ={
            array:[],
            isLoading:false,
            searchQuery :'',
            activePage :1,
            totalItemsCount:1
        }
        /**event binding */
        this.getDynamicsLogs = this.getDynamicsLogs.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.submitFilters = this.submitFilters.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
    }

    componentWillMount(){
        this.getDynamicsLogs();
    }

    render() {
        const { array, isLoading, filterData} = this.state;
        return (
            <div >
                {isLoading && <Loader />}

                <div className="portlet light bordered">
                    <div className="portlet-body">
                        <FilterForm submitFunction={this.submitFilters}
                            resetFunction={this.resetFilters}
                            searchPlaceholder="Search"
                            limitComp
                        />
                        {!isLoading && !array.length ? <Alert bsStyle="warning">
                            <strong>No Data Found !</strong>
                        </Alert>:
                            <div style={{ display: "flow-root"}}>
                            <Table responsive striped bordered condensed hover>
                                <thead>
                                    <tr>
                                        <th> Created On </th>
                                        <th> Url </th>
                                        <th> Type </th>
                                        <th> Code </th>
                                        <th> Message </th>
                                        <th> Payload  </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {array.map(log => {
                                        return (
                                            <ROW key={log._id} log={log} />
                                        )
                                    })}
                                </tbody>
                            </Table>
                                <div style={{float:"right"}}>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={filterData ? filterData.limit : window.limit}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={3}
                                    onChange={this.handlePageChange}
                                />
                             </div>
                        </div>
                    }
                    </div>
                </div>
            </div>
        );
    }

    /**PAGINATION */
    handlePageChange(eventKey){
        const { filterData} =this.state;
        this.setState({ activePage: eventKey });
        this.getDynamicsLogs({
            type:"dynamics",
            page: eventKey ? eventKey : 1,
            ...filterData
        });
        /**to set query in route */
        this.props.history.push({
            search: '?page=' + eventKey
        })
    }


    /**to get list of roles */
    getDynamicsLogs(params={}){
        /**to start Loader */
        this.setState({  isLoading: true })

        HTTP.Request("get", window.admin.getDynamicsLogs, params)
        .then(response => {
            this.setState({array : (response.data.logs)?response.data.logs:[], isLoading:false, totalItemsCount: response.data.count });
        })
        .catch(error => {
            this.setState({array : [], isLoading:false, totalItemsCount: 0 });
        });
    }

    /**to submit all the filters */
    submitFilters(filterData) {
        this.setState({ filterData })
        let { activePage } = this.state;
        this.getDynamicsLogs({
            page: activePage || 1,
            type:"dynamics",
            ...filterData
        });
    }

    /* To reset all filters */
    resetFilters() {
        this.setState({ activePage: 1, seracherror: '', searchQuery: '' });
        let { activePage } = this.state;
        let searchEle = document.getElementById('search_field');
        if (searchEle) {
            searchEle.value = '';
        }
        this.getDynamicsLogs({
            page: activePage || 1,
            type:"dynamics"
        });
    }

}

export default TableComp ;