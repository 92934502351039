/**name of ALL action.type */
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const ADMIN_PROFILE_UPDATE = "ADMIN_PROFILE_UPDATE";
export const CHANGE_ADMIN_AVTAR = "CHANGE_ADMIN_AVTAR";
export const GET_TAXES = "GET_TAXES";
export const ADMIN_TRACK_AUDIT_LOGS = "Admin-trackAuditLogs";
export const Check_For_BlackList = "Check_For_BlackList";
export const Renovated_Flyer_Info = "Renovated_Flyer_Info";
export const Wholesale_Flyer_Info = "Wholesale_Flyer_Info";
export const logsCount = "logsCount";
export const crmlogsCount = "crmlogsCount";


