import React, { Component } from 'react';
import { Alert, HelpBlock } from "react-bootstrap";
import HTTP from '../../../services/http';
import Pagination from "react-js-pagination";

/**COMPONENTS */
import Loader from "../../common/loader";
import ROW from "./row";
import DateFilter from "../../common/filter";
import { ValidateOnlyAlpha } from "../../common/fieldValidations";
import xlsx from 'xlsx';
import _ from "lodash";
import { DropdownList } from 'react-widgets';


var timer;
class Table extends Component {

    constructor(props){
        super(props);
        this.state ={
            array:[],
            marketList:[],
            marketOptions:[],
            countyOptions:[],
            sourceOptions:[{label:"Google",value:"google",type:"source"},{label:"Facebook",value:"facebook",type:"source"},{label:"Bing",value:"bing",type:"source"}],
            isLoading:false,
            searchQuery :'',
            activePage :1,
            totalItemsCount:1,
            stateFilter:"",
            marketFilter:"",
            sourceFilter:"",
            isDownloadPermitted:false
        }
        /**event binding */
        this.getList = this.getList.bind(this);
        this.search = this.search.bind(this);
        this.filterByDate=this.filterByDate.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.downloadLeadsDetails = this.downloadLeadsDetails.bind(this);
        this.getCurrentUser = this.getCurrentUser.bind(this);
    }

    componentWillMount(){
        this.getCurrentUser()
        this.getList();
    }

    downloadLeadsDetails=()=> {
        this.setState({ isLoading: true });
        HTTP.Request("get", window.admin.downloadAllLeads)
            .then((response) => {
                const workSheet = xlsx.utils.json_to_sheet(response.data);
                this.workBook = xlsx.utils.book_new();
                xlsx.utils.book_append_sheet(this.workBook, workSheet, "Leads");
                xlsx.writeFile(this.workBook, "Leads_List.xlsx");
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
                console.log("Error downloadLeads =>>>", error);
            });
    }

    render() {
        const {array,countyFilter,marketFilter,isDownloadPermitted,sourceOptions,countyOptions,marketOptions ,isLoading,totalItemsCount, seracherror} = this.state;
        var divStyle = {
            padding: "8px 8px 8px",
          };
        return (
            <div >
                {isLoading &&<Loader />}

                <div className="portlet light bordered">
                    <div className="portlet-body min-heigh">

                        {/* actions search addnew  */}
                        <div className="table-toolbar">
                        <div className="row">
                            <div className="col-md-9">
                                   <DateFilter resetFunction={this.resetFilters} filterByDate={this.filterByDate}></DateFilter>
                            </div>
                        </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="btn-group pull-right">
                                        <input type="text" className="form-control" id="search_field" placeholder="Search Get Offers" onChange={this.search} />
                                        <HelpBlock style={{ color: '#e73d4a' }}>
                                            {seracherror ? seracherror : null}
                                        </HelpBlock>
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-offset-2">
                                    <div >
                                        <label style={divStyle} className={'label label-info bg-blue-steel bg-font-blue-steel'}>Total Count <strong>-</strong> {totalItemsCount}</label>
                                    </div>
                                </div>
                                <div style={{textAlign:"end"}}  className="col-md-9">
                                   {isDownloadPermitted &&<button   className="btn sbold green" onClick={this.downloadLeadsDetails}>Download Leads</button>}
                            </div>
                            </div>
                            <div className="col-md-8 pp-0 flex-end">
                                            <DropdownList
                                                data={countyOptions}
                                                defaultValue={undefined}
                                                textField="label"
                                                className="col-md-4 pp-0 pr2"
                                                valueField="value"
                                                name={"countyfilter"}
                                                placeholder={'---Select County----'}
                                                value={this.state.countyFilter ? this.state.countyFilter : undefined}
                                                onChange={this.filterByDate}
                                            />

                                            <DropdownList
                                                data={marketOptions}
                                                defaultValue={undefined}
                                                textField="label"
                                                className="col-md-4"
                                                valueField="value"
                                                name={"marketfilter"}
                                                placeholder={'---Select Market----'}
                                                value={this.state.marketFilter ? this.state.marketFilter : undefined}
                                                onChange={this.filterByDate}
                                            />

                                            <DropdownList
                                                data={sourceOptions}
                                                defaultValue={undefined}
                                                textField="label"
                                                className="col-md-4"
                                                valueField="value"
                                                name={"sourcefilter"}
                                                placeholder={'---Select Source----'}
                                                value={this.state.sourceFilter ? this.state.sourceFilter : undefined}
                                                onChange={this.filterByDate}
                                            />
                                        </div>
                        </div>
                        {/* actions search addnew END */}

                        {/* if list is empty */}
                        {!isLoading && !array.length ? <Alert bsStyle="warning">
                            <strong>No Data Found !</strong>
                        </Alert>:
                            <div style={{ display: "flow-root"}}>
                            <table style={{ "table-layout": "fixed"}} className="table table-striped table-bordered table-hover table-checkable order-column" id="sample_1">
                                <thead>
                                    <tr>
                                        <th>CRM Sync</th>
                                        <th width="10%">First Name</th>
                                        <th>Last Name</th>
                                        <th width="20%">Email </th>
                                        <th>UTM Search Engine </th>
                                        {/*<th>Work Phone</th>
                                        <th>Mobile Phone </th> */}
                                        <th> Date </th>
                                        <th> Street </th>
                                        <th> Unit </th>
                                        <th> City </th>
                                        <th> State </th>
                                        <th> Zip </th>
                                        <th> County </th>
                                        <th> Market </th>
                                         {/* <th width='5%'> Action </th> */}
                                    </tr>
                                </thead>

                                <tbody>
                                    {array.map(element => {
                                        return (
                                            <ROW key={element._id} element={element} delete={this.delete}/>
                                        )
                                    })}
                                </tbody>
                            </table>
                                <div style={{float:"right"}}>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={window.limit}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={3}
                                    onChange={this.handlePageChange}

                              />
                             </div>
                        </div>
                    }
                    </div>
                </div>
            </div>
        );
    }

     /* search data by date */
     filterByDate(data) {
        const { end_date,start_date } = data;
        if(!!data.type && data.type == "county"){
            this.state.countyFilter = data.value;
            this.setState({
                countyFilter: data.value,
            })
        }

        if(!!data.type && data.type == "market"){
            this.state.marketFilter = data.value;
            this.setState({
                marketFilter: data.value
            })
        }
        if(!!data.type && data.type == "source"){
            this.state.sourceFilter = data.value;
            this.setState({
                sourceFilter: data.value
            })
        }
        this.setState({
            start_date: start_date,
            end_date: end_date
        })
        this.getList({
            page:1,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
            marketFilter: this.state.marketFilter ? this.state.marketFilter : '',
            countyFilter: this.state.countyFilter ? this.state.countyFilter : '',
            sourceFilter: this.state.sourceFilter ? this.state.sourceFilter : '',
            ...data
        });
    }

    /* To reset all filters */
    resetFilters() {
        this.setState({ countyFilter:"",sourceFilter:"",marketFilter:"",activePage: 1, seracherror: '', searchQuery: '', start_date: '',  end_date: '' });
        let searchEle = document.getElementById('search_field');
        if (searchEle) {
            searchEle.value = '';
        }
        this.getList({
            page: 1
        });
        this.props.history.push({
            search: '?page=' + 1
        });
    }

    /**PAGINATION */
    handlePageChange(eventKey) {
        this.setState({ activePage: eventKey });
        this.getList({
            page: eventKey ? eventKey : 1,
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
            marketFilter: this.state.marketFilter ? this.state.marketFilter : '',
            countyFilter: this.state.countyFilter ? this.state.countyFilter : '',
            sourceFilter: this.state.sourceFilter ? this.state.sourceFilter : '',
        });
        /**to set query in route */
        this.props.history.push({
            search: '?page=' + eventKey
        })
    }
    /**SEARCH */
    search(e) {
        /**to remove Event Pooling  */
        e.persist()
        let searchedValue = e.target.value;
        let seracherror = ValidateOnlyAlpha(searchedValue)
        if (seracherror) {
            this.setState({ seracherror: seracherror });
            return;
        }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
            this.props.history.push({
                search: '?page=' + 1
            });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
                search: '?page=' + 1
            });
            this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror });
        } else {
            this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '' });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getList({
            start_date:this.state.start_date,
            end_date:this.state.end_date,
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery,
            filter: this.state.filter ? this.state.filter : 2,
            marketFilter: this.state.marketFilter ? this.state.marketFilter : '',
            countyFilter: this.state.countyFilter ? this.state.countyFilter : '',
            sourceFilter: this.state.sourceFilter ? this.state.sourceFilter : '',
        });
        }, 1000);        
    }

    /**to get list  */
    getList(params = {}) {
       /**to start Loader */
        this.setState({ isLoading: true });
        HTTP.Request("get",window.admin.getanOfferList,params)
        .then(result => {
            this.setState({
                isLoading: false,
                array: result.data.offer,
                totalItemsCount: result.data.offerCount
            });
        })
        .catch(err =>this.setState({isLoading:false}));

        HTTP.Request("get", window.admin.allMarketList)
            .then(result => {
                let uniqueCounty = _.uniqBy(result.data,"county")
                this.setState({
                    countyOptions: uniqueCounty.map(e=>{return {value:e.county , label: e.county,type:"county"}}),
                    marketList: result.data,
                    marketOptions: _.uniqBy(result.data,"market").map(e=>{return {value:e.market,label:e.market,type:"market"}}),
                });
            })
            .catch(err => this.setState({ isLoading: false }));
    }

    getCurrentUser(){
        HTTP.Request('get', window.admin.getACurrentUser, {}).then((result) => {
        if(!!result && !!result.roleType && !!result.roleType.permissions &&result.roleType.permissions.includes("DOWNLOAD_LEADS")){
            this.setState({
              isDownloadPermitted: true,
            });
        }else{
            this.setState({
                isDownloadPermitted: false,
            }); 
        }
        });
      }

}

export default Table ;