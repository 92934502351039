import React, { Component } from 'react';

/**COMPONENTS */
import PageHeader from "../common/pageheader";
import Rectangle from "./element/rectangle"
import HTTP from "../../services/http";
/**PAGE LEVEL CSS */

class Dashboard extends Component {


    constructor(props){
        super(props);

        this.state = {
            reviews: 0,
            market : 0,
            outofmarket: 0
        }

        this.getreviews = this.getreviews.bind(this);
    }


    componentWillMount(){
        this.getreviews();
        this.getOutofMarketLead();
        this.getMarketLeads();
    }

    render() {
        
        return (
            <div> 
                <PageHeader pageTitle="Admin Dashboard " route="Dashboard" />
                <h1 >Todays Statistics</h1>
                <br/>
                <div className="row">
                    <Rectangle color="blue" icon="fa fa-comments" value={this.state.market} title="Market Leads"  />
                    <Rectangle color="red-mint" icon="icon-basket" value={this.state.outofmarket} title="Out Of Market Leads"  />{/* sign="M$" */}
                    <Rectangle color="green" icon="fa fa-shopping-cart" value={this.state.reviews} title="Reviews"  />
                    {/* <Rectangle color="purple" icon="fa fa-globe" value={0} title="Sample Kit"  /> */}
                </div>

            </div>
        );
    }





    getreviews () {

        HTTP.Request('get', window.admin.reviewsCount)
        .then((result)=> {
            this.setState({

                reviews: result.result

            })
        }, (error) => {
            console.log(error);
        })

    }

    getOutofMarketLead () {
        HTTP.Request('get', window.admin.outofMarketsCount)
        .then((result)=> {
            // console.log(result);
            this.setState({

                outofmarket: result.count

            })
        }, (error) => {
            console.log(error);
        })
    }

    getMarketLeads () {
        HTTP.Request('get', window.admin.MarketsCount)
        .then ((result) => {
            // console.log(result);
            this.setState({

                market: result.count

            })

        }, (error)=> {
            console.log('error', error);
        })
    }

}


export default Dashboard;

