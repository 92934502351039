import React,{Component} from 'react';
import {connect} from 'react-redux';
/**COMPONENTS */
import Table from "./element/table";
import PageHeader from "../common/pageheader";

class RecContacts extends Component{
    
    render(){
        return(
            <div>
                <PageHeader pageTitle="Buyer Leads" route="Buyer Leads" />
                <div className="profile-content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="portlet light ">
                                <div className="portlet-title tabbable-line">
                                    <Table {...this.props} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(RecContacts);