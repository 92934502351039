import React, { Component } from 'react';
import { connect } from 'react-redux';
import RenderField from "../common/renderField";
import { Tabs, Tab } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Loader from "../common/loader";
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multi";
import DropdownCompV2 from "../common/dropdown_v2";
import { STATUS } from "../common/options";
import DropdownComp from "../common/DropdownList";
import ImageCropper from "../common/ImageCropper";

import DatePicker from "../common/DateTimePicker";
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import HTTP from "../../services/http";


import { required, ValidateOnlyAlpha, mobileValidate, emailValiadte } from '../common/fieldValidations';

class MArketImageManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formAction: "ADD",
            content: "",
            isLoading: false,
            status: false
        }
        this.upsertProp = this.upsertProp.bind(this);
        this.getProp = this.getProp.bind(this);
    }

    componentDidMount() {
        this.getProp();
    }

    render() {
        const { handleSubmit } = this.props;
        const { isLoading, formAction, status } = this.state;
        return (
            <div className='relative'>
                {isLoading ? <Loader /> : <div><PageHeader route={formAction === "ADD" ? "Add  Market Image" : "Edit  Market Image"} parent='Market Image' parentRoute='/market-image-list' />
                    <div  >
                        <Tabs defaultActiveKey={1} animation={false} id="profileTabs" >
                            <Tab eventKey={1} title={formAction === "ADD" ? "Add New Market Image" : "Edit Market Image"}>
                                <form onSubmit={handleSubmit(this.upsertProp)}  >
                                    <Field
                                        name="name"
                                        fieldName="Name*"
                                        type="text"
                                        component={RenderField}
                                        validate={[required]}
                                    />
                                    <label>Image for Summary Page*</label>
                                    <Field component={ImageCropper} id={"Listing_Image"}
                                        name="image"
                                        minWidth={250}
                                        minHeight={250}
                                        dimensionsCheck={true} ratioUpper={672}
                                        ratioLower={533}
                                        validate={formAction === 'ADD' ? [required] : []}
                                    />
                                    <br />
                                    {this.state.image ? (
                                        <img
                                            src={this.state.image.secure_url}
                                            alt=""
                                            width="120px"
                                            className="img-responsive img-thumbnail i-bot"
                                        />
                                    ) : null}
                                    <br />
                                    <Field
                                        name="order"
                                        fieldName="Order*"
                                        type="Number"
                                        component={RenderField}
                                    />
                                    <Field
                        name="status"
                        placeholder="-select-"
                        options={STATUS}
                        label="Status*"
                        defaultValue={
                          this.state.status == true
                            ? 'Active'
                            : this.state.status == false
                              ? 'Inactive'
                              : null
                        }
                        textField="title"
                        valueField="value"
                        component={DropdownComp}
                        validate={[required]}
                      />
                                    <div className="form-actions">
                                        <button type="submit" className="btn green uppercase" disabled={this.props.invalid || this.props.submitting}>{formAction === "ADD" ? "Add" : "Update"}</button>
                                    </div>
                                    <Field
                                        name="lat"
                                        //fieldName="Latitude*"
                                        type="hidden"
                                        component={RenderField}
                                    />

                                    <Field
                                        name="lng"
                                        //fieldName="Longitude*"
                                        type="hidden"
                                        component={RenderField}
                                    />
                                    
                                </form>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
                }
            </div>
        )
    }

    upsertProp(data) {
        const { match } = this.props;
        this.setState({ isLoading: true });
        if (match.params._id) data.editID = match.params._id;
        console.log(match.params);
        console.log("data", data)
        delete data.updated_at
        const formData = new FormData();
        formData.append("data", JSON.stringify(data));
        formData.append("file", data.image);

        HTTP.Request("post", window.admin.upsertMarketImage, formData).then(result => {
            this.setState({
                isLoading: false,
                image: result.data.image ? result.data.image : "",

            });
            this.setState({ isLoading: false });
            this.props.dispatch(push("/market-image-list"));
            toast(result.message, { type: "success" });
        }).catch(err => {
            console.log(err)
            this.setState({ isLoading: false });
            toast(err.message, { type: 'error' })
        });
    }

    getProp() {
        const { match, initialize } = this.props;
        /*extract plant id from request*/
        let roleID = (match.params._id) ? match.params._id : null;
        if (roleID) {
            this.setState({ isLoading: true, formAction: "EDIT" })
            HTTP.Request("get", window.admin.marketImageView, { id: roleID }).then(result => {
                console.log(result)
                this.setState({
                    isLoading: false,
                    image: result.data.image ? result.data.image : "",
                    status: result.data.status
                })
                /*set data to form*/
                initialize(result.data);
            })
        }
    }


}

let marketImageForm = reduxForm({
    form: "marketImageForm"
})(MArketImageManage)

export default connect()(marketImageForm);