import  React, {Component} from 'react';



class BuysersLead extends Component {

    render(){
        return (
            <div>
                <h1>Buyer Leads</h1>
            </div>
        )
    }

}

export default BuysersLead;