import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import Moment from "react-moment";
import { Panel, Table } from "react-bootstrap";
import moment from "moment-timezone";
import { required, ValidateOnlyAlpha } from "../common/fieldValidations";

/**COMPONENT */
import RenderFiled from "../common/renderField";
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multiselect";
import Loader from "../common/loader";
import DropdownComp from "../common/DropdownList";
import infoOf from "../common/tooltip-text";

/**CONSTANT DATA */
import { OPTIONS } from "../common/options";

class ViewMarket extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            status: true,
            market: {}
        };
        /**event binding  */
        this.getList = this.getList.bind(this);
    }

    componentWillMount() {
        this.getList();
    }
    render() {
        const { isLoading, market, status } = this.state;
        console.log("market =>>>", market);
        return (
            <div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <PageHeader
                            route={"View Market"}
                            parent="County List Management"
                            parentRoute="/market-management-list"
                        />
                        <div className="tab-pane active">
                            {/* payment details  start */}
                            <div>
                                <Panel bsStyle="info">
                                    <Panel.Heading>View Market</Panel.Heading>
                                    <Panel.Body>
                                        <Table striped bordered condensed hover>
                                            <tbody>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>State</strong>
                                                    </td>
                                                    <td>{market.state}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>
                                                            Abbreviation
                                                        </strong>
                                                    </td>
                                                    <td>{market.state_code}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>County</strong>
                                                    </td>
                                                    <td>{market.userEnteredCounty ? market.userEnteredCounty : market.county}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Market</strong>
                                                    </td>
                                                    <td>{market.market}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>
                                                            Payment Date
                                                        </strong>
                                                    </td>
                                                    {/* <td>
                                                        <Moment format="MM/DD/YY hh:mm:ss A">
                                                            {market.created_at}
                                                        </Moment>
                                                    </td> */}


                                                    

                                                    <td>
                                                         <Moment format="MM/DD/YY">{moment(market.created_at).zone("-04:00")}</Moment>
                                                        {' '}  {moment(market.created_at).zone("-04:00").format('h:mm:ss a')}
                                                     
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Panel.Body>
                                </Panel>
                            </div>
                            {/* payment details  end */}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    getList(params = {}) {
        /**to start Loader */
        this.setState({ isLoading: true });
        let id = this.props.match.params;
        HTTP.Request("get", window.admin.getmarketView, id)
            .then(result => {
                this.setState({
                    isLoading: false,
                    market: result.data
                });
            })
            .catch(err => this.setState({ isLoading: false }));
    }
}

export default ViewMarket;
