import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../common/modal';
import TT from "../common/tooltip";

class ROW extends Component {

    constructor(props){
        super(props);

        this.state={
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    render() {
        const {cms} = this.props;

        return (
          <tr className="odd gradeX">
            <td> {cms.firstname} </td>
            <td className="center">{cms.lastname}</td>
            <td className="center">{cms.email}</td>
            <td className="center">{cms.mobile}</td>
            <td className="center">
              {cms.user_type === '1'
                ? 'Buyer'
                : cms.user_type === '2'
                ? 'Investor'
                : cms.user_type === '3'
                ? 'Realtor'
                : cms.user_type}
            </td>
            <td className="center">{cms.renovated_market}</td>
            <td className="center">{cms.renovated_county}</td>
            <td className="center">
              {cms.register_renovated === true
                ? 'True'
                : cms.register_renovated === false
                ? 'False'
                : ''}
            </td>
            <td className="center">
              {cms.register_wholesale === true
                ? 'True'
                : cms.register_wholesale === false
                ? 'False'
                : ''}
            </td>
            <td className="center">{cms.wholesale_market}</td>
            <td className="center">{cms.wholesale_county}</td>
            <td className="center">
              {cms.communication === '1'
                ? 'SMS and Email'
                : cms.communication === '2'
                ? 'Email'
                : cms.communication === '4'
                ? 'SMS'
                : 'Unsubscribe'}
            </td>
            <td className="center">{cms.source}</td>

            {/* <td className="center">{cms.mobile}</td> */}
          </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow(){
        this.setState({ isLoading: true,show:false });
        /*delete row by call prop func*/
        this.props.delete(this.props.cms._id);
    }

    closeModal() {
        this.setState({ show: false });
    }

}

export default ROW;
