import React,{Component} from 'react';
import { Link } from 'react-router-dom';
import { HelpBlock,Table,Alert } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { ValidateOnlyAlpha } from "../../common/fieldValidations";
import HTTP from '../../../services/http';
import xlsx from 'xlsx';
import { connect } from "react-redux";
/**COMPONENTS */
import Loader from "../../common/loader";
import ROW from "./row";
import '../table-style.css'

var timer;
class TableComp extends Component{
    constructor(props){
        super(props);
        this.state={
            array:[],
            isLoading:false,
            searchQuery :'',
            activePage :1,
            totalItemsCount:1
        }
        this.getProperty=this.getProperty.bind(this);
        this.search = this.search.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.downloadUsers = this.downloadUsers.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentWillMount(){
        this.getProperty();
    }

    componentWillReceiveProps(){
    }

    render(){
        const {array ,isLoading,seracherror} = this.state;
        return(
            <div>
                {isLoading &&<Loader />}
                <div className="portlet light bordered">
                <div className="portlet-body">
                    {/* actions search addnew  */}
                    <div className="table-toolbar">

                                {/* <!-- add new --> */}
                                <div className="row">
                                    <div className="col-12 col-lg-3">
                                        <div className="btn-group">
                                            <input type="text" className="form-control" placeholder="Search User(s)" onChange={this.search} />
                                            <HelpBlock style={{ color: '#e73d4a' }}>
                                                {seracherror ? seracherror : null}
                                            </HelpBlock>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-9">
                                        <div className="btn-group pull-right">
                                            <Link to="/users-add"><button id="sample_editable_1_new" className="btn sbold green"> Add New
                                            </button></Link>
                                            {this.props.user.user && this.props.user.user.role.title !== 'Dispositions Consultant' ?

                                            <Link to="/investor-imports"><button id="sample_editable_1_new" className="btn sbold green ml-10"> BULK UPLOAD
                                            </button></Link>
                                            : ''}
                                            {this.props.user.user && this.props.user.user.role.title !== 'Dispositions Consultant' &&

                                            <div className="btn-group pull-right ml-10">
                                            <button onClick={this.downloadUsers} id="sample_editable_1_new" className="btn sbold green"> Download Users  </button>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>
                           {/* if list is empty */}
                           {!isLoading && !array.length ? <Alert bsStyle="warning">
                            <strong>No Data Found !</strong>
                        </Alert>:
                            <div style={{ display: "flow-root"}}>
                            <Table responsive striped bordered condensed hover>
                                <thead>
                                    <tr>
                                        <th> Name </th>
                                        <th> Email </th>
                                        <th> Source </th>
                                        <th> Status </th>
                                        <th> User Type </th>
                                        <th> Communication </th>
                                        <th> TCPA Agreed On </th>
                                        <th> Terms & Privacy Agreed On </th>
                                        <th> Updated Date/Time </th>
                                        <th> Created Date </th>
                                        <th width="15%"> Actions </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {array.map(cms => {
                                        return (
                                            <ROW key={cms._id} cms={cms} delete={this.delete}  user = {this.props.user}/>
                                        )
                                    })}
                                </tbody>
                            </Table>
                                <div style={{float:"right"}}>
                                <Pagination
                                    activePage={this.state.activePage}
                                    itemsCountPerPage={window.limit}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={3}
                                    onChange={this.handlePageChange}
                                />
                             </div>
                        </div>
                    }
                </div>
                </div>
            </div>
        )
    }

    /**PAGINATION */
    handlePageChange(eventKey){
        this.setState({ activePage: eventKey });
        this.getProperty({
            page: eventKey ? eventKey : 1,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : '',
        });
        /**to set query in route */
        this.props.history.push({
            search: '?page=' + eventKey
        })
    }
    /**SEARCH */
    search(e) {
        /**to remove Event Pooling  */
        e.persist();
        let searchedValue = e.target.value;
        let seracherror = ValidateOnlyAlpha(searchedValue);
        if (seracherror) {
            this.setState({ seracherror: seracherror });
            return;
        }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
            this.props.history.push({
                search: '?page=' + 1
            });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror });
        } else {
            this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '' });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getProperty({
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery,
            filter: this.state.filter ? this.state.filter : 2
        });
        }, 1000);
    }

    getProperty(params={}){
        /**to start Loader */
        this.setState({  isLoading: true })
        HTTP.Request("get",window.admin.RealStateInvesterListing,params)
        .then((response) =>{
            console.log(response.data)
             this.setState({
                array : response.data.records,
                isLoading:false,
                totalItemsCount: response.data.total
            })
        })

    }


    /* Download all users */
    downloadUsers () {
        this.setState ({isLoading: true});
        HTTP.Request("get", window.admin.DownloadAllUsers)
        .then((response) => {
            response.data.map((e)=> {

                if (e.user_type) {
                    e.user_type = e.user_type.map(type =>
                        type === 1
                            ? 'Buyer'
                            : type === 2
                                ? 'Investor'
                                : type === 3
                                    ? 'Realtor': 'Unknown'
                    );
                    e.user_type = e.user_type.join(',');
                }

                if (e.communication) {
                    e.communication =
                        e.communication === 1
                            ? 'SMS and Email'
                            : e.communication === 2
                                ? 'Email'
                                : e.communication === 3
                                    ? 'Unsusbcribe'
                                    : e.communication === 4
                                    ? 'SMS'
                                        : 'Unknown'
                }

                if (e.socialType) {
                    if (e.socialType === 'default') {
                        e.socialType = 'None'
                    }
                }

                if (e.wholesale_market) {
                    let wholesale_markets = [];
                    let wholesale_county = [];
                    if(Array.isArray(e.wholesale_market)) {
                        e.wholesale_market.forEach(market => {
                            if(market.market) {
                                wholesale_markets.push(market.market);
                                if(wholesale_county.length === 0) {
                                    wholesale_county = [...market.counties];
                                } else {
                                    wholesale_county = [...wholesale_county,';',...market.counties];
                                }
                            }
                        });
                        e.wholesale_market = wholesale_markets.join(',');
                        e.wholesale_county = wholesale_county.join(',');
                    }
                }

                if (e.renovated_county && !e.migrated) {
                    e.renovated_county = e.renovated_county.join(',');
                }

                if (e.renovated_market) {
                    let renovated_markets = [];
                    let renovated_county = [];
                    if(Array.isArray(e.renovated_market)) {
                        e.renovated_market.forEach(market => {
                            if(market.market) {
                                renovated_markets.push(market.market);
                                if(renovated_county.length === 0) {
                                    renovated_county = [...market.counties];
                                } else {
                                    renovated_county = [...renovated_county,';', ...market.counties];
                                }
                            }
                        });
                        e.renovated_market = renovated_markets.join(',');
                        e.renovated_county = renovated_county.join(',');
                    }
                }

                if (e.register_renovated === false) {
                    e.register_renovated = 'false';
                } else if (e.register_renovated === true) {
                    e.register_renovated = 'true';
                }

                if (e.register_wholesale === false) {
                    e.register_wholesale = 'false';
                } else if (e.register_wholesale === true) {
                    e.register_wholesale = 'true';
                }

                if (e.status === false) {
                    e.status = 'Inactive'
                } else if (e.status === true) {
                    e.status = 'Active'
                }

                if (e.trash === false) {
                    e.trash = 'false'
                } else if (e.trash === true) {
                    e.trash = 'true'
                }

            })
            var ws = xlsx.utils.json_to_sheet(response.data,{header:['firstname','lastname','mobile','email','source',
            'socialType','status','user_type','communication','register_renovated','renovated_market',
            'renovated_county','register_wholesale','wholesale_market','wholesale_county',
            'created_at','updated_at']});
            console.log(ws);
            this.wb = xlsx.utils.book_new();
            xlsx.utils.book_append_sheet(this.wb, ws, "People");
            console.log(this.wb);
            xlsx.writeFile(this.wb, "Marketing_List.xlsx");

            this.setState({
                isLoading: false
            })
        }) .catch(error => {
            this.setState({ isLoading: false});
            console.log("err-->",error)
        });
    }
    /* delete */
    delete(id){

        let page = 1;
        let data={};
        data.editID = id;
        data.trash=true;
        HTTP.Request("post", window.admin.removeRealtorInvestor, data)
        .then(result => {
            console.log("result after delete",result)
             /**to stop loader */
             this.setState({ isLoading: false});
             /**refresh list after deletion */
             this.getProperty();
        })
        .catch(error => {
            this.setState({ isLoading: false});
            console.log("err-->",error)
        });

        // HTTP.Request("post", window.admin.RealStateInvesterUpsert, data)
        // .then(result => {
        //      /**to stop loader */
        //      this.setState({ isLoading: false});
        //      /**refresh list after deletion */
        //      this.getProperty();
        // })
        // .catch(error => {
        //     this.setState({ isLoading: false});
        // });

    }
}
    const mapStateToProp = state => {
        return ({
            user: state.admin
        })
    }

export default connect (mapStateToProp)(TableComp);