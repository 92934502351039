import lodash from "lodash";

(function () {
  const PORT = window.location.port ? window.location.port : "7010";
  const SOCKETPORT = 8024;
  /*creating an env for application*/
  window._env = window._env || {};

  window._env = {
    app: "houseBuyer",
    prefix: "_DEV_",
    baseUrl: "http://13.58.255.242:" + PORT + "/",
    socketUrl: "http://13.58.255.242:" + SOCKETPORT
  };

  if (
    window.location.host === "admin.housebuyersofamerica.com" ||
    window.location.host.indexOf("admin.housebuyersofamerica.com" >= 0)
  ) {
    window._env.baseUrl =
      window.location.protocol + "//" + window.location.host + "/";
    window._env.socketUrl = "http://13.58.255.242:" + SOCKETPORT;
  }

  // if (window.location.host.indexOf("admin.housebuyersofamerica.com" >= 0) || window.location.host === "housebuyersofamerica.com") {
  // 	window._env.baseUrl = "https://admin.housebuyersofamerica.com/";
  // 	//window._env.baseUrl = window.location.protocol+"//" + window.location.host +"/";
  // 	window._env.socketUrl = "https://100.100.6.241:" + SOCKETPORT
  // } 

  if (PORT === "3000") {

    window._env.baseUrl = "http://13.59.152.44:3000/";
    window._env.socketUrl = "http://13.59.152.44:3000:" + SOCKETPORT;
    // window._env.baseUrl = "http://stageadmin.housebuyersofamerica.com/";
    // window._env.socketUrl = "http://100.100.7.52:" + SOCKETPORT;
  }

  if (PORT === "3001") {
    // window._env.baseUrl = "http://13.59.152.44:3000/";

    // window._env.baseUrl = "http://localhost:3000/";

    window._env.baseUrl = "http://100.100.6.56:3000/";
    window._env.socketUrl = "http://13.58.255.242:" + SOCKETPORT;
  }
  if (PORT === "8000") {
    window._env.baseUrl = "http://localhost:8000/";
    window._env.socketUrl = "http://localhost:" + SOCKETPORT;
  }
  if (PORT === "3006") {
    window._env.baseUrl = "http://localhost:4000/";
    window._env.socketUrl = "http://localhost:" + SOCKETPORT;
  }
  if (PORT === "3009") {
    window._env.baseUrl = "http://localhost:3000/";
    window._env.socketUrl = "http://localhost:" + SOCKETPORT;
  }


  window.limit = 50;
  window._env.appPath = window._env.baseUrl + "api/";
  window._env.adminPath = window._env.baseUrl + "admin_api/";
  /* Global variables */
  window._ = lodash;
})();
/**CATCHPA site key
 * EMAIL :- flexsin.nodejs@gmail.com
 * pswd:-flexsin@123
 */
export const sitekey = "6LeN_kwUAAAAAPFyDlfPZqZqf4tkb6L8eDPA7MvE";
