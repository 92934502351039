import { call, put } from "redux-saga/effects";
import { upsertCMSCall, getCMSCall, deleteCMSCall,deletePageCall,upsertReviewCall ,deleteReviewCall,getReviewCall,getBlogCall,upsertBlogCall,deleteBlogCall,getPageCall,getCmsBlockCall,deleteCmsBlockCall,upsertCmsBlockCall,getSocialCmsCall,deleteCmsSocialCall,getSlideCall,deleteSlideCall,upsertSlideCall,getFaqCategoryCall,deleteFaqCategoryCall,upsertFaqCategoryCall,getFaqCall,deleteFaqCall,upsertFaqCall,getAdsCall,deleteAdsCall,upsertAdsCall,getBuyerCmsCall,upsertBuyerCmsCall,upsertInvestorCmsCall,upsertRealtorCmsCall,getInvestorCmsCall,getRealtorCmsCall} from "../api/cms";

const _reducer = "_reducer";

export function* getCMS(action) {
	try {
		const CMSs = yield call(getCMSCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertCMS(action) {
	try {
		const addCMS = yield call(upsertCMSCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* deleteCMS(action) {
	try {
		const deleteCMS = yield call(deleteCMSCall, action);

		yield put({
			type: action.type + _reducer
		});

		action.success(deleteCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getReview(action) {
	try {
		const CMSs = yield call(getReviewCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertReview(action) {
	try {
		const addCMS = yield call(upsertReviewCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* deleteReview(action) {
	try {
		const deleteCMS = yield call(deleteReviewCall, action);

		yield put({
			type: action.type + _reducer
		});

		action.success(deleteCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getBlog(action) {
	try {
		const CMSs = yield call(getBlogCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertBlog(action) {
	try {
		const addCMS = yield call(upsertBlogCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* deleteBlog(action) {
	try {
		const deleteCMS = yield call(deleteBlogCall, action);

		yield put({
			type: action.type + _reducer
		});

		action.success(deleteCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getPage(action) {
	try {
		const CMSs = yield call(getPageCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* deletePage(action) {
	try {
		const deleteCMS = yield call(deletePageCall, action);

		yield put({
			type: action.type + _reducer
		});

		action.success(deleteCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getCmsBlock(action) {
	try {
		const CMSs = yield call(getCmsBlockCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertCmsBlock(action) {
	try {
		const addCMS = yield call(upsertCmsBlockCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* deleteCmsBlock(action) {
	try {
		const deleteCMS = yield call(deleteCmsBlockCall, action);

		yield put({
			type: action.type + _reducer
		});

		action.success(deleteCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getSocialCms(action) {
	try {
		const CMSs = yield call(getSocialCmsCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* deleteSocialCms(action) {
	try {
		const deleteCMS = yield call(deleteCmsSocialCall, action);

		yield put({
			type: action.type + _reducer
		});

		action.success(deleteCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getSlide(action) {
	try {
		const CMSs = yield call(getSlideCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertSlide(action) {
	try {
		const addCMS = yield call(upsertSlideCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* deleteSlide(action) {
	try {
		const deleteCMS = yield call(deleteSlideCall, action);

		yield put({
			type: action.type + _reducer
		});

		action.success(deleteCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getFaqCategory(action) {
	try {
		const CMSs = yield call(getFaqCategoryCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertFaqCategory(action) {
	try {
		const addCMS = yield call(upsertFaqCategoryCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* deleteFaqCategory(action) {
	try {
		const deleteCMS = yield call(deleteFaqCategoryCall, action);

		yield put({
			type: action.type + _reducer
		});

		action.success(deleteCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getFaq(action) {
	try {
		const CMSs = yield call(getFaqCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertFaq(action) {
	try {
		const addCMS = yield call(upsertFaqCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* deleteFaq(action) {
	try {
		const deleteCMS = yield call(deleteFaqCall, action);

		yield put({
			type: action.type + _reducer
		});

		action.success(deleteCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getAds(action) {
	try {
		const CMSs = yield call(getAdsCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertAds(action) {
	try {
		const addCMS = yield call(upsertAdsCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* deleteAds(action) {
	try {
		const deleteCMS = yield call(deleteAdsCall, action);

		yield put({
			type: action.type + _reducer
		});

		action.success(deleteCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getBuyerCms(action) {
	try {
		const CMSs = yield call(getBuyerCmsCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertBuyerCms(action) {
	try {
		const addCMS = yield call(upsertBuyerCmsCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getInvestorCms(action) {
	try {
		const CMSs = yield call(getInvestorCmsCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertInvestorCms(action) {
	try {
		const addCMS = yield call(upsertInvestorCmsCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}

export function* getRealtorCms(action) {
	try {
		const CMSs = yield call(getRealtorCmsCall, action);

		yield put({
			type: action.type + _reducer,
			newCMS: CMSs
		});
		action.success(CMSs);
	} catch (e) {
		action.error(e);
	}
}

export function* upsertRealtorCms(action) {
	try {
		const addCMS = yield call(upsertRealtorCmsCall, action);
		yield put({
			type: action.type + _reducer,
			newCMS: addCMS
		});
		action.success(addCMS);
	} catch (e) {
		action.error(e);
	}
}