import { Modal, Table } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';

/**COMPONENTS */
import { required } from "../../common/fieldValidations";
import DatePicker from '../../common/DateTimePicker';
import HTTP from '../../../services/http';
import OfferROW from './offer';

class OfferTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    /**event binding */
    this.restore = this.restore.bind(this);
  }

  render() {
    const { isLoading } = this.state;
    const {
      showOfferDeleteModal,
      showOfferDelete,
      offers,
      acceptOffer,
      declineOffer,
      deleteOffer,
      closeOfferDeleteModal,
      offerSetllementDialogFlag,
      closeOfferSettelmentModal,
      offerSetllementDialogFun,
      setSettlementDate,
      isRedirect,
    } = this.props;
    const settlementFormSubmit = (data) => {
      console.log("data",data);
      setSettlementDate(data);
    }

    return (
      <div className="relative">
          <div className="portlet-body min-heigh-">
            {/* if list is empty */}
            {!isLoading && !offers.length ? (
              <div
                style={{
                  'fontStyle': 'italic',
                  'textAlign': 'center',
                  background: '#efefef',
                  padding: '10px',
                  margin: '10px',
                }}> No offer found</div>
            ) : (
              <div style={{ display: 'flow-root' }}>
                <Table responsive striped bordered condensed hover>
                  <thead>
                    <tr>
                      <th width="20%"> Property </th>
                      <th> Buyer </th>
                      <th> Price </th>
                      <th width="15%"> EMD </th>
                      <th> Status </th>
                      <th width="15%"> Received On </th>
                      <th width="15%">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {offers.map((offer) => {
                      return (
                        <OfferROW
                          key={offer._id}
                          offer={offer}
                          restore={this.restore}
                          acceptOffer={acceptOffer}
                          declineOffer={declineOffer}
                          deleteOffer={deleteOffer}
                          showOfferDeleteModal={showOfferDeleteModal}
                          offerSetllementDialogFlag={offerSetllementDialogFlag}
                          offerSetllementDialogFun={offerSetllementDialogFun}
                          closeOfferSettelmentModal={closeOfferSettelmentModal}
                          closeOfferDeleteModal={closeOfferDeleteModal}
                          isRedirect={isRedirect}
                        />
                      ); 
                    })}
                  </tbody>
                  <Modal show={showOfferDelete} onHide={() => this.props.closeOfferDeleteModal()}>
                    <Modal.Header closeButton className="theme-bg">
                      <Modal.Title>DELETE OFFER</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div>
                        Are you sure you want to delete this offer?
                        {/* {offer.property.street_address} - {offer && offer.buyer}" */}
                      </div>
                    </Modal.Body>

                    <Modal.Footer>
                      {/* <Button className='btn btn-default' onClick={this.closeModel}>Cancel</Button> */}
                      <Button onClick={() => this.props.closeOfferDeleteModal()} className="btn red-mint">
                        Cancel
                      </Button>
                      <button type="submit" onClick={() => this.props.deleteOffer()} className="btn green uppercase">
                        Confirm
                      </button>
                    </Modal.Footer>
                  </Modal>

                  {/* Offer Settelment Dialog */}
                  <Modal show={offerSetllementDialogFlag} onHide={() => this.props.closeOfferSettelmentModal()}>
                      <Modal.Header closeButton className="theme-bg">
                      <Modal.Title>SETTLEMENT DATE</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                      <form onSubmit={this.props.handleSubmit(settlementFormSubmit)}>
                          <div>
                              Specify settlement date for this offer
                              <Field name="settlement_date" id="settlement_date" autocomplete="off" component={DatePicker} validate={[required]} />
                              <Button onClick={() => this.props.closeOfferSettelmentModal()} className="btn red-mint"> Cancel </Button>
                              <button style={{margin: "10px"}} type="submit" className="btn green uppercase"> Set and Accept Offer </button>
                          </div>
                      </form>
                      </Modal.Body>

                      <Modal.Footer>
                          {/* <Button onClick={() => this.closeOfferSettelmentModal()} className="btn red-mint"> Cancel </Button>
                          <button type="submit" onClick={() => this.acceptOffer()} className="btn green uppercase"> Set and Accept Offer </button> */}
                      </Modal.Footer>
                  </Modal>
                </Table>
              </div>
            )}
          </div>
      </div>
    );
  }

  /*Restore Revision Data...*/
  restore(revisionId, revisionOf) {
    /*Restore Revision*/
    HTTP.Request('put', window.admin.restoreRevision, {
      revisionId,
      revisionOf,
    })
      .then((r) => {
        this.props.dispatch(push('/ads'));
        toast.success(r.message);
      })
      .catch((error) => console.log(error));
  }
}

// export default connect()(OfferTable);


let OfferTableComp = reduxForm({
  form: 'OfferTable',
})(OfferTable);
export default connect()(OfferTableComp);
