import React, { Component } from 'react';
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import Modal from '../../common/modal';
import TT from "../../common/tooltip";
import moment from "moment-timezone";
import "../images.css"
class ROW extends Component {
    
    constructor(props){
        super(props);

        this.state={
            show: false,
            currentMarketStatus:false,
            currentMarket:{}
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    componentWillMount() {
        this.currentMarket()
    }
    render() {
        const {cms} = this.props;
        const {currentMarketStatus} = this.state;
        const displayAddress = [cms.street_address.split(",")[0],cms.city,cms.state_code+" "+cms.postal_code].join(", ")
        return (
            <tr className="odd gradeX" >
                <td className="tcap"> {cms.category} </td>             
                <td> <Link to={'/update-property/' + cms._id}>{displayAddress}</Link> </td>  
                <td className={`${currentMarketStatus ? "" : "market-bg-redd"}` }> {cms.market} </td>  
                <td> {cms.county} </td>  
                <td className="center"> <label className={ cms.status_prop?(cms.status_prop == 1)? 'label label-success' : (cms.status_prop=='2')?'label label-warning':'label label-info':''}>{!cms.status_prop ? "" : (cms.status_prop ==1)?"For sale":(cms.status_prop ==2)?"Pending":"Sold"}</label> </td>
                <td className="center">{cms.isDistressed ? "Yes":  "No"} </td>
                <td className="center" width="10%">
                    {" "}
                    <label
                        className={
                            cms.status
                                ? "label label-info"
                                : "label label-danger"
                        }
                    >
                        {cms.status ? "Active" : "Inactive"}
                    </label>{" "}
                    
                </td>
                <td> <Moment format="MM/DD/YY">{moment(cms.created_at).zone("-04:00")}</Moment> </td>
                <td> <Moment format="MM/DD/YY">{moment(cms.updated_at).zone("-04:00")}</Moment> </td>
                <td>
                {
                    (this.props.allowDeleteProperties) ?
                    <TT tooltip="Remove"><a className=" btn btn-xs red-mint" onClick={this.showModal} title="View"><i className="fa fa-trash no-pointer"  ></i></a></TT>
                        : ""
                }
                    {/* <TT tooltip="Edit"><Link to={'/update-property/' + cms._id} className=" btn btn-xs grey-mint" ><i className="fa fa-pencil-square-o"></i></Link></TT> */}
                    {this.state.show && <Modal show={true} func={this.deleteRow} closeParentModal={this.closeModal} title={cms.title} />}  
                    {!!cms.draft && cms.draft !== null && cms.draft !== "" ? (<TT tooltip="Draft">
                    <Link
                    to={
                        '/update-property/' + cms._id + "/draft"
                    }
                    className=" btn btn-xs grey-mint"
                    >
                    <i className="fa fa-floppy-o"></i>
                    </Link>
                </TT>):('')}                  
                </td>
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow(){
        this.setState({ isLoading: true,show:false });
        /*delete row by call prop func*/
        this.props.delete(this.props.cms._id);        
    }

    closeModal() {
        this.setState({ show: false });
    }

    currentMarket(){
     this.state.currentMarket = this.props.markets.find(e=>{return e.name == this.props.cms.market})
     if(!this.state.currentMarket){
         this.state.currentMarketStatus = false;
     }else{
        this.state.currentMarketStatus = this.state.currentMarket.status
     }
    }
}

export default ROW;
