import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { createNumberMask } from 'redux-form-input-masks';
import { Modal, Table } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import moment from "moment-timezone";
import { Button } from 'reactstrap';
import Moment from "react-moment";
// window.$ = window.jQuery = $;

import { required, yearBuiltValidation } from '../../common/fieldValidations';
import { normalizeBuilt, normalizePhone} from './../numberValidation';
import LocationSearchInput from '../../common/address';
import RenderFieldV1 from '../../common/renderFieldV1';
import DropdownCompV2 from '../../common/dropdown_v2';
import CreateNewComarables from './createComparables';
import DatePicker from '../../common/DateTimePicker';
import RenderField from '../../common/renderField';
import { slugify } from '../../../libs/Helper';
import {BED, BATH} from '../../common/options';
import HTTP from '../../../services/http';
import TT from "../../common/tooltip";

const currencyMask = createNumberMask({
  prefix: '',
  suffix: '',
  allowEmpty: true,
  locale: 'en-US',
});

let readOnlyChecker = true,
  zipCodeChecker = true,
  cityCodeChecker = true;
class Comparables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisable: false,
      selectedComprable: {},
      isAdd: false,
      comprablesList: [],
      show: false,
    };
    this.onAddComparables = this.onAddComparables.bind(this);
    this.onCloseComparable = this.onCloseComparable.bind(this);
  }

  handleChange = (selectedComprable) => {
    
      if (!selectedComprable.city) {
        cityCodeChecker = false;
      } else {
        cityCodeChecker = true;
      }

    this.setState({ selectedComprable: selectedComprable, isAdd: false });
    const { initialize } = this.props;
    initialize(selectedComprable);
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 1000);
  };

  closeModel = () => {
    this.setState({show:false})
  };
  onAddComparables = () => {
    this.setState({ isAdd: false });
    this.getComparablesList();
  };
  closeSelectedComparable = () => {
    this.setState({ selectedComprable: {} });
    const { initialize } = this.props;
    initialize({});
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  onCloseComparable = () => {
    this.setState({ isAdd: false });
  };

  componentDidMount() {
    console.log('Calling Comparable List');
    this.getComparablesList();
  }

  fillFormFields(address) {
    const { change } = this.props;
    console.log('props values', this.props);
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    if (address.length > 0) {
      const obj = {};
      let address_components = address[0].address_components;
      change(
        'street_address',
        address[0].formatted_address.replace(', USA', ''),
      );
      change(
        'slug',
        slugify(address[0].formatted_address.replace(', USA', '')),
      );
      console.log(address_components);
      for (var i = 0; i < address_components.length; i++) {
        var addressType = address_components[i].types[0];
        var checkState = address_components[i].types[1];
        if (componentForm[addressType]) {
          var val = address_components[i][componentForm[addressType]];
          obj[addressType] = val;

          if (addressType === 'administrative_area_level_1') {
            change('state', val);
            if (checkState === 'political') {
              let state_value = address_components[i]['short_name'];
              change('state_code', state_value);
            }
          }
          if (addressType === 'locality') {
            cityCodeChecker = true;
            change('city', val);
          }
          if (!document.getElementsByClassName("comparableForm")[0].querySelector("input[name='city']").value) {
            cityCodeChecker = false;
          }

          if (addressType === 'country') {
            change('country', val);
          }

          if (addressType === 'postal_code') {
            zipCodeChecker = true;
            change('zipcode', val);
          } else {
            zipCodeChecker = false;
          }
        }
      }

      HTTP.Request('post', window.admin.getCounty, obj)
        .then((result) => {
          const { data } = result;
          console.log(data);
          if (data.length > 0) {
            change('county', data[0].county);
            change('market', data[0].market);
            readOnlyChecker = true;
            change('unit_no', '');
            console.log('inside County', data[0].county);
          } else {
            readOnlyChecker = false;
            change('unit_no', ' ');
            console.log('outside County', readOnlyChecker);
          }
        })
        .catch((err) => {
          if (err) {
            toast(err.message, {
              type: 'error',
            });
          }
        });
    }
  }

  handleSelect = (address) => {
    const { change } = this.props;
    geocodeByAddress(address)
      .then((result) => {
        this.fillFormFields(result);
        return getLatLng(result[0]);
      })
      .then(({ lat, lng }) => {
        let request = {
          lat,
          lng,
        };
        change('lat', request.lat);
        change('lng', request.lng);

        this.setState({
          coordinates: request,
        });

        console.log(readOnlyChecker, this.state);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  removeComparable = (cid) => {
    if (cid) {
      HTTP.Request('delete', window.admin.removeComparablesList + '?id=' + cid)
        .then((r) => {
          this.getComparablesList();
          this.closeSelectedComparable();
          this.setState({show:false})
        })
        .catch((error) => console.log(error));
    }
  };

  getComparablesList = () => {
    const propertyId = this.props.property_id;
    if (propertyId) {
      HTTP.Request('get', window.admin.getComparablesList, {property_id: propertyId,})
        .then((r) => {
          this.setState({ comprablesList: r.data.comparable });
          window.scrollTo(0, 0);
        })
        .catch((error) => console.log(error));
    }
  };

  compareableDOMList = () => {
    return this.state.comprablesList.map((e) => {
      return (
        <>
          <tbody>
            <tr className="odd gradeX">
              <td>
                {' '}
                <a onClick={() => this.handleChange(e)}>
                  {e.street_address} 
                  {/* , {e.city}, {e.state}, {e.zipcode}{' '} */}
                </a>
              </td>
              <td>
                {' '}
                {e.bedrooms ? e.bedrooms + ' / ' : ''} {e.bathrooms}
                {!e.bedrooms && !e.bathrooms ? '-' : ''}

              </td>
              <td> {e.sq_footage ? this.currencyFormat(e.sq_footage) : '-'} </td>
              <td> {e.year_built ? e.year_built : '-'} </td>
              <td> {e.last_sold_price ? '$' + this.currencyFormat(e.last_sold_price) : '-'} </td>
              <td>
                {e.last_sold_date ? <Moment format="MM/DD/YY">
                  {moment(e.last_sold_date).zone('-04:00')}
                </Moment> : '-'}
              </td>
              <td>
                <TT tooltip="Remove">
                  <a
                    onClick={() =>
                      this.setState({ show: true, selectedComprable :{_id:e._id}})
                    }
                    // onClick={() => this.removeComparable(e._id)}
                    className="btn btn-xs red-mint"
                    title="View"
                  >
                    <i className="fa fa-trash no-pointer"></i>
                  </a>
                </TT>
              </td>
            </tr>
          </tbody>
        </>
      );
    });
  };

  currencyFormat(num) {
      return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  updateComparable = (data) => {
    // event.preventDefault();
    let param = {
      ...data,
      property_id: this.props.property_id,
    };
    HTTP.Request('put', window.admin.updateComparablesList, param)
      .then((r) => {
        console.log('on Update Comparables', r);
        toast.success(r.message);
        this.props.reset();
        this.getComparablesList();
        this.setState({ selectedComprable: {} });
      })
      .catch((error) => console.log(error));
  };

  render() {
    const {
      showComparablesAutoAddModal,
      showComparablesAddModal,
      closeComparablesAddModal,
    } = this.props;
    return (
      <div>
        {
          this.props.propertyType != "renovated" && 
          <div className="row">
            <div className="col-12">
              <div className="access-btn pull-right" style={{ marginRight: 15 }}>
                <button
                  className="btn green uppercase"
                  style={{ marginRight: 10 }}
                  onClick={() =>
                    this.setState({ isAdd: true, selectedComprable: {} })
                  }
                  disabled={this.state.isAdd}
                >
                  <i className="fa fa-plus"></i>Add Manual Comparable
                </button>
              </div>
            </div>
          </div>
        }
        {this.state.isAdd ? (
          <CreateNewComarables
            onCloseComparable={this.onCloseComparable}
            onAddComparables={this.onAddComparables}
            property_id={this.props.property_id}
          />
        ) : null}

        {this.state.comprablesList && this.state.comprablesList.length > 0 ? (
          <div style={{ margin: '15px 0' }}>
            <Table responsive striped bordered condensed hover>
              <thead>
                <tr>
                  <th> Full Address </th>
                  <th> Bed / Bath </th>
                  <th> Sq. Ft. </th>
                  <th> Year Built </th>
                  <th> Last Sold Price </th>
                  <th> Last Sold Date </th>
                  <th> Action </th>
                </tr>
              </thead>
              {this.compareableDOMList()}
            </Table>
            {/* <div style={{ float: "right" }}>
                <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={window.limit}
                    totalItemsCount={this.state.totalItemsCount}
                    pageRangeDisplayed={3}
                    onChange={this.handlePageChange}
                />
            </div> */}
          </div>
        ) : (
          <div
            style={{
              'font-style': 'italic',
              'text-align': 'center',
              background: '#efefef',
              padding: '10px',
              margin: '10px',
            }}
          >
            {this.props.propertyType == "renovated" ? "You cannot add comparable for Renovated Property." : "No Comparable created yet."}
          </div>
        )}

        {Object.keys(this.state.selectedComprable).length > 0 &&
        this.state.selectedComprable.street_address ? (
          <>
            <div className="box" style={{ marginTop: 15 }}>
              <h2
                style={{
                  background: '#efefef',
                  padding: '10px',
                  margin: '0 0 20px',
                  'font-size': '24px',
                }}
              >
                Update Comparable
              </h2>
              <form onSubmit={this.props.handleSubmit(this.updateComparable)}>
                <div className="row comparableForm">
                  <div className="col-md-3">
                    <Field
                      component={LocationSearchInput}
                      type="text"
                      formGroupClassName="col-md-3 col-lg-3"
                      name="street_address"
                      autocomplete="off"
                      label="Street Address*"
                      placesAutocomplete={true}
                      onSelect={this.handleSelect}
                      id="street_address"
                      placeholder="Enter Street Address"
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="zipcode"
                      fieldName="Zipcode*"
                      type="text"
                      max={5}
                      autocomplete="off"
                      id="zipcode"
                      readOnly={zipCodeChecker}
                      component={zipCodeChecker == true ? RenderFieldV1 : RenderField}
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="city"
                      fieldName="City*"
                      autocomplete="off"
                      type="text"
                      id="city"
                      readOnly={cityCodeChecker}
                      component={cityCodeChecker == true ? RenderFieldV1 : RenderField}
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="state"
                      fieldName="State*"
                      autocomplete="off"
                      type="text"
                      autocomplete="off"
                      id="state"
                      readOnly={true}
                      component={RenderFieldV1}
                      validate={[required]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Field
                      name="unit"
                      fieldName="Unit"
                      type="text"
                      autocomplete="off"
                      id="unit_no"
                      component={RenderField}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="year_built"
                      fieldName="Year Built*"
                      autocomplete="off"
                      type="text"
                      max={4}
                      normalize={normalizeBuilt}
                      id="year_built"
                      validate={[required, yearBuiltValidation]}
                      component={RenderField}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="lat"
                      fieldName="Latitude*"
                      type="number"
                      id="lat"
                      readOnly={true}
                      component={RenderFieldV1}
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="lng"
                      fieldName="Longitude*"
                      type="number"
                      id="lng"
                      readOnly={true}
                      component={RenderFieldV1}
                      // component={RenderField}
                      validate={[required]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Field
                      name="sq_footage"
                      fieldName="Square Footage*"
                      autocomplete="off"
                      type="number"
                      id="square_footage"
                      component={RenderField}
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="lot_size"
                      fieldName="Lot Size"
                      autocomplete="off"
                      type="number"
                      id="lot_size"
                      component={RenderField}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="bedrooms"
                      fieldName="Bedrooms"
                      id="bedrooms"
                      options={BED}
                      label="Beds*"
                      autocomplete="off"
                      placeholder="Select"
                      textField="title"
                      valueField="value"
                      component={DropdownCompV2}
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="bathrooms"
                      fieldName="Bathrooms"
                      id="bathrooms"
                      options={BATH}
                      autocomplete="off"
                      label="Baths*"
                      placeholder="Select"
                      textField="title"
                      valueField="value"
                      component={DropdownCompV2}
                      validate={[required]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <Field
                      name="last_sold_price"
                      fieldName="Last Sold Price*"
                      type="text"
                      autocomplete="off"
                      normalize={normalizePhone}
                      {...currencyMask}
                      id="last_sold_price"
                      component={RenderField}
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-3">
                    <Field
                      name="last_sold_date"
                      autocomplete="off"
                      id="last_sold_date"
                      fieldName="Last Sold Date*"
                      component={DatePicker}
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-2">
                    <button
                      className="btn green uppercase w-100"
                      style={{ marginTop: 25 }}
                      type="submit"
                    >
                      Update
                    </button>
                  </div>
                  <div className="col-md-2">
                    <a
                      onClick={() => this.setState({ show: true })}
                      className="btn red uppercase w-100"
                      style={{ marginTop: 25 }}
                    >
                      Delete
                    </a>
                  </div>
                  <div className="col-md-2">
                    <a
                      onClick={() => this.closeSelectedComparable()}
                      className="btn yellow uppercase w-100"
                      style={{ marginTop: 25 }}
                    >
                      Close
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </>
        ) : null}

        <Modal show={this.state.show} onHide={this.closeModel}>
          <Modal.Header closeButton className="theme-bg">
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete?</p>
            <p>
              <strong>
                <small>This action cannot be undone.</small>
              </strong>
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button className="btn btn-default" onClick={this.closeModel}>
              Cancel
            </Button>
            <Button
              className="btn red-mint"
              onClick={() =>
                this.removeComparable(this.state.selectedComprable._id)
              }
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
let CreateComarables = reduxForm({
  form: 'Comparables',
})(Comparables);
const mapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(CreateComarables);
