import React, { Component } from "react";
import { connect } from "react-redux";
import RenderFiled from "../common/renderField";
import { Tabs, Tab } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import Loader from "../common/loader";
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multi";
import DropdownCompV2 from "../common/dropdown_v2";
import FroalaEditorComp from "../common/floalaEditor";
import { STATUS } from "../common/options";
import DropdownComp from "../common/DropdownList";
import ImageCropper from "../common/ImageCropper";
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
import DatePicker from "../common/DateTimePicker";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import TT from "../common/tooltip";

import {
  required,
  ValidateOnlyAlpha,
  mobileValidate,
  emailValiadte
} from "../common/fieldValidations";

class RealtorCMSAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "",
      section_five:"",
      section_four:"",
      isLoading: false,
      formAction: "ADD",
      status: false,
      editID:""
    };
    /**event binding  */
    this.upsertCMS = this.upsertCMS.bind(this);
    this.getaCMS = this.getaCMS.bind(this);
   /*  this.getFile = this.getFile.bind(this); */
  }

  componentDidMount() {
    this.getaCMS();
  }

  render() {
    const { handleSubmit } = this.props;
    const { isLoading, formAction, invalidfFile } = this.state;
    return (
      <div className="relative">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <PageHeader
              route={
                formAction === "ADD"
                  ? "Add Realtors CMS"
                  : "Edit Realtors CMS"
              }
              parent="Realtors CMS"
              parentRoute="/realtor-cms-add"
              // parentRoute="/realtor-cms-list"
            />

            <div className="tab-pane active">
              <Tabs defaultActiveKey={1} animation={false} id="profileTabs">
                <Tab
                  eventKey={1}
                  title={formAction === "ADD" ? "Add New Realtors" : "Edit Realtors"}
                >
                  <form onSubmit={handleSubmit(this.upsertCMS)}>
                    <Field
                      name="title"
                      fieldName="Title*"
                      type="text"
                      component={RenderFiled}
                      validate={[required, ValidateOnlyAlpha]}
                    />
                    <Field name="meta_title"   fieldName="Meta Title*" type="text" component={RenderFiled} validate={[required]} />
                    <Field name="meta_description" textarea fieldName="Meta Description" component={RenderFiled}/> 
                    <Field name="section_one"   fieldName="Section One Title*" type="text" component={RenderFiled} validate={[required]} />
                    <Field name="content" fieldName="Section One Description*" type="text" component={FroalaEditorComp} validate={[required]} /><br/> 
                    <Field name="section_two"   fieldName="Section Two Title*" type="text" component={RenderFiled} validate={[required]} />
                    <Field name="section_four" fieldName="Section Two Description*" type="text" component={FroalaEditorComp} validate={[required]} /> <br/>       
                    <Field name="section_three"   fieldName="Section Three Title*" type="text" component={RenderFiled} validate={[required]} />
                    <Field name="section_five" fieldName="Section Three Description*" type="text" component={FroalaEditorComp} validate={[required]} /> <br/>              

                    {/* <Field textarea name="summary" validate={[required]} component={RenderFiled} fieldName="Summary" />                                */}
        
                    {/* <label>Image</label>
                    <Field
                      component={ImageCropper}
                      id={"image"}
                      name="image"
                      minWidth={450}
                      minHeight={300}
                      dimensionsCheck={true}
                      ratioUpper={450}
                      ratioLower={300}
                    />
                  {this.state.image ? <img src={this.state.image.secure_url} alt="" width='120px' className="img-responsive img-thumbnail i-bot"/> : null} */}
                    
                    <nr />
                    <Field
                      name="status"
                      options={[
                        { label: "Active", value: true },
                        { label: "Inactive", value: false }
                      ]}
                      label="Status"
                      defaultValue={this.state.status ? "Active" : "Inactive"}
                      textField="label"
                      valueField="value"
                      component={DropdownComp}
                    />
                    <br />

                    <div className="form-actions">
                      <button
                        type="submit"
                        className="btn green uppercase"
                        disabled={this.props.invalid || this.props.submitting}
                      >
                        {formAction === "ADD" ? "Add" : "Update"}
                      </button>
                    </div>
                  </form>
                </Tab>
                
              </Tabs>
            </div>
          </div>
        )}
      </div>
    );
  }

  upsertCMS(data) {
    const { match } = this.props;
    const {editID}=this.state;
    this.setState({isLoading: true});
     
    /*bind type of Post*/
    data.type = "realtors";
   
    if(editID) data.editID = editID;

    let formData = new FormData();
    /*add file to request*/
    formData.append("file", data.image);
    formData.append("data", JSON.stringify(data));

    this.props.dispatch({
        type : "Admin-upsertRealtorCms",
        data : formData,
        success : (r) => {
          data.editID=r.data._id;
            this.props.dispatch(push("/realtor-cms-add"));
            toast.success(r.message);
            this.setState({isLoading: false});
            /*log audits for user*/
            this.props.dispatch({
                type: ADMIN_TRACK_AUDIT_LOGS,
                action: {
                    comment: "Modified the content of Slide - " + r.data.title,
                    type: "audit"
                }
            });
        },
        error : (e) => {
            if(e.errors){
                e.errors.map((error) => toast('The Title already exists', {type: "error"}))
            }
        }
    });
}

getaCMS(){
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let cmsID = (match.params._id) ? match.params._id : null;
        this.setState({ isLoading: true,  formAction:"EDIT"})
        HTTP.Request("get", window.admin.getRealtorCms, { type: "realtors"})
        .then(result => {
          initialize(result.data);
          console.log("result.data._id",result.data._id)
            this.setState({ isLoading: false, 
              status: result.data.status, 
              // section_five:result.data.section_five?result.data.section_five:"",
              // section_four:result.data.section_four?result.data.section_four:"",
              editID:result.data._id?result.data._id:"",
           
            })
            /*set data to form*/
            
        }).catch(error=>{
          this.setState({ isLoading: false});
        })

}


  
}

let RealtorCMSForm = reduxForm({
  form: "RealtorCMSForm"
})(RealtorCMSAdd);

export default connect()(RealtorCMSForm);
