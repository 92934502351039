import React, { Component } from 'react';
import Moment from "react-moment";
import moment from "moment-timezone";
import { Link } from 'react-router-dom';
import Modal from '../../common/modal';
import TT from "../../common/tooltip";

class ROW extends Component {
    
    constructor(props){
        super(props);

        this.state={
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    
    render() {
        const {cms} = this.props;
        const displayAddress = [cms.street_address.split(",")[0],cms.city,cms.state_code+" "+cms.postal_code].join(", ")
        return (
            <tr className="odd gradeX" >
                <td> {cms.category} </td>             
                <td> {displayAddress} </td>  
                <td className="center"> <label className={cms.status_prop?(cms.status_prop ==1)? 'label label-success' :(cms.status_prop=='2')?'label label-danger':'label label-info':''}>{(cms.status_prop ==1)?"For sale":(cms.status_prop ==2)?"Pending":"Sold"}</label> </td>
                <td className="center" width="10%">
                    {" "}
                    <label
                        className={
                            cms.status
                                ? "label label-info"
                                : "label label-danger"
                        }
                    >
                        {cms.status ? "Active" : "Inactive"}
                    </label>{" "}
                </td>
                <td><Moment format="DD MM YYYY">{moment(cms.created_at).zone("-04:00")}</Moment></td>

                {/* <td><Moment format="MM/DD/YY">{cms.created_at}</Moment></td> */}
                <td>
                    <TT tooltip="Add Flyer"><Link to={'/wholesale-flyer/' + cms._id} className=" btn btn-xs grey-mint" ><i className="fa fa-pencil-square-o"></i></Link></TT>
                    {this.state.show && <Modal show={true} func={this.deleteRow} closeParentModal={this.closeModal} title={cms.title} />}                    
                </td>
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow(){
        this.setState({ isLoading: true,show:false });
        /*delete row by call prop func*/
        this.props.delete(this.props.cms._id);        
    }

    closeModal() {
        this.setState({ show: false });
    }

}

export default ROW;

