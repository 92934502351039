import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'reactstrap';

import { connect } from 'react-redux';
import DatePicker from '../../common/DateTimePicker';
import TimePicker from '../../common/timePicker';
import { toast } from 'react-toastify';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Field, reduxForm } from 'redux-form';
import HTTP from '../../../services/http';
import moment from 'moment';
import CreateOpenHouses from './createOpenHouse';

class OpenHouses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showCancelModal: false,
      showRemoveBuyerModal: false,
      showInfo: false,
      showRemoveOpenHouseModal: false,
      openHouseData: [],
      selectedOpenHouse: '',
      buyerId: '',
      buyerRemoveId: '',
      buyerOpenHouseId: '',
      isDisable: false,
      items: [],
    };
    /*bind this with methods*/
    this.showModal = this.showModal.bind(this);
    this.showInfoModal = this.showInfoModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onAddOpenHouse = this.onAddOpenHouse.bind(this);
  }

  onAddOpenHouse = () => {
    this.getOpenHouseListData();
  };

  handleChange = (name, event) => {
    console.log('data', name, event);
    this.setState({ name: event });
  };
  handleOnSearch = (string, results) => {
    console.log('search', string, results);
    HTTP.Request('post', window.admin.searchBuyerList, { keyword: string })
      .then((result) => {
        console.log('Search Buyer result - ', result);
        const tempData = result.data;
        const item = tempData.map((items, index) => {
          return {
            _id: items._id,
            name: items.firstName + ' ' + items.lastName,
          };
        });
        this.setState({ items: item });
      })
      .catch((err) => {
        toast(err.message, { type: 'error' });
      });
  };

  handleOnSelect = (item) => {
    // the item selected
    console.log(item);
    this.setState({ buyerId: item._id });
  };
  getOpenHouseDetail = (data) => {
    const { initialize } = this.props;
    HTTP.Request('get', window.admin.getOpenHouseDetail, {
      id: data,
    })
      .then((result) => {
        console.log('listopenhouse----->>>', result);
        initialize(result.data);
        let temp = result.data.updated_status;
        const status = temp == 'CANCELLED' ? true : false;
        this.setState({ isDisable: status });

        //  toast(result.message, { type: 'success' });
      })
      .catch((err) => {
        toast(err.message, { type: 'error' });
      });
  };
  getOpenHouseListData = () => {
    const propertyId = this.props.property_id;
    console.log(propertyId, 'propertyId');

    if (propertyId) {
      HTTP.Request('get', window.admin.openHouseList, { id: propertyId })
        .then((result) => {
          this.setState({ openHouseData: result.data});
          toast(result.message, { type: 'success' });
        })
        .catch((err) => {
          toast(err.message, { type: 'error' });
        });
    }
  };
  componentDidMount() {
    this.getOpenHouseListData();
  }
  handleCancelOpenHouse = () => {
    HTTP.Request('get', window.admin.cancelOpenHouse, {
      id: this.state.selectedOpenHouse,
    })
      .then((result) => {
        console.log('listopenhouse----->>>', result);
        toast(result.message, { type: 'success' });
        this.getOpenHouseDetail(this.state.selectedOpenHouse);
        this.setState({ showCancelModal: false });
      })
      .catch((err) => {
        toast(err.message, { type: 'error' });
        this.setState({ showCancelModal: false });
      });
  };
  handleUpdateOpenHouse = (data) => {
    console.log('Updated Data - ', data);

    const tempTimeTo = Date.parse('01/01/2011 ' + data.updated_time_to);
    const tempTime = Date.parse('01/01/2011 ' + data.updated_time);
    const timeDiff = Math.abs(tempTimeTo - tempTime) / 36e5;
    const isTimeInValid = tempTimeTo > tempTime;

    if (data.updated_time && data.updated_time_to && data.updated_date) {
      if (!isTimeInValid) {
        toast('From Time should be less then To Time.', { type: 'error' });
        return false;
      }
      if (timeDiff > 2) {
        toast('Time difference should not be more then 2 hours.', {
          type: 'error',
        });
        return false;
      }
      let addData = {
        ...data,
        property_id: this.props.property_id,
        edit_id: this.state.selectedOpenHouse,
      };
      HTTP.Request('post', window.admin.updateOpenHouse, addData)
        .then((result) => {
          toast(result.message, { type: 'success' });
          this.getOpenHouseListData();
        })
        .catch((err) => {
          toast(err.message, { type: 'error' });
        });
    } else {
      toast('Please select valid Date and Time.', { type: 'error' });
    }
  };
  handleAddOpenHouseBuyer = () => {
    let addData = {
      id: this.state.selectedOpenHouse,
      buyerId: this.state.buyerId,
    };

    HTTP.Request('post', window.admin.addOpenHouseBuyer, addData)
      .then((result) => {
        this.getOpenHouseListData();
        document.getElementsByClassName('clear-icon')[0].click();
        toast(result.message, { type: 'success' });
      })
      .catch((err) => {
        toast(err.message, { type: 'error' });
      });
  };
  handleShowOpenHouse = (e) => {
    console.log('data--->', e);
    this.setState({ selectedOpenHouse: e._id, showInfo: true, selectedOpenHouseData: e });
    this.getOpenHouseDetail(e._id);
  };
  handleRemoveOpenHouse = () => {
    HTTP.Request('get', window.admin.removeOpenHouse, {
      id: this.state.selectedOpenHouse,
    })
      .then((result) => {
        this.getOpenHouseListData();
        toast(result.message, { type: 'success' });
        this.setState({
          showRemoveOpenHouseModal: false,
          showInfo: false,
        });
      })
      .catch((err) => {
        toast(err.message, { type: 'error' });
        this.setState({ showRemoveOpenHouseModal: false });
      });
  };
  handleRemoveBuyer = () => {
    let param = {
      buyerId: this.state.buyerRemoveId,
      id: this.state.buyerOpenHouseId,
    };
    console.log(param, 'Param Data');
    HTTP.Request('post', window.admin.removeBuyerOpenHouse, param)
      .then((result) => {
        this.getOpenHouseListData();
        toast(result.message, { type: 'success' });
        this.setState({ showRemoveBuyerModal: false });
      })
      .catch((err) => {
        toast(err.message, { type: 'error' });
        this.setState({ showRemoveBuyerModal: false });
      });
  };
  arrayToCSV = (objArray) => {
    const array =
      typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(',')}` + '\r\n';

    return array.reduce((str, next) => {
      str +=
        `${Object.values(next)
          .map((value) => `"${value}"`)
          .join(',')}` + '\r\n';
      return str;
    }, str);
  };
  handleDownloadBuyers = () => {
    let param = {
      houseId: this.state.selectedOpenHouse,
      propertyId: this.props.property_id,
    };
    HTTP.Request('post', window.admin.downlaodBuyersData, param)
      .then((result) => {
        console.log('result', result);
        this.arrayToCSV(result.data);
        const csvString = [
          ...result.data.map((item) => [
            item.buyerId,
            item.email,
            item.mobile,
            item.firstname,
            item.lastname,
            item.created_at,
          ]),
        ]
          .map((e) => e.join(','))
          .join('\n');
        window.open(
          'data:text/csv;charset=utf-8,' + escape(csvString),
          '_self',
        );
        console.log(csvString);
        toast(result.message, { type: 'success' });
      })
      .catch((err) => {
        toast(err.message, { type: 'error' });
      });
  };
  render() {
    console.log("STATE IS--->",this.state.items);
    const suggestions = this.state.items.sort().filter((v) => v);
    const renderData =
      this.state.openHouseData && this.state.openHouseData.length > 0 ? (
        this.state.openHouseData.map((e) => {
          return (
            <>
              <div className="mb-10 links">
                <a
                  id={e._id}
                  className={
                    this.state.selectedOpenHouse == e._id ? 'active' : ''
                  }
                  onClick={() => this.handleShowOpenHouse(e)}
                  key={e._id}
                >
                  {moment(e.date).format('MMM DD')} {e.time} - {e.time_to}{' '}
                </a>
              </div>

              <div style={{ padding: '0 0 12px' }}>
                {e.buyers && e.buyers.length > 0
                  ? e.buyers.map((buyer) => {
                      if (buyer.buyerName) {
                        return (
                          <>
                            <div style={{ marginLeft: 15 }}>
                              <a
                                onClick={() =>
                                  this.setState({
                                    showRemoveBuyerModal: true,
                                    buyerRemoveId: buyer.buyerId,
                                    buyerOpenHouseId: e._id,
                                  })
                                }
                              >
                                <i
                                  className="fa fa-times mr-10"
                                  aria-hidden="true"
                                ></i>
                              </a>
                              {buyer.buyerName}
                            </div>
                          </>
                        );
                      }
                    })
                  : null}
              </div>
            </>
          );
        })
      ) : (
        <>
          <div style={{ 'font-style': 'italic' }}>
            No Open Houses created yet
          </div>
        </>
      );
    return (
      <div>
        <div className="row" style={{ background: '#efefef', padding: '15px' }}>
          <div className="col-sm-3">
            <div
              className="open_house_list"
              style={{ marginTop: 15, marginLeft: 50 }}
            >
              {renderData}
            </div>
          </div>
          <div className="col-sm-9" style={{ borderLeft: '1px solid gray' }}>
            {this.state.showInfo ? (
              <>
                <form
                  onSubmit={this.props.handleSubmit(this.handleUpdateOpenHouse)}
                >
                  <div className="col-sm-4">
                    <Field
                      name="updated_date"
                      id="updated_date"
                      fieldName="Date*"
                      component={DatePicker}
                      disabled={this.state.isDisable}
                      min={new Date()}
                      max={new Date('1-01-2030')}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Field
                      name="updated_time"
                      id="updated_time"
                      fieldName="Time From*"
                      component={TimePicker}
                      disabled={this.state.isDisable}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Field
                      name="updated_time_to"
                      id="updated_time_to"
                      fieldName="Time To*"
                      component={TimePicker}
                      disabled={this.state.isDisable}
                    />
                  </div>
                  <div className="col-sm-4">
                    <button
                      className="btn green w-100"
                      type="submit"
                      disabled={this.state.isDisable}
                    >
                      Update Open house
                    </button>
                  </div>
                </form>
                <div className="col-sm-4">
                  {this.state.isDisable ? (
                    <button
                      className="btn btn-danger w-100"
                      onClick={() =>
                        this.setState({ showRemoveOpenHouseModal: true })
                      }
                    >
                      Remove Open house
                    </button>
                  ) : (
                    <button
                      className="btn red w-100"
                      onClick={() => {
                        this.setState({ showCancelModal: true });
                      }}
                    >
                      Cancel Open house
                    </button>
                  )}
                </div>
                <div className="col-sm-4">
                  <button
                    className="btn green w-100"
                    disabled={
                      this.state.isDisable ||
                      !(
                        this.state?.selectedOpenHouseData?.buyers?.length &&
                        this.state?.selectedOpenHouseData?.buyers[0]?.buyerName
                      )
                    }
                    onClick={this.handleDownloadBuyers}
                  >
                    Download Buyers
                  </button>
                </div>
                {this.state.isDisable ? null : (
                  <div className="m-top-30">
                    <div className="col-sm-4 m-top-30">
                      <ReactSearchAutocomplete
                        placeholder="Search with Buyer Name"
                        items={this.state.items}
                        onSearch={this.handleOnSearch}
                        onSelect={this.handleOnSelect}
                        styling={{
                          borderRadius: '5px',
                          height: '32px',
                          zIndex: 1,
                          fontSize: '1.4rem',
                          padding: '0',
                        }}
                      />
                    </div>
                    <div className="col-sm-4 m-top-30">
                      <button
                        className="btn green w-100"
                        onClick={this.handleAddOpenHouseBuyer}
                      >
                        + Add Buyer
                      </button>
                    </div>
                    <div className="col-sm-4 m-top-30">
                      <button className="btn green w-100">
                        Send Notification
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : null}
          </div>
        </div>
        <CreateOpenHouses
          onAddOpenHouse={this.onAddOpenHouse}
          property_id={this.props.property_id}
        />
        <Modal show={this.state.showCancelModal} onHide={this.closeModal}>
          <Modal.Header closeButton className="theme-bg">
            <Modal.Title>CANCEL OPEN HOUSE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure you want to cancel open house?</div>
          </Modal.Body>

          <Modal.Footer>
            {/* <Button className='btn btn-default' onClick={this.closeModel}>Cancel</Button> */}
            <Button onClick={this.closeModal} className="btn red-mint">
              Cancel
            </Button>
            <button
              type="submit"
              onClick={this.handleCancelOpenHouse}
              className="btn green uppercase"
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showRemoveBuyerModal} onHide={this.closeModal}>
          <Modal.Header closeButton className="theme-bg">
            <Modal.Title>REMOVE OPEN HOUSE REGISTRATION</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure you want to unregister Test tech?</div>
          </Modal.Body>

          <Modal.Footer>
            {/* <Button className='btn btn-default' onClick={this.closeModel}>Cancel</Button> */}
            <Button onClick={this.closeModal} className="btn red-mint">
              Cancel
            </Button>
            <button
              type="submit"
              onClick={() => this.handleRemoveBuyer()}
              className="btn green uppercase"
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showRemoveOpenHouseModal}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton className="theme-bg">
            <Modal.Title>REMOVE OPEN HOUSE</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure you want to remove open house?</div>
          </Modal.Body>

          <Modal.Footer>
            {/* <Button className='btn btn-default' onClick={this.closeModel}>Cancel</Button> */}
            <Button onClick={this.closeModal} className="btn red-mint">
              Cancel
            </Button>
            <button
              type="submit"
              onClick={this.handleRemoveOpenHouse}
              className="btn green uppercase"
            >
              Confirm
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  showInfoModal() {
    /*show popup and confirm before delete*/
    this.setState({ showInfo: true });
  }

  showModal() {
    /*show popup and confirm before delete*/
    this.setState({ show: true });
  }

  closeModal() {
    this.setState({
      showCancelModal: false,
      showRemoveBuyerModal: false,
      showRemoveOpenHouseModal: false,
    });
  }
}
let UpdateOpenHouses = reduxForm({
  form: 'OpenHouses',
})(OpenHouses);
const mapStateToProps = (state) => {
  return {
    state,
  };
};
export default connect(mapStateToProps)(UpdateOpenHouses);
