
import { logsCount } from "../components/common/actions";

const initState = {
};

const _reducer = "_reducer";
export default function logs_count(state = initState, data) {
    switch (data.type) {
        case logsCount: {
            console.log("data of logsCount", data);
            return data.params
        }
        default: {
            return state;
        }
    }
}