import React, { Component } from "react";
import { Alert } from "react-bootstrap";
import HTTP from "../../../services/http";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import Loader from "../../common/loader";
import { ValidateOnlyAlpha } from "../../common/fieldValidations";

/**COMPONENTS */

import ROW from "./row";

var timer;
class Table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: [],
            isLoading: false,
            searchQuery: "",
            activePage: 1,
            totalItemsCount: 1
        };
        /**event binding */
        this.getList = this.getList.bind(this);
        this.search = this.search.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    componentWillMount() {
        this.getList();
    }
    render() {
        const { array, isLoading, seracherror } = this.state;
        return (
            <div>
                {isLoading && <Loader />}

                <div className="portlet light bordered">
                    <div className="portlet-body min-heigh">
                        {/* actions search addnew  */}
                        <div className="table-toolbar">
                            <div className="row">
                                <div className="col-md-7 col-sm-offset-5" />
                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="btn-group pull-left">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search from photo gallery(s)"
                                            onChange={this.search}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="btn-group pull-right">
                                        <Link to="/property-management/add">
                                            <button className="btn sbold green">
                                                {" "}
                                                <i className="fa fa-plus" />
                                                <span>
                                                    {" "}
                                                    Add New Photo Gallery
                                                </span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* actions search addnew END */}

                        {/* if list is empty */}
                        {!isLoading && !array.length ? (
                            <Alert bsStyle="warning">
                                <strong>No Data Found !</strong>
                            </Alert>
                        ) : (
                            <div style={{ display: "flow-root" }}>
                                <table
                                    className="table table-striped table-bordered table-hover table-checkable order-column"
                                    id="sample_1"
                                >
                                    <thead>
                                        <tr>
                                            <th width="30%">Address</th>
                                            <th width="15%">Before Image</th>
                                            <th width="15%">After Image</th>
                                            <th width="10%"> Status </th>
                                            <th width="10%"> Date </th>
                                            <th width="5%"> Action </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {array.map(element => {
                                            return (
                                                <ROW
                                                    key={element._id}
                                                    element={element}
                                                    delete={this.delete}
                                                />
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div style={{ float: "right" }}>
                                    <Pagination
                                        activePage={this.state.activePage}
                                        itemsCountPerPage={window.limit}
                                        totalItemsCount={
                                            this.state.totalItemsCount
                                        }
                                        pageRangeDisplayed={3}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    /**PAGINATION */
    handlePageChange(eventKey) {
        this.setState({ activePage: eventKey });
        this.getList({
            page: eventKey ? eventKey : 1,
            searchQuery: this.state.searchQuery ? this.state.searchQuery : ""
        });
        /**to set query in route */
        this.props.history.push({
            search: "?page=" + eventKey
        });
    }
    /**SEARCH */
    search(e) {
        /**to remove Event Pooling  */
        e.persist();
        let searchedValue = e.target.value;
        let seracherror = ValidateOnlyAlpha(searchedValue);
        if (seracherror) {
            this.setState({ seracherror: seracherror });
            return;
        }

        if (!searchedValue.length) {
            this.setState({ searchQuery: '', seracherror: '', activePage: 1 });
            this.props.history.push({
                search: '?page=' + 1
            });
        } else if (searchedValue.length > 2) {
            this.props.history.push({
            search: '?page=' + 1
        });
        this.setState({ searchQuery: searchedValue, activePage: 1, seracherror: seracherror });
        } else {
            this.setState({ seracherror: 'Enter minimum 3 characters to search.', searchQuery: '' });
            return;
        }
    
        clearTimeout(timer);
        timer = setTimeout(() => {
        this.getList({
            page: this.state.activePage ? this.state.activePage : 1,
            searchQuery: this.state.searchQuery,
            filter: this.state.filter ? this.state.filter : 2
        });
        }, 1000);
    }

    /**to get list  */
    getList(params = {}) {
        /**to start Loader */
        this.setState({ isLoading: true });
        HTTP.Request("get", window.admin.getpropertyList, params)
            .then(result => {
                this.setState({
                    isLoading: false,
                    array: result.data.offer,
                    totalItemsCount: result.data.offerCount
                });
            })
            .catch(err => this.setState({ isLoading: false }));
    }
}

export default Table;
