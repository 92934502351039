import React, { Component } from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import HTTP from '../../../services/http';
import Socket from '../../../sockets';
import Switch from '@material-ui/core/Switch';
import {
  required,
  emailValiadte,
  mobileValidate,
} from '../../common/fieldValidations';
import { ADMIN_TRACK_AUDIT_LOGS } from '../../common/actions';
/**COMPONENT */
import RenderFiled from '../../common/renderField';
import PageHeader from '../../common/pageheader';
import Multiselect from '../../common/multiselect';
import Loader from '../../common/loader';
import DropdownComp from '../../common/DropdownList';
class AddUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roleOptions: [],
      isLoading: false,
      formAction: 'ADD',
      status: true,
      investorLiftAccess: true,
      // selectedRole:{}
    };

    /**event binding  */
    this.addEditUser = this.addEditUser.bind(this);
    this.getAUser = this.getAUser.bind(this);
    this.getRolesOptions = this.getRolesOptions.bind(this);

    this.currentUserRole = '';
  }

  componentWillMount() {
    this.getAUser();
    this.getRolesOptions({ type: 'admin' });
  }

  render() {
    const { handleSubmit } = this.props;
    const { roleOptions, isLoading, formAction, selectedRole, defaultRole } =
      this.state;

    var activeInacticeList = '';
    if (
      this.currentUserRole == 'Super Admin' ||
      this.currentUserRole == 'Admin'
    ) {
      activeInacticeList = (
        <Field
          name="status"
          options={[
            { label: 'Active', value: true },
            { label: 'Inactive', value: false },
          ]}
          label="Status"
          defaultValue={this.state.status ? 'Active' : 'Inactive'}
          textField="label"
          valueField="value"
          component={DropdownComp}
        />
      );
    }

    return (
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <PageHeader
              route={formAction === 'ADD' ? 'Add New User' : 'Edit User'}
              parent="User Management"
              parentRoute="/user-management"
            />

            <div className="tab-pane active">
              <form onSubmit={handleSubmit(this.addEditUser)}>
                <Field
                  name="role"
                  options={roleOptions}
                  selectedValues={[selectedRole ? selectedRole : defaultRole]}
                  multi={false}
                  component={Multiselect}
                  validate={[required]}
                  statusBased
                  fieldName="Role Name*"
                />
                <Field
                  name="firstname"
                  fieldName="First Name*"
                  type="text"
                  component={RenderFiled}
                  validate={[required]}
                />
                <Field
                  name="lastname"
                  fieldName="Last Name*"
                  type="text"
                  component={RenderFiled}
                  validate={[required]}
                />
                <Field
                  name="username"
                  fieldName="User Name*"
                  type="text"
                  component={RenderFiled}
                  validate={[required]}
                />
                <Field
                  name="email"
                  fieldName="Email*"
                  type="text"
                  placeholder="abc@yourdomain.com"
                  component={RenderFiled}
                  validate={[emailValiadte, required]}
                />
                <Field
                  name="mobile"
                  fieldName="Mobile*"
                  type="number"
                  component={RenderFiled}
                  validate={[mobileValidate, required]}
                />

                {activeInacticeList}

                <br />
                <p>Access to Investorlift</p>
                <Switch
                  checked={this.state.investorLiftAccess}
                  onChange={this.handleChange('investorLiftAccess')}
                  value="investorLiftAccess"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />

                <div className="form-actions">
                  <button
                    type="submit"
                    className="btn green uppercase"
                    disabled={this.props.invalid || this.props.submitting}
                  >
                    {formAction === 'ADD' ? 'Add ' : 'Update'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }

  addEditUser(data) {
    data = { ...data, investorLiftAccess: this.state.investorLiftAccess };
    return HTTP.Request('post', window.admin.addEditUser, data)
      .then((result) => {
        console.log('addEditUser =>>>', result);
        this.props.dispatch(push('/user-management'));
        toast(result.message, { type: 'success' });

        /**to check if the role of the user is changed */
        if (
          result.data.role &&
          this.state.selectedRole &&
          result.data.role._id !== this.state.selectedRole._id
        ) {
          /**call socket to logout the user whose role has been changed */
          Socket.callEvent('logout', { userId: result.data._id });
        }

        /*log audits for user*/
        this.props.dispatch({
          type: ADMIN_TRACK_AUDIT_LOGS,
          action: {
            comment:
              'Modified details of Admin User - ' +
              (result.data.firstname +
                ' ' +
                result.data.lastname +
                ' (' +
                result.data.email +
                ')'),
            type: 'audit',
          },
        });
      })
      .catch((err) => {
        if (err && err.error && err.error.length > 0) {
          let validationErrorsObj = {};
          err.error.map((errorElem) => {
            toast(errorElem.message, { type: 'error' });
            validationErrorsObj[errorElem.field] = errorElem.message;
          });
          throw new SubmissionError(validationErrorsObj);
        } else {
          toast(err.message, { type: 'error' });
        }
      });
  }

  getAUser() {
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let userID = match.params.id ? match.params.id : null;

    if (userID) {
      this.setState({ isLoading: true, formAction: 'EDIT' });
      HTTP.Request('get', window.admin.getAUser, { id: userID }).then(
        (result) => {
          setTimeout(() => {
            this.setState({
              isLoading: false,
              selectedRole: result.data.role ? result.data.role : {},
              status: result.data.status,
              investorLiftAccess: result.data.investorLiftAccess,
            });
          }, 1000);

          console.log('getAUser =>>>', result);
          /*set data to form*/
          initialize(result.data);
        },
      );
    }
  }

  getRolesOptions(params = {}) {
    /**start loader and stop it only in edit case */
    const { match } = this.props;
    let userID = match.params.id ? match.params.id : null;
    this.setState({ isLoading: true });
    HTTP.Request('get', window.admin.getRolesOptions, params)
      .then((result) => {
        let defaultRole = result.data.filter(
          (e) => e.title === result.roles.role.title,
        )[0];

        let roleListing = result.data;

        if (result.roles) {
          let currentRole = result.roles.role.title;

          this.currentUserRole = result.roles.role.title;
          if (currentRole != 'Super Admin') {
            roleListing = result.data.filter(
              (val) => val.title != 'Super Admin',
            );
          }

          if (currentRole == 'Super Admin') {
          } else if (currentRole == 'Admin') {
            roleListing = result.data.filter(
              (val) => val.title != 'Super Admin',
            );
          } else {
            roleListing = result.data.filter((val) => val.title == currentRole);
          }
        }

        let _state = { roleOptions: roleListing, defaultRole: defaultRole };
        if (!userID) _state.isLoading = false;
        this.setState(_state);
      })
      .catch((err) => console.log('err', err));
  }

  // toggle changes
  handleChange = (name) => (event) => {
    console.log('this is  name of toggle', name);
    this.setState({ ...this.state, [name]: event.target.checked });
  };
}

//decorate form component
let AddUser_Form = reduxForm({
  form: 'addUser_Form',
})(AddUser);

export default AddUser_Form;
