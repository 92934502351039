import React from 'react';
import { DateTimePicker } from 'react-widgets';
import {HelpBlock} from"react-bootstrap";
import momentLocaliser from 'react-widgets-moment';
import moment from 'moment';
import './dropdown.css';
class DatePicker extends React.Component {
    constructor(props){
        super(props);
        this.state={
            showError:false,
        }
        momentLocaliser(moment) ;
        /**event bind  */
        this.handleChange = this.handleChange.bind(this);
        this._handleInputBlur = this._handleInputBlur.bind(this);
    }
    handleChange(e){
        this.props.input.onChange(e);
        const {changeDate} =this.props
        console.log('changes', e);
        if(changeDate){
            console.log(e);
            this.props.changeDate(e);
        }
    }

    _handleInputBlur(e) {
        const { input:{value},id} = this.props;
        if(!value && id =="listing_date"){
           const {showError} =this.state
           this.setState({showError:true})
        }else{
            const {showError} =this.state
            this.setState({showError:false})
        }
    }

    _handleOnKeyDown = (e) => {
        e.preventDefault();
    }
    
    render(){
        const { input: { value },id, showTime,meta:{invalid,error,touched},fieldName, min,max, disabled} =this.props
        const {showError}=this.state;
        return(
            <div className="section form-group">
                <label>{fieldName}</label>
                <DateTimePicker
                    id={id}
                    onChange={this.handleChange}
                     placeholder = "MM/DD/YY"
                    // format={{ raw: 'DD/MMM/YYYY' }}
                    format="MM/DD/YY"
                    time={showTime ? true : false}
                    value={!value ? undefined : new Date(value)}
                    max={max ? max:new Date()}
                    min={min}
                    disabled={disabled}
                    onBlur={this._handleInputBlur}
                    className={touched && error ?'date-select-error':null}
                    // onKeyDown={this._handleOnKeyDown}
                />
                <HelpBlock style={{ color: '#e73d4a' }}>
                    {touched && error ? error : null}
                    {showError&& invalid && error && !touched ?error:null}
                    {/* {id=='sold_date' && !touched && invalid && error ? error : null}  */}
                </HelpBlock>
            </div>
        )
    }
}

export default DatePicker ;
