/* global _ */
import React, { Component } from "react";
import { HelpBlock } from 'react-bootstrap';
import { Z_DEFAULT_STRATEGY } from "zlib";
import './dropdown.css';

class FileSelect extends Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
    this.state = {
      tmpSrc: "",
      src: "",
      showImage: false,
      invalidFile: false,
      invalidSize: false,
      invalidRes: false
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.getDataUrl = this.getDataUrl.bind(this);
  }

  onChange(e) {
    const { dimensionsCheck, id } = this.props;
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return;
    }
    console.log("files[0].size", files[0].size);
    // 5242880
    if (files[0].size > 900000) {
      this.setState({ invalidSize: true });
      document.getElementById(id).value = "";
    } else if (
      !["image/jpeg", "image/jpg", "image/png", "image/gif"].includes(
        files[0].type
      )
    ) {
      this.setState({ invalidFile: true });
    } else if (dimensionsCheck) {
      const scope = this;

      const img = new Image();
      img.onload = function() {
        let { minHeight, minWidth } = scope.props;

        if (this.width < minWidth && this.height < minHeight) {
          scope.setState({ invalidRes: true });
          document.getElementById(id).value = "";
        } else if (this.height < minHeight) {
          scope.setState({ invalidRes: true });
          document.getElementById(id).value = "";
        } else if (this.width < minWidth) {
          scope.setState({ invalidRes: true });
          document.getElementById(id).value = "";
        } else {
          console.log(
            "else",
            this.width <= minWidth && this.height <= minHeight
          );
          scope.setState({
            invalidFile: false,
            invalidSize: false,
            invalidRes: false
          });
          const reader = new FileReader();
          reader.onload = e => {
            scope.setState({
              tmpSrc: reader.result,
              showImage: true,
              width: this.width,
              height: this.height
            });
          };
          reader.readAsDataURL(files[0]);
          const {
            input: { onChange }
          } = scope.props;
          onChange(files[0])
      
        }
      };
      img.src = window.URL.createObjectURL(files[0]);
    } else {
        console.log("inside else ----->")
      this.setState({ invalidFile: false, invalidSize: false });
      const reader = new FileReader();
      reader.onload = e => {
        this.setState({ tmpSrc: reader.result, showImage: true });
      };
      reader.readAsDataURL(files[0]);
      const {
        input: { onChange }
      } = this.props;
      onChange(files[0])
      
    }
  }

  toggleModal() {
    this.setState({ showImage: false });
    const { id } = this.props;
    if (this.state.tmpSrc) {
      document.getElementById(id).value = "";
    }
  }

  getDataUrl(data) {
    const {
      input: { onChange }
    } = this.props;
    const { id } = this.props;
    const { blob } = data;
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result, showImage: false });
    };

    reader.readAsDataURL(blob);
    document.getElementById(id).value = "";
    onChange(blob);
  }

  
  render() {
    const {
      logo,
      meta:{touched,error},
      displayText,
      input,
      ratioUpper,
      ratioLower,
      id,
      minWidth,
      minHeight,
      imagetype
    } = this.props;
 
    const { src, tmpSrc, showImage, invalidRes, invalidSize } = this.state;
    const fileInputKey = input.value ? input.value.name : +new Date();
    const imageStyle = {
      backgroundImage: "url(" + (src || logo) + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center"
    };
   
    return (
      <div className={touched && error ? 'image-upload-error':null}>
        {/* 	{src &&< div className="camera-icon" style={imageStyle}>
				<img src={src || logo} />
		</div>} */}

        <div className="form-control" >
  
          <input
            type="file"
            key={fileInputKey}
            onChange={this.onChange}
            accept="image/*"
            id={id}
            name={id}
            /* 	id="upload-photo" */
          />
          
          <div className="camera-upload-content">
            {invalidSize && (
              <span className="invalidText" style={{ color: "#e73d4a" }}>
                maximum image size 900kb
              </span>
            )}
          </div>
          <div className="camera-upload-content">
            {invalidRes && (
              <span
                className="invalidText"
                style={{
                  color: "#e73d4a",
                  position: "relative",
                  top: "5px",
                  left: "-11px"
                }}
              >{`Please select image with minimum resolution of ${minWidth} x ${minHeight} pixels`}</span>
            )}
          </div>
          <HelpBlock>
                    <span style={{color:"#e73d4a"}}>{touched && error ? error : null}</span>
            </HelpBlock>
        </div>
        {tmpSrc && (
          <img
            src={tmpSrc}
            alt=""
            width="120px"
            height="120px"
            className="img-responsive img-thumbnail i-bot"
          />
        )}
      </div>
    );
  }
}

export default FileSelect;
