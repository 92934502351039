import React, { Component } from 'react';
import Moment from "react-moment";
import moment from "moment-timezone";
import { Link } from 'react-router-dom';
import Modal from '../../common/modal';
import TT from "../../common/tooltip";

class ROW extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);

        console.log('row props', this.props);
    }

    render() {
        const { cms } = this.props;

        return (
            <tr className="odd gradeX" >
                <td> <Link to={'/users-edit/' + cms._id}>{cms.firstname} {cms.lastname}</Link> </td>
                <td className="center">{cms.email}</td>
                <td className="center">{cms.source}</td>
                <td className="center" width="10%">
                    {" "}
                    <label
                        className={
                            cms.status
                                ? "label label-info"
                                : "label label-danger"
                        }
                    >
                        {cms.status ? "Active" : "Inactive"}
                    </label>{" "}
                </td>
                {/* <td><Moment format="MM/DD/YY">{cms.updated_at}</Moment> </td> */}
                <td>
                    {this.userTypeValue(cms.user_type)}
                </td>
                <td> {cms.communication === 1 ? 'SMS and Email' : cms.communication === 2 ? 'Email' : cms.communication === 3 ? 'Unsubscribe' : 'SMS'} </td>
                {/* <td>{moment(cms.updated_at).zone("-04:00").format('MM/DD/YY')} {moment(cms.updated_at).zone("-04:00").format('h:mm:ss a')}</td> */}
                <td>{cms.tcpa_agreed_on ? this.toDSTTime(cms.tcpa_agreed_on): ''}</td>
                <td>{cms.terms_privacy_agreed_on ? this.toDSTTime(cms.terms_privacy_agreed_on): ''}</td>
                <td>{this.toDSTTime(cms.updated_at)}</td>
                {/* <td>{moment(cms.updated_at).zone("-04:00").format('h:mm:ss a')}</td> */}
                <td>{moment(cms.created_at).format('MM/DD/YY')}</td>

                {/* <td><Moment format="MM/DD/YY">{cms.created_at}</Moment></td> */}
                <td>
                    {this.props.user.user && this.props.user.user.role.title !== 'Dispositions Consultant' && <TT tooltip="Remove"><a className=" btn btn-xs red-mint" onClick={this.showModal} title="View"><i className="fa fa-trash no-pointer"  ></i></a></TT>}
                    <TT tooltip="Edit"><Link to={'/users-edit/' + cms._id} className=" btn btn-xs grey-mint" ><i className="fa fa-pencil-square-o"></i></Link></TT>
                    {this.state.show && <Modal show={true} func={this.deleteRow} closeParentModal={this.closeModal} title={cms.title} />}
                </td>
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow() {
        this.setState({ isLoading: true, show: false });
        /*delete row by call prop func*/
        this.props.delete(this.props.cms._id);
    }

    closeModal() {
        this.setState({ show: false });
    }


    toDSTTime = time => {
        let newTime = "";
        let d = moment(time);

        if (d.isDST()) {
            console.log("this is dst time", d.isDST())
            newTime = moment(time)
                .format("MM/DD/YY h:mm:ss a");
        } else {
            console.log("this is not dst", d.isDST())
            newTime = moment(time).format("MM/DD/YY h:mm:ss a");
        }
        return newTime;
    }

    userTypeValue = (type) => {
        let userAccType = {
            1: "Buyer",
            2: "Investor",
            3: "Realtor",
        }  
        let value = [];
        if (!type || !type.length) {
            return "-";
        }
        for (let t of type) {
            value.push(userAccType[t]);
        }

        return value.join('/');
    }
}



export default ROW;

