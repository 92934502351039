import React, { Component } from 'react';
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import Modal from '../../common/modal';
import FaqModal from '../../common/faqCategoryModal';
import TT from "../../common/tooltip";
import HTTP from "../../../services/http";
import moment from "moment-timezone";

class ROW extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            faqPopup:false,
            questionsCount:0
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    render() {
        const {element,getList} = this.props;
        return (
            <tr className="odd gradeX" >
                <td> {element.faq_category} </td>
                <td> {element.order} </td>
                <td><Moment format="MM/DD/YY">{moment(element.created_at).zone("-04:00")}</Moment></td>
                
                {/* <td><Moment format="MM/DD/YY">{element.created_at}</Moment></td>             */}
                {/* <td> {element.status?"Active":"Inactive"} </td> */}
                <td className="center" width="10%">
                    {" "}
                    <label
                        className={
                            element.status
                                ? "label label-info"
                                : "label label-danger"
                        }
                    >
                        {element.status ? "Active" : "Inactive"}
                    </label>{" "}
                </td>
                <td>                    
                    <TT tooltip="Remove"><a className=" btn btn-xs red-mint" onClick={()=>this.showModal(element._id)}><i className="fa fa-trash no-pointer"></i></a></TT>
                    <TT tooltip="Edit"><Link to={'/faq-category/edit/' + element._id} className=" btn btn-xs grey-mint"><i className="fa fa-pencil-square-o"></i></Link></TT>
                    {this.state.show && <FaqModal show={true} func={this.deleteRow} records={this.state.records} elementID={element._id} questionsCount={this.state.questionsCount} closeParentModal={this.closeModal} title={element.title} getList={getList}/>}
                   {this.state.faqPopup && <Modal show={true} func={this.deleteRow} closeParentModal={this.closeModal} title={element.title} />} 
                
                </td>
            </tr>
        );
    }

    showModal(ID) {
       if (ID) {
            HTTP.Request("get", window.admin.faqQuestionCount, { _id: ID })
            .then(result => {
                if(result.data >0){
                    this.setState({ isLoading: false,show:true,questionsCount:result.data ,records:result.records})
                }else{
                    this.setState({ isLoading: false,faqPopup:true,questionsCount:result.data  })
                }
            })
        } 
        /*show popup and confirm before delete*/
       /*  this.setState({ show: true }); */
    }

    deleteRow() {
        this.setState({ isLoading: true, show: false });
        /*delete row by call prop func*/
        this.props.delete(this.props.element._id);
    }

    closeModal() {
        this.setState({ show: false,faqPopup:false });
    }

}



export default ROW;

