import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { arrayMove, SortableContainer } from 'react-sortable-hoc';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { createNumberMask } from 'redux-form-input-masks';
import RenderField from '../common/renderField';
import { Tabs, Tab } from 'react-bootstrap';
import { push } from 'react-router-redux';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';

import MaterialMultiV1 from '../common/material_multi_v1';
import RenderFieldV1 from '../common/renderFieldV1';
import LocationSearchInput from '../common/address';
import DropdownCompV2 from '../common/dropdown_v2';
import DatePicker from '../common/DateTimePicker';
import DropdownComp from '../common/DropdownList';
import FileSelect from '../common/onfileChnage';
import PageHeader from '../common/pageheader';
import { slugify } from '../../libs/Helper';
import { STATUS } from '../common/options';
import ShowPopup from '../common/modalsq';
import ShowOveridePopup from '../common/modalOveride';
import Loader from '../common/loader';
import HTTP from '../../services/http';
import Offers from './element/offers';
import Autosuggest from 'react-autosuggest';
import Session from '../../services/session';
import { defaultTheme } from 'react-autosuggest/dist/theme';
import Banner from 'react-js-banner';
import { Link } from 'react-router-dom';
import "./images.css"

import {
  BED,
  BATH,
  Basement,
  Appliances,
  otherFeatures,
  Exterior,
  BackYard,
  DECK,
  PORCH,
  VIEW,
  HOAFeePeriod,
  COAFeePeriod,
  YEARBUILT,
  PropertyStatus,
} from '../common/options';
import {
  requiredInCrm,
  requireArrayInCrm,
  required,
  requireArray,
  ValidateOnlyAlpha,
  isValidAddress,
  PriceMax,
  schoolName,
  squareValidation,
  COAFee,
  RemarksMax,
  HOAFee,
  yearBuiltValidation,
  RemarksMin,
  soldDateValidate,
} from '../common/fieldValidations';

import { scrollToFirstError } from '../common/scrollToError';
import ShowPopupArv from '../common/modalArv';

import MultiFileUpload from './test';
import { GridItem } from './gridItem';
import { normalizePhone, normalizeBuilt, normalizeHoa } from './numberValidation';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import OpenHouses from './element/openHouses';
import Comparables from './element/comparables';
const gridStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gridGap: '16px',
};
const normalizeLot = (value, previousValue, dhdh) => {
  console.log(value, previousValue, "normalize")
  var onlyNums;
  if (!value) {
    return value
  }
  if (value > 43560) {
    value = (value / 43560).toFixed(2)
  }
  else {
    value = value.replace(/[^\d]/g, '')
  }
  return value
}

const distressedArray = [
  {
    value: true,
    code: 172380000
  }, 
  {
    value: false,
    code: 172380001
  }
];

const currencyMask = createNumberMask({
  prefix: '',
  suffix: '',
  // decimalPlaces: 0,
  allowEmpty: true,
  locale: 'en-US',
});

let readOnlyChecker = true,
  zipCodeChecker = true,
  cityCodeChecker = true;
const Grid = SortableContainer(({ images, removeImage, onError }) => (
  <div style={gridStyles}>
    {' '}
    {images.map((image, index) => (
      <GridItem
        key={`item-${index}`}
        index={index}
        image={image}
        onError={onError}
        removeImage={removeImage}
      />
    ))}{' '}
  </div>
));

const getSuggestions = async (value) => {
  const token = Session.getSession('token');
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
    body: JSON.stringify({ searchKeyword: value })
  };
  let response = await fetch(window.admin.searchPropertyInCrm, requestOptions);
  const data = await response.json();
  return data.value;
};

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <div>
    {suggestion.new_propaddress}
  </div>
);

class AddProp extends Component {
  getSuggestionValue = suggestion => {
    suggestion.new_propertydetailsid = suggestion.new_propertydetailsid.toLowerCase()
    HTTP.Request('get', window.admin.getCountByGuid + "/" + suggestion.new_propertydetailsid)
      .then(res => {
        if (res.result == 0) {
          this.fillForm(suggestion)

          this.setState({
            disableStreetAddress: true,
            streetAddressValue: suggestion.new_propaddress,
            isSuggestionSelected: true
          })
        }
        else {
          this.setState({
            modalPropExists: true
          })
        }
      })
    return suggestion.new_propaddress
  }
  constructor(props) {
    super(props);
    let checkPrice;
    this.state = {
      orignalData: {},
      currentUser:{},
      orignalStatus: true,
      galleryImages: {},
      minImagesPopup: false,
      isAgentExistsPopup: false,
      unknownAgentGuid: "",
      showOveridePopup:false,
      overideMinImage:false,
      isAgentExists: false,
      isAgentActive: true,
      isListingDateInFuture: false,
      isListingDateInFuturePopup: false,
      propertyGuid: "",
      lot: 0,
      disableStreetAddress: false,
      listingType: "",
      isSyncing: false,
      remarks: {},
      spreadStatus: false,
      currentAgent: "",
      isActiveBanner: false,
      propFormValue:{},
      streetAddressValue: "",
      selectedHeatingType: [],
      selectedCoolingType: [],
      selectedParkingType: [],
      selectedViewType: [],
      isUpset: true,
      isMarketExistPopup: false,
      isMarketActivePopup: false,
      modalPropExists: false,
      isSuggestionSelected: false,
      value: '',
      isSyncing:false,
      suggestions: [],
      currentMarket: {},
      isRedirect: true,
      isMarketActiveBanner: false,
      isMarketExistBanner: false,
      formAction: 'ADD',
      content: '',
      hoa: 'None',
      coa: 'None',
      modalOpen: false,
      modalOpenArv: false,
      isLoading: false,
      status: false,
      showGallery: false,
      count: 1,
      showComparablesAutoAddModal: false,
      isThereAnHoa: "",
      isThereACoa: "",
      show: false,
      arvCount: 1,
      squareLot: '',
      files_array: [],
      flyerUrl: '',
      offers: [],
      offer_id: '',
      openHouse: [],
      // images:[],
      offerSetllementDialogFlag: false,
      showOfferDelete: false,
      dispositions_manager: [],
      coordinates: {
        lat: '',
        lng: '',
      },
      defaultTabSelected: 1,
      allowSelectedFieldReadOnly: true,
    };
    this.upsertProp = this.upsertProp.bind(this);
    this.upsertDraft = this.upsertDraft.bind(this);
    this.closeModel = this.closeModel.bind(this);
    this.closeDistressedModel = this.closeDistressedModel.bind(this);
    this.sendFlyer = this.sendFlyer.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.getProp = this.getProp.bind(this);
    this.getPropOffers = this.getPropOffers.bind(this);
    this.setSettlementDate = this.setSettlementDate.bind(this);
    this.hanldeCategory = this.hanldeCategory.bind(this);
    this.getAgent = this.getAgent.bind(this);
    this.getCounties = this.getCounties.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeHoa = this.onChangeHoa.bind(this);
    this.onChangeCoa = this.onChangeCoa.bind(this);
    // this.onChangeLot = this.onChangeLot.bind(this);
    this.onFocusSqft = this.onFocusSqft.bind(this);
    this.closeParentModal = this.closeParentModal.bind(this);
    this.onFocusArv = this.onFocusArv.bind(this);
    /* show gallery */
    this.showGallery = this.showGallery.bind(this);

    this.sendFlyers = this.sendFlyers.bind(this);
    this.setSelectedTab = this.setSelectedTab.bind(this);
    this.getCurrentUser = this.getCurrentUser.bind(this);
    this.overidePopup = this.overidePopup.bind(this);
  }

  onSugChange = (event, { newValue }) => {
    if (newValue == "") {
      this.setState({
        isSuggestionSelected: false,
      });
    }
    this.setState({
      value: newValue,
    });
  };

  minImages = (value,allValues,props) => {
    if(!!value && value.length > 0 && this.state.galleryImages.length !== 0 && this.state.currentUser && this.state.currentUser.role && this.state.currentUser.role.title == "Settlement Manager"){
      return  undefined
    }
    else {
      if(!!allValues.category && allValues.category == "renovated"){
        return value && value.length !== 0 ? value.length >= 10 ? undefined : "Minimum 10 gallery images are required" : "This field is required"
      }
      else{
        return value && value.length !== 0 ? value.length >= 3 ? undefined : "Minimum 3 gallery images are required" : "This field is required"
      }
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    if (value.length >= 3) {
      getSuggestions(value)
        .then(data => {
          if (data.Error) {
            this.setState({
              suggestions: []
            });
          } else {
            this.setState({
              suggestions: data
            });
          }
        })
    }
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  async componentDidMount() {
    this.getCurrentUser()
    this.getAllWebUser();
    await this.getPropOffers();
    await this.getAgent();
    this.getCounties();
    this.getProp();


    const currentPath = window.location.pathname.split('/');
    if (currentPath.length == 4) {
      if (currentPath[3] == 'offers') {
        this.setState({ defaultTabSelected: 4 });
      } else if (currentPath[3] == 'comparables') {
        this.setState({ defaultTabSelected: 5 });
      } else if (currentPath[3] == 'inquiries') {
        this.setState({ defaultTabSelected: 3 });
      } else if (currentPath[3] == 'open-houses') {
        this.setState({ defaultTabSelected: 2 });
      } else if (currentPath[3] == 'edit') {
        this.setState({ defaultTabSelected: 1 });
      } else {
        this.setState({ defaultTabSelected: 1 });
      }
    }
  }

  resetBanners() {
    const { isMarketActiveBanner, isMarketExistPopup, isMarketActivePopup, isMarketExistBanner } = this.state
    this.setState({
      isMarketActiveBanner: false,
      isMarketExistBanner: false,
      isMarketExistPopup: false,
    })
  }
  upsertAgent(data) {
    const { match } = this.props;
    /* this.setState({isLoading: true}); */
    if (match.params._id) data.editID = match.params._id;
    console.log('upsertPropertDetails', data);
    if (data.status == 'false' || data.status == false) {
      HTTP.Request('get', window.admin.getAgentCount, {
        id: data._id,
      }).then((result) => {
        console.log(result);
        if (result.count > 0) {
          console.log('records', result.agent);
          this.setState({
            isLoading: false,
            show: true,
            property: result.count,
            records: result.agent,
          });
        } else {
          this.updateAgent(data);
        }
      });
    } else {
      this.updateAgent(data);
    }
  }

  async updateAgent(data) {
    HTTP.Request('post', window.admin.upsertAgent, data)
      .then(async (result) => {
        this.setState({
          isLoading: false,
        });
        await this.getAgent()
        toast(result.message, {
          type: 'success',
        });
        change('agent', result.data._id)
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
        toast(err.message, {
          type: 'error',
        });
      });
  }

  async fillForm(address) {
    this.state.isActiveBanner = true
    const { change } = this.props;
    this.getAgent()
    this.resetBanners()
    const { marketImage, isAgentExistsPopup, isAgentExists } = this.state
    this.state.remarks = { publicRemarks: address.emn_publicremarks, wholeSaleRemarks: address.emn_wholesaleremarks }
    let bathRooms = (parseInt(address.emn_marketingtotalfb) || 0) + ((!!address.emn_marketingtotalhb) ? (parseInt(address.emn_marketingtotalhb) / 2) : 0)
    let bedRooms = parseInt(address.emn_marketingtotalbr) || 0
    var fullStateName = ""
    var statesArray = [
      {
        "id": 5,
        "title": "Georgia",
        "code": "GA",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "34.000144",
        "longitude": "-84.355803",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 3,
        "title": "Maryland",
        "code": "MD",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "39.045755",
        "longitude": "-76.641271",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 6,
        "title": "Oklahoma",
        "code": "OK",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "35.547968",
        "longitude": "-97.291182",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 7,
        "title": "Texas",
        "code": "TX",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "32.833705",
        "longitude": "-96.810243",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 2,
        "title": "Virginia",
        "code": "VA",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "37.431573",
        "longitude": "-78.656894",
        "zoom": 9,
        "is_default": 0
      },
      {
        "id": 1,
        "title": "Washington",
        "code": "WA",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "38.907192",
        "longitude": "-77.036871",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 8,
        "title": "Alabama",
        "code": "AL",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 9,
        "title": "Alaska",
        "code": "AK",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 10,
        "title": "Delaware",
        "code": "DE",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 11,
        "title": "District of Columbia",
        "code": "DC",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 12,
        "title": "Florida",
        "code": "FL",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 14,
        "title": "Hawaii",
        "code": "HI",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 15,
        "title": "Idaho",
        "code": "ID",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 16,
        "title": "Illinois",
        "code": "IN",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 17,
        "title": "Indiana",
        "code": "IN",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 18,
        "title": "Iowa",
        "code": "IA",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 19,
        "title": "Kansas",
        "code": "KS",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 20,
        "title": "Kentucky",
        "code": "KY",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 21,
        "title": "Louisiana",
        "code": "LA",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 22,
        "title": "Maine",
        "code": "ME",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 23,
        "title": "Massachusetts",
        "code": "MA",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 24,
        "title": "Michigan",
        "code": "MI",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 25,
        "title": "Minnesota",
        "code": "MN",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 26,
        "title": "Mississippi",
        "code": "MS",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 27,
        "title": "Missouri",
        "code": "MO",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 28,
        "title": "Montana",
        "code": "MT",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 29,
        "title": "Nebraska",
        "code": "NE",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 30,
        "title": "Nevada",
        "code": "NV",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 31,
        "title": "New Hampshire",
        "code": "NH",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 32,
        "title": "New Jersey",
        "code": "NJ",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 33,
        "title": "New Mexico",
        "code": "NM",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 34,
        "title": "New York",
        "code": "NY",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 35,
        "title": "North Carolina",
        "code": "NC",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 36,
        "title": "North Dakota",
        "code": "ND",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 37,
        "title": "Ohio",
        "code": "OH",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 38,
        "title": "Oregon",
        "code": "OR",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 39,
        "title": "Pennsylvania",
        "code": "PA",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 40,
        "title": "Puerto Rico",
        "code": "PR",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 41,
        "title": "Rhode Island",
        "code": "RI",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 42,
        "title": "South Carolina",
        "code": "SC",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 43,
        "title": "South Dakota",
        "code": "SD",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 44,
        "title": "Tennessee",
        "code": "TN",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 45,
        "title": "Utah",
        "code": "UT",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 46,
        "title": "Vermont",
        "code": "VT",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 47,
        "title": "Virgin Islands",
        "code": "VI",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 48,
        "title": "West Virginia",
        "code": "WV",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 49,
        "title": "Wisconsin",
        "code": "WI",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 50,
        "title": "Wyoming",
        "code": "WY",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 51,
        "title": "Arizona",
        "code": "AZ",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 52,
        "title": "California",
        "code": "CA",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 53,
        "title": "Colorado",
        "code": "CO",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 54,
        "title": "Connecticut",
        "code": "CT",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
      {
        "id": 55,
        "title": "Arkansas",
        "code": "AR",
        "created_at": "2019-09-11 02:25:36",
        "updated_at": "2020-03-27 07:23:08",
        "slug": null,
        "latitude": "",
        "longitude": "",
        "zoom": 11,
        "is_default": 0
      },
    ]
    statesArray.find(e => {
      if (e.code == address["new_propstates@OData.Community.Display.V1.FormattedValue"]) {
        fullStateName = e.title
        return true
      }
    })

    switch (address.emn_isthereacoa) {
      case 172380000: this.state.isThereACoa = "Yes";
        break;
      case 172380001: this.state.isThereACoa = "No";
        break;
      default: this.state.isThereACoa = null;
        break;
    }

    switch (address.emn_isthereanhoa) {
      case 172380000: this.state.isThereAnHoa = "Yes";
        break;
      case 172380001: this.state.isThereAnHoa = "No";
        break;
      default: this.state.isThereAnHoa = null;
        break;
    }
    let hoafeecode = "";
    switch (address.emn_hoafeeperiodoptioncode) {
      case "172380000: Monthly": hoafeecode = "Monthly";
        break;
      case "172380001: Quarterly": hoafeecode = "Quarterly";
        break;
      case "172380002: Semi-Annually": hoafeecode = "Semi-Annually";
        break;
      case "172380003: Annually": hoafeecode = "Annually";
        break;
      default: hoafeecode = ""
        break;
    }

    let coafeecode = "";
    switch (address.emn_coafeeperiodoptioncode) {
      case "172380000: Monthly": coafeecode = "Monthly";
        break;
      case "172380001: Quarterly": coafeecode = "Quarterly";
        break;
      case "172380002: Semi-Annually": coafeecode = "Semi-Annually";
        break;
      case "172380003: Annually": coafeecode = "Annually";
        break;
      default: coafeecode = "";
        break;
    }

    let basement = [];
    switch (address["emn_hasbasementpostdd@OData.Community.Display.V1.FormattedValue"]) {
      case "Yes": basement = address["emn_basementfinish_postdd@OData.Community.Display.V1.FormattedValue"];
        break;
      case "No": basement = "None";
        break;
      default: basement = !!address["emn_basementfinish_postdd@OData.Community.Display.V1.FormattedValue"] ? address["emn_basementfinish_postdd@OData.Community.Display.V1.FormattedValue"]:undefined;
        break;
    }
    let heatingType = [];
    if (!!address.emn_heattypepostdd && address.emn_heattypepostdd.indexOf(":") > -1) {
      heatingType.push(address.emn_heattypepostdd.split(': ')[1]);
    }
    else if (!!address.emn_heattypepostdd) {
      heatingType = address.emn_heattypepostdd.split(",")
    }
    else {
      heatingType = [];
    }
    this.state.selectedHeatingType = heatingType
    let parkingType = [];
    if (!!address.emn_parkingtypepostdd && address.emn_parkingtypepostdd.indexOf(":") > -1) {
      parkingType.push(address.emn_parkingtypepostdd.split(': ')[1]);
    }
    else if (!!address.emn_parkingtypepostdd) {
      parkingType = address.emn_parkingtypepostdd.split(",")
    }
    else {
      parkingType = []
    }
    this.state.selectedParkingType = parkingType

    let coolingType = [];
    if (!!address.emn_coolingtypepostdd && address.emn_coolingtypepostdd.indexOf(":") > -1) {
      coolingType.push(address.emn_coolingtypepostdd.split(': ')[1]);
    }
    else if (!!address.emn_coolingtypepostdd) {
      coolingType = address.emn_coolingtypepostdd.split(",")
    }
    else {
      coolingType = [];
    }
    this.state.selectedCoolingType = coolingType

    let viewType = [];
    if (!!address.emn_specialviewpostdd && address.emn_specialviewpostdd.indexOf(":") > -1) {
      viewType.push(address.emn_specialviewpostdd.split(': ')[1]);
    }
    else if (!!address.emn_specialviewpostdd) {
      viewType = address.emn_specialviewpostdd.split(",")
    }
    else {
      viewType = [];
    }
    this.setState({
      propertyGuid:address.new_propertydetailsid
    })
    
    this.state.selectedViewType = viewType
    let temp = `${address.emn_propunit || ''} ${address.new_propaddress.split(",")[0] || ''} ${address.new_propcity || ''} ${address["new_propstates@OData.Community.Display.V1.FormattedValue"] || ''} ${address.new_propzip || ''}`;
    this.setState({
      remarks:{publicRemarks:address.emn_publicremarks,wholeSaleRemarks: address.emn_wholesaleremarks},
    })
    change('publicRemarks',address.emn_publicremarks)
    change('wholesaleRemarks',address.emn_wholesaleremarks)
    change(
      'slug',
      slugify(temp),
    );
    change('acquisition_ratification_date',address["emn_acqratifydate@OData.Community.Display.V1.FormattedValue"])
    change('street_address', address.new_propaddress)
    change('key_features.property_type', address["emn_propertytype_postdd@OData.Community.Display.V1.FormattedValue"]);
    change('city', address.new_propcity);
    change('county', address.new_propcounty);
    change('lat', address.emn_melissalatitude);
    change('lng', address.emn_melissalongitude);
    change('state', fullStateName);
    change('state_code', address["new_propstates@OData.Community.Display.V1.FormattedValue"]);
    change('unit_no', address.emn_propunit);
    change('country', "USA");
    change('postal_code', address.new_propzip);
    change('key_features.year_built', address.emn_yrbuilt_postdd);
    change('key_features.heating', heatingType);
    change('key_features.parking_type', parkingType);
    change('key_features.lot', address.emn_lotsf_postdd);
    change('key_features.cooling', coolingType);
    this.state.selectedHeatingType= heatingType
    this.state.selectedParkingType= parkingType
    this.state.selectedViewType= viewType
    this.state.selectedCoolingType= coolingType
    change('interior_features.bed', bedRooms);
    change('interior_features.basement', basement);
    change('exterior_features.view', viewType);
    change('interior_features.bath', bathRooms);
    change('associations.hoaFeePeriod', address["emn_hoafeeperiodoptioncode@OData.Community.Display.V1.FormattedValue"]);
    change('associations.hoaFee', address.emn_hoafee);
    change('associations.isThereAnHoa', this.state.isThereAnHoa)
    change('associations.isThereACoa', this.state.isThereACoa)
    change('associations.coaFeePeriod', address["emn_coafeeperiodoptioncode@OData.Community.Display.V1.FormattedValue"]);
    change('associations.coaFee', address.emn_coafee);
    change('listing_type', address["emn_listingtype@OData.Community.Display.V1.FormattedValue"])
    this.setState({
      listingType:address["emn_listingtype@OData.Community.Display.V1.FormattedValue"]
    })
    change('listing_date', address.emn_initiallistdate)
    change('price', address.new_listprice);
    change("status", "Inactive")
    change('sold_date', address.emn_finalsalesettlement)

    // check if listing data is in future
    const today = new Date();
    if (address.emn_initiallistdate && new Date(address.emn_initiallistdate) > today) {
      this.setState({
        isListingDateInFuture: true
      });
    } else {
      this.setState({
        isListingDateInFuture: false
      });
    }

    if(!address._emn_dispositionsconsultantid_value){
      this.setState({
        isAgentExists: false,
      })
    }
    else if (this.state.selectedAgent.length >= 1 && !this.state.selectedAgent.find(e => { return e.guid == address._emn_dispositionsconsultantid_value })) {
      this.setState({
        isAgentExists: false,
        unknownAgentGuid: address._emn_dispositionsconsultantid_value
      })
      let token = Session.getSession('token');
            let propRequestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
              body: JSON.stringify({ searchKeyword: address.new_propertydetailsid })
              };
             let propResponse =  await fetch(window.admin.getCrmPropertyByGuid, propRequestOptions);
             let crmPropData = await propResponse.json();
      
            let agentRequestOptions = {
             method: 'POST',
             headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
             body: JSON.stringify({ agentGuid: crmPropData.value[0]._emn_dispositionsconsultantid_value })
             };
            let response =  await fetch(window.admin.searchAgentInCrm, agentRequestOptions);
            let crmAgentData = await response.json();
            let agentData={
              guid : crmAgentData.value[0].emn_userid,
              editGuid : crmAgentData.value[0].emn_userid,
              firstname : crmAgentData.value[0].emn_name.split(" ")[0],
              lastname : crmAgentData.value[0].emn_name.split(" ")[1],
              email : crmAgentData.value[0].emn_email,
              status : true,
              phone_no :crmAgentData.value[0].emn_mobilenumber.replaceAll("-",""),
              trash:false
            }
            await this.upsertAgent(agentData) 
            await this.getAgent()
            await this.refreshAgents()
            let agentChange = this.state.selectedAgent.find(e => { return e.guid == crmAgentData.value[0].emn_userid})
            change('agent', agentChange._id)
            await this.refreshAgents()
            if(this.formAction == "ADD"){
              this.setState({
                formAction:"EDIT"
              })
              this.setState({
                formAction:"ADD"
              })
            }
            if(this.formAction == "EDIT"){
              this.setState({
                formAction:"ADD"
              })
              this.setState({
                formAction:"EDIT"
              })
            }
    } else {
      this.state.currentAgent = this.state.selectedAgent.find(e => { return e.guid == address._emn_dispositionsconsultantid_value })
      const {currentAgent} = this.state
      change('agent', currentAgent._id)
      this.setState({
        isAgentExists: true,
        unknownAgentGuid: address._emn_dispositionsconsultantid_value
      })
      if(!currentAgent.status){
        this.setState({
          isAgentActive: false,
        })
      }else{
        this.setState({
          isAgentActive: true,
        })
      }
    }
    switch (address.new_marketname) {
      case 100000000: address.new_marketname = "DC Metro"
        break;
      case 100000004: address.new_marketname = "Baltimore"
        break;
      case 100000005: address.new_marketname = "Richmond"
        break;
      case 100000006: address.new_marketname = "Hampton Roads"
        break;
      case 100000007: address.new_marketname = "Philadelphia"
        break;
      case 100000008: address.new_marketname = "Dallas"
        break;
      case 100000009: address.new_marketname = "Oklahoma City"
        break;
      case 100000010: address.new_marketname = "Atlanta"
        break;
      case 100000011: address.new_marketname = "Connecticut"
        break;
      case 100000012: address.new_marketname = "New York City"
        break;
      case 100000013: address.new_marketname = "Wilmington"
        break;
      case 100000014: address.new_marketname = "Boston"
        break;
      case 100000003: address.new_marketname = "Out of Market"
        break;
      default: address.new_marketname = ""
    }
    if(this.state.formAction == "EDIT"){
    this.setState({
      formAction:"ADD"
    })
    this.setState({
      formAction:"EDIT"
    })
   }
    if(this.state.formAction == "ADD"){
    this.setState({
      formAction:"EDIT"
    })
    this.setState({
      formAction:"ADD"
    })
   }
    change('market', address["new_marketname@OData.Community.Display.V1.FormattedValue"]);
    let currentMarket = marketImage.find(e => {
      return e.name == address.new_marketname
    })
    this.state.currentMarket = currentMarket

    if (!currentMarket) {
      this.setState({
        isMarketExistBanner: true
      })
    } else {
      if (!currentMarket.status) {
        this.setState({
          isMarketActiveBanner: true
        })
      }
    }
    change('guid', address.new_propertydetailsid)
  }

  refreshAgents = async () => {
   await this.getAgent()
  }
  onStatusChange = async (event, value, previousValue, name) => {
    const { change } = this.props;
    this.state.status = value
    if (value == 'false') {
      change("status", "Inactive")
      this.state.status = false
    }
    if (event == "" && value == true) {
      change("status", "Active")
      this.state.status = true
    }


    await this.getAgent()
    this.resetBanners()
    const { marketImage } = this.state
    let updatedCurrentMarket = marketImage.find(e => {
      if (!this.state.currentMarket) {
        return false
      } else {
        return e.name == this.state.currentMarket.name
      }

    })
    this.state.currentMarket = updatedCurrentMarket
    const { currentMarket,unknownAgentGuid } = this.state
    if (!currentMarket) {
      this.setState({
        isMarketExistBanner: true
      })
    } else {
      if (!currentMarket.status) {
        this.setState({
          isMarketActiveBanner: true
        })
      }
    }
    const { galleryImages } = this.state
    if (!this.state.overideMinImage && ((this.state.category == "renovated" && Object.keys(this.state.galleryImages).length <= 3) || ((!this.state.category || this.state.category == "wholesale") && Object.keys(this.state.galleryImages).length <= 10))) {
      if(!(this.state.galleryImages.length == 0)&& this.state.currentUser && this.state.currentUser.role && this.state.currentUser.role.title == "Settlement Manager"){
        this.setState({
          showOveridePopup: true
        })
      }else{
        this.setState({
          minImagesPopup: true
        })
      }
      this.state.status = false
      change('status', 'Inactive')
    }
    if (this.state.status) {
      // checking ARV validation
      let currentPrice = document.getElementsByName('price')[0].value;
      currentPrice = currentPrice.replaceAll(",", "")
      if (!!document.getElementsByName('avr')[0]) {
        let currentArv = document.getElementsByName('avr')[0].value;
        currentArv = currentArv.replaceAll(",", "")
        let spread = parseInt(currentArv) - parseInt(currentPrice);
        if (spread > 25000 && spread > (currentArv / 100) * 30) {
          this.setState({
            spreadStatus: false
          })
        } else {
          this.setState({
            spreadStatus: true
          })
          change('status', 'Inactive')
        }
      }
      // checking if the current market exist or not

      if (!currentMarket) {
        this.setState({
          isMarketExistPopup: true
        })
        change("status", 'Inactive');
        this.state.status = false
      } else if (!currentMarket.status) {
        this.setState({
          isMarketActivePopup: true
        })
        change("status", 'Inactive');
        this.state.status = false
      }
      // checking if the current agent exist or not
      if (this.state.selectedAgent.length >= 1 && !this.state.selectedAgent.find(e => { return e.guid == this.state.unknownAgentGuid })) {
        this.setState({
          isAgentExists: false,
        })
      } else {
        this.state.currentAgent = this.state.selectedAgent.find(e => { return e.guid == this.state.unknownAgentGuid })
        change('agent', this.state.currentAgent._id)
        this.setState({
          isAgentExists: true
        })
      }
      if (!this.state.currentAgent) {
        this.setState({
          isAgentExistsPopup: true
        })
        change("status", 'Inactive');
        this.state.status = false
      }
      if (this.state.isListingDateInFuture) {
        this.setState({
          isListingDateInFuturePopup: true
        })
        change("status", 'Inactive');
        this.state.status = false
      }
    }

  }

  closeIsListDateFutureActivePopup = () => {
    this.setState({
      isListingDateInFuturePopup: false
    })
  }

  closeIsMarketActivePopup = () => {
    this.setState({
      isMarketActivePopup: false
    })
  }
  closeMinImagesPopup = () => {
    this.setState({
      minImagesPopup: false
    })
  }

  closeOveridePopup = () => {
    this.setState({
      showOveridePopup: false
    })
  }

  closeIsMarketExistPopup = () => {
    this.setState({
      isMarketExistPopup: false
    })
  }
  fillFormFields(address) {
    const { change } = this.props;
    console.log('props values', this.props);
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    if (address.length > 0) {
      const obj = {};
      let address_components = address[0].address_components;
      change(
        'street_address',
        address[0].formatted_address.replace(', USA', ''),
      );
      change(
        'slug',
        slugify(address[0].formatted_address.replace(', USA', '')),
      );
      console.log(address_components);
      for (var i = 0; i < address_components.length; i++) {
        var addressType = address_components[i].types[0];
        var checkState = address_components[i].types[1];
        if (componentForm[addressType]) {
          var val = address_components[i][componentForm[addressType]];
          obj[addressType] = val;

          if (addressType === 'administrative_area_level_1') {
            change('state', val);
            if (checkState === 'political') {
              let state_value = address_components[i]['short_name'];
              change('state_code', state_value);
            }
          }
          if (addressType === 'locality') {
            cityCodeChecker = true;
            change('city', val);
          }
          if (!document.getElementsByName('city')[0].value) {
            cityCodeChecker = false;
            console.log('inside document');
          }

          if (addressType === 'country') {
            change('country', val);
          }

          if (addressType === 'postal_code') {
            zipCodeChecker = true;
            change('postal_code', val);
          } else {
            zipCodeChecker = false;
          }
        }
      }

      HTTP.Request('post', window.admin.getCounty, obj)
        .then((result) => {
          const { data } = result;
          console.log(data);
          if (data.length > 0) {
            change('county', data[0].county);
            change('market', data[0].market);
            readOnlyChecker = true;
            change('unit_no', '');
            console.log('inside County', data[0].county);
          } else {
            readOnlyChecker = false;
            change('unit_no', ' ');
            console.log('outside County', readOnlyChecker);
          }
        })
        .catch((err) => {
          if (err) {
            toast(err.message, {
              type: 'error',
            });
          }
        });
    }
  }

  hanldeCategory(e, data) {
    const { change } = this.props
    this.setState({ category: data });
    if (data == "wholesale") {
      this.state.allowSelectedFieldReadOnly = true;
    } else {
      this.state.allowSelectedFieldReadOnly = false;
    }
  }

  handleSelect(address) {
    const { change } = this.props;
    geocodeByAddress(address)
      .then((result) => {
        this.fillFormFields(result);
        return getLatLng(result[0]);
      })
      .then(({ lat, lng }) => {
        let request = {
          lat,
          lng,
        };
        change('lat', request.lat);
        change('lng', request.lng);

        this.setState({
          coordinates: request,
        });

        console.log(this.state);
        console.log(readOnlyChecker);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  showGallery() {
    this.setState({
      showGallery: !this.state.showGallery,
    });
  }

  onChange(event) {
    const { street_address_v2, dispatch, change } = this.props;
    let unit_no = event.target.value;

    let temp = `${unit_no || ''} ${street_address_v2 || ''}`;
    change('slug', slugify(temp));
    dispatch(change('propForm', 'slug', slugify(`${temp}`)));
  }

  onChangeHoa(event) {
    this.setState({
      hoa: event,
    });
  }

  onChangeCoa(event) {
    this.setState({
      coa: event,
    });
  }

  onChangeIsThereHOA = (event, value) => {
    this.setState({
      isThereAnHoa: value
    });
  }

  onChangeIsThereCOA = (event, value) => {
    this.state.isThereACoa = value
  }

  onFocusSqft(event) {
    if (this.state.count === 1) {
      this.setState({
        modalOpen: true,
        count: 3,
      });
    }
  }

  onFocusArv(event) {
    if (this.state.arvCount === 1) {
      this.setState({
        modalOpenArv: true,
        count: 3,
      });
    }
  }

  closeParentModal() {
    this.setState({
      modalOpen: false,
    });
  }


  closeProExistModal = () => {
    this.setState({
      modalPropExists: false
    });
  }

  closeSpreadPopup = () => {
    this.setState({
      spreadStatus: false
    });
  }

  closeIsAgentExistPopup = () =>{
    this.setState({
      isAgentExistsPopup: false
    });
  }

  /*Filter and return particular image */
  filter = (id) => {
    return this.props.images_v1.filter(
      (image) => image.image.public_id !== id.image.public_id,
    );
  };


  /*Remove particular image */
  removeImage = (id) => {
    const { change, images_v1 } = this.props;
    change('images', this.filter(id));
  };

  storeImages = (image)=>{
    this.setState({
      galleryImages:image
    })
  }
  /*Error Occur while uploading image */
  onError = (id) => {
    toast('Oops, something went wrong', {
      type: 'error',
    });
    const { change, images_v1 } = this.props;
    change('images', this.filter(id));
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { change } = this.props;
    change('images', arrayMove(this.props.images_v1, oldIndex, newIndex));
  };

  activateProperty = () => {
    this.onStatusChange("", true)
  };

openEditAgent(id){
  window.open(window.location.origin+`/admin/update-disposition-consultant/${id}`, '')
}

  openAddMarket() {
    window.open(window.location.origin+`/market-image-add`, '')
  }

  openEditMarket(id) {
    window.open(window.location.origin+`/market-image-edit/${id}`, '')
  }

  renderAutoSuggest() {
    const { handleSubmit } = this.props;
    const {
      propertyGuid,
      minImagesPopup,
      showOveridePopup,
      currentUser,
      formAction,
      suggestions,
      isAgentExistsPopup,
      unknownAgentGuid,
      value,
      modalPropExists,
      isMarketExistPopup,
      isMarketActivePopup,
      currentMarket,
      status,
      category,
      isActiveBanner,
      disableStreetAddress,
      isListingDateInFuturePopup
    } = this.state;

    var inputProps = {
      placeholder: 'Type Street Address',
      value,
      onChange: this.onSugChange
    };
    if (formAction === 'ADD') {
      return (
        <div>
          <Banner showBanner={!status && isActiveBanner} css={{ color: "#FFF", backgroundColor: "#ff4545ab", borderRadius: "4px", cursor: "pointer" }}>
            <div onClick={this.activateProperty}>Property is inactive, click here to make it active</div>
          </Banner>
          {isListingDateInFuturePopup && (
            <ShowPopup
              closeParentModal={this.closeIsListDateFutureActivePopup}
              message={
                ' List date is in future, please change in CRM before making property active.'
              }
            />
          )}{' '}
          {isMarketActivePopup && (
            <ShowPopup
              closeParentModal={this.closeIsMarketActivePopup}
              message={
                <div onClick={() => this.openEditMarket(currentMarket._id)}>This market is inactive, click here to make it active.</div>
              }
            />
          )}{' '}
          {isMarketExistPopup && (
            <ShowPopup
              closeParentModal={this.closeIsMarketExistPopup}
              message={
                <div onClick={() => this.openAddMarket()}>This market is missing from admin panel, click here to add market.</div>
              }
            />
          )}{' '}
          {modalPropExists && (
            <ShowPopup
              closeParentModal={this.closeProExistModal}
              message={
                'Property already exists in the Admin. Search another property.'
              }
            />
          )}{' '}
          {isAgentExistsPopup && (
            <ShowPopup
              closeParentModal={this.closeIsAgentExistPopup}
              message={
                <div onClick={() => this.addAgent(propertyGuid)}>This disposition consultant is missing from admin panel, click here to add disposition consultant.</div>
              }
            />
          )}{' '}
          { 
           showOveridePopup && (
            <ShowOveridePopup
              closeParentModal={this.closeOveridePopup}
              func={this.overidePopup}
              message={!!category && category == "renovated"?
                <div> Minimum 10 Gallery Images is Required.</div> : <div> Minimum 3 Gallery Images is Required.</div>
              }
            />
          )}{' '}
          {minImagesPopup &&(
                <ShowPopup
                closeParentModal={this.closeMinImagesPopup}
                message={
                  !!category && category == "renovated"?
                  <div> Minimum 10 Gallery Images is Required.</div> : <div> Minimum 3 Gallery Images is Required.</div>
                }
                />)
          }{' '}
          <div style={disableStreetAddress ? { display: "none" } : {}}>
            <p className='fw-bold'>Property Address</p>
            <label>Street Address*</label>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
              getSuggestionValue={this.getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={inputProps}
              id="streetaddress"
              className="col-md-12 col-lg-12 form-control "
              name="streetaddress"
              theme={defaultTheme}
            />
          </div>
        </div>

      )
    }

  }
  addAgent = (propGuid) => {
    window.open(window.location.origin+`/admin/new-disposition-consultant/${propGuid}`, '')
    window.location.href=window.location.origin+"/admin/property-list"
  }
  renderForm() {
    const { handleSubmit,match, change } = this.props;
    const {
      currentAgent,
      unknownAgentGuid,
      minImagesPopup,
      isAgentExists,
      lot,
      listingType,
      isAgentExistsPopup,
      isLoading,
      isAgentActive,
      formAction,
      status,
      selectedAgent,
      marketImage,
      countyList,
      selectedappliances,
      selectedExterior,
      selectedotherfeatures,
      category,
      isThereACoa,
      isThereAnHoa,
      dispositions_manager,
      defaultTabSelected,
      orignalData,
      orignalStatus,
      suggestions,
      isMarketExistPopup,
      isMarketActivePopup,
      isMarketActiveBanner,
      isActiveBanner,
      isMarketExistBanner,
      value,
      showOveridePopup,
      isSuggestionSelected,
      currentMarket,
      propertyGuid,
      disableStreetAddress,
      isDistressed,
      isListingDateInFuturePopup
    } = this.state;
    var inputProps = {
      placeholder: 'Type Street Address',
      value,
      onChange: this.onChange
    };

    if (formAction === 'ADD') {
      if (isSuggestionSelected) {
        return (
          <form onSubmit={handleSubmit(this.upsertProp)}>

            <div className="row">
              <div className="col-sm-12" style={disableStreetAddress ? {} : { display: "none" }}>
                <p className='fw-bold'>Property Address</p>
                <Field
                  readOnly={true}
                  name="street_address"
                  fieldName="Street Address*"
                  type="text"
                  id="street_address"
                  validate={[requiredInCrm]}
                  component={RenderFieldV1}
                />{' '}
              </div>{' '}

              <div className="col-sm-12" >
                <Field
                  name="slug"
                  readOnly={true}
                  fieldName="Custom URL*"
                  type="text"
                  id="slug"
                  component={RenderField}
                  validate={[required]}
                />{' '}
              </div>
              <div className="col-sm-3">

                <Field
                  name="city"
                  fieldName="City*"
                  type="text"
                  id="city"
                  readOnly={cityCodeChecker}
                  component={
                    cityCodeChecker == true
                      ? RenderFieldV1
                      : RenderField
                  }
                  validate={[requiredInCrm]}
                />{' '}
              </div>{' '}
              <div className="col-sm-3">
                <Field
                  name="state"
                  fieldName="State*"
                  type="text"
                  id="state"
                  readOnly={true}
                  component={RenderFieldV1}
                  // component={RenderField}
                  validate={[requiredInCrm]}
                />{' '}
              </div>{' '}
              <div className="col-sm-3">
                <Field
                  name="state_code"
                  fieldName="State Code*"
                  type="text"
                  id="state_code"
                  readOnly={true}
                  className="onFocus"
                  component={RenderFieldV1}
                  // component={RenderField}
                  validate={[requiredInCrm]}
                />{' '}
              </div>{' '}
              <div className="col-sm-3">
                <Field
                  name="postal_code"
                  fieldName="Zipcode*"
                  type="text"
                  id="postal_code"
                  readOnly={zipCodeChecker}
                  component={
                    zipCodeChecker == true ? RenderFieldV1 : RenderField
                  }
                // component={RenderField}
                />{' '}
              </div>{' '}
            </div>{' '}
            <div className="row">
              <div className="col-sm-3">
                <Field
                  name="unit_no"
                  fieldName="Unit No"
                  type="text"
                  id="unit_no"
                  readOnly={true}
                  onChange={this.onChange}
                  component={RenderField}
                />{' '}
              </div>{' '}
              <div className="col-sm-3">
                <Field
                  name="county"
                  fieldName="County*"
                  type="text"
                  id="county"
                  valueField="county"
                  validate={[requiredInCrm]}
                  readOnly={readOnlyChecker}
                  component={
                    readOnlyChecker == true
                      ? RenderFieldV1
                      : RenderField
                  }
                />{' '}
              </div>{' '}
              <div className="col-sm-3">
                <Field
                  name="lat"
                  fieldName="Latitude*"
                  type="text"
                  id="lat"
                  readOnly={true}
                  component={RenderFieldV1}
                  validate={[requiredInCrm]}
                />{' '}
              </div>
              <div className="col-sm-3">
                <Field
                  name="lng"
                  fieldName="Longitude*"
                  type="text"
                  id="lng"
                  readOnly={true}
                  component={RenderFieldV1}
                  validate={[requiredInCrm]}
                />{' '}
              </div>{' '}
            </div>
            <div>
              <p className='fw-bold'>Listing Type & Images</p>
              <Field
                name="category"
                textField="label"
                valueField="value"
                label="Category*"
                id="category"
                component={DropdownCompV2}
                placeholder="-select-"
                validate={[required]}
                ref="category"
                onChange={this.hanldeCategory}
                options={[
                  {
                    label: 'Renovated',
                    value: 'renovated',
                  },
                  {
                    label: 'Wholesale',
                    value: 'wholesale',
                  },
                ]}
              />{' '}
              <div className='row col-12'>
                <div className="col-sm-6">
                  <label> Feature Image * </label>{' '}
                  <Field
                    component={FileSelect}
                    id={'feature_image'}
                    name="feature_image"
                    minWidth={450}
                    minHeight={300}
                    dimensionsCheck={true}
                    ratioUpper={450}
                    ratioLower={300}
                    formAction={formAction}
                    validate={formAction === 'ADD' ? [required] : []}
                  />
                </div>
                <div className="col-sm-6">
                  <Field
                    name="img_alt_featured"
                    fieldName="Image Alternative Text"
                    type="text"
                    component={RenderField}
                  />
                </div>
              </div>
              {' '}
              {this.state.feature_image ? (
                <img
                  src={this.state.feature_image.secure_url}
                  alt=""
                  width="120px"
                  className="img-responsive img-thumbnail i-bot"
                  validate={[required]}
                />
              ) : null}{' '}
            </div>
            <div className="GalleryHd">
              <p className='fw-bold'>Gallery Images</p>{' '}
              <Field
                name="img_alt_gallery"
                fieldName="Image Alternative Text"
                type="text"
                component={RenderField}
              />
            </div>{' '}
            <Field
              name="images"
              component={MultiFileUpload}
              validate={[this.minImages]}
              onChange={this.storeImages}
            />{' '}
            <Button
              className="expendImg"
              color="primary"
              type="button"
              onClick={this.showGallery}
              style={{
                marginBottom: '1rem',
              }}
            >
              Expand Images{' '}
              <i className="fa fa-angle-down expandIcon"> </i>{' '}
            </Button>{' '}
            <Collapse isOpen={this.state.showGallery}>
              <Card>
                <CardBody>
                  <div className="galleryOuter">
                    <Grid
                      images={this.props.images_v1 || []}
                      onSortEnd={this.onSortEnd}
                      removeImage={this.removeImage}
                      axis="xy"
                      onError={this.onError}
                    />{' '}
                  </div>{' '}
                </CardBody>{' '}
              </Card>{' '}
            </Collapse>{' '}
            <p className='fw-bold'>Listing Details</p>
            <div className="row">
              <div className="col-sm-3">
                <Field
                  name="status_prop"
                  textField="label"
                  valueField="value"
                  label="Listing Status*"
                  id="status_prop"
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={required}
                  options={PropertyStatus}
                />{' '}
              </div>{' '}
              <div className="col-sm-3">
                <Field
                  name="listing_type"
                  textField="label"
                  placeholder="-select-"
                  valueField="value"
                  fieldName="Listing Type"
                  readOnly={true}
                  label="Listing Type*"
                  validate={[requiredInCrm]}
                  type="text"
                  options={[
                    {
                      label: 'MLS',
                      value: 'MLS',
                    },
                    {
                      label: 'Wholesale',
                      value: 'Wholesale',
                    },
                    {
                      label: "MLS Wholesale",
                      value: "MLS Wholesale"
                    }
                  ]}
                  component={DropdownCompV2}
                />{' '}
              </div>
              <div className="col-sm-3">
                <Field
                  name="listing_date"
                  disabled={true}
                  id="listing_date"
                  fieldName="Listing Date*"
                  component={DatePicker}
                  validate={[requiredInCrm]}
                />{' '}
              </div>{' '}
              <div className="col-sm-3">
                <Field
                  name="sold_date"
                  fieldName="Sold Date"
                  disabled={true}
                  validate={[soldDateValidate]}
                  id="sold_date"
                  component={DatePicker}
                />{' '}
              </div>{' '}
            </div>{' '}
            <div className="row">

              <div className="col-sm-3">
                <Field
                  name="sqft"
                  fieldName="Total Sq Ft*"
                  type="text"
                  id="sqft"
                  normalize={normalizePhone}
                  {...currencyMask}
                  onFocus={this.onFocusSqft}
                  component={RenderField}
                  validate={[required, squareValidation]}
                />
              </div>{' '}

              <div className="col-sm-3">
                <Field
                  name="price"
                  fieldName="Price($)*"
                  type="text"
                  readOnly={true}
                  id="price"
                  component={RenderField}
                  {...currencyMask}
                  normalize={normalizePhone}
                  validate={[requiredInCrm]}
                />{' '}
              </div>
              <div className="col-sm-3">
                {category === 'wholesale' ? (
                  <Field
                    name="buy_now_price"
                    fieldName="Buy Now Price($)"
                    type="text"
                    id="buy_now_price"
                    component={RenderField}
                    {...currencyMask}
                    normalize={normalizePhone}
                    validate={
                      category === 'wholesale'
                        ? [squareValidation, PriceMax]
                        : [squareValidation]
                    }
                  />
                ) : (
                  ''
                )}
              </div>{' '}
              <div className="col-sm-3">
                {category === 'wholesale' ? (
                  <Field
                    name="purchase_price"
                    fieldName="Acquisition Price($)*"
                    type="text"
                    id="purchase_price"
                    component={RenderField}
                    {...currencyMask}
                    normalize={normalizePhone}
                    validate={
                      category === 'wholesale'
                        ? [required, squareValidation, PriceMax]
                        : [squareValidation]
                    }
                  />
                ) : (
                  ''
                )}
              </div>{' '}
              <div className="col-sm-3">
                {category === 'wholesale' ? (
                  <Field
                    name="min_price_reserve"
                    fieldName="Minimum Reserve Price($)"
                    type="text"
                    id="min_price_reserve"
                    component={RenderField}
                    {...currencyMask}
                    normalize={normalizePhone}
                    validate={
                      category === 'wholesale'
                        ? [squareValidation, PriceMax]
                        : [squareValidation]
                    }
                  />
                ) : (
                  ''
                )}
              </div>{' '}
              <div className="col-sm-3">
                {' '}
                {category == 'wholesale' ? (
                  <Field
                    name="avr"
                    fieldName="ARV*"
                    onFocus={this.onFocusArv}
                    type="text"
                    id="avr"
                    normalize={normalizePhone}
                    {...currencyMask}
                    component={RenderField}
                    validate={[required, squareValidation, PriceMax]}
                  />
                ) : (
                  ''
                )}{' '}
              </div>{' '}
              <div className="col-sm-3">
                {' '}
                {category === 'wholesale' ? (
                  <Field
                    name="seller_name"
                    fieldName="Seller Name (For contract)*"
                    type="text"
                    id="seller_name"
                    component={RenderField}
                    validate={
                      category === 'wholesale' ? [required] : []
                    }
                  />
                ) : (
                  ''
                )}
              </div>
              {category === 'wholesale' ? (
                <div className="col-sm-3">
                  {' '}
                  <Field
                    name="acquisition_ratification_date"
                    fieldName="Acquisition Ratification Date"
                    id="acquisition_ratification_date"
                    disabled={true}
                    max={(function () {
                      let date = new Date();
                      date.setMonth(date.getMonth() + 2);
                      return date;
                    })()}
                    component={DatePicker}
                  />
                </div>
              ) : (
                ''
              )}
              {listingType === 'Wholesale'?(
              <div className="col-sm-6">
                <Field
                  name="wholesaleRemarks"
                  fieldName="Wholesale Remarks*"
                  validate={[requiredInCrm]}
                  type="text"
                  rows={3}
                  id="wholesaleRemarks"
                  textarea
                  maxLength="600"
                  defaultValue={this.state.remarks.wholesaleRemarks}
                  component={RenderField}
                  readOnly={true}
                // validate={[required, RemarksMin]}
                />
              </div>):(
                ""
              )}
              {listingType === 'MLS' || listingType === 'MLS Wholesale' ?(
            <div className="col-sm-6">
              <Field
                name="publicRemarks"
                fieldName="Public Remarks*"
                validate={[requiredInCrm]}
                type="text"
                rows={3}
                id="publicRemarks"
                textarea
                maxLength="600"
                defaultValue={this.state.remarks.publicRemarks}
                component={RenderField}
                readOnly={true}
              // validate={[required, RemarksMin]}
              />
            </div>):(
              ""
              )}

            </div>{' '}
            <h3> Key Features </h3>{' '}
            <div className="row">
              <div className="col-sm-4">
                <Field
                  name="key_features.property_type"
                  textField="label"
                  valueField="value"
                  readOnly={this.state.allowSelectedFieldReadOnly}
                  label="Property Type*"
                  id="key_features.property_type"
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={[this.state.allowSelectedFieldReadOnly ? requiredInCrm : required]}
                  options={[
                    {
                      label: 'Single Family',
                      value: 'Single Family',
                    },
                    {
                      label: 'Townhouse',
                      value: 'Townhouse',
                    },
                    {
                      label: 'Townhouse-Duplex',
                      value: 'Townhouse-Duplex',
                    },
                    {
                      label: 'Townhouse-Triplex',
                      value: 'Townhouse-Triplex',
                    },
                    {
                      label: 'Condo',
                      value: 'Condo',
                    },
                    {
                      label: 'Semi-Detached',
                      value: 'Semi-Detached',
                    },
                  ]}
                />{' '}
              </div>{' '}
              <div className="col-sm-4">
                <Field
                  name="key_features.year_built"
                  fieldName="Year Built*"
                  type="text"
                  max={4}
                  readOnly={true}
                  normalize={normalizeBuilt}
                  component={RenderField}
                  validate={[requiredInCrm]}
                />{' '}
              </div>{' '}
              <div className="col-sm-4">
                <Field
                  name="key_features.heating"
                  textField="title"
                  selectedValues={this.state.selectedHeatingType}
                  readOnly={this.state.allowSelectedFieldReadOnly}
                  id="key_features.heating"
                  label="Heating*"
                  component={MaterialMultiV1}
                  placeholder="-select-"
                  validate={[this.state.allowSelectedFieldReadOnly ? requireArrayInCrm : requireArray]}
                  options={[
                    {
                      title: 'None',
                      _id: 'None',
                    }, {
                      title: '90% Forced Air',
                      _id: '90% Forced Air',
                    }, {
                      title: 'Baseboard',
                      _id: 'Baseboard',
                    }, {
                      title: 'Bio Fuel',
                      _id: 'Bio Fuel',
                    }, {
                      title: 'Ceiling',
                      _id: 'Ceiling',
                    }, {
                      title: 'Central',
                      _id: 'Central',
                    },
                    {
                      title: 'Coal',
                      _id: 'Coal',
                    },
                    {
                      title: 'Electric',
                      _id: 'Electric',
                    },
                    {
                      title: 'Electric Air Filter',
                      _id: 'Electric Air Filter',
                    }, {
                      title: 'Electric Star Heating',
                      _id: 'Electric Star Heating',
                    }, {
                      title: 'Floor Furnace',
                      _id: 'Floor Furnace',
                    }, {
                      title: 'Forced Air',
                      _id: 'Forced Air',
                    }, {
                      title: 'Gas',
                      _id: 'Gas',
                    }, {
                      title: 'Geothermal',
                      _id: 'Geothermal',
                    }, {
                      title: 'Geothermal Heat Pump',
                      _id: 'Geothermal Heat Pump',
                    }, {
                      title: 'Gravity',
                      _id: 'Gravity',
                    }, {
                      title: 'Heat Pump - Electric Backup',
                      _id: 'Heat Pump - Electric Backup',
                    }, {
                      title: 'Heat Pump - Gas Backup',
                      _id: 'Heat Pump - Gas Backup',
                    }, {
                      title: 'Heat Pump - Oil Backup',
                      _id: 'Heat Pump - Oil Backup',
                    }, {
                      title: 'Heat Pump(s)',
                      _id: 'Heat Pump(s)',
                    }, {
                      title: 'Hot Water',
                      _id: 'Hot Water',
                    }, {
                      title: 'Humidifier',
                      _id: 'Humidifier',
                    }, {
                      title: 'Oil',
                      _id: 'Oil',
                    }, {
                      title: 'Pellet Stove',
                      _id: 'Pellet Stove',
                    }, {
                      title: 'Programmable Thermostat',
                      _id: 'Programmable Thermostat',
                    }, {
                      title: 'Propane',
                      _id: 'Propane',
                    }, {
                      title: 'Radiant',
                      _id: 'Radiant',
                    }, {
                      title: 'Radiator',
                      _id: 'Radiator',
                    }, {
                      title: 'S/W Changeover',
                      _id: 'S/W Changeover',
                    }, {
                      title: 'Solar Active/Passive',
                      _id: 'Solar Active/Passive',
                    }, {
                      title: 'Solar Off Grid',
                      _id: 'Solar Off Grid',
                    }, {
                      title: 'Solar On Grid',
                      _id: 'Solar On Grid',
                    }, {
                      title: 'Solar Rough-In',
                      _id: 'Solar Rough-In',
                    },
                    {
                      title: 'Space Heater',
                      _id: 'Space Heater',
                    },
                    {
                      title: 'Steam',
                      _id: 'Steam',
                    },
                    {
                      title: 'Wall Unit',
                      _id: 'Wall Unit',
                    }, {
                      title: 'Wood Burn Stove',
                      _id: 'Wood Burn Stove',
                    },
                    {
                      title: 'Zoned',
                      _id: 'Zoned',
                    },
                    {
                      title: 'Other',
                      _id: 'Other',
                    },
                  ]}
                />{' '}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4">
                <Field
                  name="key_features.parking_type"
                  id="key_features.parking_type"
                  className='parking-types'
                  selectedValues={this.state.selectedParkingType}
                  label="Parking Type*"
                  readOnly={this.state.allowSelectedFieldReadOnly}
                  component={MaterialMultiV1}
                  placeholder="-select-"
                  validate={[this.state.allowSelectedFieldReadOnly ? requireArrayInCrm : requireArray]}
                  options={[
                    {
                      title: 'None',
                      _id: 'None'
                    },
                    {
                      title: 'Alley',
                      _id: 'Alley'
                    },
                    {
                      title: 'Attached Carport',
                      _id: 'Attached Carport',
                    },
                    {
                      title: 'Attached Garage',
                      _id: 'Attached Garage',
                    },
                    {
                      title: 'Detached Carport',
                      _id: 'Detached Carport'
                    },
                    {
                      title: 'Detached Garage',
                      _id: 'Detached Garage'
                    },
                    {
                      title: 'Driveway',
                      _id: 'Driveway'
                    },
                    {
                      title: 'Off site',
                      _id: 'Off site',
                    },
                    {
                      title: 'Off Street',
                      _id: 'Off Street',
                    },
                    {
                      title: 'On Street',
                      _id: 'On Street',
                    },
                    {
                      title: 'Parking lot',
                      _id: 'Parking lot',
                    },
                    {
                      title: 'Other',
                      _id: 'Other',
                    }
                  ]}
                />{' '}
              </div>
              <div className="col-sm-4">
                <Field
                  name="key_features.lot"
                  id="key_features.lot"
                  readOnly={true}
                  fieldName={"Lot Sq Ft"}
                  type="text"
                  normalize={normalizePhone}
                  {...currencyMask}
                  value='dkdkd'
                  component={RenderField}
                  // onChange={this.onChangeLot}
                  validate={[requiredInCrm]}
                />{' '}
              </div>
              <div className="col-sm-4">
                <Field
                  name="key_features.cooling"
                  id="key_features.cooling"
                  selectedValues={this.state.selectedCoolingType}
                  readOnly={this.state.allowSelectedFieldReadOnly}
                  label="Cooling*"
                  component={MaterialMultiV1}
                  placeholder="-select-"
                  validate={[this.state.allowSelectedFieldReadOnly ? requireArrayInCrm : requireArray]}
                  options={[
                    {
                      title: 'Central A/C',
                      _id: 'Central A/C',
                    },
                    {
                      title: 'Ceiling Fan(s)',
                      _id: 'Ceiling Fan(s)',
                    },
                    {
                      title: 'Air Purification System',
                      _id: 'Air Purification System',
                    },
                    {
                      title: 'Attic Fan',
                      _id: 'Attic Fan',
                    }, {
                      title: 'Dehumidifier',
                      _id: 'Dehumidifier',
                    }, {
                      title: 'ENERGY STAR Cooling System',
                      _id: 'ENERGY STAR Cooling System',
                    }, {
                      title: 'Fresh Air Recovery System',
                      _id: 'Fresh Air Recovery System',
                    }, {
                      title: 'Geothermal',
                      _id: 'Geothermal',
                    }, {
                      title: 'Heat Pump(s)',
                      _id: 'Heat Pump(s)',
                    }, {
                      title: 'HRV/ERV',
                      _id: 'HRV/ERV',
                    },
                    {
                      title: 'Programmable Thermostat',
                      _id: 'Programmable Thermostat',
                    }, {
                      title: 'Roof Mounted',
                      _id: 'Roof Mounted',
                    }, {
                      title: 'Solar Attic Fan',
                      _id: 'Solar Attic Fan',
                    }, {
                      title: 'Solar Off Grid',
                      _id: 'Solar Off Grid',
                    }, {
                      title: 'Solar Rough-In',
                      _id: 'Solar Rough-In',
                    }, {
                      title: 'Wall Unit',
                      _id: 'Wall Unit',
                    }, {
                      title: 'Whole House Exhaust Ventilation',
                      _id: 'Whole House Exhaust Ventilation',
                    }, {
                      title: 'Whole House Fan',
                      _id: 'Whole House Fan',
                    }, {
                      title: 'Whole House Supply Ventilation',
                      _id: 'Whole House Supply Ventilation',
                    }, {
                      title: 'Window Unit(s)',
                      _id: 'Window Unit(s)',
                    }, {
                      title: 'Zoned',
                      _id: 'Zoned',
                    },
                    {
                      title: 'Other',
                      _id: 'Other',
                    }, {
                      title: 'None',
                      _id: 'None',
                    }
                  ]}
                />{' '}
              </div>
            </div>
            <h3> Interior Features </h3>
            <div className="row">
              <div className="col-sm-4">
                <Field
                  name="interior_features.bed"
                  id="interior_features.bed"
                  options={BED}
                  label="Beds*"
                  readOnly={true}
                  placeholder="-select-"
                  textField="title"
                  valueField="value"
                  component={DropdownCompV2}
                  validate={[requiredInCrm]}
                />{' '}
              </div>
              <div className="col-sm-4">
                <Field
                  name="interior_features.bath"
                  id="interior_features.bath"
                  options={BATH}
                  label="Baths*"
                  readOnly={true}
                  placeholder="-select-"
                  textField="title"
                  valueField="value"
                  component={DropdownCompV2}
                  validate={[requiredInCrm]}
                />{' '}
              </div>
              <div className="col-sm-4">
                <Field
                  name="interior_features.basement"
                  id="interior_features.basement"
                  options={Basement}
                  label="Basement*"
                  readOnly={true}
                  placeholder="-select-"
                  textField="title"
                  valueField="value"
                  component={DropdownCompV2}
                  validate={[requiredInCrm]}
                />{' '}
              </div>
            </div>

            {category === 'renovated' ? (
              <div>
                <Field
                  options={Appliances}
                  id="interior_features.appliances_input"
                  name="interior_features.appliances"
                  selectedValues={selectedappliances}
                  label="Appliances*"
                  component={MaterialMultiV1}
                  validate={[requireArray]}
                />

                <Field
                  options={otherFeatures}
                  name="interior_features.otherfeatures"
                  selectedValues={selectedotherfeatures}
                  id="interior_features.otherfeatures_input"
                  label="Other Features*"
                  component={MaterialMultiV1}
                  validate={[requireArray]}
                />
              </div>
            ) : (
              ''
            )}

            <div className="row">
              {' '}
              {category === 'renovated' ? (
                <div className="col-sm-3">
                  <Field
                    name="interior_features.patio"
                    id="interior_features.patio"
                    textField="label"
                    valueField="value"
                    label="Patio*"
                    component={DropdownCompV2}
                    placeholder="-select-"
                    validate={required}
                    options={[
                      {
                        label: 'Yes',
                        value: 'Yes',
                      },
                      {
                        label: 'No',
                        value: 'No',
                      },
                    ]}
                  />{' '}
                </div>
              ) : (
                ''
              )}
              <div className="col-sm-3">
                <Field
                  name="interior_features.fireplace"
                  id="interior_features.fireplace"
                  textField="label"
                  valueField="value"
                  label="Fireplace*"
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={required}
                  options={[
                    {
                      label: '0',
                      value: '0',
                    },
                    {
                      label: '1',
                      value: '1',
                    },
                    {
                      label: '2',
                      value: '2',
                    },
                    {
                      label: '3',
                      value: '3',
                    },
                    {
                      label: '4',
                      value: '4',
                    },
                  ]}
                />{' '}
              </div>{' '}
              {category === 'renovated' ? (
                <div className="col-sm-3">
                  <Field
                    name="interior_features.balcony"
                    id="interior_features.balcony"
                    textField="label"
                    valueField="value"
                    label="Balcony*"
                    component={DropdownCompV2}
                    placeholder="-select-"
                    validate={required}
                    options={[
                      {
                        label: 'Yes',
                        value: 'Yes',
                      },
                      {
                        label: 'No',
                        value: 'No',
                      },
                    ]}
                  />{' '}
                </div>
              ) : (
                ''
              )}{' '}
              <div className="col-sm-3">
                <Field
                  name="interior_features.pool"
                  id="interior_features.pool"
                  textField="label"
                  valueField="value"
                  label="Pool*"
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={required}
                  options={[
                    {
                      label: 'In Ground',
                      value: 'In Ground',
                    },
                    {
                      label: 'Above Ground',
                      value: 'Above Ground',
                    },
                    {
                      label: 'None',
                      value: 'None',
                    },
                  ]}
                />{' '}
              </div>
            </div>
            <h3> Exterior Features </h3>
            {category === 'renovated' ? (
              <Field
                options={Exterior}
                name="exterior_features.exterior"
                id="exterior_features.exterior_input"
                selectedValues={selectedExterior}
                label="Exterior*"
                component={MaterialMultiV1}
                validate={[requireArray]}
              />
            ) : (
              ''
            )}
            {category === 'renovated' ? (
              <div className="row">
                <div className="col-sm-3">
                  <Field
                    name="exterior_features.view"
                    id="exterior_features.view"
                    selectedValues={this.state.selectedViewType}
                    label="View"
                    readOnly={true}
                    component={MaterialMultiV1}
                    placeholder="-select-"
                    options={VIEW}
                  />{' '}
                </div>{' '}

                <div className="col-sm-3">
                  <Field
                    name="exterior_features.backYard"
                    id="exterior_features.backYard"
                    textField="title"
                    valueField="value"
                    label="Backyard*"
                    component={DropdownCompV2}
                    placeholder="-select-"
                    validate={required}
                    options={BackYard}
                  />{' '}
                </div>{' '}
                <div className="col-sm-3">
                  <Field
                    name="exterior_features.deck"
                    id="exterior_features.deck"
                    textField="title"
                    valueField="value"
                    label="Deck*"
                    component={DropdownCompV2}
                    placeholder="-select-"
                    validate={required}
                    options={DECK}
                  />{' '}
                </div>{' '}
                <div className="col-sm-3">
                  <Field
                    name="exterior_features.porch"
                    id="exterior_features.porch"
                    textField="title"
                    valueField="value"
                    label="Porch*"
                    component={DropdownCompV2}
                    placeholder="-select-"
                    validate={required}
                    options={PORCH}
                  />{' '}
                </div>{' '}
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-4">
                  <Field
                    name="exterior_features.backYard"
                    id="exterior_features.backYard"
                    textField="title"
                    valueField="value"
                    label="Backyard*"
                    component={DropdownCompV2}
                    placeholder="-select-"
                    validate={required}
                    options={BackYard}
                  />{' '}
                </div>{' '}
                <div className="col-sm-4">
                  {' '}

                  {' '}
                </div>{' '}
              </div>
            )
            }{' '}


            <h3> Associations </h3>{' '}
            <div className="row">
              <div className="col-sm-3">
                <Field
                  name="associations.isThereAnHoa"
                  id="associations.isThereAnHoa"
                  readOnly={true}
                  options={[
                    { title: 'Yes', value: "Yes" },
                    { title: 'No', value: 'No' },
                  ]}
                  textField="title"
                  valueField="value"
                  label="Is there an HOA?*"
                  defaultValue={
                    this.state.isThereAnHoa
                  }
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={[requiredInCrm]}
                />{' '}

                {isThereAnHoa == "Yes" ? (<Field
                  name="associations.hoaFeePeriod"
                  id="associations.hoaFeePeriod"
                  readOnly={true}
                  textField="title"
                  valueField="value"
                  label="HOA Fee Period*"
                  onChangeHoa={this.onChangeHoa}
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={[requiredInCrm]}
                  options={HOAFeePeriod}
                />) : (<span>

                </span>)
                }
              </div>{' '}
              {isThereAnHoa == "Yes" && (
                <div className="col-sm-3">
                  <Field
                    name="associations.hoaFee"
                    readOnly={true}
                    id="associations.hoaFee"
                    fieldName="HOA Fee"
                    // onChange={this.onChangeHoa}
                    type="text"
                    normalize={normalizeHoa}
                    component={RenderField}
                    validate={[requiredInCrm]}
                  />{' '}
                </div>
              )}
              <div className="col-sm-3">
                <Field
                  name="associations.isThereACoa"
                  id="associations.isThereACoa"
                  readOnly={true}
                  options={[
                    { title: 'Yes', value: "Yes" },
                    { title: 'No', value: 'No' },
                  ]}
                  textField="title"
                  valueField="value"
                  label="Is there an COA?*"
                  defaultValue={
                    this.state.isThereACoa
                  }
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={[requiredInCrm]}
                />{' '}
                {isThereACoa == "Yes" ? (
                  <Field
                    name="associations.coaFeePeriod"
                    readOnly={true}
                    id="associations.coaFeePeriod"
                    textField="title"
                    valueField="value"
                    label="COA Fee Period*"
                    onChangeCoa={this.onChangeCoa}
                    component={DropdownCompV2}
                    placeholder="-select-"
                    validate={[requiredInCrm]}
                    options={COAFeePeriod}
                  />
                ) : (<span></span>)}

              </div>{' '}
              {isThereACoa == "Yes" && (
                <div className="col-sm-3">
                  <Field
                    name="associations.coaFee"
                    readOnly={true}
                    id="associations.coaFee"
                    fieldName="COA Fee"
                    type="text"
                    normalize={normalizeHoa}
                    component={RenderField}
                    validate={[requiredInCrm]}
                  />{' '}
                </div>
              )}{' '}
            </div>{' '}
            <Field
              name="agent"
              textField="name"
              id="agent"
              valueField="_id"
              label="Dispositions Consultant*"
              component={DropdownCompV2}
              placeholder="-select-"
              readOnly={true}
              validate={[requiredInCrm]}
              options={selectedAgent}
            />{' '}
            <Field
              name="market"
              id="market"
              textField="name"
              valueField="name"
              className={`${this.state.isMarketActiveBanner || this.state.isMarketExistBanner ? "bg-redd" : ""}`}
              label="Market*"
              component={DropdownCompV2}
              placeholder="-select-"
              validate={[requiredInCrm]}
              readOnly={true}
              options={marketImage}
            />

            <Banner showBanner={isMarketActiveBanner} css={{ color: "#FFF", backgroundColor: "#ff4545ab", borderRadius: "4px", cursor: "pointer" }}>
              <div onClick={() => this.openEditMarket(currentMarket._id)}>this market is inactive, click here to make it active.</div>
            </Banner>
            <Banner showBanner={isMarketExistBanner} css={{ color: "#FFF", backgroundColor: "#ff4545ab", borderRadius: "4px", cursor: "pointer" }}>
              <div onClick={() => this.openAddMarket()}>This market is missing from admin panel, click here to add market.</div>
            </Banner>
            <div style={{ display: "none" }}>
              <Field
                name="guid"
                style={{ display: "none" }}
                readOnly={true}
                id="guid"
                fieldName="GUID*"
                type="text"
                component={RenderField}
              />
            </div>
            <Field
              name="status"
              placeholder="-select-"
              options={STATUS}
              id="status"
              label="Status*"
              onChange={this.onStatusChange}
              defaultValue={
                this.state.status == true
                  ? 'Active'
                  : this.state.status == false
                    ? 'Inactive'
                    : null
              }

              textField="title"
              valueField='value'
              component={DropdownComp}
            />
            <br />
            <div className="form-actions">
            <button
              className="btn grey uppercase mr-12" style={{marginRight:"12px"}}
            onClick={this.onBackButtonClick}
            >
              {'BACK'}{' '}
            </button>

              <button
                type="submit"
                className="btn green uppercase"
                disabled={this.props.submitting}
              >
                {formAction === 'ADD' ? 'Add' : 'Update'}{' '}
              </button>
            </div>
          </form>
        )
      }
    }
    else {
      
      if(isDistressed && !category) {
        change("category", "wholesale");
        this.setState({ 
          category: 'wholesale'
        });
      } 

      return (
        <form onSubmit={handleSubmit(this.upsertProp)}>
          <Banner showBanner={(!status || status == "false")} css={{ color: "#FFF", backgroundColor: "#ff4545ab", borderRadius: "4px", cursor: "pointer" }}>
            <div onClick={this.activateProperty}>Property is inactive, click here to make it active</div>
          </Banner>
          {isListingDateInFuturePopup && (
            <ShowPopup
              closeParentModal={this.closeIsListDateFutureActivePopup}
              message={
                ' List date is in future, please change in CRM before making property active.'
              }
            />
          )}{' '}
          {isMarketActivePopup && (
            <ShowPopup
              closeParentModal={this.closeIsMarketActivePopup}
              message={
                <div onClick={() => this.openEditMarket(currentMarket._id)}>This market is inactive, click here to make it active.</div>
              }
            />
          )}{' '}
          { 
           showOveridePopup && (
            <ShowOveridePopup
              closeParentModal={this.closeOveridePopup}
              func={this.overidePopup}
              message={
                !!category && category == "renovated"?
                <div> Minimum 10 Gallery Images is Required.</div> : <div> Minimum 3 Gallery Images is Required.</div>
              }
            />
          )}{' '}
          {minImagesPopup && (
            <ShowPopup
              closeParentModal={this.closeMinImagesPopup}
              message={
                !!category && category == "renovated"?
                <div> Minimum 10 Gallery Images is Required.</div> : <div> Minimum 3 Gallery Images is Required.</div>
              }
            />
          )}{' '}
          {isMarketExistPopup && (
            <ShowPopup
              closeParentModal={this.closeIsMarketExistPopup}
              message={
                <div onClick={() => this.openAddMarket()}>This market is missing from admin panel, click here to add market.</div>
              }
            />
          )}{' '}
          {isAgentExistsPopup && (
            <ShowPopup
              closeParentModal={this.closeIsAgentExistPopup}
              message={
                <div onClick={() => this.addAgent(propertyGuid)}>This disposition consultant is missing from admin panel, click here to add disposition consultant.</div>
              }
            />
          )}{' '}
          <p className='fw-bold'>Property Address</p>

          <div className="row">

            <div className="col-sm-12">

              <Field
                name="street_address"
                fieldName="Street Address*"
                type="text"
                readOnly={true}
                id="street_address"
                component={RenderFieldV1}
              />{' '}
            </div>{' '}
            <div className="col-sm-12">
              <Field
                readOnly={true}
                name="slug"
                fieldName="Custom URL*"
                type="text"
                id="slug"
                component={RenderField}
                validate={[required]}
              />{' '}
            </div>{' '}

            <div className="col-sm-3">
              <Field
                name="city"
                fieldName="City*"
                type="text"
                id="city"
                readOnly={cityCodeChecker}
                validate={[requiredInCrm]}
                component={
                  cityCodeChecker == true
                    ? RenderFieldV1
                    : RenderField
                }
              />{' '}
            </div>{' '}
            <div className="col-sm-3">
              <Field
                name="state"
                fieldName="State*"
                type="text"
                id="state"
                readOnly={true}
                component={RenderFieldV1}
                validate={[requiredInCrm]}
              />{' '}
            </div>{' '}
            <div className="col-sm-3">
              <Field
                name="state_code"
                fieldName="State Code*"
                type="text"
                id="state_code"
                readOnly={true}
                component={RenderFieldV1}
                validate={[requiredInCrm]}
              />{' '}
            </div>{' '}
            <div className="col-sm-3">
              <Field
                name="postal_code"
                fieldName="Zipcode*"
                type="text"
                id="postal_code"
                validate={[requiredInCrm]}
                readOnly={zipCodeChecker}
                component={
                  zipCodeChecker == true ? RenderFieldV1 : RenderField
                }
              />{' '}
            </div>{' '}
          </div>{' '}
          <div className="row">
            <div className="col-sm-3">
              <Field
                name="unit_no"
                fieldName="Unit No"
                type="text"
                id="unit_no"
                readOnly={true}
                onChange={this.onChange}
                component={RenderField}
              />{' '}
            </div>{' '}
            <div className="col-sm-3">
              <Field
                name="county"
                fieldName="County*"
                type="text"
                id="county"
                validate={[requiredInCrm]}
                valueField="county"
                readOnly={readOnlyChecker}
                component={
                  readOnlyChecker == true
                    ? RenderFieldV1
                    : RenderField
                }
              />{' '}
            </div>{' '}
            <div className="col-sm-3">
              <Field
                name="lat"
                fieldName="Latitude*"
                validate={[requiredInCrm]}
                type="text"
                id="lat"
                readOnly={true}
                component={RenderFieldV1}
              />{' '}
            </div>
            <div className="col-sm-3">
              <Field
                name="lng"
                validate={[requiredInCrm]}
                fieldName="Longitude*"
                type="text"
                id="lng"
                readOnly={true}
                component={RenderFieldV1}
              />{' '}
            </div>{' '}
          </div>
          <div>
            <p className='fw-bold'>Listing Type & Images</p>
            <Field
              name="category"
              textField="label"
              valueField="value"
              label="Category*"
              id="category"
              value={this.state.category}
              readOnly={this.state.disableCategory}
              component={DropdownCompV2}
              placeholder="-select-"
              validate={[required]}
              ref="category"
              onChange={this.hanldeCategory}
              options={[
                {
                  label: 'Renovated/Move-In Ready',
                  value: 'renovated',
                },
                {
                  label: 'Wholesale',
                  value: 'wholesale',
                },
              ]}
            />{' '}
            <div className='row'>
              <div className={"col-sm-6"}>
                <label> Feature Image * </label>{' '}
                <Field
                  component={FileSelect}
                  id={'feature_image'}
                  name="feature_image"
                  minWidth={450}
                  minHeight={300}
                  dimensionsCheck={true}
                  ratioUpper={450}
                  ratioLower={300}
                  formAction={formAction}
                  validate={[required]}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name="img_alt_featured"
                  fieldName="Image Alternative Text"
                  type="text"
                  component={RenderField}
                />
              </div>
            </div>
            {' '}
            {this.state.feature_image ? (
              <img
                src={this.state.feature_image.secure_url}
                alt=""
                width="120px"
                className="img-responsive img-thumbnail i-bot"
              />
            ) : null}{' '}
          </div>
          <div className="GalleryHd">
            <p className='fw-bold'>Gallery Images</p>{' '}
            <Field
              name="img_alt_gallery"
              fieldName="Image Alternative Text"
              type="text"
              component={RenderField}
            />
          </div>{' '}
          <Field
            name="images"
            component={MultiFileUpload}
            validate={[this.minImages]}
            onChange={this.storeImages}
          />{' '}
          <Button
            className="expendImg"
            color="primary"
            type="button"
            onClick={this.showGallery}
            style={{
              marginBottom: '1rem',
            }}
          >
            Expand Images{' '}
            <i className="fa fa-angle-down expandIcon"> </i>{' '}
          </Button>{' '}
          <Collapse isOpen={this.state.showGallery}>
            <Card>
              <CardBody>
                <div className="galleryOuter">
                  <Grid
                    images={this.props.images_v1 || []}
                    onSortEnd={this.onSortEnd}
                    removeImage={this.removeImage}
                    axis="xy"
                    onError={this.onError}
                  />{' '}
                </div>{' '}
              </CardBody>{' '}
            </Card>{' '}
          </Collapse>{' '}
          <p className='fw-bold'>Listing Details</p>
          <div className="row">
            <div className="col-sm-3">
              <Field
                name="status_prop"
                textField="label"
                valueField="value"
                label="Listing Status*"
                id="status_prop"
                component={DropdownCompV2}
                placeholder="-select-"
                validate={required}
                options={PropertyStatus}
              />{' '}
            </div>{' '}
            <div className="col-sm-3">
              <Field
                name="listing_type"
                fieldName="Listing Type"
                placeholder="-select-"
                readOnly={true}
                label="Listing Type*"
                validate={[requiredInCrm]}
                value={this.state.listingType}
                textField="label"
                valueField="value"
                component={DropdownCompV2}
                options={[
                  {
                    label: 'MLS',
                    value: 'MLS',
                  },
                  {
                    label: 'Wholesale',
                    value: 'Wholesale',
                  },
                  {
                    label: "MLS Wholesale",
                    value: "MLS Wholesale"
                  }
                ]}
              />{' '}
            </div>
            <div className="col-sm-3">
              <Field
                name="listing_date"
                id="listing_date"
                disabled={true}
                fieldName="Listing Date*"
                validate={[requiredInCrm]}
                component={DatePicker}
              />{' '}
            </div>{' '}
            <div className="col-sm-3">
              <Field
                name="sold_date"
                fieldName="Sold Date*"
                validate={[soldDateValidate]}
                disabled={true}
                id="sold_date"
                component={DatePicker}
              />{' '}
            </div>{' '}
          </div>{' '}
          <div className="row">
            <div className="col-sm-3">
              <Field
                name="sqft"
                fieldName="Total Sq Ft*"
                type="text"
                id="sqft"
                normalize={normalizePhone}
                {...currencyMask}
                onFocus={this.onFocusSqft}
                component={RenderField}
                validate={[required, squareValidation]}
              />
            </div>{' '}
            <div className="col-sm-3">
              <Field
                name="price"
                fieldName="Price($)*"
                validate={[requiredInCrm]}
                readOnly={true}
                type="text"
                id="price"
                component={RenderField}
                {...currencyMask}
                normalize={normalizePhone}
              />{' '}
            </div>
            <div className="col-sm-3">
              {category === 'wholesale' ? (
                <Field
                  name="buy_now_price"
                  fieldName="Buy Now Price($)"
                  type="text"
                  id="buy_now_price"
                  component={RenderField}
                  {...currencyMask}
                  normalize={normalizePhone}
                  validate={
                    category === 'wholesale'
                      ? [squareValidation, PriceMax]
                      : [squareValidation]
                  }
                />
              ) : (
                ''
              )}
            </div>{' '}
            <div className="col-sm-3">
              {category === 'wholesale' ? (
                <Field
                  name="purchase_price"
                  fieldName="Acquisition Price($)*"
                  type="text"
                  id="purchase_price"
                  component={RenderField}
                  {...currencyMask}
                  normalize={normalizePhone}
                  validate={
                    category === 'wholesale'
                      ? [required, squareValidation, PriceMax]
                      : [squareValidation]
                  }
                />
              ) : (
                ''
              )}
            </div>{' '}
            <div className="col-sm-3">
              {category === 'wholesale' ? (
                <Field
                  name="min_price_reserve"
                  fieldName="Minimum Reserve Price($)"
                  type="text"
                  id="min_price_reserve"
                  component={RenderField}
                  {...currencyMask}
                  normalize={normalizePhone}
                  validate={
                    category === 'wholesale'
                      ? [squareValidation, PriceMax]
                      : [squareValidation]
                  }
                />
              ) : (
                ''
              )}
            </div>{' '}
            <div className="col-sm-3">
              {' '}
              {category == 'wholesale' ? (
                <Field
                  name="avr"
                  fieldName="ARV*"
                  onFocus={this.onFocusArv}
                  type="text"
                  id="avr"
                  normalize={normalizePhone}
                  {...currencyMask}
                  component={RenderField}
                  validate={[required, squareValidation, PriceMax]}
                />
              ) : (
                ''
              )}
            </div>
            <div className="col-sm-3">
              <Field
                name="isDistressed"
                placeholder="-select-"
                readOnly={true}
                label="Distressed*"
                value={isDistressed}
                textField="label"
                valueField="value"
                component={DropdownCompV2}
                options={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false || undefined,
                  }
                ]}
              />
            </div>
            <div className="col-sm-3">
              {category === 'wholesale' ? (
                <Field
                  name="seller_name"
                  fieldName="Seller Name (For contract)*"
                  type="text"
                  id="seller_name"
                  component={RenderField}
                  validate={
                    category === 'wholesale' ? [required] : []
                  }
                />
              ) : (
                ''
              )}
            </div>
            {category === 'wholesale' ? (
              <div className="col-sm-3">
                {' '}
                <Field
                  name="acquisition_ratification_date"
                  fieldName="Acquisition Ratification Date*"
                  id="acquisition_ratification_date"
                  disabled={true}
                  max={(function () {
                    let date = new Date();
                    date.setMonth(date.getMonth() + 2);
                    return date;
                  })()}
                  component={DatePicker}
                  validate={
                    category === 'wholesale' ? [required] : []
                  }
                />
              </div>
            ) : (
              ''
            )}
            {listingType === 'Wholesale'?(
            <div className="col-sm-6">
              <Field
                name="wholesaleRemarks"
                fieldName="Wholesale Remarks*"
                validate={[requiredInCrm]}
                type="text"
                rows={3}
                id="wholesaleRemarks"
                textarea
                maxLength="600"
                defaultValue={this.state.remarks.wholesaleRemarks}
                component={RenderField}
                readOnly={true}
              // validate={[required, RemarksMin]}
              />
            </div>):(
              ""
              )}
              {listingType === 'MLS' || listingType === 'MLS Wholesale'?(
            <div className="col-sm-6">
              <Field
                name="publicRemarks"
                fieldName="Public Remarks*"
                validate={[requiredInCrm]}
                type="text"
                rows={3}
                id="publicRemarks"
                textarea
                maxLength="600"
                defaultValue={this.state.remarks.publicRemarks}
                component={RenderField}
                readOnly={true}
              />
            </div>):(
              ""
              )}
            

          </div>{' '}
          <h3> Key Features </h3>{' '}
          <div className="row">
            <div className="col-sm-4">
              <Field
                name="key_features.property_type"
                textField="label"
                valueField="value"
                label="Property Type*"
                readOnly={this.state.allowSelectedFieldReadOnly}
                id="key_features.property_type"
                validate={[this.state.allowSelectedFieldReadOnly ? requiredInCrm : required]}
                component={DropdownCompV2}
                placeholder="-select-"
                // validate={required}
                options={[
                  {
                    label: 'Single Family',
                    value: 'Single Family',
                  },
                  {
                    label: 'Townhouse',
                    value: 'Townhouse',
                  },
                  {
                    label: 'Townhouse-Duplex',
                    value: 'Townhouse-Duplex',
                  },
                  {
                    label: 'Townhouse-Triplex',
                    value: 'Townhouse-Triplex',
                  },
                  {
                    label: 'Condo',
                    value: 'Condo',
                  },
                  {
                    label: 'Semi-Detached',
                    value: 'Semi-Detached',
                  },
                ]}
              />{' '}
            </div>{' '}
            <div className="col-sm-4">
              <Field
                name="key_features.year_built"
                fieldName="Year Built*"
                type="text"
                validate={[requiredInCrm]}
                max={4}
                normalize={normalizeBuilt}
                component={RenderField}
                readOnly={true}
              />{' '}
            </div>{' '}
            
            <div className="col-sm-4">
              <Field
                name="key_features.heating"
                id="key_features.heating"
                validate={[this.state.allowSelectedFieldReadOnly ? requireArrayInCrm : requireArray ]}
                selectedValues={this.state.selectedHeatingType}
                label="Heating*"
                component={MaterialMultiV1}
                placeholder="-select-"
                readOnly={this.state.allowSelectedFieldReadOnly}
                options={[
                  {
                    title: 'None',
                    _id: 'None',
                  }, {
                    title: '90% Forced Air',
                    _id: '90% Forced Air',
                  }, {
                    title: 'Baseboard',
                    _id: 'Baseboard',
                  }, {
                    title: 'Bio Fuel',
                    _id: 'Bio Fuel',
                  }, {
                    title: 'Ceiling',
                    _id: 'Ceiling',
                  }, {
                    title: 'Central',
                    _id: 'Central',
                  },
                  {
                    title: 'Coal',
                    _id: 'Coal',
                  },
                  {
                    title: 'Electric',
                    _id: 'Electric',
                  },
                  {
                    title: 'Electric Air Filter',
                    _id: 'Electric Air Filter',
                  }, {
                    title: 'Electric Star Heating',
                    _id: 'Electric Star Heating',
                  }, {
                    title: 'Floor Furnace',
                    _id: 'Floor Furnace',
                  }, {
                    title: 'Forced Air',
                    _id: 'Forced Air',
                  }, {
                    title: 'Gas',
                    _id: 'Gas',
                  }, {
                    title: 'Geothermal',
                    _id: 'Geothermal',
                  }, {
                    title: 'Geothermal Heat Pump',
                    _id: 'Geothermal Heat Pump',
                  }, {
                    title: 'Gravity',
                    _id: 'Gravity',
                  }, {
                    title: 'Heat Pump - Electric Backup',
                    _id: 'Heat Pump - Electric Backup',
                  }, {
                    title: 'Heat Pump - Gas Backup',
                    _id: 'Heat Pump - Gas Backup',
                  }, {
                    title: 'Heat Pump - Oil Backup',
                    _id: 'Heat Pump - Oil Backup',
                  }, {
                    title: 'Heat Pump(s)',
                    _id: 'Heat Pump(s)',
                  }, {
                    title: 'Hot Water',
                    _id: 'Hot Water',
                  }, {
                    title: 'Humidifier',
                    _id: 'Humidifier',
                  }, {
                    title: 'Oil',
                    _id: 'Oil',
                  }, {
                    title: 'Pellet Stove',
                    _id: 'Pellet Stove',
                  }, {
                    title: 'Programmable Thermostat',
                    _id: 'Programmable Thermostat',
                  }, {
                    title: 'Propane',
                    _id: 'Propane',
                  }, {
                    title: 'Radiant',
                    _id: 'Radiant',
                  }, {
                    title: 'Radiator',
                    _id: 'Radiator',
                  }, {
                    title: 'S/W Changeover',
                    _id: 'S/W Changeover',
                  }, {
                    title: 'Solar Active/Passive',
                    _id: 'Solar Active/Passive',
                  }, {
                    title: 'Solar Off Grid',
                    _id: 'Solar Off Grid',
                  }, {
                    title: 'Solar On Grid',
                    _id: 'Solar On Grid',
                  }, {
                    title: 'Solar Rough-In',
                    _id: 'Solar Rough-In',
                  },
                  {
                    title: 'Space Heater',
                    _id: 'Space Heater',
                  },
                  {
                    title: 'Steam',
                    _id: 'Steam',
                  },
                  {
                    title: 'Wall Unit',
                    _id: 'Wall Unit',
                  }, {
                    title: 'Wood Burn Stove',
                    _id: 'Wood Burn Stove',
                  },
                  {
                    title: 'Zoned',
                    _id: 'Zoned',
                  },
                  {
                    title: 'Other',
                    _id: 'Other',
                  },
                ]}
              />{' '}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              <Field
                name="key_features.parking_type"
                id="key_features.parking_type"
                validate={[this.state.allowSelectedFieldReadOnly ? requireArrayInCrm : requireArray]}
                selectedValues={this.state.selectedParkingType}
                label="Parking Type*"
                component={MaterialMultiV1}
                readOnly={this.state.allowSelectedFieldReadOnly}
                placeholder="-select-"
                options={[
                  {
                    title: 'None',
                    _id: 'None'
                  },
                  {
                    title: 'Alley',
                    _id: 'Alley'
                  },
                  {
                    title: 'Attached Carport',
                    _id: 'Attached Carport',
                  },
                  {
                    title: 'Attached Garage',
                    _id: 'Attached Garage',
                  },
                  {
                    title: 'Detached Carport',
                    _id: 'Detached Carport'
                  },
                  {
                    title: 'Detached Garage',
                    _id: 'Detached Garage'
                  },
                  {
                    title: 'Driveway',
                    _id: 'Driveway'
                  },
                  {
                    title: 'Off site',
                    _id: 'Off site',
                  },
                  {
                    title: 'Off Street',
                    _id: 'Off Street',
                  },
                  {
                    title: 'On Street',
                    _id: 'On Street',
                  },
                  {
                    title: 'Parking lot',
                    _id: 'Parking lot',
                  },
                  {
                    title: 'Other',
                    _id: 'Other',
                  },
                ]}
              />{' '}
            </div>
            <div className="col-sm-4">
              <Field
                name="key_features.lot"
                id="key_features.lot"
                fieldName={"Lot Sq Ft"}
                type="text"
                normalize={normalizePhone}
                {...currencyMask}
                component={RenderField}
                readOnly={true}
              />{' '}
            </div>
            <div className="col-sm-4">
              <Field
                name="key_features.cooling"
                validate={[this.state.allowSelectedFieldReadOnly ? requireArrayInCrm : requireArray]}
                id="key_features.cooling"
                selectedValues={this.state.selectedCoolingType}
                label="Cooling*"
                component={MaterialMultiV1}
                placeholder="-select-"
                readOnly={this.state.allowSelectedFieldReadOnly}
                options={[
                  {
                    title: 'Central A/C',
                    _id: 'Central A/C',
                  },
                  {
                    title: 'Ceiling Fan(s)',
                    _id: 'Ceiling Fan(s)',
                  },
                  {
                    title: 'Air Purification System',
                    _id: 'Air Purification System',
                  },
                  {
                    title: 'Attic Fan',
                    _id: 'Attic Fan',
                  },
                  {
                    title: 'Dehumidifier',
                    _id: 'Dehumidifier',
                  },
                  {
                    title: 'ENERGY STAR Cooling System',
                    _id: 'ENERGY STAR Cooling System',
                  },
                  {
                    title: 'Fresh Air Recovery System',
                    _id: 'Fresh Air Recovery System',
                  },
                  {
                    title: 'Geothermal',
                    _id: 'Geothermal',
                  },
                  {
                    title: 'Heat Pump(s)',
                    _id: 'Heat Pump(s)',
                  },
                  {
                    title: 'HRV/ERV',
                    _id: 'HRV/ERV',
                  },
                  {
                    title: 'Programmable Thermostat',
                    _id: 'Programmable Thermostat',
                  },
                  {
                    title: 'Roof Mounted',
                    _id: 'Roof Mounted',
                  },
                  {
                    title: 'Solar Attic Fan',
                    _id: 'Solar Attic Fan',
                  },
                  {
                    title: 'Solar Off Grid',
                    _id: 'Solar Off Grid',
                  },
                  {
                    title: 'Solar Rough-In',
                    _id: 'Solar Rough-In',
                  },
                  {
                    title: 'Wall Unit',
                    _id: 'Wall Unit',
                  },
                  {
                    title: 'Whole House Exhaust Ventilation',
                    _id: 'Whole House Exhaust Ventilation',
                  },
                  {
                    title: 'Whole House Fan',
                    _id: 'Whole House Fan',
                  },
                  {
                    title: 'Whole House Supply Ventilation',
                    _id: 'Whole House Supply Ventilation',
                  },
                  {
                    title: 'Window Unit(s)',
                    _id: 'Window Unit(s)',
                  },
                  {
                    title: 'Zoned',
                    _id: 'Zoned',
                  },
                  {
                    title: 'Other',
                    _id: 'Other',
                  },
                  {
                    title: 'None',
                    _id: 'None',
                  },
                ]}
              />{' '}
            </div>
          </div>
          <h3> Interior Features </h3>
          <div className="row">
            <div className="col-sm-4">
              <Field
                name="interior_features.bed"
                validate={[requiredInCrm]}
                id="interior_features.bed"
                options={BED}
                label="Beds*"
                placeholder="-select-"
                textField="title"
                valueField="value"
                component={DropdownCompV2}
                readOnly={true}
              />{' '}
            </div>
            <div className="col-sm-4">
              <Field
                name="interior_features.bath"
                validate={[requiredInCrm]}
                id="interior_features.bath"
                options={BATH}
                label="Baths*"
                placeholder="-select-"
                textField="title"
                valueField="value"
                component={DropdownCompV2}
                readOnly={true}
              />{' '}
            </div>
            <div className="col-sm-4">
              <Field
                name="interior_features.basement"
                validate={[requiredInCrm]}
                id="interior_features.basement"
                options={Basement}
                label="Basement*"
                placeholder="-select-"
                textField="title"
                valueField="value"
                component={DropdownCompV2}
                readOnly={true}
              />{' '}
            </div>
          </div>
          {category === 'renovated' ? (
            <div>
              <Field
                options={Appliances}
                id="interior_features.appliances_input"
                name="interior_features.appliances"
                selectedValues={selectedappliances}
                label="Appliances*"
                component={MaterialMultiV1}
                validate={[requireArray]}
              />

              <Field
                options={otherFeatures}
                name="interior_features.otherfeatures"
                selectedValues={selectedotherfeatures}
                id="interior_features.otherfeatures_input"
                label="Other Features*"
                component={MaterialMultiV1}
                validate={[requireArray]}
              />
            </div>
          ) : (
            ''
          )}
          <div className="row">
            {' '}
            {category === 'renovated' ? (
              <div className="col-sm-3">
                <Field
                  name="interior_features.patio"
                  id="interior_features.patio"
                  textField="label"
                  valueField="value"
                  label="Patio*"
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={required}
                  options={[
                    {
                      label: 'Yes',
                      value: 'Yes',
                    },
                    {
                      label: 'No',
                      value: 'No',
                    },
                  ]}
                />{' '}
              </div>
            ) : (
              ''
            )}
            <div className="col-sm-3">
              <Field
                name="interior_features.fireplace"
                id="interior_features.fireplace"
                textField="label"
                valueField="value"
                label="Fireplace*"
                component={DropdownCompV2}
                placeholder="-select-"
                validate={required}
                options={[
                  {
                    label: '0',
                    value: '0',
                  },
                  {
                    label: '1',
                    value: '1',
                  },
                  {
                    label: '2',
                    value: '2',
                  },
                  {
                    label: '3',
                    value: '3',
                  },
                  {
                    label: '4',
                    value: '4',
                  },
                ]}
              />{' '}
            </div>{' '}
            {category === 'renovated' ? (
              <div className="col-sm-3">
                <Field
                  name="interior_features.balcony"
                  id="interior_features.balcony"
                  textField="label"
                  valueField="value"
                  label="Balcony*"
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={required}
                  options={[
                    {
                      label: 'Yes',
                      value: 'Yes',
                    },
                    {
                      label: 'No',
                      value: 'No',
                    },
                  ]}
                />{' '}
              </div>
            ) : (
              ''
            )}{' '}
            <div className="col-sm-3">
              <Field
                name="interior_features.pool"
                id="interior_features.pool"
                textField="label"
                valueField="value"
                label="Pool*"
                component={DropdownCompV2}
                placeholder="-select-"
                validate={required}
                options={[
                  {
                    label: 'In Ground',
                    value: 'In Ground',
                  },
                  {
                    label: 'Above Ground',
                    value: 'Above Ground',
                  },
                  {
                    label: 'None',
                    value: 'None',
                  },
                ]}
              />{' '}
            </div>
          </div>
          <h3> Exterior Features </h3>
          {category === 'renovated' ? (
            <Field
              options={Exterior}
              name="exterior_features.exterior"
              id="exterior_features.exterior_input"
              selectedValues={selectedExterior}
              label="Exterior*"
              component={MaterialMultiV1}
              validate={[requireArray]}
            />
          ) : (
            ''
          )}
          {category === 'renovated' ? (
            <div className="row">
              <div className="col-sm-3">
                <Field
                  name="exterior_features.view"
                  id="exterior_features.view"
                  selectedValues={this.state.selectedViewType}
                  label="View"
                  component={MaterialMultiV1}
                  placeholder="-select-"
                  readOnly={true}
                  options={VIEW}
                />{' '}
              </div>{' '}
              <div className="col-sm-3">
                <Field
                  name="exterior_features.backYard"
                  id="exterior_features.backYard"
                  textField="title"
                  valueField="value"
                  label="Backyard*"
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={required}
                  options={BackYard}
                />{' '}
              </div>{' '}
              <div className="col-sm-3">
                <Field
                  name="exterior_features.deck"
                  id="exterior_features.deck"
                  textField="title"
                  valueField="value"
                  label="Deck*"
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={required}
                  options={DECK}
                />{' '}
              </div>{' '}
              <div className="col-sm-3">
                <Field
                  name="exterior_features.porch"
                  id="exterior_features.porch"
                  textField="title"
                  valueField="value"
                  label="Porch*"
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={required}
                  options={PORCH}
                />{' '}
              </div>{' '}
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-4">
                <Field
                  name="exterior_features.backYard"
                  id="exterior_features.backYard"
                  textField="title"
                  valueField="value"
                  label="Backyard*"
                  component={DropdownCompV2}
                  placeholder="-select-"
                  validate={required}
                  options={BackYard}
                />{' '}
              </div>{' '}
              <div className="col-sm-4">
                {' '}
              </div>{' '}
            </div>
          )}{' '}
          <h3> Associations </h3>{' '}
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <Field
                name="associations.isThereAnHoa"
                id="associations.isThereAnHoa"
                onChange={this.onChangeIsThereHOA}
                options={[
                  { title: 'Yes', value: "Yes" },
                  { title: 'No', value: 'No' },
                ]}
                textField="title"
                valueField="value"
                validate={[requiredInCrm]}
                label="Is there an HOA?*"
                defaultValue={
                  this.state.isThereAnHoa
                }
                component={DropdownCompV2}
                placeholder="-select-"
                readOnly={true}
              /></div>{' '}

            {isThereAnHoa == "Yes" ? (
              <div className="col-lg-4 col-md-4 col-sm-12">
                <Field
                  name="associations.hoaFeePeriod"
                  id="associations.hoaFeePeriod"
                  textField="title"
                  valueField="value"
                  label="HOA Fee Period*"
                  onChangeHoa={this.onChangeHoa}
                  component={DropdownCompV2}
                  placeholder="-select-"
                  readOnly={true}
                  options={HOAFeePeriod}
                /></div>) : (<span></span>)
            }
            {isThereAnHoa == "Yes" && (
              <div className="col-lg-4 col-md-4 col-sm-12">
                <Field
                  name="associations.hoaFee"
                  id="associations.hoaFee"
                  fieldName="HOA Fee"
                  type="text"
                  normalize={normalizeHoa}
                  readOnly={true}
                  component={RenderField}
                />
              </div>
            )}
          </div>{' '}

          <div className='row'>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <Field
                name="associations.isThereACoa"
                id="associations.isThereACoa"
                validate={[requiredInCrm]}
                options={[
                  { title: 'Yes', value: "Yes" },
                  { title: 'No', value: 'No' },
                ]}
                onChange={this.onChangeIsThereCOA}
                textField="title"
                valueField="value"
                label="Is there an COA?*"
                readOnly={true}
                defaultValue={
                  this.state.isThereACoa
                }
                component={DropdownCompV2}
                placeholder="-select-"
              />{' '}
            </div>{' '}
            {isThereACoa == "Yes" ? (
              <div className="col-lg-4 col-md-4 col-sm-12">
                <Field
                  name="associations.coaFeePeriod"
                  id="associations.coaFeePeriod"
                  textField="title"
                  valueField="value"
                  label="COA Fee Period*"
                  onChangeCoa={this.onChangeCoa}
                  component={DropdownCompV2}
                  placeholder="-select-"
                  readOnly={true}
                  options={COAFeePeriod}
                /></div>) : (<span></span>)}
            {isThereACoa == "Yes" && (
              <div className="col-lg-4 col-md-4 col-sm-12">
                <Field
                  name="associations.coaFee"
                  id="associations.coaFee"
                  fieldName="COA Fee"
                  type="text"
                  normalize={normalizeHoa}
                  component={RenderField}
                  readOnly={true}
                />
              </div>
            )}{' '}

          </div>{' '}
          <Field
            name="agent"
            textField="name"
            id="agent"
            valueField="_id"
            label="Dispositions Consultant*"
            validate={[requiredInCrm]}
            component={DropdownCompV2}
            readOnly={true}
            placeholder="-select-"
            // validate={required}
            options={selectedAgent}
          />{' '}
          <Field
            name="market"
            id="market"
            textField="name"
            valueField="name"
            label="Market*"
            validate={[requiredInCrm]}
            className={`${isMarketActiveBanner || isMarketExistBanner ? "bg-redd" : ""}`}
            style={{ backgroundColor: "#ff4545ab" }}
            component={DropdownCompV2}
            placeholder="-select-"
            readOnly={true}
            options={marketImage}
          />
          <Banner showBanner={isMarketActiveBanner} css={{ color: "#FFF", backgroundColor: "#ff4545ab", borderRadius: "4px", cursor: "pointer" }}>
            <div onClick={() => this.openEditMarket(currentMarket._id)}>This market is inactive, click here to make it active.</div>
          </Banner>
          <Banner showBanner={isMarketExistBanner} css={{ color: "#FFF", backgroundColor: "#ff4545ab", borderRadius: "4px", cursor: "pointer" }}>
            <div onClick={() => this.openAddMarket()}>This market is missing from admin panel, click here to add market.</div>
          </Banner>
          <div style={{ display: "none" }}>
            <Field
              name="guid"
              readOnly={true}
              id="guid"
              fieldName="GUID*"
              type="text"
              component={RenderField}
            />
          </div>
          <Field
            name="status"
            placeholder="-select-"
            options={STATUS}
            label="Status*"
            defaultValue={
              this.state.status == true
                ? 'Active'
                : this.state.status == false
                  ? 'Inactive'
                  : null
            }
            textField="title"
            onChange={this.onStatusChange}
            valueField="value"
            component={DropdownComp}
            validate={[required]}
            disabled={this.state.status == true && !this.props.solvedPermissions?.markPropertyInative}
          />
          <br />
          <div className="form-actions">
          <button
              className="btn grey uppercase mr-12"
            //disabled={this.props.invalid || this.props.submitting}
            onClick={this.onBackButtonClick}
            >
              {'BACK'}{' '}
            </button>

            <button
              type="submit"
              className="btn green uppercase"
              disabled={this.props.submitting}
              //disabled={this.props.invalid || this.props.submitting}
              onClick={handleSubmit(e => {
                this.upsertProp(e)
              })}
            >
              {formAction === 'ADD' ? 'Add' : 'Update'}{' '}
            </button>
            {!orignalStatus || orignalStatus == "false" || match.params.draft=="draft"? (
              <button id="draftButton"style={{ marginLeft: "6px" }}
                className="btn green uppercase ps-1"
                onClick={()=>{this.upsertDraft(window["propFormValue"].values,'draft')}}
              >
                {'Save as draft'}
              </button>) : ("")}
          </div>
        </form >
      )
    }
  }

  render() {
    const { handleSubmit } = this.props;

    const {
      lot,
      isLoading,
      formAction,
      status,
      selectedAgent,
      marketImage,
      countyList,
      selectedappliances,
      selectedExterior,
      selectedotherfeatures,
      category,
      modalOpen,
      isMarketExistPopup,
      isMarketActivePopup,
      selectedYearBuilt,
      modalOpenArv,
      dispositions_manager,
      defaultTabSelected,
      suggestions,
      value,
      isSuggestionSelected,
      spreadStatus
    } = this.state;

    return (
      <div className="relative">
        {' '}
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <PageHeader
              route={
                formAction === 'ADD' ? 'Add New Property' : 'Edit Property'
              }
              parent="Property"
              parentRoute="/property-list"
            />
            <div>
              <Tabs
                defaultActiveKey={defaultTabSelected}
                onSelect={this.setSelectedTab}
                animation={false}
                id="profileTabs"
              >
                <Tab
                  eventKey={1}
                  title={
                    formAction === 'ADD' ? 'Add New Property' : 'Edit Property'
                  }

                >
                  { formAction === 'EDIT'?(
                    <div style={{display:"flex",justifyContent:"end"}}>
                    <button className="btn sbold green m3" onClick={this.getCrmProp}>
                         { this.state.isSyncing ? 'Loading...' : 'Sync' }
                    </button>
                    <button disabled={!this.state.status || this.state.status === 'false'} style={{marginLeft: 10}} className="btn sbold green m3" onClick={this.syncPropertyWithWordPress}>
                        {this.state.isSyncing ? 'Loading...' : 'WordPress Sync'}
                      </button>
                  </div>
                  ):("")    
                  }

                  {
                    this.renderAutoSuggest()
                  }
                  {
                    this.renderForm()
                  }
                </Tab>

                <Tab
                  eventKey={2}
                  title="Open Houses"
                  disabled={formAction === 'ADD'}
                >
                  <OpenHouses property_id={this.props.match.params._id} />
                </Tab>
                {/* <Tab eventKey={3} title="Inquiries" disabled={formAction === 'ADD'}>
                  <div>Inquiries</div>
                </Tab> */}

                <Tab
                  eventKey={4}
                  title="Offers"
                  disabled={formAction === 'ADD'}
                >
                  <Offers
                    offers={this.state.offers}
                    acceptOffer={this.acceptOffer}
                    declineOffer={this.declineOffer}
                    deleteOffer={this.deleteOffer}
                    showOfferDeleteModal={this.showOfferDeleteModal}
                    closeOfferDeleteModal={this.closeOfferDeleteModal}
                    showOfferDelete={this.state.showOfferDelete}
                    isRedirect={this.state.isRedirect}
                    offerSetllementDialogFun={this.offerSetllementDialogFun}
                    offerSetllementDialogFlag={
                      this.state.offerSetllementDialogFlag
                    }
                    closeOfferSettelmentModal={this.closeOfferSettelmentModal}
                    setSettlementDate={this.setSettlementDate}
                    propertyType={category}
                  />
                </Tab>
                <Tab
                  eventKey={5}
                  title="Comparables"
                  disabled={formAction === 'ADD'}
                >
                  <Comparables
                    showComparablesAutoAddModal={
                      this.state.showComparablesAutoAddModal
                    }
                    showComparablesAddModal={this.showComparablesAddModal}
                    closeComparablesAddModal={this.closeComparablesAddModal}
                    property_id={this.props.match.params._id}
                    propertyType={category}
                  />
                </Tab>
              </Tabs>
              {spreadStatus && (
                <ShowPopup errorModal={true}
                  closeParentModal={this.closeSpreadPopup}
                  message={
                    [<div>The ARV and Price values do NOT meet the following requirements. Please adjust the ARV or the price so that the spread is bigger, we can’t advertise a small spread to investors.<br></br>&emsp;&emsp;&emsp;Spread (ARV – Price) &#60; $25,000 <br></br>&emsp;&emsp;&emsp;Spread (ARV – Price) &#60;	 30% of ARV</div>]
                  }
                />
              )}{' '}
              {modalOpen && (
                <ShowPopup
                  closeParentModal={this.closeParentModal}
                  message={
                    'This is total sq ft, includes above and below sq ft as well as unfinished and finished sq ft.'
                  }
                />
              )}{' '}
              {modalOpenArv && (
                <ShowPopupArv
                  closeParentModal={this.closeParentModal}
                  message={`Use the "ARV HI” from the latest valuation.`}
                />
              )}{' '}
            </div>{' '}
          </div>
        )}
        <Modal show={this.state.show} onHide={this.closeModel}>
          <Modal.Header closeButton className="theme-bg">
            <Modal.Title>Do you want to send Flyers Now?</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit(this.sendFlyer)}>
            <Modal.Body>
              <div>
                Please click on the send button OR choose later for sending
                afterwards
              </div>
            </Modal.Body>

            <Modal.Footer>
              {/* <Button className='btn btn-default' onClick={this.closeModel}>Cancel</Button> */}
              <button
                type="submit"
                onClick={this.sendFlyers}
                className="btn green uppercase"
              >
                {'Send'}
              </button>
              <Button onClick={this.closeModel} className="btn red-mint">
                Later
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal show={this.state.showDistressedModal}>
          <Modal.Header closeButton className="theme-bg">
            <Modal.Title>Distressed Property Issue</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit(this.sendFlyer)}>
            <Modal.Body>
              <div>
                If a property is marked as distressed, the category should be selected as wholesale.
                Please selected correct category or mark this property as none distressed in CRM.
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={this.closeDistressedModel} className="btn green uppercase">
                Ok
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
  showOfferDeleteModal = (data) => {
    /*show popup and confirm before delete*/
    this.setState({ showOfferDelete: true, offer_id: data });
  };
  closeOfferDeleteModal = () => {
    this.setState({ showOfferDelete: false });
  };
  offerSetllementDialogFun = (data) => {
    // document.getElementById('settlement_date_input').value="";
    this.setState({ offerSetllementDialogFlag: true, offer_id: data });
    console.log(this.state.offerSetllementDialogFlag, 'Flag Offer', data);
  };
  closeOfferSettelmentModal = () => {
    this.setState({ offerSetllementDialogFlag: false });
  };
  showComparablesAddModal = () => {
    this.setState({ showComparablesAutoAddModal: true });
  };
  closeComparablesAddModal = () => {
    this.setState({ showComparablesAutoAddModal: false });
  };
  sendFlyer() { }

  closeModel() {
    /**to close modal */
    this.props.dispatch(push('/property-list'));

    // this.setState({show:false})
  }

  closeDistressedModel() {
    this.setState({
      showDistressedModal: false
    })
  }

  sendFlyers() {
    this.props.dispatch(push(this.state.flyerUrl));
  }

  overidePopup(){
    this.setState({
      overideMinImage:true,
      showOveridePopup:false
    })
  }

  upsertProp(data) {
    const { change } = this.props
    if (this.state.status == false) {
      change("status", "false")
    }
    // checking ARV validation
    data.guid = data.guid.toLowerCase()
    if(document.getElementsByName('price')[0].value){
      let currentPrice = document.getElementsByName('price')[0].value;
    currentPrice = currentPrice.replaceAll(",", "")
    if (!!document.getElementsByName('avr')[0]) {
      let currentArv = document.getElementsByName('avr')[0].value;
      currentArv = currentArv.replaceAll(",", "")
      let spread = parseInt(currentArv) - parseInt(currentPrice);
      if (spread > 25000 && spread > (currentArv / 100) * 25) {
        this.setState({
          spreadStatus: false
        })
      } else {
        this.setState({
          spreadStatus: true
        })
        return
      }
    }
    if ((!this.state.galleryImages || this.state.category == "renovated" && Object.keys(this.state.galleryImages).length <= 3) || (this.state.category == "wholesale" && Object.keys(this.state.galleryImages).length <= 10)) {
      if(!this.state.overideMinImage && this.state.currentUser && this.state.currentUser.role && this.state.currentUser.role.title == "Settlement Manager"){
        this.setState({
          showOveridePopup: true
        })
        return false
      }
    }
    
    if ((!this.state.galleryImages || this.state.category == "renovated" && Object.keys(this.state.galleryImages).length <= 3) || (this.state.category == "wholesale" && Object.keys(this.state.galleryImages).length <= 10)) {
      if(!this.state.overideMinImage && this.state.currentUser && this.state.currentUser.role && this.state.currentUser.role.title == "Settlement Manager"){
        this.setState({
          showOveridePopup: true
        })
        return false
      }
    }
    
    if ((!this.state.galleryImages || this.state.category == "renovated" && Object.keys(this.state.galleryImages).length <= 3) || (this.state.category == "wholesale" && Object.keys(this.state.galleryImages).length <= 10)) {
      if(!this.state.overideMinImage && this.state.currentUser && this.state.currentUser.role && this.state.currentUser.role.title == "Settlements"){
        this.setState({
          showOveridePopup: true
        })
        return false
      }
    }
    if (this.state.formAction === 'ADD') {
      data["street_address"] = this.state.streetAddressValue
    }
    console.log('data--->', data);
    let imagesObj = [];
    this.setState({
      isLoading: true,
    });

    console.log('this.state-->', this.state);

    const { match } = this.props;
    if (data && data.interior_features && data.interior_features.bed) {
      data.interior_features.bed = Number(data.interior_features.bed);
    }

    if (match.params._id) data.editID = match.params._id;

    let formData = new FormData();
    if (data.associations) {
      if (data.associations.hoaFeePeriod == 'None') {
        data.associations.hoaFee = '';
      }

      if (data.associations.coaFeePeriod == 'None') {
        data.associations.coaFee = '';
      }
    }

    if (data.key_features && data.key_features.lot) {
      data.exterior_features.lot = data.key_features.lot;
    }
    // check price and set the
    if (data.price != this.checkPrice && data.status_prop === 1) {
      data.isemail = false;
      data.property_flyer_type = 2;
    }

    if (!data.key_features.lot) {
      data.exterior_features.lot = '';
    }
    if (!data.agent || data.agent == "") {
      data.agent = null;
    }
    if (!data.status || data.status == "false") {
      data.status = false
    } else if (data.status == "Inactive") {
      data.status = false
    }
    else if (data.status == "Active") {
      data.status = true
    }
    formData.append('feature_image', data.feature_image);
    formData.append('data', JSON.stringify(data));
    console.log(formData.listing_date);
    HTTP.Request('post', window.admin.upsertPropertDetails, formData)
      .then((result) => {
        this.props.dispatch({
          type: ADMIN_TRACK_AUDIT_LOGS,
          action: {
              comment: "Modified the content of Property - " + result.data.street_address,
              type: "audit"
          }
      });

        this.setState({
          isLoading: false,
        });
        console.log(result);
        // check for flyers
        let isOutOfMarket = result.data.market === 'Out of Market';

        if (
          result.data.status_prop === 1 &&
          result.data.category === 'wholesale' &&
          result.data.status === true &&
          !isOutOfMarket
        ) {
          this.setState({
            show: true,
            flyerUrl: '/wholesale-flyer/' + result.data._id,
          });
        } else if (
          result.data.status_prop === 1 &&
          result.data.category === 'renovated' &&
          result.data.status === true && 
          !isOutOfMarket
        ) {
          this.setState({
            show: true,
            flyerUrl: '/renovated-flyer/' + result.data._id,
          });
        }
        //  else {
        //   this.props.dispatch(push('/property-list'));
        // }

        // this.props.dispatch(push("/property-list"));
        toast(result.message, {
          type: 'success',
        });
      })
      .catch((err) => {
        if (
          (err.duplicate && err.duplicate.codeName) ||
          (err.duplicate && err.duplicate.code)
        ) {
          toast('This slug is already in used', {
            type: 'error',
          });
        }
        this.setState({
          isLoading: false,
        });

        /*  toast(err.message, { type: "error" }); */
      });
  }
  }
  upsertDraft(data) {
    const { change } = this.state
    if (this.state.status == false) {
      this.state.status = "false"
      change("status", "false")
    }

    data.guid = data.guid.toLowerCase()
    let imagesObj = [];
    this.setState({
      isLoading: true,
    });

    const { match } = this.props;
    if (data && data.interior_features && data.interior_features.bed) {
      data.interior_features.bed = Number(data.interior_features.bed);
    }

    if (match.params._id) data.editID = match.params._id;

    let formData = new FormData();
    if (data.associations) {
      if (data.associations.hoaFeePeriod == 'None') {
        data.associations.hoaFee = '';
      }

      if (data.associations.coaFeePeriod == 'None') {
        data.associations.coaFee = '';
      }
    }

    if (data.key_features && data.key_features.lot) {
      data.exterior_features.lot = data.key_features.lot;
    }
    // check price and set the
    if (data.price != this.checkPrice && data.status_prop === 1) {
      data.isemail = false;
      data.property_flyer_type = 2;
    }

    if (!data.key_features.lot) {
      data.exterior_features.lot = '';
    }
    if (!data.agent || data.agent == "") {
      data.agent = null;
    }
    if (!data.status || data.status == "false") {
      data.status = false
    } else if (data.status == "Inactive") {
      data.status = false
    }
    else if (data.status == "Active") {
      data.status = true
    }
    formData.append('feature_image', data.feature_image);
      this.state.orignalData["editID"] = data._id
      this.state.orignalData["draft"] = JSON.stringify(data)
      formData.append('data', JSON.stringify(this.state.orignalData));
    HTTP.Request('post', window.admin.upsertPropertDetails, formData)
    .then((result) => {
      this.setState({
        isLoading: false,
      });
      this.props.dispatch({
        type: ADMIN_TRACK_AUDIT_LOGS,
        action: {
            comment: "Modified the content of Property - " + result.data.street_address,
            type: "audit"
        }
    });
        this.props.dispatch(push("/property-list"));
        toast(result.message, {
          type: 'success',
        });
      })
      .catch((err) => {
        if (
          (err.duplicate && err.duplicate.codeName) ||
          (err.duplicate && err.duplicate.code)
        ) {
          toast('This slug is already in used', {
            type: 'error',
          });
        }
        this.setState({
          isLoading: false,
        });
        /*  toast(err.message, { type: "error" }); */
      });
  }

  getAgent = async () => {
    const token = Session.getSession('token');
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
    };
    let response = await fetch(window.admin.allAgentsDynamic, requestOptions);
    const result = await response.json();
    this.state.selectedAgent = result.data ? result.data.agent : []
    this.setState({
      selectedAgent: result.data ? result.data.agent : [],
      marketImage: result.data ? result.data.market : [],
    });
    return result.value;
  }

  onBackButtonClick = () => {
    this.props.dispatch(push('/property-list'));
  }

  getAllWebUser() {
    this.setState({
      isLoading: true,
    });
    HTTP.Request('get', window.admin.getallwebuser, {}).then((result) => {
      console.log('result web', result);
      this.setState({
        //   isLoading: false,
        dispositions_manager: result.data ? result.data : [],
        //   marketImage: result.data ? result.data.market : [],
      });
    });
  }
  getCounties() {
    this.setState({
      isLoading: true,
    });

    HTTP.Request('get', window.admin.getCountyList, {}).then((result) => {
      this.setState({
        isLoading: false,
        countyList: result.data ? result.data : [],
      });
    });
  }

  getCurrentUser(){
    HTTP.Request('get', window.admin.getACurrentUser, {}).then((result) => {
      this.setState({
        currentUser: result,
      });
    });
  }

  getCrmProp = async () => {
    const { match, initialize, isSyncing } = this.props;
    this.setState({
      isSyncing: true
    })
    const token = Session.getSession('token');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
      body: JSON.stringify({ searchKeyword: this.state.propertyGuid })
    };
    let propResponse = await fetch(window.admin.getCrmPropertyByGuid, requestOptions);
    let crmPropData = await propResponse.json();
    let crmData = crmPropData.value[0];
    let isDistressedProperty = distressedArray.find(e => e.code === crmData.emn_distressedasisoptioncode)?.value || false
    if (!isDistressedProperty) {
      crmData.emn_coolingtypepostdd = "";
      crmData.emn_heattypepostdd = "";
      crmData.emn_parkingtypepostdd = "";
      crmData["emn_propertytype_postdd@OData.Community.Display.V1.FormattedValue"] = "";
    }
    this.fillForm(crmPropData.value[0])
    this.setState({
      isSyncing: false
    })
  }

  syncPropertyWithWordPress = async () => {
    this.setState({
      isSyncing: true
    })
    HTTP.Request('post', window.admin.syncPropertyWithWordpress, {
    guid: this.state.propertyGuid,
    }).then((result) => {
      this.setState({
        isSyncing: false
      })
    }).catch((error) => {
      this.setState({
        isSyncing: false
      })
    });
  }

  getProp = async () => {
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let roleID = match.params._id ? match.params._id : null;

    if (roleID) {
      this.setState({
        isLoading: true,
        formAction: 'EDIT',
      });
      HTTP.Request('get', window.admin.getPropertDetails, {
        id: roleID,
      }).then((result) => {
        if (!result.data.agent) {
          this.setState({
            isAgentExists: true,
          })
        }
        else if ((this.state.selectedAgent.find(e => e._id == result.data.agent)) == undefined) {
          this.setState({
            isAgentExists: false,
            unknownAgentGuid: result.data.agent
          })
          result._emn_dispositionsconsultantid_value = null;
          result.data.agent = ""
        } else {
          let currentAg = this.state.selectedAgent.find(e => e._id == result.data.agent)
          this.state.currentAgent = currentAg
          this.setState({
            isAgentExists: true,
            unknownAgentGuid: currentAg.guid
          })
          if (!this.state.currentAgent.status) {
            this.setState({
              isAgentActive: false,
            })
          } else {
            this.setState({
              isAgentActive: true,
            })
          }
        }
        this.setState({
          propertyGuid: result.guid
        })
        console.log('result--->', result);
        // var sorted = result.data.images.sort((a, b) => {
        //   return Number(a.order)-Number(b.order)
        // });
        let currentMarket = this.state.marketImage.find(e => {
          return e.name == result.data.market
        })

        if (!result.data.status) {
          this.setState({
            status: false,
            isActiveBanner: true,
          })
        }
        this.state.currentMarket = currentMarket
        if (!this.state.currentMarket) {
          this.setState({
            isMarketExistBanner: true
          })
        } else {
          if (!this.state.currentMarket.status) {
            this.setState({
              isMarketActiveBanner: true
            })
          }
        }
        /*set data to form*/
        this.state.orignalData = result.data
        if (result.data.status === false) {
          result.data.status = 'false';
          this.setState({
            status: 'false'
          })
        }
        if (match.params.draft == "draft") {
          initialize(JSON.parse(result.data.draft));
          result.data = JSON.parse(result.data.draft)
        } else {
          initialize(result.data);
        }
        //initialize({...result.data,"key_features.year_built":result.data.key_features.year_built});
        this.state.selectedHeatingType = result.data.key_features.parking_type;
        this.state.heatingType = result.data.key_features.heating
        this.state.coolingType = result.data.key_features.cooling
        this.state.remarks.publicRemarks = result.data.publicRemarks
        this.state.remarks.wholesaleRemarks = result.data.wholesaleRemarks
        //set price in variable
        this.checkPrice = result.data.price;
        console.log('store price ', this.checkPrice);

        
        readOnlyChecker = true;
        zipCodeChecker = true;
        cityCodeChecker = true;
        this.setState({
          propertyGuid: result.data.guid,
          isLoading: false,
          category: result.data.category,
          selectedExterior: result.data.exterior_features.exterior,
          feature_image: result.data.feature_image,
          //images: result.data.images? result.data.images:[],
          hoa: result.data.associations
            ? result.data.associations.hoaFeePeriod
            : 'None',
          coa: result.data.associations
            ? result.data.associations.coaFeePeriod
            : 'None',
          selectedappliances: result.data.interior_features.appliances,
          selectedotherfeatures: result.data.interior_features.otherfeatures,
          selectedYearBuilt: result.data.key_features.year_built,
          status: result.data.status,
          selectedCoolingType: result.data.key_features.cooling,
          selectedHeatingType: result.data.key_features.heating,
          orignalStatus: result.data.status,
          selectedParkingType: result.data.key_features.parking_type,
          selectedViewType: result.data.exterior_features.view,
          listingType: result.data.listing_type,
          isThereAnHoa: !!result.data.associations && !!result.data.associations.isThereAnHoa ? result.data.associations.isThereAnHoa : "No",
          isThereACoa: !!result.data.associations && !!result.data.associations.isThereACoa ? result.data.associations.isThereACoa : "No",
          status: result.data.status,
          lot: result.data.key_features.lot,
          galleryImages: result.data.images,
          disableCategory: !!result.data.isDistressed,
          isDistressed: !!result.data.isDistressed,
          showDistressedModal: (result.data.category != "wholesale" && result.data.isDistressed === true)
        });

        if (result.data.category != "wholesale" && result.data.isDistressed !== true) {
          this.state.allowSelectedFieldReadOnly = false;
        } else {
          this.state.allowSelectedFieldReadOnly = true;
        }

        // check if listing data is in future
        const today = new Date();
        if (result.data.listing_date && new Date(result.data.listing_date) > today) {
          this.setState({
            isListingDateInFuture: true
          })
        }

      });
    }
  }
  getPropOffers() {
    HTTP.Request('get', window.admin.getPropertyOffers, {
      property_id: this.props.match.params._id,
      page: 1,
    }).then((result) => {
      this.setState({ offers: result.data ? result.data : [] });
    });
  }

  setSettlementDate(data) {
    this.setState({ isLoading: true });
    let param = {
      offer_id: this.state.offer_id,
      ...data
    };
    HTTP.Request('post', window.admin.offerSettlement, param).then((result) => {
      console.log(result);
      this.setState({ offerSetllementDialogFlag: false, isLoading: false });
      toast.success(result.message);
      this.acceptOffer();
    }).catch((error) => {
      this.setState({ isLoading: false });
      toast.error(error.message || 'Some Error');
    });
  };

  acceptOffer = () => {
    HTTP.Request('get', window.admin.acceptOffers, {
      offer_id: this.state.offer_id,
      page: 1,
    }).then((result) => {
      toast.success(result.message);
      this.getPropOffers();
    }).catch((error) => {
      this.setState({ isLoading: false });
      toast.error(error.message || 'Some Error');
    });
  };

  declineOffer = (data) => {
    HTTP.Request('get', window.admin.declineOffers, {
      offer_id: data,
      page: 1,
    }).then((result) => {
      toast(result.message, { type: 'error' });
      this.getPropOffers();
    });
  };

  deleteOffer = () => {
    HTTP.Request('delete', window.admin.deleteOffers + '?offer_id=' + this.state.offer_id)
      .then((result) => {
        this.getPropOffers();
        toast(result.message, { type: 'error' });
        this.setState({ showOfferDelete: false });
      }).catch((error) => {
        this.setState({ showOfferDelete: false });
      });
  };

  setSelectedTab = (key) => {
    console.log(key);
    this.setState({
      defaultTabSelected: key
    });

    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let propertyId = match.params._id ? match.params._id : null;

    if (propertyId) {
      if (key == 2) {
        this.props.history.push("/update-property/" + propertyId + "/open-houses");
      } else if (key == 3) {
        this.props.history.push("/update-property/" + propertyId + "/inquiries");
      } else if (key == 4) {
        this.props.history.push("/update-property/" + propertyId + "/offers");
      } else if (key == 5) {
        this.props.history.push("/update-property/" + propertyId + "/comparables");
      } else {
        this.props.history.push("/update-property/" + propertyId + "/edit");
      }
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { unit_no, street_address_v2, change, slug_v1 } = nextProps;
    let old_slug = nextProps.slug;
  }
}

let propForm = reduxForm({
  form: 'propForm',
  validate: (values) => {
    const errors = {};
    /**check if user selected value of either Status*/
    if (values.status_prop === 3) {
      /**throw error if user not selected start Date */
      if (!values.sold_date) errors.sold_date = 'Required';
    }

    return errors;
  },
  asyncValidate: (values, dispatch, props, current_fieldname) => {
    return new Promise((resolve) => {
      isValidAddress(values, dispatch, props, current_fieldname)
        .then((result) => {
          // resolve(asyncErrorObj);
        })
        .catch((error) => {
          console.log(error);
          resolve(error);
        });
    });
  },
  asyncBlurFields: ['street_address'],
  shouldAsyncValidate: ({ trigger, blurredField, initialized, pristine }) => {
    if (
      blurredField !== 'street_address' ||
      blurredField !== 'key_features.year_built'
    ) {
      return false;
    }
    switch (trigger) {
      case 'blur':
        return true;
      case 'submit':
        return !pristine || !initialized;
      default:
        return false;
    }
  },
  onSubmitFail: scrollToFirstError,
})(AddProp);
const selector = formValueSelector('propForm');
const mapStateToProps = (state) => {
  window["propFormValue"] = state.form.propForm;
  var solvedPermissions = {};

  if (
    state.admin.permissions &&
    state.admin.permissions.indexOf('EDIT_CUSTOM_URL') !== -1
  ) {
    solvedPermissions.allowCustomURLEdit = true;
  }
  if (
    state.admin.permissions &&
    state.admin.permissions.indexOf('MARK_PROPERTY_INACTIVE') !== -1
  ) {
    solvedPermissions.markPropertyInative = true;
  } else {
    solvedPermissions.markPropertyInative = false;
  }
  return {
    unit_no: selector(state, 'unit_no'),
    slug_v1: selector(state, 'slug'),
    street_address_v2: selector(state, 'street_address'),
    images_v1: selector(state, 'images'),
    solvedPermissions
  };
};

export default connect(mapStateToProps)(propForm);
