import React, { Component } from 'react';
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import TT from "../../common/tooltip";
import moment from "moment-timezone";

class ROW extends Component {

    render() {
        const {element} = this.props;

        let replied = <label className='label label-success'>Replied</label>;
        let notReplied = <label className='label label-danger'>Not Replied</label>
        return (
            <tr className="odd gradeX" >
                <td> {element.firstname} </td>
                <td> {element.lastname} </td>
                <td> {element.email} </td>
                <td> {element.phone} </td>
                <td><Moment format="MM/DD/YY">{moment(element.created_at).zone("-04:00")}</Moment></td>

                {/* <td><Moment format="MM/DD/YY">{element.created_at}</Moment></td>            
             */}
                {/* <td> {element.replies && element.replies.length > 0 ? replied : notReplied} </td> */}
                {/* <td>                    
                    <TT tooltip="Reply"><Link to={'/feedback/reply/' + element._id} className=" btn btn-xs blue-madison"><i className="fa fa-reply"></i></Link></TT>
                </td> */}
            </tr>
        );
    }

}



export default ROW;

