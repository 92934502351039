import * as _ from 'lodash';
/**
 * List state of all the modules which wiill be accessed on the basis of the
 * ROLE
 */
/**OPTIONS FOR MULTISELECT */

/**USERS/ADMIN ROLE OPTIONS */
export const OPTIONS = _.orderBy(
  [
    { title: 'Change Password', _id: 'CHANGE_PASSWORD' },
    { title: 'Manage Roles', _id: 'MANAGE_ROLE' },
    { title: 'Edit Custom URL', _id: 'EDIT_CUSTOM_URL' },
    { title: 'Manage Users', _id: 'MANAGE_USER' },
    { title: 'Profile', _id: 'PROFILE' },
    { title: 'Pages', _id: 'PAGES' },
    { title: 'Property Management', _id: 'PROPERTY_MANAGEMENT' },
    { title: 'Download Properties', _id: 'DOWNLOAD_PROPERTIES' },
    { title: 'Download Leads', _id: 'DOWNLOAD_LEADS' },
    { title: 'Market Management', _id: 'MARKET_MANAGEMENT' },
    { title: 'Short Form Lead', _id: 'SHORT_FORM_LEAD' },
    { title: 'FAQ', _id: 'FAQ' },
    { title: 'Reviews', _id: 'REVIEWS' },
    { title: 'FAQ Category', _id: 'FAQ_CATEGORY' },
    { title: 'Photo Gallery', _id: 'PHOTO_GALLERY' },
    { title: 'Press Release', _id: 'PRESS_RELEASE' },
    { title: 'Career', _id: 'CAREER' },
    { title: 'Blogs', _id: 'MANAGE_BLOGS' },
    { title: 'Customer Stories', _id: 'CUSTOMER_STORIES' },
    { title: 'Get Offer Leads', _id: 'GET_AN_OFFER' },
    { title: 'Out of Market Leads', _id: 'OUT_OF_MARKET_LEADS' },
    { title: 'CMS', _id: 'CMS' },
    { title: 'Sitemap Management', _id: 'MANAGE_SITEMAP' },
    { title: 'Renovated Flyer', _id: 'RENOVATED_FLYER' },
    { title: 'Wholesale Flyer', _id: 'WHOLESALE_FLYER' },

    { title: 'Email Management', _id: 'MANAGE_EMAIL' },
    { title: 'Settings', _id: 'MANAGE_SETTINGS' },
    { title: 'Logs Management', _id: 'MANAGE_LOGS' },
    { title: 'Buyers', _id: 'BUYERS' },
    { title: 'Realtors', _id: 'Realtors' },
    { title: 'Real Estate Investors', _id: 'REALSTATEINVESTORS' },
    { title: 'Buy Landing Page', _id: 'BUYLANDINGPAGE' },
    { title: 'Buy Property Page', _id: 'BUYPROPERTYPAGE' },
    { title: 'Investors', _id: 'Investors' },
    { title: 'Investor Management', _id: 'INVESTORCMSMANAGE' },
    { title: 'Realtors Management', _id: 'REALTORSCMSMANAGE' },
    { title: 'Realtors Signup Management', _id: 'REALTORSIGNUPMANAGE' },
    { title: 'REC_CONTACTS_LIST', _id: 'REC_CONTACTS_LIST' },
    { title: 'Delete Properties', _id: 'DELETE_PROPERTIES' },
    { title: 'Mark Property Inactive', _id: 'MARK_PROPERTY_INACTIVE'}
  ],
  ['title'],
);

export const RoutesValue = {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  MANAGE_ROLE: 'MANAGE_ROLE',
  MANAGE_USER: 'MANAGE_USER',
  PROFILE: 'PROFILE',
  MANAGE_PAGES: 'PAGES',
  MANAGE_BLOGS: 'MANAGE_BLOGS',
  CUSTOMER_ROLE: 'CUSTOMER_ROLE',
  EMAIL_MANAGE: 'EMAIL_MANAGE',

  RENOVATED_FLYER: 'RENOVATED_FLYER',
  WHOLESALE_FLYER: 'WHOLESALE_FLYER',
  TAXES: 'TAXES',
  GET_AN_OFFER: 'GET_AN_OFFER',
  CONTACT_US: 'CONTACT_US',
  MARKET_MANAGEMENT: 'MARKET_MANAGEMENT',
  PROPERTY_MANAGEMENT: 'PROPERTY_MANAGEMENT',
  SHORT_FORM_LEAD: 'SHORT_FORM_LEAD',
  FAQ: 'FAQ',
  REVIEWS: 'REVIEWS',
  FAQ_CATEGORY: 'FAQ_CATEGORY',
  PHOTO_GALLERY: 'PHOTO_GALLERY',
  PRESS_RELEASE: 'PRESS_RELEASE',
  CAREER: 'CAREER',
  CUSTOMER_STORIES: 'CUSTOMER_STORIES',
  OUT_OF_MARKET_LEADS: 'OUT_OF_MARKET_LEADS',
  CMS: 'CMS',
  MANAGE_SITEMAP: 'MANAGE_SITEMAP',
  MANAGE_TOPARTICLES: 'MANAGE_TOPARTICLES',
  MANAGE_EMAIL: 'MANAGE_EMAIL',
  MANAGE_SETTINGS: 'MANAGE_SETTINGS',
  MANAGE_LOGS: 'MANAGE_LOGS',
  BUYERS: 'BUYERS',
  BUYERS_LEAD: 'BUYERS_LEAD',
  Realtors: 'Realtors',
  REALSTATEINVESTORS: 'REALSTATEINVESTORS',
  BUYLANDINGPAGE: 'BUYLANDINGPAGE',
  BUYPROPERTYPAGE: 'BUYPROPERTYPAGE',
  Investors: 'Investors',
  INVESTORCMSMANAGE: 'INVESTORCMSMANAGE',
  REALTORSCMSMANAGE: 'REALTORSCMSMANAGE',
  REALTORSIGNUPMANAGE: 'REALTORSIGNUPMANAGE',
  REC_CONTACTS_LIST: 'REC_CONTACTS_LIST',
  DELETE_PROPERTIES: 'DELETE_PROPERTIES',
};

/**STATUS related OPTIONS */
export const STATUS = [
  { title: 'Active', value: true },
  { title: 'Inactive', value: 'false' },
];

/* year buil options */
export const YEARBUILT = [
  {
    _id: '1700',
    title: '1700',
  },
  {
    _id: '1701',
    title: '1701',
  },
  {
    _id: '1702',
    title: '1702',
  },
  {
    _id: '1703',
    title: '1703',
  },
  {
    _id: '1704',
    title: '1704',
  },
  {
    _id: '1705',
    title: '1705',
  },
  {
    _id: '1706',
    title: '1706',
  },
  {
    _id: '1707',
    title: '1707',
  },
  {
    _id: '1708',
    title: '1708',
  },
  {
    _id: '1709',
    title: '1709',
  },
  {
    _id: '1710',
    title: '1710',
  },
  {
    _id: '1711',
    title: '1711',
  },
  {
    _id: '1712',
    title: '1712',
  },
  {
    _id: '1713',
    title: '1713',
  },
  {
    _id: '1714',
    title: '1714',
  },
  {
    _id: '1715',
    title: '1715',
  },
  {
    _id: '1716',
    title: '1716',
  },
  {
    _id: '1717',
    title: '1717',
  },
  {
    _id: '1718',
    title: '1718',
  },
  {
    _id: '1719',
    title: '1719',
  },
  {
    _id: '1720',
    title: '1720',
  },
  {
    _id: '1721',
    title: '1721',
  },
  {
    _id: '1722',
    title: '1722',
  },
  {
    _id: '1723',
    title: '1723',
  },
  {
    _id: '1724',
    title: '1724',
  },
  {
    _id: '1725',
    title: '1725',
  },
  {
    _id: '1726',
    title: '1726',
  },
  {
    _id: '1727',
    title: '1727',
  },
  {
    _id: '1728',
    title: '1728',
  },
  {
    _id: '1729',
    title: '1729',
  },
  {
    _id: '1730',
    title: '1730',
  },
  {
    _id: '1731',
    title: '1731',
  },
  {
    _id: '1732',
    title: '1732',
  },
  {
    _id: '1733',
    title: '1733',
  },
  {
    _id: '1734',
    title: '1734',
  },
  {
    _id: '1735',
    title: '1735',
  },
  {
    _id: '1736',
    title: '1736',
  },
  {
    _id: '1737',
    title: '1737',
  },
  {
    _id: '1738',
    title: '1738',
  },
  {
    _id: '1739',
    title: '1739',
  },
  {
    _id: '1740',
    title: '1740',
  },
  {
    _id: '1741',
    title: '1741',
  },
  {
    _id: '1742',
    title: '1742',
  },
  {
    _id: '1743',
    title: '1743',
  },
  {
    _id: '1744',
    title: '1744',
  },
  {
    _id: '1745',
    title: '1745',
  },
  {
    _id: '1746',
    title: '1746',
  },
  {
    _id: '1747',
    title: '1747',
  },
  {
    _id: '1748',
    title: '1748',
  },
  {
    _id: '1749',
    title: '1749',
  },
  {
    _id: '1750',
    title: '1750',
  },
  {
    _id: '1751',
    title: '1751',
  },
  {
    _id: '1752',
    title: '1752',
  },
  {
    _id: '1753',
    title: '1753',
  },
  {
    _id: '1754',
    title: '1754',
  },
  {
    _id: '1755',
    title: '1755',
  },
  {
    _id: '1756',
    title: '1756',
  },
  {
    _id: '1757',
    title: '1757',
  },
  {
    _id: '1758',
    title: '1758',
  },
  {
    _id: '1759',
    title: '1759',
  },
  {
    _id: '1760',
    title: '1760',
  },
  {
    _id: '1761',
    title: '1761',
  },
  {
    _id: '1762',
    title: '1762',
  },
  {
    _id: '1763',
    title: '1763',
  },
  {
    _id: '1764',
    title: '1764',
  },
  {
    _id: '1765',
    title: '1765',
  },
  {
    _id: '1766',
    title: '1766',
  },
  {
    _id: '1767',
    title: '1767',
  },
  {
    _id: '1768',
    title: '1768',
  },
  {
    _id: '1769',
    title: '1769',
  },
  {
    _id: '1770',
    title: '1770',
  },
  {
    _id: '1771',
    title: '1771',
  },
  {
    _id: '1772',
    title: '1772',
  },
  {
    _id: '1773',
    title: '1773',
  },
  {
    _id: '1774',
    title: '1774',
  },
  {
    _id: '1775',
    title: '1775',
  },
  {
    _id: '1776',
    title: '1776',
  },
  {
    _id: '1777',
    title: '1777',
  },
  {
    _id: '1778',
    title: '1778',
  },
  {
    _id: '1779',
    title: '1779',
  },
  {
    _id: '1780',
    title: '1780',
  },
  {
    _id: '1781',
    title: '1781',
  },
  {
    _id: '1782',
    title: '1782',
  },
  {
    _id: '1783',
    title: '1783',
  },
  {
    _id: '1784',
    title: '1784',
  },
  {
    _id: '1785',
    title: '1785',
  },
  {
    _id: '1786',
    title: '1786',
  },
  {
    _id: '1787',
    title: '1787',
  },
  {
    _id: '1788',
    title: '1788',
  },
  {
    _id: '1789',
    title: '1789',
  },
  {
    _id: '1790',
    title: '1790',
  },
  {
    _id: '1791',
    title: '1791',
  },
  {
    _id: '1792',
    title: '1792',
  },
  {
    _id: '1793',
    title: '1793',
  },
  {
    _id: '1794',
    title: '1794',
  },
  {
    _id: '1795',
    title: '1795',
  },
  {
    _id: '1796',
    title: '1796',
  },
  {
    _id: '1797',
    title: '1797',
  },
  {
    _id: '1798',
    title: '1798',
  },
  {
    _id: '1799',
    title: '1799',
  },
  {
    _id: '1800',
    title: '1800',
  },
  {
    _id: '1801',
    title: '1801',
  },
  {
    _id: '1802',
    title: '1802',
  },
  {
    _id: '1803',
    title: '1803',
  },
  {
    _id: '1804',
    title: '1804',
  },
  {
    _id: '1805',
    title: '1805',
  },
  {
    _id: '1806',
    title: '1806',
  },
  {
    _id: '1807',
    title: '1807',
  },
  {
    _id: '1808',
    title: '1808',
  },
  {
    _id: '1809',
    title: '1809',
  },
  {
    _id: '1810',
    title: '1810',
  },
  {
    _id: '1811',
    title: '1811',
  },
  {
    _id: '1812',
    title: '1812',
  },
  {
    _id: '1813',
    title: '1813',
  },
  {
    _id: '1814',
    title: '1814',
  },
  {
    _id: '1815',
    title: '1815',
  },
  {
    _id: '1816',
    title: '1816',
  },
  {
    _id: '1817',
    title: '1817',
  },
  {
    _id: '1818',
    title: '1818',
  },
  {
    _id: '1819',
    title: '1819',
  },
  {
    _id: '1820',
    title: '1820',
  },
  {
    _id: '1821',
    title: '1821',
  },
  {
    _id: '1822',
    title: '1822',
  },
  {
    _id: '1823',
    title: '1823',
  },
  {
    _id: '1824',
    title: '1824',
  },
  {
    _id: '1825',
    title: '1825',
  },
  {
    _id: '1826',
    title: '1826',
  },
  {
    _id: '1827',
    title: '1827',
  },
  {
    _id: '1828',
    title: '1828',
  },
  {
    _id: '1829',
    title: '1829',
  },
  {
    _id: '1830',
    title: '1830',
  },
  {
    _id: '1831',
    title: '1831',
  },
  {
    _id: '1832',
    title: '1832',
  },
  {
    _id: '1833',
    title: '1833',
  },
  {
    _id: '1834',
    title: '1834',
  },
  {
    _id: '1835',
    title: '1835',
  },
  {
    _id: '1836',
    title: '1836',
  },
  {
    _id: '1837',
    title: '1837',
  },
  {
    _id: '1838',
    title: '1838',
  },
  {
    _id: '1839',
    title: '1839',
  },
  {
    _id: '1840',
    title: '1840',
  },
  {
    _id: '1841',
    title: '1841',
  },
  {
    _id: '1842',
    title: '1842',
  },
  {
    _id: '1843',
    title: '1843',
  },
  {
    _id: '1844',
    title: '1844',
  },
  {
    _id: '1845',
    title: '1845',
  },
  {
    _id: '1846',
    title: '1846',
  },
  {
    _id: '1847',
    title: '1847',
  },
  {
    _id: '1848',
    title: '1848',
  },
  {
    _id: '1849',
    title: '1849',
  },
  {
    _id: '1850',
    title: '1850',
  },
  {
    _id: '1851',
    title: '1851',
  },
  {
    _id: '1852',
    title: '1852',
  },
  {
    _id: '1853',
    title: '1853',
  },
  {
    _id: '1854',
    title: '1854',
  },
  {
    _id: '1855',
    title: '1855',
  },
  {
    _id: '1856',
    title: '1856',
  },
  {
    _id: '1857',
    title: '1857',
  },
  {
    _id: '1858',
    title: '1858',
  },
  {
    _id: '1859',
    title: '1859',
  },
  {
    _id: '1860',
    title: '1860',
  },
  {
    _id: '1861',
    title: '1861',
  },
  {
    _id: '1862',
    title: '1862',
  },
  {
    _id: '1863',
    title: '1863',
  },
  {
    _id: '1864',
    title: '1864',
  },
  {
    _id: '1865',
    title: '1865',
  },
  {
    _id: '1866',
    title: '1866',
  },
  {
    _id: '1867',
    title: '1867',
  },
  {
    _id: '1868',
    title: '1868',
  },
  {
    _id: '1869',
    title: '1869',
  },
  {
    _id: '1870',
    title: '1870',
  },
  {
    _id: '1871',
    title: '1871',
  },
  {
    _id: '1872',
    title: '1872',
  },
  {
    _id: '1873',
    title: '1873',
  },
  {
    _id: '1874',
    title: '1874',
  },
  {
    _id: '1875',
    title: '1875',
  },
  {
    _id: '1876',
    title: '1876',
  },
  {
    _id: '1877',
    title: '1877',
  },
  {
    _id: '1878',
    title: '1878',
  },
  {
    _id: '1879',
    title: '1879',
  },
  {
    _id: '1880',
    title: '1880',
  },
  {
    _id: '1881',
    title: '1881',
  },
  {
    _id: '1882',
    title: '1882',
  },
  {
    _id: '1883',
    title: '1883',
  },
  {
    _id: '1884',
    title: '1884',
  },
  {
    _id: '1885',
    title: '1885',
  },
  {
    _id: '1886',
    title: '1886',
  },
  {
    _id: '1887',
    title: '1887',
  },
  {
    _id: '1888',
    title: '1888',
  },
  {
    _id: '1889',
    title: '1889',
  },
  {
    _id: '1890',
    title: '1890',
  },
  {
    _id: '1891',
    title: '1891',
  },
  {
    _id: '1892',
    title: '1892',
  },
  {
    _id: '1893',
    title: '1893',
  },
  {
    _id: '1894',
    title: '1894',
  },
  {
    _id: '1895',
    title: '1895',
  },
  {
    _id: '1896',
    title: '1896',
  },
  {
    _id: '1897',
    title: '1897',
  },
  {
    _id: '1898',
    title: '1898',
  },
  {
    _id: '1899',
    title: '1899',
  },
  {
    _id: '1900',
    title: '1900',
  },
  {
    _id: '1901',
    title: '1901',
  },
  {
    _id: '1902',
    title: '1902',
  },
  {
    _id: '1903',
    title: '1903',
  },
  {
    _id: '1904',
    title: '1904',
  },
  {
    _id: '1905',
    title: '1905',
  },
  {
    _id: '1906',
    title: '1906',
  },
  {
    _id: '1907',
    title: '1907',
  },
  {
    _id: '1908',
    title: '1908',
  },
  {
    _id: '1909',
    title: '1909',
  },
  {
    _id: '1910',
    title: '1910',
  },
  {
    _id: '1911',
    title: '1911',
  },
  {
    _id: '1912',
    title: '1912',
  },
  {
    _id: '1913',
    title: '1913',
  },
  {
    _id: '1914',
    title: '1914',
  },
  {
    _id: '1915',
    title: '1915',
  },
  {
    _id: '1916',
    title: '1916',
  },
  {
    _id: '1917',
    title: '1917',
  },
  {
    _id: '1918',
    title: '1918',
  },
  {
    _id: '1919',
    title: '1919',
  },
  {
    _id: '1920',
    title: '1920',
  },
  {
    _id: '1921',
    title: '1921',
  },
  {
    _id: '1922',
    title: '1922',
  },
  {
    _id: '1923',
    title: '1923',
  },
  {
    _id: '1924',
    title: '1924',
  },
  {
    _id: '1925',
    title: '1925',
  },
  {
    _id: '1926',
    title: '1926',
  },
  {
    _id: '1927',
    title: '1927',
  },
  {
    _id: '1928',
    title: '1928',
  },
  {
    _id: '1929',
    title: '1929',
  },
  {
    _id: '1930',
    title: '1930',
  },
  {
    _id: '1931',
    title: '1931',
  },
  {
    _id: '1932',
    title: '1932',
  },
  {
    _id: '1933',
    title: '1933',
  },
  {
    _id: '1934',
    title: '1934',
  },
  {
    _id: '1935',
    title: '1935',
  },
  {
    _id: '1936',
    title: '1936',
  },
  {
    _id: '1937',
    title: '1937',
  },
  {
    _id: '1938',
    title: '1938',
  },
  {
    _id: '1939',
    title: '1939',
  },
  {
    _id: '1940',
    title: '1940',
  },
  {
    _id: '1941',
    title: '1941',
  },
  {
    _id: '1942',
    title: '1942',
  },
  {
    _id: '1943',
    title: '1943',
  },
  {
    _id: '1944',
    title: '1944',
  },
  {
    _id: '1945',
    title: '1945',
  },
  {
    _id: '1946',
    title: '1946',
  },
  {
    _id: '1947',
    title: '1947',
  },
  {
    _id: '1948',
    title: '1948',
  },
  {
    _id: '1949',
    title: '1949',
  },
  {
    _id: '1950',
    title: '1950',
  },
  {
    _id: '1951',
    title: '1951',
  },
  {
    _id: '1952',
    title: '1952',
  },
  {
    _id: '1953',
    title: '1953',
  },
  {
    _id: '1954',
    title: '1954',
  },
  {
    _id: '1955',
    title: '1955',
  },
  {
    _id: '1956',
    title: '1956',
  },
  {
    _id: '1957',
    title: '1957',
  },
  {
    _id: '1958',
    title: '1958',
  },
  {
    _id: '1959',
    title: '1959',
  },
  {
    _id: '1960',
    title: '1960',
  },
  {
    _id: '1961',
    title: '1961',
  },
  {
    _id: '1962',
    title: '1962',
  },
  {
    _id: '1963',
    title: '1963',
  },
  {
    _id: '1964',
    title: '1964',
  },
  {
    _id: '1965',
    title: '1965',
  },
  {
    _id: '1966',
    title: '1966',
  },
  {
    _id: '1967',
    title: '1967',
  },
  {
    _id: '1968',
    title: '1968',
  },
  {
    _id: '1969',
    title: '1969',
  },
  {
    _id: '1970',
    title: '1970',
  },
  {
    _id: '1971',
    title: '1971',
  },
  {
    _id: '1972',
    title: '1972',
  },
  {
    _id: '1973',
    title: '1973',
  },
  {
    _id: '1974',
    title: '1974',
  },
  {
    _id: '1975',
    title: '1975',
  },
  {
    _id: '1976',
    title: '1976',
  },
  {
    _id: '1977',
    title: '1977',
  },
  {
    _id: '1978',
    title: '1978',
  },
  {
    _id: '1979',
    title: '1979',
  },
  {
    _id: '1980',
    title: '1980',
  },
  {
    _id: '1981',
    title: '1981',
  },
  {
    _id: '1982',
    title: '1982',
  },
  {
    _id: '1983',
    title: '1983',
  },
  {
    _id: '1984',
    title: '1984',
  },
  {
    _id: '1985',
    title: '1985',
  },
  {
    _id: '1986',
    title: '1986',
  },
  {
    _id: '1987',
    title: '1987',
  },
  {
    _id: '1988',
    title: '1988',
  },
  {
    _id: '1989',
    title: '1989',
  },
  {
    _id: '1990',
    title: '1990',
  },
  {
    _id: '1991',
    title: '1991',
  },
  {
    _id: '1992',
    title: '1992',
  },
  {
    _id: '1993',
    title: '1993',
  },
  {
    _id: '1994',
    title: '1994',
  },
  {
    _id: '1995',
    title: '1995',
  },
  {
    _id: '1996',
    title: '1996',
  },
  {
    _id: '1997',
    title: '1997',
  },
  {
    _id: '1998',
    title: '1998',
  },
  {
    _id: '1999',
    title: '1999',
  },
  {
    _id: '2000',
    title: '2000',
  },
  {
    _id: '2001',
    title: '2001',
  },
  {
    _id: '2002',
    title: '2002',
  },
  {
    _id: '2003',
    title: '2003',
  },
  {
    _id: '2004',
    title: '2004',
  },
  {
    _id: '2005',
    title: '2005',
  },
  {
    _id: '2006',
    title: '2006',
  },
  {
    _id: '2007',
    title: '2007',
  },
  {
    _id: '2008',
    title: '2008',
  },
  {
    _id: '2009',
    title: '2009',
  },
  {
    _id: '2010',
    title: '2010',
  },
  {
    _id: '2011',
    title: '2011',
  },
  {
    _id: '2012',
    title: '2012',
  },
  {
    _id: '2013',
    title: '2013',
  },
  {
    _id: '2014',
    title: '2014',
  },
  {
    _id: '2015',
    title: '2015',
  },
  {
    _id: '2016',
    title: '2016',
  },
  {
    _id: '2017',
    title: '2017',
  },
  {
    _id: '2018',
    title: '2018',
  },
  {
    _id: '2019',
    title: '2019',
  },
  {
    _id: '2020',
    title: '2020',
  },
  {
    _id: '2021',
    title: '2021',
  },
  {
    _id: '2022',
    title: '2022',
  },
  {
    _id: '2023',
    title: '2023',
  },
  {
    _id: '2024',
    title: '2024',
  },
  {
    _id: '2025',
    title: '2025',
  },
  {
    _id: '2026',
    title: '2026',
  },
  {
    _id: '2027',
    title: '2027',
  },
  {
    _id: '2028',
    title: '2028',
  },
  {
    _id: '2029',
    title: '2029',
  },
  {
    _id: '2030',
    title: '2030',
  },
  {
    _id: '2031',
    title: '2031',
  },
  {
    _id: '2032',
    title: '2032',
  },
  {
    _id: '2033',
    title: '2033',
  },
  {
    _id: '2034',
    title: '2034',
  },
  {
    _id: '2035',
    title: '2035',
  },
  {
    _id: '2036',
    title: '2036',
  },
  {
    _id: '2037',
    title: '2037',
  },
  {
    _id: '2038',
    title: '2038',
  },
  {
    _id: '2039',
    title: '2039',
  },
  {
    _id: '2040',
    title: '2040',
  },
  {
    _id: '2041',
    title: '2041',
  },
  {
    _id: '2042',
    title: '2042',
  },
  {
    _id: '2043',
    title: '2043',
  },
  {
    _id: '2044',
    title: '2044',
  },
  {
    _id: '2045',
    title: '2045',
  },
  {
    _id: '2046',
    title: '2046',
  },
  {
    _id: '2047',
    title: '2047',
  },
  {
    _id: '2048',
    title: '2048',
  },
  {
    _id: '2049',
    title: '2049',
  },
  {
    _id: '2050',
    title: '2050',
  },
  {
    _id: '2051',
    title: '2051',
  },
  {
    _id: '2052',
    title: '2052',
  },
  {
    _id: '2053',
    title: '2053',
  },
  {
    _id: '2054',
    title: '2054',
  },
  {
    _id: '2055',
    title: '2055',
  },
  {
    _id: '2056',
    title: '2056',
  },
  {
    _id: '2057',
    title: '2057',
  },
  {
    _id: '2058',
    title: '2058',
  },
  {
    _id: '2059',
    title: '2059',
  },
  {
    _id: '2060',
    title: '2060',
  },
  {
    _id: '2061',
    title: '2061',
  },
  {
    _id: '2062',
    title: '2062',
  },
  {
    _id: '2063',
    title: '2063',
  },
  {
    _id: '2064',
    title: '2064',
  },
  {
    _id: '2065',
    title: '2065',
  },
  {
    _id: '2066',
    title: '2066',
  },
  {
    _id: '2067',
    title: '2067',
  },
  {
    _id: '2068',
    title: '2068',
  },
  {
    _id: '2069',
    title: '2069',
  },
  {
    _id: '2070',
    title: '2070',
  },
  {
    _id: '2071',
    title: '2071',
  },
  {
    _id: '2072',
    title: '2072',
  },
  {
    _id: '2073',
    title: '2073',
  },
  {
    _id: '2074',
    title: '2074',
  },
  {
    _id: '2075',
    title: '2075',
  },
  {
    _id: '2076',
    title: '2076',
  },
  {
    _id: '2077',
    title: '2077',
  },
  {
    _id: '2078',
    title: '2078',
  },
  {
    _id: '2079',
    title: '2079',
  },
  {
    _id: '2080',
    title: '2080',
  },
  {
    _id: '2081',
    title: '2081',
  },
  {
    _id: '2082',
    title: '2082',
  },
  {
    _id: '2083',
    title: '2083',
  },
  {
    _id: '2084',
    title: '2084',
  },
  {
    _id: '2085',
    title: '2085',
  },
  {
    _id: '2086',
    title: '2086',
  },
  {
    _id: '2087',
    title: '2087',
  },
  {
    _id: '2088',
    title: '2088',
  },
  {
    _id: '2089',
    title: '2089',
  },
  {
    _id: '2090',
    title: '2090',
  },
  {
    _id: '2091',
    title: '2091',
  },
  {
    _id: '2092',
    title: '2092',
  },
  {
    _id: '2093',
    title: '2093',
  },
  {
    _id: '2094',
    title: '2094',
  },
  {
    _id: '2095',
    title: '2095',
  },
  {
    _id: '2096',
    title: '2096',
  },
  {
    _id: '2097',
    title: '2097',
  },
  {
    _id: '2098',
    title: '2098',
  },
  {
    _id: '2099',
    title: '2099',
  },
  {
    _id: '2100',
    title: '2100',
  },
  {
    _id: '2101',
    title: '2101',
  },
  {
    _id: '2102',
    title: '2102',
  },
  {
    _id: '2103',
    title: '2103',
  },
  {
    _id: '2104',
    title: '2104',
  },
  {
    _id: '2105',
    title: '2105',
  },
  {
    _id: '2106',
    title: '2106',
  },
  {
    _id: '2107',
    title: '2107',
  },
  {
    _id: '2108',
    title: '2108',
  },
  {
    _id: '2109',
    title: '2109',
  },
  {
    _id: '2110',
    title: '2110',
  },
  {
    _id: '2111',
    title: '2111',
  },
  {
    _id: '2112',
    title: '2112',
  },
  {
    _id: '2113',
    title: '2113',
  },
  {
    _id: '2114',
    title: '2114',
  },
  {
    _id: '2115',
    title: '2115',
  },
  {
    _id: '2116',
    title: '2116',
  },
  {
    _id: '2117',
    title: '2117',
  },
  {
    _id: '2118',
    title: '2118',
  },
  {
    _id: '2119',
    title: '2119',
  },
  {
    _id: '2120',
    title: '2120',
  },
  {
    _id: '2121',
    title: '2121',
  },
  {
    _id: '2122',
    title: '2122',
  },
  {
    _id: '2123',
    title: '2123',
  },
  {
    _id: '2124',
    title: '2124',
  },
  {
    _id: '2125',
    title: '2125',
  },
  {
    _id: '2126',
    title: '2126',
  },
  {
    _id: '2127',
    title: '2127',
  },
  {
    _id: '2128',
    title: '2128',
  },
  {
    _id: '2129',
    title: '2129',
  },
  {
    _id: '2130',
    title: '2130',
  },
  {
    _id: '2131',
    title: '2131',
  },
  {
    _id: '2132',
    title: '2132',
  },
  {
    _id: '2133',
    title: '2133',
  },
  {
    _id: '2134',
    title: '2134',
  },
  {
    _id: '2135',
    title: '2135',
  },
  {
    _id: '2136',
    title: '2136',
  },
  {
    _id: '2137',
    title: '2137',
  },
  {
    _id: '2138',
    title: '2138',
  },
  {
    _id: '2139',
    title: '2139',
  },
  {
    _id: '2140',
    title: '2140',
  },
  {
    _id: '2141',
    title: '2141',
  },
  {
    _id: '2142',
    title: '2142',
  },
  {
    _id: '2143',
    title: '2143',
  },
  {
    _id: '2144',
    title: '2144',
  },
  {
    _id: '2145',
    title: '2145',
  },
  {
    _id: '2146',
    title: '2146',
  },
  {
    _id: '2147',
    title: '2147',
  },
  {
    _id: '2148',
    title: '2148',
  },
  {
    _id: '2149',
    title: '2149',
  },
  {
    _id: '2150',
    title: '2150',
  },
  {
    _id: '2151',
    title: '2151',
  },
  {
    _id: '2152',
    title: '2152',
  },
  {
    _id: '2153',
    title: '2153',
  },
  {
    _id: '2154',
    title: '2154',
  },
  {
    _id: '2155',
    title: '2155',
  },
  {
    _id: '2156',
    title: '2156',
  },
  {
    _id: '2157',
    title: '2157',
  },
  {
    _id: '2158',
    title: '2158',
  },
  {
    _id: '2159',
    title: '2159',
  },
  {
    _id: '2160',
    title: '2160',
  },
  {
    _id: '2161',
    title: '2161',
  },
  {
    _id: '2162',
    title: '2162',
  },
  {
    _id: '2163',
    title: '2163',
  },
  {
    _id: '2164',
    title: '2164',
  },
  {
    _id: '2165',
    title: '2165',
  },
  {
    _id: '2166',
    title: '2166',
  },
  {
    _id: '2167',
    title: '2167',
  },
  {
    _id: '2168',
    title: '2168',
  },
  {
    _id: '2169',
    title: '2169',
  },
  {
    _id: '2170',
    title: '2170',
  },
  {
    _id: '2171',
    title: '2171',
  },
  {
    _id: '2172',
    title: '2172',
  },
  {
    _id: '2173',
    title: '2173',
  },
  {
    _id: '2174',
    title: '2174',
  },
  {
    _id: '2175',
    title: '2175',
  },
  {
    _id: '2176',
    title: '2176',
  },
  {
    _id: '2177',
    title: '2177',
  },
  {
    _id: '2178',
    title: '2178',
  },
  {
    _id: '2179',
    title: '2179',
  },
  {
    _id: '2180',
    title: '2180',
  },
  {
    _id: '2181',
    title: '2181',
  },
  {
    _id: '2182',
    title: '2182',
  },
  {
    _id: '2183',
    title: '2183',
  },
  {
    _id: '2184',
    title: '2184',
  },
  {
    _id: '2185',
    title: '2185',
  },
  {
    _id: '2186',
    title: '2186',
  },
  {
    _id: '2187',
    title: '2187',
  },
  {
    _id: '2188',
    title: '2188',
  },
  {
    _id: '2189',
    title: '2189',
  },
  {
    _id: '2190',
    title: '2190',
  },
  {
    _id: '2191',
    title: '2191',
  },
  {
    _id: '2192',
    title: '2192',
  },
  {
    _id: '2193',
    title: '2193',
  },
  {
    _id: '2194',
    title: '2194',
  },
  {
    _id: '2195',
    title: '2195',
  },
  {
    _id: '2196',
    title: '2196',
  },
  {
    _id: '2197',
    title: '2197',
  },
  {
    _id: '2198',
    title: '2198',
  },
  {
    _id: '2199',
    title: '2199',
  },
  {
    _id: '2200',
    title: '2200',
  },
  {
    _id: '2201',
    title: '2201',
  },
  {
    _id: '2202',
    title: '2202',
  },
  {
    _id: '2203',
    title: '2203',
  },
  {
    _id: '2204',
    title: '2204',
  },
  {
    _id: '2205',
    title: '2205',
  },
  {
    _id: '2206',
    title: '2206',
  },
  {
    _id: '2207',
    title: '2207',
  },
  {
    _id: '2208',
    title: '2208',
  },
  {
    _id: '2209',
    title: '2209',
  },
  {
    _id: '2210',
    title: '2210',
  },
  {
    _id: '2211',
    title: '2211',
  },
  {
    _id: '2212',
    title: '2212',
  },
  {
    _id: '2213',
    title: '2213',
  },
  {
    _id: '2214',
    title: '2214',
  },
  {
    _id: '2215',
    title: '2215',
  },
  {
    _id: '2216',
    title: '2216',
  },
  {
    _id: '2217',
    title: '2217',
  },
  {
    _id: '2218',
    title: '2218',
  },
  {
    _id: '2219',
    title: '2219',
  },
  {
    _id: '2220',
    title: '2220',
  },
  {
    _id: '2221',
    title: '2221',
  },
  {
    _id: '2222',
    title: '2222',
  },
  {
    _id: '2223',
    title: '2223',
  },
  {
    _id: '2224',
    title: '2224',
  },
  {
    _id: '2225',
    title: '2225',
  },
  {
    _id: '2226',
    title: '2226',
  },
  {
    _id: '2227',
    title: '2227',
  },
  {
    _id: '2228',
    title: '2228',
  },
  {
    _id: '2229',
    title: '2229',
  },
  {
    _id: '2230',
    title: '2230',
  },
  {
    _id: '2231',
    title: '2231',
  },
  {
    _id: '2232',
    title: '2232',
  },
  {
    _id: '2233',
    title: '2233',
  },
  {
    _id: '2234',
    title: '2234',
  },
  {
    _id: '2235',
    title: '2235',
  },
  {
    _id: '2236',
    title: '2236',
  },
  {
    _id: '2237',
    title: '2237',
  },
  {
    _id: '2238',
    title: '2238',
  },
  {
    _id: '2239',
    title: '2239',
  },
  {
    _id: '2240',
    title: '2240',
  },
  {
    _id: '2241',
    title: '2241',
  },
  {
    _id: '2242',
    title: '2242',
  },
  {
    _id: '2243',
    title: '2243',
  },
  {
    _id: '2244',
    title: '2244',
  },
  {
    _id: '2245',
    title: '2245',
  },
  {
    _id: '2246',
    title: '2246',
  },
  {
    _id: '2247',
    title: '2247',
  },
  {
    _id: '2248',
    title: '2248',
  },
  {
    _id: '2249',
    title: '2249',
  },
  {
    _id: '2250',
    title: '2250',
  },
  {
    _id: '2251',
    title: '2251',
  },
  {
    _id: '2252',
    title: '2252',
  },
  {
    _id: '2253',
    title: '2253',
  },
  {
    _id: '2254',
    title: '2254',
  },
  {
    _id: '2255',
    title: '2255',
  },
  {
    _id: '2256',
    title: '2256',
  },
  {
    _id: '2257',
    title: '2257',
  },
  {
    _id: '2258',
    title: '2258',
  },
  {
    _id: '2259',
    title: '2259',
  },
  {
    _id: '2260',
    title: '2260',
  },
  {
    _id: '2261',
    title: '2261',
  },
  {
    _id: '2262',
    title: '2262',
  },
  {
    _id: '2263',
    title: '2263',
  },
  {
    _id: '2264',
    title: '2264',
  },
  {
    _id: '2265',
    title: '2265',
  },
  {
    _id: '2266',
    title: '2266',
  },
  {
    _id: '2267',
    title: '2267',
  },
  {
    _id: '2268',
    title: '2268',
  },
  {
    _id: '2269',
    title: '2269',
  },
  {
    _id: '2270',
    title: '2270',
  },
  {
    _id: '2271',
    title: '2271',
  },
  {
    _id: '2272',
    title: '2272',
  },
  {
    _id: '2273',
    title: '2273',
  },
  {
    _id: '2274',
    title: '2274',
  },
  {
    _id: '2275',
    title: '2275',
  },
  {
    _id: '2276',
    title: '2276',
  },
  {
    _id: '2277',
    title: '2277',
  },
  {
    _id: '2278',
    title: '2278',
  },
  {
    _id: '2279',
    title: '2279',
  },
  {
    _id: '2280',
    title: '2280',
  },
  {
    _id: '2281',
    title: '2281',
  },
  {
    _id: '2282',
    title: '2282',
  },
  {
    _id: '2283',
    title: '2283',
  },
  {
    _id: '2284',
    title: '2284',
  },
  {
    _id: '2285',
    title: '2285',
  },
  {
    _id: '2286',
    title: '2286',
  },
  {
    _id: '2287',
    title: '2287',
  },
  {
    _id: '2288',
    title: '2288',
  },
  {
    _id: '2289',
    title: '2289',
  },
  {
    _id: '2290',
    title: '2290',
  },
  {
    _id: '2291',
    title: '2291',
  },
  {
    _id: '2292',
    title: '2292',
  },
  {
    _id: '2293',
    title: '2293',
  },
  {
    _id: '2294',
    title: '2294',
  },
  {
    _id: '2295',
    title: '2295',
  },
  {
    _id: '2296',
    title: '2296',
  },
  {
    _id: '2297',
    title: '2297',
  },
  {
    _id: '2298',
    title: '2298',
  },
  {
    _id: '2299',
    title: '2299',
  },
  {
    _id: '2300',
    title: '2300',
  },
  {
    _id: '2301',
    title: '2301',
  },
  {
    _id: '2302',
    title: '2302',
  },
  {
    _id: '2303',
    title: '2303',
  },
  {
    _id: '2304',
    title: '2304',
  },
  {
    _id: '2305',
    title: '2305',
  },
  {
    _id: '2306',
    title: '2306',
  },
  {
    _id: '2307',
    title: '2307',
  },
  {
    _id: '2308',
    title: '2308',
  },
  {
    _id: '2309',
    title: '2309',
  },
  {
    _id: '2310',
    title: '2310',
  },
  {
    _id: '2311',
    title: '2311',
  },
  {
    _id: '2312',
    title: '2312',
  },
  {
    _id: '2313',
    title: '2313',
  },
  {
    _id: '2314',
    title: '2314',
  },
  {
    _id: '2315',
    title: '2315',
  },
  {
    _id: '2316',
    title: '2316',
  },
  {
    _id: '2317',
    title: '2317',
  },
  {
    _id: '2318',
    title: '2318',
  },
  {
    _id: '2319',
    title: '2319',
  },
  {
    _id: '2320',
    title: '2320',
  },
  {
    _id: '2321',
    title: '2321',
  },
  {
    _id: '2322',
    title: '2322',
  },
  {
    _id: '2323',
    title: '2323',
  },
  {
    _id: '2324',
    title: '2324',
  },
  {
    _id: '2325',
    title: '2325',
  },
  {
    _id: '2326',
    title: '2326',
  },
  {
    _id: '2327',
    title: '2327',
  },
  {
    _id: '2328',
    title: '2328',
  },
  {
    _id: '2329',
    title: '2329',
  },
  {
    _id: '2330',
    title: '2330',
  },
  {
    _id: '2331',
    title: '2331',
  },
  {
    _id: '2332',
    title: '2332',
  },
  {
    _id: '2333',
    title: '2333',
  },
  {
    _id: '2334',
    title: '2334',
  },
  {
    _id: '2335',
    title: '2335',
  },
  {
    _id: '2336',
    title: '2336',
  },
  {
    _id: '2337',
    title: '2337',
  },
  {
    _id: '2338',
    title: '2338',
  },
  {
    _id: '2339',
    title: '2339',
  },
  {
    _id: '2340',
    title: '2340',
  },
  {
    _id: '2341',
    title: '2341',
  },
  {
    _id: '2342',
    title: '2342',
  },
  {
    _id: '2343',
    title: '2343',
  },
  {
    _id: '2344',
    title: '2344',
  },
  {
    _id: '2345',
    title: '2345',
  },
  {
    _id: '2346',
    title: '2346',
  },
  {
    _id: '2347',
    title: '2347',
  },
  {
    _id: '2348',
    title: '2348',
  },
  {
    _id: '2349',
    title: '2349',
  },
  {
    _id: '2350',
    title: '2350',
  },
  {
    _id: '2351',
    title: '2351',
  },
  {
    _id: '2352',
    title: '2352',
  },
  {
    _id: '2353',
    title: '2353',
  },
  {
    _id: '2354',
    title: '2354',
  },
  {
    _id: '2355',
    title: '2355',
  },
  {
    _id: '2356',
    title: '2356',
  },
  {
    _id: '2357',
    title: '2357',
  },
  {
    _id: '2358',
    title: '2358',
  },
  {
    _id: '2359',
    title: '2359',
  },
  {
    _id: '2360',
    title: '2360',
  },
  {
    _id: '2361',
    title: '2361',
  },
  {
    _id: '2362',
    title: '2362',
  },
  {
    _id: '2363',
    title: '2363',
  },
  {
    _id: '2364',
    title: '2364',
  },
  {
    _id: '2365',
    title: '2365',
  },
  {
    _id: '2366',
    title: '2366',
  },
  {
    _id: '2367',
    title: '2367',
  },
  {
    _id: '2368',
    title: '2368',
  },
  {
    _id: '2369',
    title: '2369',
  },
  {
    _id: '2370',
    title: '2370',
  },
  {
    _id: '2371',
    title: '2371',
  },
  {
    _id: '2372',
    title: '2372',
  },
  {
    _id: '2373',
    title: '2373',
  },
  {
    _id: '2374',
    title: '2374',
  },
  {
    _id: '2375',
    title: '2375',
  },
  {
    _id: '2376',
    title: '2376',
  },
  {
    _id: '2377',
    title: '2377',
  },
  {
    _id: '2378',
    title: '2378',
  },
  {
    _id: '2379',
    title: '2379',
  },
  {
    _id: '2380',
    title: '2380',
  },
  {
    _id: '2381',
    title: '2381',
  },
  {
    _id: '2382',
    title: '2382',
  },
  {
    _id: '2383',
    title: '2383',
  },
  {
    _id: '2384',
    title: '2384',
  },
  {
    _id: '2385',
    title: '2385',
  },
  {
    _id: '2386',
    title: '2386',
  },
  {
    _id: '2387',
    title: '2387',
  },
  {
    _id: '2388',
    title: '2388',
  },
  {
    _id: '2389',
    title: '2389',
  },
  {
    _id: '2390',
    title: '2390',
  },
  {
    _id: '2391',
    title: '2391',
  },
  {
    _id: '2392',
    title: '2392',
  },
  {
    _id: '2393',
    title: '2393',
  },
  {
    _id: '2394',
    title: '2394',
  },
  {
    _id: '2395',
    title: '2395',
  },
  {
    _id: '2396',
    title: '2396',
  },
  {
    _id: '2397',
    title: '2397',
  },
  {
    _id: '2398',
    title: '2398',
  },
  {
    _id: '2399',
    title: '2399',
  },
  {
    _id: '2400',
    title: '2400',
  },
  {
    _id: '2401',
    title: '2401',
  },
  {
    _id: '2402',
    title: '2402',
  },
  {
    _id: '2403',
    title: '2403',
  },
  {
    _id: '2404',
    title: '2404',
  },
  {
    _id: '2405',
    title: '2405',
  },
  {
    _id: '2406',
    title: '2406',
  },
  {
    _id: '2407',
    title: '2407',
  },
  {
    _id: '2408',
    title: '2408',
  },
  {
    _id: '2409',
    title: '2409',
  },
  {
    _id: '2410',
    title: '2410',
  },
  {
    _id: '2411',
    title: '2411',
  },
  {
    _id: '2412',
    title: '2412',
  },
  {
    _id: '2413',
    title: '2413',
  },
  {
    _id: '2414',
    title: '2414',
  },
  {
    _id: '2415',
    title: '2415',
  },
  {
    _id: '2416',
    title: '2416',
  },
  {
    _id: '2417',
    title: '2417',
  },
  {
    _id: '2418',
    title: '2418',
  },
  {
    _id: '2419',
    title: '2419',
  },
  {
    _id: '2420',
    title: '2420',
  },
  {
    _id: '2421',
    title: '2421',
  },
  {
    _id: '2422',
    title: '2422',
  },
  {
    _id: '2423',
    title: '2423',
  },
  {
    _id: '2424',
    title: '2424',
  },
  {
    _id: '2425',
    title: '2425',
  },
  {
    _id: '2426',
    title: '2426',
  },
  {
    _id: '2427',
    title: '2427',
  },
  {
    _id: '2428',
    title: '2428',
  },
  {
    _id: '2429',
    title: '2429',
  },
  {
    _id: '2430',
    title: '2430',
  },
  {
    _id: '2431',
    title: '2431',
  },
  {
    _id: '2432',
    title: '2432',
  },
  {
    _id: '2433',
    title: '2433',
  },
  {
    _id: '2434',
    title: '2434',
  },
  {
    _id: '2435',
    title: '2435',
  },
  {
    _id: '2436',
    title: '2436',
  },
  {
    _id: '2437',
    title: '2437',
  },
  {
    _id: '2438',
    title: '2438',
  },
  {
    _id: '2439',
    title: '2439',
  },
  {
    _id: '2440',
    title: '2440',
  },
  {
    _id: '2441',
    title: '2441',
  },
  {
    _id: '2442',
    title: '2442',
  },
  {
    _id: '2443',
    title: '2443',
  },
  {
    _id: '2444',
    title: '2444',
  },
  {
    _id: '2445',
    title: '2445',
  },
  {
    _id: '2446',
    title: '2446',
  },
  {
    _id: '2447',
    title: '2447',
  },
  {
    _id: '2448',
    title: '2448',
  },
  {
    _id: '2449',
    title: '2449',
  },
  {
    _id: '2450',
    title: '2450',
  },
  {
    _id: '2451',
    title: '2451',
  },
  {
    _id: '2452',
    title: '2452',
  },
  {
    _id: '2453',
    title: '2453',
  },
  {
    _id: '2454',
    title: '2454',
  },
  {
    _id: '2455',
    title: '2455',
  },
  {
    _id: '2456',
    title: '2456',
  },
  {
    _id: '2457',
    title: '2457',
  },
  {
    _id: '2458',
    title: '2458',
  },
  {
    _id: '2459',
    title: '2459',
  },
  {
    _id: '2460',
    title: '2460',
  },
  {
    _id: '2461',
    title: '2461',
  },
  {
    _id: '2462',
    title: '2462',
  },
  {
    _id: '2463',
    title: '2463',
  },
  {
    _id: '2464',
    title: '2464',
  },
  {
    _id: '2465',
    title: '2465',
  },
  {
    _id: '2466',
    title: '2466',
  },
  {
    _id: '2467',
    title: '2467',
  },
  {
    _id: '2468',
    title: '2468',
  },
  {
    _id: '2469',
    title: '2469',
  },
  {
    _id: '2470',
    title: '2470',
  },
  {
    _id: '2471',
    title: '2471',
  },
  {
    _id: '2472',
    title: '2472',
  },
  {
    _id: '2473',
    title: '2473',
  },
  {
    _id: '2474',
    title: '2474',
  },
  {
    _id: '2475',
    title: '2475',
  },
  {
    _id: '2476',
    title: '2476',
  },
  {
    _id: '2477',
    title: '2477',
  },
  {
    _id: '2478',
    title: '2478',
  },
  {
    _id: '2479',
    title: '2479',
  },
  {
    _id: '2480',
    title: '2480',
  },
  {
    _id: '2481',
    title: '2481',
  },
  {
    _id: '2482',
    title: '2482',
  },
  {
    _id: '2483',
    title: '2483',
  },
  {
    _id: '2484',
    title: '2484',
  },
  {
    _id: '2485',
    title: '2485',
  },
  {
    _id: '2486',
    title: '2486',
  },
  {
    _id: '2487',
    title: '2487',
  },
  {
    _id: '2488',
    title: '2488',
  },
  {
    _id: '2489',
    title: '2489',
  },
  {
    _id: '2490',
    title: '2490',
  },
  {
    _id: '2491',
    title: '2491',
  },
  {
    _id: '2492',
    title: '2492',
  },
  {
    _id: '2493',
    title: '2493',
  },
  {
    _id: '2494',
    title: '2494',
  },
  {
    _id: '2495',
    title: '2495',
  },
  {
    _id: '2496',
    title: '2496',
  },
  {
    _id: '2497',
    title: '2497',
  },
  {
    _id: '2498',
    title: '2498',
  },
  {
    _id: '2499',
    title: '2499',
  },
  {
    _id: '2500',
    title: '2500',
  },
  {
    _id: '2501',
    title: '2501',
  },
  {
    _id: '2502',
    title: '2502',
  },
  {
    _id: '2503',
    title: '2503',
  },
  {
    _id: '2504',
    title: '2504',
  },
  {
    _id: '2505',
    title: '2505',
  },
  {
    _id: '2506',
    title: '2506',
  },
  {
    _id: '2507',
    title: '2507',
  },
  {
    _id: '2508',
    title: '2508',
  },
  {
    _id: '2509',
    title: '2509',
  },
  {
    _id: '2510',
    title: '2510',
  },
  {
    _id: '2511',
    title: '2511',
  },
  {
    _id: '2512',
    title: '2512',
  },
  {
    _id: '2513',
    title: '2513',
  },
  {
    _id: '2514',
    title: '2514',
  },
  {
    _id: '2515',
    title: '2515',
  },
  {
    _id: '2516',
    title: '2516',
  },
  {
    _id: '2517',
    title: '2517',
  },
  {
    _id: '2518',
    title: '2518',
  },
  {
    _id: '2519',
    title: '2519',
  },
  {
    _id: '2520',
    title: '2520',
  },
  {
    _id: '2521',
    title: '2521',
  },
  {
    _id: '2522',
    title: '2522',
  },
  {
    _id: '2523',
    title: '2523',
  },
  {
    _id: '2524',
    title: '2524',
  },
  {
    _id: '2525',
    title: '2525',
  },
  {
    _id: '2526',
    title: '2526',
  },
  {
    _id: '2527',
    title: '2527',
  },
  {
    _id: '2528',
    title: '2528',
  },
  {
    _id: '2529',
    title: '2529',
  },
  {
    _id: '2530',
    title: '2530',
  },
  {
    _id: '2531',
    title: '2531',
  },
  {
    _id: '2532',
    title: '2532',
  },
  {
    _id: '2533',
    title: '2533',
  },
  {
    _id: '2534',
    title: '2534',
  },
  {
    _id: '2535',
    title: '2535',
  },
  {
    _id: '2536',
    title: '2536',
  },
  {
    _id: '2537',
    title: '2537',
  },
  {
    _id: '2538',
    title: '2538',
  },
  {
    _id: '2539',
    title: '2539',
  },
  {
    _id: '2540',
    title: '2540',
  },
  {
    _id: '2541',
    title: '2541',
  },
  {
    _id: '2542',
    title: '2542',
  },
  {
    _id: '2543',
    title: '2543',
  },
  {
    _id: '2544',
    title: '2544',
  },
  {
    _id: '2545',
    title: '2545',
  },
  {
    _id: '2546',
    title: '2546',
  },
  {
    _id: '2547',
    title: '2547',
  },
  {
    _id: '2548',
    title: '2548',
  },
  {
    _id: '2549',
    title: '2549',
  },
  {
    _id: '2550',
    title: '2550',
  },
  {
    _id: '2551',
    title: '2551',
  },
  {
    _id: '2552',
    title: '2552',
  },
  {
    _id: '2553',
    title: '2553',
  },
  {
    _id: '2554',
    title: '2554',
  },
  {
    _id: '2555',
    title: '2555',
  },
  {
    _id: '2556',
    title: '2556',
  },
  {
    _id: '2557',
    title: '2557',
  },
  {
    _id: '2558',
    title: '2558',
  },
  {
    _id: '2559',
    title: '2559',
  },
  {
    _id: '2560',
    title: '2560',
  },
  {
    _id: '2561',
    title: '2561',
  },
  {
    _id: '2562',
    title: '2562',
  },
  {
    _id: '2563',
    title: '2563',
  },
  {
    _id: '2564',
    title: '2564',
  },
  {
    _id: '2565',
    title: '2565',
  },
  {
    _id: '2566',
    title: '2566',
  },
  {
    _id: '2567',
    title: '2567',
  },
  {
    _id: '2568',
    title: '2568',
  },
  {
    _id: '2569',
    title: '2569',
  },
  {
    _id: '2570',
    title: '2570',
  },
  {
    _id: '2571',
    title: '2571',
  },
  {
    _id: '2572',
    title: '2572',
  },
  {
    _id: '2573',
    title: '2573',
  },
  {
    _id: '2574',
    title: '2574',
  },
  {
    _id: '2575',
    title: '2575',
  },
  {
    _id: '2576',
    title: '2576',
  },
  {
    _id: '2577',
    title: '2577',
  },
  {
    _id: '2578',
    title: '2578',
  },
  {
    _id: '2579',
    title: '2579',
  },
  {
    _id: '2580',
    title: '2580',
  },
  {
    _id: '2581',
    title: '2581',
  },
  {
    _id: '2582',
    title: '2582',
  },
  {
    _id: '2583',
    title: '2583',
  },
  {
    _id: '2584',
    title: '2584',
  },
  {
    _id: '2585',
    title: '2585',
  },
  {
    _id: '2586',
    title: '2586',
  },
  {
    _id: '2587',
    title: '2587',
  },
  {
    _id: '2588',
    title: '2588',
  },
  {
    _id: '2589',
    title: '2589',
  },
  {
    _id: '2590',
    title: '2590',
  },
  {
    _id: '2591',
    title: '2591',
  },
  {
    _id: '2592',
    title: '2592',
  },
  {
    _id: '2593',
    title: '2593',
  },
  {
    _id: '2594',
    title: '2594',
  },
  {
    _id: '2595',
    title: '2595',
  },
  {
    _id: '2596',
    title: '2596',
  },
  {
    _id: '2597',
    title: '2597',
  },
  {
    _id: '2598',
    title: '2598',
  },
  {
    _id: '2599',
    title: '2599',
  },
  {
    _id: '2600',
    title: '2600',
  },
  {
    _id: '2601',
    title: '2601',
  },
  {
    _id: '2602',
    title: '2602',
  },
  {
    _id: '2603',
    title: '2603',
  },
  {
    _id: '2604',
    title: '2604',
  },
  {
    _id: '2605',
    title: '2605',
  },
  {
    _id: '2606',
    title: '2606',
  },
  {
    _id: '2607',
    title: '2607',
  },
  {
    _id: '2608',
    title: '2608',
  },
  {
    _id: '2609',
    title: '2609',
  },
  {
    _id: '2610',
    title: '2610',
  },
  {
    _id: '2611',
    title: '2611',
  },
  {
    _id: '2612',
    title: '2612',
  },
  {
    _id: '2613',
    title: '2613',
  },
  {
    _id: '2614',
    title: '2614',
  },
  {
    _id: '2615',
    title: '2615',
  },
  {
    _id: '2616',
    title: '2616',
  },
  {
    _id: '2617',
    title: '2617',
  },
  {
    _id: '2618',
    title: '2618',
  },
  {
    _id: '2619',
    title: '2619',
  },
  {
    _id: '2620',
    title: '2620',
  },
  {
    _id: '2621',
    title: '2621',
  },
  {
    _id: '2622',
    title: '2622',
  },
  {
    _id: '2623',
    title: '2623',
  },
  {
    _id: '2624',
    title: '2624',
  },
  {
    _id: '2625',
    title: '2625',
  },
  {
    _id: '2626',
    title: '2626',
  },
  {
    _id: '2627',
    title: '2627',
  },
  {
    _id: '2628',
    title: '2628',
  },
  {
    _id: '2629',
    title: '2629',
  },
  {
    _id: '2630',
    title: '2630',
  },
  {
    _id: '2631',
    title: '2631',
  },
  {
    _id: '2632',
    title: '2632',
  },
  {
    _id: '2633',
    title: '2633',
  },
  {
    _id: '2634',
    title: '2634',
  },
  {
    _id: '2635',
    title: '2635',
  },
  {
    _id: '2636',
    title: '2636',
  },
  {
    _id: '2637',
    title: '2637',
  },
  {
    _id: '2638',
    title: '2638',
  },
  {
    _id: '2639',
    title: '2639',
  },
  {
    _id: '2640',
    title: '2640',
  },
  {
    _id: '2641',
    title: '2641',
  },
  {
    _id: '2642',
    title: '2642',
  },
  {
    _id: '2643',
    title: '2643',
  },
  {
    _id: '2644',
    title: '2644',
  },
  {
    _id: '2645',
    title: '2645',
  },
  {
    _id: '2646',
    title: '2646',
  },
  {
    _id: '2647',
    title: '2647',
  },
  {
    _id: '2648',
    title: '2648',
  },
  {
    _id: '2649',
    title: '2649',
  },
  {
    _id: '2650',
    title: '2650',
  },
  {
    _id: '2651',
    title: '2651',
  },
  {
    _id: '2652',
    title: '2652',
  },
  {
    _id: '2653',
    title: '2653',
  },
  {
    _id: '2654',
    title: '2654',
  },
  {
    _id: '2655',
    title: '2655',
  },
  {
    _id: '2656',
    title: '2656',
  },
  {
    _id: '2657',
    title: '2657',
  },
  {
    _id: '2658',
    title: '2658',
  },
  {
    _id: '2659',
    title: '2659',
  },
  {
    _id: '2660',
    title: '2660',
  },
  {
    _id: '2661',
    title: '2661',
  },
  {
    _id: '2662',
    title: '2662',
  },
  {
    _id: '2663',
    title: '2663',
  },
  {
    _id: '2664',
    title: '2664',
  },
  {
    _id: '2665',
    title: '2665',
  },
  {
    _id: '2666',
    title: '2666',
  },
  {
    _id: '2667',
    title: '2667',
  },
  {
    _id: '2668',
    title: '2668',
  },
  {
    _id: '2669',
    title: '2669',
  },
  {
    _id: '2670',
    title: '2670',
  },
  {
    _id: '2671',
    title: '2671',
  },
  {
    _id: '2672',
    title: '2672',
  },
  {
    _id: '2673',
    title: '2673',
  },
  {
    _id: '2674',
    title: '2674',
  },
  {
    _id: '2675',
    title: '2675',
  },
  {
    _id: '2676',
    title: '2676',
  },
  {
    _id: '2677',
    title: '2677',
  },
  {
    _id: '2678',
    title: '2678',
  },
  {
    _id: '2679',
    title: '2679',
  },
  {
    _id: '2680',
    title: '2680',
  },
  {
    _id: '2681',
    title: '2681',
  },
  {
    _id: '2682',
    title: '2682',
  },
  {
    _id: '2683',
    title: '2683',
  },
  {
    _id: '2684',
    title: '2684',
  },
  {
    _id: '2685',
    title: '2685',
  },
  {
    _id: '2686',
    title: '2686',
  },
  {
    _id: '2687',
    title: '2687',
  },
  {
    _id: '2688',
    title: '2688',
  },
  {
    _id: '2689',
    title: '2689',
  },
  {
    _id: '2690',
    title: '2690',
  },
  {
    _id: '2691',
    title: '2691',
  },
  {
    _id: '2692',
    title: '2692',
  },
  {
    _id: '2693',
    title: '2693',
  },
  {
    _id: '2694',
    title: '2694',
  },
  {
    _id: '2695',
    title: '2695',
  },
  {
    _id: '2696',
    title: '2696',
  },
  {
    _id: '2697',
    title: '2697',
  },
  {
    _id: '2698',
    title: '2698',
  },
  {
    _id: '2699',
    title: '2699',
  },
  {
    _id: '2700',
    title: '2700',
  },
  {
    _id: '2701',
    title: '2701',
  },
  {
    _id: '2702',
    title: '2702',
  },
  {
    _id: '2703',
    title: '2703',
  },
  {
    _id: '2704',
    title: '2704',
  },
  {
    _id: '2705',
    title: '2705',
  },
  {
    _id: '2706',
    title: '2706',
  },
  {
    _id: '2707',
    title: '2707',
  },
  {
    _id: '2708',
    title: '2708',
  },
  {
    _id: '2709',
    title: '2709',
  },
  {
    _id: '2710',
    title: '2710',
  },
  {
    _id: '2711',
    title: '2711',
  },
  {
    _id: '2712',
    title: '2712',
  },
  {
    _id: '2713',
    title: '2713',
  },
  {
    _id: '2714',
    title: '2714',
  },
  {
    _id: '2715',
    title: '2715',
  },
  {
    _id: '2716',
    title: '2716',
  },
  {
    _id: '2717',
    title: '2717',
  },
  {
    _id: '2718',
    title: '2718',
  },
  {
    _id: '2719',
    title: '2719',
  },
  {
    _id: '2720',
    title: '2720',
  },
  {
    _id: '2721',
    title: '2721',
  },
  {
    _id: '2722',
    title: '2722',
  },
  {
    _id: '2723',
    title: '2723',
  },
  {
    _id: '2724',
    title: '2724',
  },
  {
    _id: '2725',
    title: '2725',
  },
  {
    _id: '2726',
    title: '2726',
  },
  {
    _id: '2727',
    title: '2727',
  },
  {
    _id: '2728',
    title: '2728',
  },
  {
    _id: '2729',
    title: '2729',
  },
  {
    _id: '2730',
    title: '2730',
  },
  {
    _id: '2731',
    title: '2731',
  },
  {
    _id: '2732',
    title: '2732',
  },
  {
    _id: '2733',
    title: '2733',
  },
  {
    _id: '2734',
    title: '2734',
  },
  {
    _id: '2735',
    title: '2735',
  },
  {
    _id: '2736',
    title: '2736',
  },
  {
    _id: '2737',
    title: '2737',
  },
  {
    _id: '2738',
    title: '2738',
  },
  {
    _id: '2739',
    title: '2739',
  },
  {
    _id: '2740',
    title: '2740',
  },
  {
    _id: '2741',
    title: '2741',
  },
  {
    _id: '2742',
    title: '2742',
  },
  {
    _id: '2743',
    title: '2743',
  },
  {
    _id: '2744',
    title: '2744',
  },
  {
    _id: '2745',
    title: '2745',
  },
  {
    _id: '2746',
    title: '2746',
  },
  {
    _id: '2747',
    title: '2747',
  },
  {
    _id: '2748',
    title: '2748',
  },
  {
    _id: '2749',
    title: '2749',
  },
  {
    _id: '2750',
    title: '2750',
  },
  {
    _id: '2751',
    title: '2751',
  },
  {
    _id: '2752',
    title: '2752',
  },
  {
    _id: '2753',
    title: '2753',
  },
  {
    _id: '2754',
    title: '2754',
  },
  {
    _id: '2755',
    title: '2755',
  },
  {
    _id: '2756',
    title: '2756',
  },
  {
    _id: '2757',
    title: '2757',
  },
  {
    _id: '2758',
    title: '2758',
  },
  {
    _id: '2759',
    title: '2759',
  },
  {
    _id: '2760',
    title: '2760',
  },
  {
    _id: '2761',
    title: '2761',
  },
  {
    _id: '2762',
    title: '2762',
  },
  {
    _id: '2763',
    title: '2763',
  },
  {
    _id: '2764',
    title: '2764',
  },
  {
    _id: '2765',
    title: '2765',
  },
  {
    _id: '2766',
    title: '2766',
  },
  {
    _id: '2767',
    title: '2767',
  },
  {
    _id: '2768',
    title: '2768',
  },
  {
    _id: '2769',
    title: '2769',
  },
  {
    _id: '2770',
    title: '2770',
  },
  {
    _id: '2771',
    title: '2771',
  },
  {
    _id: '2772',
    title: '2772',
  },
  {
    _id: '2773',
    title: '2773',
  },
  {
    _id: '2774',
    title: '2774',
  },
  {
    _id: '2775',
    title: '2775',
  },
  {
    _id: '2776',
    title: '2776',
  },
  {
    _id: '2777',
    title: '2777',
  },
  {
    _id: '2778',
    title: '2778',
  },
  {
    _id: '2779',
    title: '2779',
  },
  {
    _id: '2780',
    title: '2780',
  },
  {
    _id: '2781',
    title: '2781',
  },
  {
    _id: '2782',
    title: '2782',
  },
  {
    _id: '2783',
    title: '2783',
  },
  {
    _id: '2784',
    title: '2784',
  },
  {
    _id: '2785',
    title: '2785',
  },
  {
    _id: '2786',
    title: '2786',
  },
  {
    _id: '2787',
    title: '2787',
  },
  {
    _id: '2788',
    title: '2788',
  },
  {
    _id: '2789',
    title: '2789',
  },
  {
    _id: '2790',
    title: '2790',
  },
  {
    _id: '2791',
    title: '2791',
  },
  {
    _id: '2792',
    title: '2792',
  },
  {
    _id: '2793',
    title: '2793',
  },
  {
    _id: '2794',
    title: '2794',
  },
  {
    _id: '2795',
    title: '2795',
  },
  {
    _id: '2796',
    title: '2796',
  },
  {
    _id: '2797',
    title: '2797',
  },
  {
    _id: '2798',
    title: '2798',
  },
  {
    _id: '2799',
    title: '2799',
  },
  {
    _id: '2800',
    title: '2800',
  },
  {
    _id: '2801',
    title: '2801',
  },
  {
    _id: '2802',
    title: '2802',
  },
  {
    _id: '2803',
    title: '2803',
  },
  {
    _id: '2804',
    title: '2804',
  },
  {
    _id: '2805',
    title: '2805',
  },
  {
    _id: '2806',
    title: '2806',
  },
  {
    _id: '2807',
    title: '2807',
  },
  {
    _id: '2808',
    title: '2808',
  },
  {
    _id: '2809',
    title: '2809',
  },
  {
    _id: '2810',
    title: '2810',
  },
  {
    _id: '2811',
    title: '2811',
  },
  {
    _id: '2812',
    title: '2812',
  },
  {
    _id: '2813',
    title: '2813',
  },
  {
    _id: '2814',
    title: '2814',
  },
  {
    _id: '2815',
    title: '2815',
  },
  {
    _id: '2816',
    title: '2816',
  },
  {
    _id: '2817',
    title: '2817',
  },
  {
    _id: '2818',
    title: '2818',
  },
  {
    _id: '2819',
    title: '2819',
  },
  {
    _id: '2820',
    title: '2820',
  },
  {
    _id: '2821',
    title: '2821',
  },
  {
    _id: '2822',
    title: '2822',
  },
  {
    _id: '2823',
    title: '2823',
  },
  {
    _id: '2824',
    title: '2824',
  },
  {
    _id: '2825',
    title: '2825',
  },
  {
    _id: '2826',
    title: '2826',
  },
  {
    _id: '2827',
    title: '2827',
  },
  {
    _id: '2828',
    title: '2828',
  },
  {
    _id: '2829',
    title: '2829',
  },
  {
    _id: '2830',
    title: '2830',
  },
  {
    _id: '2831',
    title: '2831',
  },
  {
    _id: '2832',
    title: '2832',
  },
  {
    _id: '2833',
    title: '2833',
  },
  {
    _id: '2834',
    title: '2834',
  },
  {
    _id: '2835',
    title: '2835',
  },
  {
    _id: '2836',
    title: '2836',
  },
  {
    _id: '2837',
    title: '2837',
  },
  {
    _id: '2838',
    title: '2838',
  },
  {
    _id: '2839',
    title: '2839',
  },
  {
    _id: '2840',
    title: '2840',
  },
  {
    _id: '2841',
    title: '2841',
  },
  {
    _id: '2842',
    title: '2842',
  },
  {
    _id: '2843',
    title: '2843',
  },
  {
    _id: '2844',
    title: '2844',
  },
  {
    _id: '2845',
    title: '2845',
  },
  {
    _id: '2846',
    title: '2846',
  },
  {
    _id: '2847',
    title: '2847',
  },
  {
    _id: '2848',
    title: '2848',
  },
  {
    _id: '2849',
    title: '2849',
  },
  {
    _id: '2850',
    title: '2850',
  },
  {
    _id: '2851',
    title: '2851',
  },
  {
    _id: '2852',
    title: '2852',
  },
  {
    _id: '2853',
    title: '2853',
  },
  {
    _id: '2854',
    title: '2854',
  },
  {
    _id: '2855',
    title: '2855',
  },
  {
    _id: '2856',
    title: '2856',
  },
  {
    _id: '2857',
    title: '2857',
  },
  {
    _id: '2858',
    title: '2858',
  },
  {
    _id: '2859',
    title: '2859',
  },
  {
    _id: '2860',
    title: '2860',
  },
  {
    _id: '2861',
    title: '2861',
  },
  {
    _id: '2862',
    title: '2862',
  },
  {
    _id: '2863',
    title: '2863',
  },
  {
    _id: '2864',
    title: '2864',
  },
  {
    _id: '2865',
    title: '2865',
  },
  {
    _id: '2866',
    title: '2866',
  },
  {
    _id: '2867',
    title: '2867',
  },
  {
    _id: '2868',
    title: '2868',
  },
  {
    _id: '2869',
    title: '2869',
  },
  {
    _id: '2870',
    title: '2870',
  },
  {
    _id: '2871',
    title: '2871',
  },
  {
    _id: '2872',
    title: '2872',
  },
  {
    _id: '2873',
    title: '2873',
  },
  {
    _id: '2874',
    title: '2874',
  },
  {
    _id: '2875',
    title: '2875',
  },
  {
    _id: '2876',
    title: '2876',
  },
  {
    _id: '2877',
    title: '2877',
  },
  {
    _id: '2878',
    title: '2878',
  },
  {
    _id: '2879',
    title: '2879',
  },
  {
    _id: '2880',
    title: '2880',
  },
  {
    _id: '2881',
    title: '2881',
  },
  {
    _id: '2882',
    title: '2882',
  },
  {
    _id: '2883',
    title: '2883',
  },
  {
    _id: '2884',
    title: '2884',
  },
  {
    _id: '2885',
    title: '2885',
  },
  {
    _id: '2886',
    title: '2886',
  },
  {
    _id: '2887',
    title: '2887',
  },
  {
    _id: '2888',
    title: '2888',
  },
  {
    _id: '2889',
    title: '2889',
  },
  {
    _id: '2890',
    title: '2890',
  },
  {
    _id: '2891',
    title: '2891',
  },
  {
    _id: '2892',
    title: '2892',
  },
  {
    _id: '2893',
    title: '2893',
  },
  {
    _id: '2894',
    title: '2894',
  },
  {
    _id: '2895',
    title: '2895',
  },
  {
    _id: '2896',
    title: '2896',
  },
  {
    _id: '2897',
    title: '2897',
  },
  {
    _id: '2898',
    title: '2898',
  },
  {
    _id: '2899',
    title: '2899',
  },
  {
    _id: '2900',
    title: '2900',
  },
  {
    _id: '2901',
    title: '2901',
  },
  {
    _id: '2902',
    title: '2902',
  },
  {
    _id: '2903',
    title: '2903',
  },
  {
    _id: '2904',
    title: '2904',
  },
  {
    _id: '2905',
    title: '2905',
  },
  {
    _id: '2906',
    title: '2906',
  },
  {
    _id: '2907',
    title: '2907',
  },
  {
    _id: '2908',
    title: '2908',
  },
  {
    _id: '2909',
    title: '2909',
  },
  {
    _id: '2910',
    title: '2910',
  },
  {
    _id: '2911',
    title: '2911',
  },
  {
    _id: '2912',
    title: '2912',
  },
  {
    _id: '2913',
    title: '2913',
  },
  {
    _id: '2914',
    title: '2914',
  },
  {
    _id: '2915',
    title: '2915',
  },
  {
    _id: '2916',
    title: '2916',
  },
  {
    _id: '2917',
    title: '2917',
  },
  {
    _id: '2918',
    title: '2918',
  },
  {
    _id: '2919',
    title: '2919',
  },
  {
    _id: '2920',
    title: '2920',
  },
  {
    _id: '2921',
    title: '2921',
  },
  {
    _id: '2922',
    title: '2922',
  },
  {
    _id: '2923',
    title: '2923',
  },
  {
    _id: '2924',
    title: '2924',
  },
  {
    _id: '2925',
    title: '2925',
  },
  {
    _id: '2926',
    title: '2926',
  },
  {
    _id: '2927',
    title: '2927',
  },
  {
    _id: '2928',
    title: '2928',
  },
  {
    _id: '2929',
    title: '2929',
  },
  {
    _id: '2930',
    title: '2930',
  },
  {
    _id: '2931',
    title: '2931',
  },
  {
    _id: '2932',
    title: '2932',
  },
  {
    _id: '2933',
    title: '2933',
  },
  {
    _id: '2934',
    title: '2934',
  },
  {
    _id: '2935',
    title: '2935',
  },
  {
    _id: '2936',
    title: '2936',
  },
  {
    _id: '2937',
    title: '2937',
  },
  {
    _id: '2938',
    title: '2938',
  },
  {
    _id: '2939',
    title: '2939',
  },
  {
    _id: '2940',
    title: '2940',
  },
  {
    _id: '2941',
    title: '2941',
  },
  {
    _id: '2942',
    title: '2942',
  },
  {
    _id: '2943',
    title: '2943',
  },
  {
    _id: '2944',
    title: '2944',
  },
  {
    _id: '2945',
    title: '2945',
  },
  {
    _id: '2946',
    title: '2946',
  },
  {
    _id: '2947',
    title: '2947',
  },
  {
    _id: '2948',
    title: '2948',
  },
  {
    _id: '2949',
    title: '2949',
  },
  {
    _id: '2950',
    title: '2950',
  },
  {
    _id: '2951',
    title: '2951',
  },
  {
    _id: '2952',
    title: '2952',
  },
  {
    _id: '2953',
    title: '2953',
  },
  {
    _id: '2954',
    title: '2954',
  },
  {
    _id: '2955',
    title: '2955',
  },
  {
    _id: '2956',
    title: '2956',
  },
  {
    _id: '2957',
    title: '2957',
  },
  {
    _id: '2958',
    title: '2958',
  },
  {
    _id: '2959',
    title: '2959',
  },
  {
    _id: '2960',
    title: '2960',
  },
  {
    _id: '2961',
    title: '2961',
  },
  {
    _id: '2962',
    title: '2962',
  },
  {
    _id: '2963',
    title: '2963',
  },
  {
    _id: '2964',
    title: '2964',
  },
  {
    _id: '2965',
    title: '2965',
  },
  {
    _id: '2966',
    title: '2966',
  },
  {
    _id: '2967',
    title: '2967',
  },
  {
    _id: '2968',
    title: '2968',
  },
  {
    _id: '2969',
    title: '2969',
  },
  {
    _id: '2970',
    title: '2970',
  },
  {
    _id: '2971',
    title: '2971',
  },
  {
    _id: '2972',
    title: '2972',
  },
  {
    _id: '2973',
    title: '2973',
  },
  {
    _id: '2974',
    title: '2974',
  },
  {
    _id: '2975',
    title: '2975',
  },
  {
    _id: '2976',
    title: '2976',
  },
  {
    _id: '2977',
    title: '2977',
  },
  {
    _id: '2978',
    title: '2978',
  },
  {
    _id: '2979',
    title: '2979',
  },
  {
    _id: '2980',
    title: '2980',
  },
  {
    _id: '2981',
    title: '2981',
  },
  {
    _id: '2982',
    title: '2982',
  },
  {
    _id: '2983',
    title: '2983',
  },
  {
    _id: '2984',
    title: '2984',
  },
  {
    _id: '2985',
    title: '2985',
  },
  {
    _id: '2986',
    title: '2986',
  },
  {
    _id: '2987',
    title: '2987',
  },
  {
    _id: '2988',
    title: '2988',
  },
  {
    _id: '2989',
    title: '2989',
  },
  {
    _id: '2990',
    title: '2990',
  },
  {
    _id: '2991',
    title: '2991',
  },
  {
    _id: '2992',
    title: '2992',
  },
  {
    _id: '2993',
    title: '2993',
  },
  {
    _id: '2994',
    title: '2994',
  },
  {
    _id: '2995',
    title: '2995',
  },
  {
    _id: '2996',
    title: '2996',
  },
  {
    _id: '2997',
    title: '2997',
  },
  {
    _id: '2998',
    title: '2998',
  },
  {
    _id: '2999',
    title: '2999',
  },
  {
    _id: '3000',
    title: '3000',
  },
];

/* Bad */
export const BED = [
  { title: '0', value: '0' },
  { title: '1', value: '1' },
  { title: '2', value: '2' },
  { title: '3', value: '3' },
  { title: '4', value: '4' },
  { title: '5', value: '5' },
  { title: '6', value: '6' },
  { title: '7', value: '7' },
  { title: '8', value: '8' },
  { title: '9', value: '9' },
  { title: '10', value: '10' },
  { title: '11', value: '11' },
  { title: '12', value: '12' },
  { title: '13', value: '13' },
  { title: '14', value: '14' },
  { title: '15', value: '15' },
];
/* Bath */
export const BATH = [
  { title: '0', value: 0 },
  { title: '0.5', value: 0.5 },
  { title: '1', value: 1 },
  { title: '1.5', value: 1.5 },
  { title: '2', value: 2 },
  { title: '2.5', value: 2.5 },
  { title: '3', value: 3 },
  { title: '3.5', value: 3.5 },
  { title: '4', value: 4 },
  { title: '4.5', value: 4.5 },
  { title: '5', value: 5 },
  { title: '5.5', value: 5.5 },
  { title: '6', value: 6 },
  { title: '6.5', value: 6.5 },
  { title: '7', value: 7 },
  { title: '7.5', value: 7.5 },
  { title: '8', value: 8 },
  { title: '8.5', value: 8.5 },
  { title: '9', value: 9 },
  { title: '9.5', value: 9.5 },
  { title: '10', value: 10 },
];
/* basement */
export const Basement = [
  { title: 'Fully Finished', value: 'Fully Finished' },
  { title: 'Partially Finished', value: 'Partially Finished' },
  { title: 'Unfinished', value: 'Unfinished' },
  { title: 'None', value: 'None' },
];
/* Appliances */
export const Appliances = [
  { title: 'Built-In Microwave', _id: 'Built-In Microwave' },
  { title: 'Cooktop', _id: 'Cooktop' },
  { title: 'Chimney Hood Vent', _id: 'Chimney Hood Vent' },
  { title: 'Dishwasher', _id: 'Dishwasher' },
  { title: 'Double Wall Oven', _id: 'Double Wall Oven' },
  { title: 'Dryer', _id: 'dryer' },
  { title: 'Refrigerator', _id: 'Refrigerator' },
  { title: 'Range', _id: 'Range' },
  { title: 'Single Wall Oven', _id: 'Single Wall Oven' },
  { title: 'Washer', _id: 'washer' },
  { title: 'No Appliances', _id: '' },

  // { title: "Hood Vent", _id: "Hood Vent" },
  // { title: "Oven", _id: "Oven" },
];
/* Other Features */
export const otherFeatures = [
  // { title: "Attached Master Bath", _id: "Attached Master Bath"},
  { title: 'Built In Bookcases', _id: 'Built In Bookcases' },
  { title: 'Ceiling Fan(s)', _id: 'Ceiling Fan(s)' },
  { title: 'Chair Railing', _id: 'Chair Railing' },
  { title: 'Crown Molding', _id: 'Crown Molding' },
  { title: 'Double Vanities', _id: 'Double Vanities' },
  { title: 'Eat In Kitchen', _id: 'Eat In Kitchen' },
  { title: 'Entry Level Bedroom', _id: 'Entry Level Bedroom' },
  { title: 'Garage Door Opener', _id: 'Garage Door Opener' },
  { title: 'Garage Remote', _id: 'Garage Remote' },
  { title: 'Granite Counters', _id: 'Granite Counters' },
  { title: 'Hardwood Floors', _id: 'Hardwood Floors' },
  { title: 'Kitchen Breakfast Bar', _id: 'Kitchen Breakfast Bar' },
  { title: 'Kitchen Island', _id: 'Kitchen Island' },
  { title: 'Open Floor Plan', _id: 'Open Floor Plan' },
  { title: 'Pendant Lights', _id: 'Pendant Lights' },
  { title: 'Quartz Counters', _id: 'Quartz Counters' },
  { title: 'Recessed Lighting', _id: 'Recessed Lighting' },
  { title: 'Separate Vanities', _id: 'Separate Vanities' },
  { title: 'Stainless Steel Appliances', _id: 'Stainless Steel Appliances' },
  { title: 'Tile Backsplash', _id: 'Tile Backsplash' },
  { title: 'Vaulted Ceiling', _id: 'Vaulted Ceiling' },
  { title: 'Walk-In Closets', _id: 'Walk-In Closets' },
  { title: 'Wall to Wall Carpeting', _id: 'Wall to Wall Carpeting' },
  { title: 'Wet Bar', _id: 'Wet Bar' },
];

export const countyToMarket = [
  {
    state: 'Alabama',
    state_code: 'AL',
  },
  {
    state: 'Alaska',
    state_code: 'AK',
  },
  {
    state: 'American Samoa',
    state_code: 'AS',
  },
  {
    state: 'Arizona',
    state_code: 'AZ',
  },
  {
    state: 'Arkansas',
    state_code: 'AR',
  },
  {
    state: 'California',
    state_code: 'CA',
  },
  {
    state: 'Colorado',
    state_code: 'CO',
  },
  {
    state: 'Connecticut',
    state_code: 'CT',
  },
  {
    state: 'Delaware',
    state_code: 'DE',
  },
  {
    state: 'Federated States Of Micronesia',
    state_code: 'FM',
  },
  {
    state: 'Florida',
    state_code: 'FL',
  },
  {
    state: 'Georgia',
    state_code: 'GA',
  },
  {
    state: 'Guam',
    state_code: 'GU',
  },
  {
    state: 'Hawaii',
    state_code: 'HI',
  },
  {
    state: 'Idaho',
    state_code: 'ID',
  },
  {
    state: 'Illinois',
    state_code: 'IL',
  },
  {
    state: 'Indiana',
    state_code: 'IN',
  },
  {
    state: 'Iowa',
    state_code: 'IA',
  },
  {
    state: 'Kansas',
    state_code: 'KS',
  },
  {
    state: 'Kentucky',
    state_code: 'KY',
  },
  {
    state: 'Louisiana',
    state_code: 'LA',
  },
  {
    state: 'Maine',
    state_code: 'ME',
  },
  {
    state: 'Marshall Islands',
    state_code: 'MH',
  },
  {
    state: 'Maryland',
    state_code: 'MD',
  },
  {
    state: 'Massachusetts',
    state_code: 'MA',
  },
  {
    state: 'Michigan',
    state_code: 'MI',
  },
  {
    state: 'Minnesota',
    state_code: 'MN',
  },
  {
    state: 'Mississippi',
    state_code: 'MS',
  },
  {
    state: 'Missouri',
    state_code: 'MO',
  },
  {
    state: 'Montana',
    state_code: 'MT',
  },
  {
    state: 'Nebraska',
    state_code: 'NE',
  },
  {
    state: 'Nevada',
    state_code: 'NV',
  },
  {
    state: 'New Hampshire',
    state_code: 'NH',
  },
  {
    state: 'New Jersey',
    state_code: 'NJ',
  },
  {
    state: 'New Mexico',
    state_code: 'NM',
  },
  {
    state: 'New York',
    state_code: 'NY',
  },
  {
    state: 'North Carolina',
    state_code: 'NC',
  },
  {
    state: 'North Dakota',
    state_code: 'ND',
  },
  {
    state: 'Northern Mariana Islands',
    state_code: 'MP',
  },
  {
    state: 'Ohio',
    state_code: 'OH',
  },
  {
    state: 'Oklahoma',
    state_code: 'OK',
  },
  {
    state: 'Oregon',
    state_code: 'OR',
  },
  {
    state: 'Palau',
    state_code: 'PW',
  },
  {
    state: 'Pennsylvania',
    state_code: 'PA',
  },
  {
    state: 'Puerto Rico',
    state_code: 'PR',
  },
  {
    state: 'Rhode Island',
    state_code: 'RI',
  },
  {
    state: 'South Carolina',
    state_code: 'SC',
  },
  {
    state: 'South Dakota',
    state_code: 'SD',
  },
  {
    state: 'Tennessee',
    state_code: 'TN',
  },
  {
    state: 'Texas',
    state_code: 'TX',
  },
  {
    state: 'Utah',
    state_code: 'UT',
  },
  {
    state: 'Vermont',
    state_code: 'VT',
  },
  {
    state: 'Virgin Islands',
    state_code: 'VI',
  },
  {
    state: 'Virginia',
    state_code: 'VA',
  },
  {
    state: 'Washington',
    state_code: 'WA',
  },
  {
    state: 'Washington DC',
    state_code: 'DC',
  },
  {
    state: 'West Virginia',
    state_code: 'WV',
  },
  {
    state: 'Wisconsin',
    state_code: 'WI',
  },
  {
    state: 'Wyoming',
    state_code: 'WY',
  },
];
/* Exterior */
export const Exterior = [
  { title: 'Brick', _id: 'Brick' },
  { title: 'Vinyl', _id: 'Vinyl' },
  { title: 'Aluminum', _id: 'Aluminum' },
  { title: 'Metal', _id: 'Metal' },
  { title: 'Wood', _id: 'Wood' },
  { title: 'Stone', _id: 'Stone' },
  { title: 'Stucco', _id: 'Stucco' },
  { title: 'Hardie Plank', _id: 'Hardie Plank' },
  { title: 'Fiber Cement', _id: 'Fiber Cement' },
  { title: 'Other', _id: 'Other' },
];
/* Back Yard */
export const BackYard = [
  { title: 'Fully Fenced', value: 'Fully Fenced' },
  { title: 'Partially Fenced', value: 'Partially Fenced' },
  { title: 'Rear Fence', value: 'Rear Fence' },
  { title: 'Privacy Fence', value: 'Privacy Fence' },
  { title: 'No Fence', value: 'No Fence' },
];

/* DECK */
export const DECK = [
  { title: 'Single', value: 'Single' },
  { title: 'Double', value: 'Double' },
  { title: 'Tiered', value: 'Tiered' },
  { title: 'None', value: 'None' },
];
/* Porch */
export const PORCH = [
  { title: 'Front', value: 'Front' },
  { title: 'Rear', value: 'Rear' },
  { title: 'Side', value: 'Side' },
  { title: 'Wrap Around', value: 'Wrap Around' },
  { title: 'None', value: 'None' },
];
/* VIEW */
export const VIEW = [
  { title: 'Trees/Woods', _id: 'Trees/Woods' },
  { title: 'Lake', _id: 'Lake' },
  { title: 'Ocean', _id: 'Ocean' },
  { title: 'Canal', _id: 'Canal' },
  { title: 'City', _id: 'City' },
  { title: ' Courtyard', _id: ' Courtyard' },
  { title: 'Creek/Stream', _id: 'Creek/Stream' },
  { title: 'Garden/Lawn', _id: 'Garden/Lawn' },
  { title: 'Golf Course', _id: 'Golf Course' },
  { title: 'Harbour', _id: 'Harbour' },
  { title: 'Limited', _id: 'Limited' },
  { title: 'Marina', _id: 'Marina' },
  { title: 'Mountain', _id: 'Mountain' },
  { title: 'Panoramic', _id: 'Panoramic' },
  { title: 'Pasture', _id: 'Pasture' },
  { title: 'Pond', _id: 'Pond' },
  { title: 'Scenic Vista', _id: 'Scenic Vista' },
  { title: 'Street', _id: 'Street' },
  { title: 'Valley', _id: 'Valley' },
  { title: 'Water', _id: 'Water' },
  { title: 'Other', _id: 'Other' },
];
/* HOAFeePeriod */
export const HOAFeePeriod = [
  { title: 'Monthly', value: 'Monthly' },
  { title: 'Quarterly', value: 'Quarterly' },
  { title: 'Semi-Annually', value: 'Semi-Annually' },
  { title: 'Annually', value: 'Annually' },
  { title: 'None', value: 'None' },
];
/* COAFeePeriod */
export const COAFeePeriod = [
  { title: 'Monthly', value: 'Monthly' },
  { title: 'Quarterly', value: 'Quarterly' },
  { title: 'Semi-Annually', value: 'Semi-Annually' },
  { title: 'Annually', value: 'Annually' },
  { title: 'None', value: 'None' },
];

export const PropertyStatus = [
  { label: 'For Sale', value: 1 },
  { label: 'Sold', value: 3 },
  { label: 'Pending', value: 2 },
];
/* State */
export const stateList = [
  { title: 'AL', value: 'AL' },
  { title: 'AK', value: 'AK' },
  { title: 'AZ', value: 'AZ' },
  { title: 'AR', value: 'AR' },
  { title: 'CA', value: 'CA' },
  { title: 'CO', value: 'CO' },
  { title: 'CT', value: 'CT' },
  { title: 'DE', value: 'DE' },
  { title: 'FL', value: 'FL' },
  { title: 'GA', value: 'GA' },
  { title: 'HI', value: 'HI' },
  { title: 'ID', value: 'ID' },
  { title: 'IL', value: 'IL' },
  { title: 'IN', value: 'IN' },
  { title: 'IS', value: 'IS' },
  { title: 'KA', value: 'KA' },
  { title: 'KY', value: 'KY' },
  { title: 'LA', value: 'LA' },
  { title: 'ME', value: 'ME' },
  { title: 'MD', value: 'MD' },
  { title: 'MA', value: 'MA' },
  { title: 'MI', value: 'MI' },
  { title: 'MN', value: 'MN' },
  { title: 'MS', value: 'MS' },
  { title: 'MO', value: 'MO' },
  { title: 'MT', value: 'MT' },
  { title: 'NE', value: 'NE' },
  { title: 'NV', value: 'NV' },
  { title: 'NH', value: 'NH' },
  { title: 'NJ', value: 'NJ' },
  { title: 'NM', value: 'NM' },
  { title: 'NY', value: 'NY' },
  { title: 'NC', value: 'NC' },
  { title: 'ND', value: 'ND' },
  { title: 'OH', value: 'OH' },
  { title: 'OK', value: 'OK' },
  { title: 'OR', value: 'OR' },
  { title: 'PA', value: 'PA' },
  { title: 'RI', value: 'RI' },
  { title: 'SC', value: 'SC' },
  { title: 'SD', value: 'SD' },
  { title: 'TN', value: 'TN' },
  { title: 'TX', value: 'TX' },
  { title: 'UT', value: 'UT' },
  { title: 'VT', value: 'VT' },
  { title: 'VA', value: 'VA' },
  { title: 'WA', value: 'WA' },
  { title: 'WV', value: 'WV' },
  { title: 'WI', value: 'WI' },
  { title: 'WY', value: 'WY' },
  { title: 'DC', value: 'DC' },
];

export const ACTIVESTATUS = [{ title: 'Active' }];
export const INACTIVESTATUS = [{ title: 'Inactive', value: 'false' }];

export const POSITIONS = [
  { title: 'Header', value: 'header' },
  { title: 'Footer', value: 'footer' },
  { title: 'Both', value: 'both' },
];
export const AdPOSITION = [
  { title: 'Header', value: 'header' },
  { title: 'Footer', value: 'footer' },
  { title: 'In Page', value: 'inpage' },
  { title: 'Sidebar', value: 'sidebar' },
];
export const AdTYPE = [
  { title: 'Internal', value: 'internal' },
  { title: 'External', value: 'external' },
];

/**CUSTOMER ROLE OPTIONS */
export const Customer_Role_OPTIONS = [
  { title: 'Sign TRF', _id: 'SIGN_TRF' },
  { title: 'Payment', _id: 'PAYMENT' },
  { title: 'Share', _id: 'SHARE' },
];

/**
 * Review rating star options
 */
export const RatingStars = [
  { title: '1 Star', value: 1 },
  { title: '2 Star', value: 2 },
  { title: '3 Star', value: 3 },
  { title: '4 Star', value: 4 },
  { title: '5 Star', value: 5 },
];
