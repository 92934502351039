import React, { Component } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import List from '../../common/list';
import TT from '../../common/tooltip';
import { Button } from 'react-bootstrap/lib/InputGroup';
import Modal from "../../common/modalTopArticles";

class ROW extends Component {

  constructor(props) {
    super(props);

    this.state = {
        show: false
    };
   
      
    /*bind this with methods*/
    this.showModal = this.showModal.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.closeModal = this.closeModal.bind(this);
}

  render() {
    const { role } = this.props;
    const permissionsStyle = {
      maxWidth: '750px',
      wordBreak: 'break-all',
    };

    return (
      <tr className="odd gradeX">
        <td> {role.title} </td>
        <td className="center">
        {role.position}
        </td>
        {/* <td>
          <Moment format="MM/DD/YY">
            {moment(role.created_at).zone('-04:00')}
          </Moment>
        </td>
        <td>
          <Moment format="MM/DD/YY">
            {moment(role.updated_at).zone('-04:00')}
          </Moment>
        </td> */}
        <td className="center">
          {' '}
          <label
            className={role.status ? 'label label-info' : 'label label-danger'}
          >
            {role.status ? 'Active' : 'Inactive'}
          </label>{' '}
        </td>
        <td>
          {!role.preBuilt ? (
            <div>
            <TT tooltip="Edit">
              <Link
                to={'/footer-pages/edit/' + role._id}
                className="btn btn-xs grey-mint"
              >
                <i className="fa fa-pencil-square-o"></i>
              </Link>
            </TT>
            <TT tooltip="Delete">
              <a
                onClick={this.showModal}
                className="btn btn-xs red-mint"
              >
                <i className="fa fa-trash"></i>
              </a>
            </TT>
            {role.position > 1 ?(<TT tooltip="Move Up">
            <span
              className="btn btn-xs grey-mint"
              onClick={()=>this.moveUp(role)}
              // disabled ={}
            >
              <i className="fa fa-chevron-up"></i>
            </span>
          </TT>):('')}
          {role.position < this.props.totalCount  ?(<TT tooltip="Move Down">
          <span 
            className="btn btn-xs grey-mint"
            onClick={()=>this.moveDown(role)}
            // disabled ={}
          >
            <i className="fa fa-chevron-down"></i>
          </span>
          </TT>):('')}
          </div>
          ) : (
            ''
          )}
        {this.state.show && (
              <Modal
                  show={true}
                  func={this.deleteRow}
                  closeParentModal={this.closeModal}
              />
          )}
        </td>
      </tr>
    );
  }

  moveUp(data){
    this.props.moveUp(data)
  }

  moveDown(data){
    this.props.moveDown(data)
  }

  deleteRow() {
    /*delete row by call prop func*/
    this.props.deleteFooterLink(this.props.role._id,this.props.role.position);}

  showModal() {
      /*show popup and confirm before delete*/
      this.setState({ show: true });
  }

  closeModal() {
      this.setState({ show: false });
  }
}

export default ROW;
