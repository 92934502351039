import moment from "moment-timezone";
import React, { Component } from 'react';
import Detail from './detail';
class ROW extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    render() {
        const { log } = this.props;
        let urlRegex = '';
        urlRegex = /(?:https:\/\/(?:housebuyersofamericainc|hboadev).crm.dynamics.com\/api\/data\/v8.2)(\/[\w]*)/;
        const slug = log.url;
        const rowStyle = {
            verticalAlign: 'baseline'
        }
        const urlStyle = {
            minWidth: '120px',
            maxWidth: '150px'
        };
        const payloadStyle = {
            wordBreak: 'break-all'
        }
        return (
            <React.Fragment>
                <tr className="odd gradeX" style={{...rowStyle}} onClick={() => this.setState({open: !this.state.open})}>
                    <td style={{minWidth: '170px'}}>{this.dynamicsLogsDST(log.created_at)}
                    </td>
                    <td style={{...urlStyle}}><span className="wordWrap">{slug} </span> </td>
                    <td style={{minWidth: '60px'}}>{log.type}</td>
                    <td>{log.statusCode}</td>
                    <td style={{minWidth: '100px'}}>{log.message
                        ? log.message
                        : 'N/A'}</td>
                    <td style={{...payloadStyle}}> {log.payload ? log.payload.substring(0,300): 'N/A'} </td>
                </tr>
                {this.state.open && (
                    <Detail url={log.url} payload={log.payload}></Detail>
                )}
            </React.Fragment>
        );
    }

    dynamicsLogsDST = time => {
        let newTime = "";
        let d = moment(time);

        if (d.isDST()) {
            console.log("dynamics logs dst time", d.isDST())
            newTime = moment(time)
                .format("MM/DD/YY h:mm:ss a");
        } else {
            console.log('dynamics logs not dst time', d.isDST())
            newTime = moment(time)
                .format("MM/DD/YY h:mm:ss a");
        }
        return newTime;
    }
}


export default ROW;

