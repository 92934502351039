
import {  Renovated_Flyer_Info} from "../components/common/actions";

const initState = {
};

const _reducer = "_reducer";
export default function renovatedFlyer(state = initState, data) {
    switch (data.type) {
        
        case Renovated_Flyer_Info: {
            return data.data
        }
        default: {
            return state;
        }
    }
}