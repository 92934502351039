import React, { Component } from 'react';
import { Modal, Button,Alert } from 'react-bootstrap';

/**
 * send props 'show={true }' to show the component
 * 'func' the delete function in the parent component
 * closeParentModal() to cahnge this.sate.show=false in parent function
 *  <ShowPopup show={true} func={this.deleteProfileImage}
 */

class ShowPopup extends Component {
   constructor(props){
       super(props);
       this.state={
           show:true
       }

       this.closeModel = this.closeModel.bind(this);
       this.deletefunc = this.deletefunc.bind(this);
   }
    componentWillMount(){
    }

    deletefunc(){
        /**to close model */
        this.setState({ show: false });
        /**function is called which is to be execute on ok */
        this.props.func(); 
    }
    
    closeModel(){
        /**to close modal */
        this.setState({show:false});
        this.props.closeParentModal();
    }

    render() {
        const {message ,footer, errorModal} =this.props;
        return (
            <div>
                <Modal 
                show={this.state.show}
                onHide={this.closeModel}
                >
                    <Modal.Header closeButton className='theme-bg'>
                        <Modal.Title>
                       
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <Alert bsStyle="info" style={{"margin":"0"}}>
                                  <strong>Warning:</strong>
                                  <span>{message}</span>
                        </Alert> 
                    </Modal.Body>

                    <Modal.Footer style={{paddingTop:"0"}}>
                        <span>{footer}</span>
                    </Modal.Footer>
                </Modal>
            </div>
            
        );
    }
}
export default ShowPopup;