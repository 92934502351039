import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { Field, reduxForm } from 'redux-form';
import HTTP from "../../../services/http";
import { ADMIN_TRACK_AUDIT_LOGS } from '../../common/actions';
import { required } from '../../common/fieldValidations';
import Loader from "../../common/loader";
import PageHeader from "../../common/pageheader";
/**COMPONENT */
import RenderFiled from "../../common/renderField";



class AddThirdPartyCredential extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            formAction: "ADD",
            status: true
        }
        /**event binding  */
        this.addEditCredential = this.addEditCredential.bind(this);
        this.getCredential = this.getCredential.bind(this);
    }

    componentWillMount() {
        this.getCredential()
    }

    render() {
        const { handleSubmit } = this.props;
        const {  isLoading, formAction, status } = this.state
        return (
            <div>
                {isLoading ? <Loader /> : <div><PageHeader route={formAction === "ADD" ? "Add New Credential" : "Edit Credential"} parent="Third Party Management" parentRoute="/third-party-services" />

                    <div className="tab-pane active" >
                        <form onSubmit={handleSubmit(this.addEditCredential)}  >
                            <Field
                                name="service"
                                fieldName= "Service"
                                disabled={formAction === "ADD" ? false: true}
                                component={RenderFiled}
                                validate={[required]}
                            />
                            <Field
                                name="username"
                                fieldName= "Username"
                                component={RenderFiled}
                            />
                            <Field
                                name="password"
                                fieldName="Password"
                                type="password"
                                component={RenderFiled}
                            />
                            <Field
                                name="api_key"
                                fieldName="API Key"
                                type="text"
                                component={RenderFiled}
                            />
                            <Field
                                name="api_secret"
                                fieldName="API Secret"
                                type="text"
                                component={RenderFiled}
                            />
                            <Field
                                name="clientId"
                                fieldName="Client ID"
                                type="text"
                                component={RenderFiled}
                            />
                            <Field
                                name="clientSecret"
                                fieldName="Client secret"
                                type="text"
                                component={RenderFiled}
                            />
                            <Field
                                name="access_token"
                                fieldName="Access token"
                                type="text"
                                component={RenderFiled}
                            />

                            <br />
                            <div className="form-actions">
                                <button type="submit" className="btn green uppercase" disabled={this.props.invalid || this.props.submitting}>
                                {formAction === 'ADD' ? 'Add' : 'Update'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                }

            </div>
        );
    }

    addEditCredential(data) {
        const url = data._id ? window.admin.editThirdPartyCredential: window.admin.addThirdPartyCredential;
        HTTP.Request("post", url, data)
            .then(result => {
                this.props.dispatch(push("/third-party-services"));
                toast(result.message, { type: "success" });

                /*log audits for user*/
                this.props.dispatch({
                    type: ADMIN_TRACK_AUDIT_LOGS,
                    action: {
                        comment: "Changed password of service - " + result.data.service,
                        type: "audit"
                    }
                });
            })
            .catch(err => {
                if (err && err.errors.length > 0) err.errors.map(message => toast(message, { type: "error" }))
                else toast(err.message, { type: 'error' })
            });
    }

    getCredential() {
        const { match, initialize } = this.props;
        /*extract plant id from request*/
        let credentialsID = (match.params.id) ? match.params.id : null;

        if (credentialsID) {
            this.setState({ isLoading: true, formAction: "EDIT" })
            HTTP.Request("get", window.admin.getThirdPartyCredential, { id: credentialsID })
                .then(result => {
                    this.setState({ isLoading: false})

                    /*set data to form*/
                    initialize(result.data);
                })
        }
    }
}

//decorate form component
let thirdPartyCredentialForm = reduxForm({
    form: "thirdPartyCredentialForm",
})(AddThirdPartyCredential);


export default thirdPartyCredentialForm;

