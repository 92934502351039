import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import Moment from "react-moment";
import { Panel, Table } from "react-bootstrap";

import { required, ValidateOnlyAlpha } from "../common/fieldValidations";

/**COMPONENT */
import RenderFiled from "../common/renderField";
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multiselect";
import Loader from "../common/loader";
import DropdownComp from "../common/DropdownList";
import infoOf from "../common/tooltip-text";

/**CONSTANT DATA */
import { OPTIONS } from "../common/options";

class ViewGetOffer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            status: true,
            market: {},
            adrs:""
        };
        /**event binding  */
        this.getList = this.getList.bind(this);
    }

    componentWillMount() {
        this.getList();
    }
    render() {
        const { isLoading, market, status, adrs } = this.state;
        return (
            <div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <PageHeader
                            route={"View Lead"}
                            parent="Application Received"
                            parentRoute="/career"
                        />
                        <div className="tab-pane active">
                            {/* payment details  start */}
                            <div>
                                <Panel bsStyle="info">
                                    <Panel.Heading>View Lead</Panel.Heading>
                                    <Panel.Body>
                                        <Table striped bordered condensed hover>
                                            <tbody>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>First Name</strong>
                                                    </td>
                                                    <td>{market.firstname}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>
                                                            Last Name
                                                        </strong>
                                                    </td>
                                                    <td>{market.lastname}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Day Time Mobile</strong>
                                                    </td>
                                                    <td>{market.dayTimePhone}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Mobile</strong>
                                                    </td>
                                                    <td>{market.phone ?market.phone: "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Email</strong>
                                                    </td>
                                                    <td>{market.email}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Address</strong>
                                                    </td>
                                                    <td>{market.address ? market.address : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>City</strong>
                                                    </td>
                                                    <td>{market.city ? market.city : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>State</strong>
                                                    </td>
                                                    <td>{market.state ? market.state : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Zip Code</strong>
                                                    </td>
                                                    <td>{market.zip ? market.zip : "NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Comment</strong>
                                                    </td>
                                                    <td>{(market.comment=="null") ?  "NA":market.comment}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>Resume</strong>
                                                    </td>
                                                    <td>{market.file ? <a href={market.file.secure_url} target="_blank"><i className="fa fa-download">Download</i></a>:"NA"}</td>
                                                </tr>
                                                <tr>
                                                    <td width="20%">
                                                        <strong>
                                                            Date
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        <Moment format="MM/DD/YY hh:mm:ss A">
                                                                {market.ts ? market.ts : market.created_at}
                                                        </Moment>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Panel.Body>
                                </Panel>
                            </div>
                            {/* payment details  end */}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    getList(params = {}) {
        /**to start Loader */
        this.setState({ isLoading: true });
        let id = this.props.match.params;
        HTTP.Request("get", window.admin.careerView, id)
            .then(result => {
                this.setState({
                    isLoading: false,
                    market: result.data,
                });
            })
            .catch(err => this.setState({ isLoading: false }));
    }
}

export default ViewGetOffer;
