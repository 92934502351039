import React, { Component } from 'react';
import { HelpBlock, Table } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { toast } from 'react-toastify';
import { Alert } from "react-bootstrap";
import MongotoCrmROW from './montocrmRow';
import Loader from '../../common/loader';
import HTTP from '../../../services/http';
// import filterForm from '../../common/filterForm';

import { connect } from "react-redux";
import FilterForm from '../../common/filterForm';
class MongoToCrm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: [],
            isLoading: false,
            searchQuery: '',
            activePage: 1,
            totalItemsCount: 1,
            page: 1,
            noOfRecords: 10,
            logsArray: [],
            isFilter: false
        }
        /**event binding */
        // this.getmongoLogs = this.getmongoLogs.bind(this);
        this.submitFilters = this.submitFilters.bind(this);
        // // this.exportCsv = this.exportCsv.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.resetFilters = this.resetFilters.bind(this);

    }
    // componentDidMount() {
    //     this.getmongoLogs()

    // }
    componentWillReceiveProps(nextprops) {
        // console.log("nextprops", nextprops);
        this.setState({
            totalItemsCount: nextprops.logsCount
        })
    }
    componentWillUpdate() {
        // console.log("update state", this.props);

        if (!isNaN(this.props.logsCount) && this.props.logsCount != this.state.totalItemsCount) {
            this.setState({
                totalItemsCount: this.props.logsCount
            })
        }

    }
    reset = () => {
        this.setState({
            logsArray: [],
            isFilter: false
        })
    }
    onChange = (value) => {
        this.setState({
            noOfRecords: value.limit
        });
    }
    render() {

        const { logsArray, noOfRecords, isLoading, filterData } = this.state;
        
        return(
            <div>
            {isLoading && <Loader />} 
                <div>

                    {/* <h4>hello</h4> */}

                    <div className="portlet light bordered">
                        <div className="portlet-body">

                            <FilterForm
                                submitFunction={this.submitFilters}
                                reset={this.reset}
                                onChange={this.onChange}
                                resetFunction={this.resetFilters}
                                limitComp
                            />

                            {(logsArray.length == 0 && this.state.isFilter) ?
                                <Alert bsStyle="warning">
                                    <strong>No Data Found !</strong>
                                </Alert> :
                                <div style={{ display: "flow-root" }}>

                                    <Table responsive striped bordered condensed hover>
                                        <thead>
                                            <tr>
                                                <th width="22%"> Created At </th>
                                                {/* <th width="18%"> Password </th> */}
                                                <th> Number of Records Added </th>
                                                <th> Number of Records Updated </th>

                                            </tr>
                                        </thead>

                                        <tbody>

                                            {/* {array.map(log => { */}

                                            <MongotoCrmROW isFilter={this.state.isFilter} filterData={this.state.logsArray} activePage={this.state.activePage} sizePerPage={this.state.noOfRecords} />

                                            {/* })} */}
                                        </tbody>
                                    </Table>
                                    <div style={{ float: "right" }}>
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={filterData ? filterData.limit : window.limit}
                                            totalItemsCount={this.state.totalItemsCount}
                                            pageRangeDisplayed={3}
                                            onChange={this.handlePageChange}
                                        />
                                    </div>
                                </div>}


                        </div>
                    </div>

                </div>
                </div>
        )
    }

    handlePageChange(eventKey) {
        this.setState({ activePage: eventKey });
        let { filterData } = this.state;
        this.getmongoLogs({
            page: eventKey ? eventKey : 1,
            ...filterData
        });
    }
    submitFilters(filterData) {
        this.setState({ filterData, isFilter: true, activePage: 1 });
        this.getmongoLogs({
            page: 1,
            ...filterData
        });
        this.props.history.push({
            search: '?page=' + 1
        });
    }

    /* To reset all filters */
    resetFilters() {
        this.setState({ activePage: 1, seracherror: '', searchQuery: '' });
        let { activePage } = this.state;
        let searchEle = document.getElementById('search_field');
        if (searchEle) {
            searchEle.value = '';
        }
        this.getmongoLogs({
            page: activePage || 1
        });
    }

    getmongoLogs(data) {
        /**to start Loader */
        this.setState({  isLoading: true })

        HTTP.Request("get", window.admin.mongotoCrmLog, data)
        .then((response) => {
            this.setState({logsArray: (response.data.logs)?response.data.logs:[],totalItemsCount: response.data.count, isLoading: false});
        })
        .catch(error => {
            this.setState({logsArray : [], isLoading:false, totalItemsCount: 0 });
        });
    }
}

function mapstateToProps(state) {
    /**to get value of dropdown */
    // console.log("state ", state);
    const { logsCount } = state
    return { logsCount: logsCount }
}

export default connect(mapstateToProps)(MongoToCrm);
