import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import ImageCropper from "../common/ImageCropper";

import { required, ValidateOnlyAlpha } from "../common/fieldValidations";

/**COMPONENT */
import RenderFiled from "../common/renderField";
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multiselect";
import Loader from "../common/loader";
import DropdownComp from "../common/dropdown_v2";
import infoOf from "../common/tooltip-text";
import DropdownComp2 from "../common/DropdownList";


/**CONSTANT DATA */
import { OPTIONS ,countyToMarket} from "../common/options";

class AddMarket extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            formAction: "ADD",
            status: true,
            countyToMarketSortByCode: []
        };
        /**event binding  */
        this.formSubmit = this.formSubmit.bind(this);
        this.getAgent=this.getAgent.bind(this);
        this.onChangeState = this.onChangeState.bind(this);
    }

    componentWillMount() {
        this.getAgent()
    }

    render() {
        const { handleSubmit } = this.props;
        const { isLoading, formAction, status,marketImage, countyToMarketSortByCode, stateCounties} = this.state;
        return (
            <div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <PageHeader
                            route={
                                formAction === "ADD" ? "Add New" : "Edit Role"
                            }
                            parent="County List Management"
                            parentRoute="/market-management-list"
                        />

                        <div className="tab-pane active">
                            <form onSubmit={handleSubmit(this.formSubmit)}>
                                <Field
                                    name="state_code"
                                    textField="state_code"
                                    valueField="state_code"
                                    label="State*"
                                    component={DropdownComp}
                                    onChangeState={this.onChangeState}
                                    placeholder="-select-"
                                    validate={[required]}
                                    options={countyToMarketSortByCode}
                                />
                                {stateCounties && <Field
                                    name="county"
                                    textField="title"
                                    valueField="title"
                                    label="County*"
                                    component={DropdownComp}
                                    placeholder="-select"
                                    validate={[required]}
                                    options={stateCounties}
                                />}
                                <Field
                                    name="market"
                                    textField="name"
                                    valueField="name"
                                    label="Market"
                                    component={DropdownComp}
                                    placeholder="-select-"
                                    validate={required}
                                    options={marketImage}
                                />
                                <Field
                                    name="status"
                                    options={[
                                        { label: "Active", value: true },
                                        { label: "Inactive", value: false }
                                    ]}
                                    label="Status"
                                    defaultValue={
                                        status ? "Active" : "Inactive"
                                    }
                                    textField="label"
                                    valueField="value"
                                    component={DropdownComp2}
                                />
                                <br />
                                <div className="form-actions">
                                    <button
                                        type="submit"
                                        className="btn green uppercase"
                                        disabled={
                                            this.props.invalid ||
                                            this.props.submitting
                                        }
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    getAgent(){
        this.setState({ isLoading: true });
        const countyToMarketSortByCode = countyToMarket.sort(
            (stateA,stateB) =>  stateA.state_code > stateB.state_code ? 1 : -1
        );
        HTTP.Request("get", window.admin.agentsDynamic,{})
            .then(result => {
                console.log("resultAgent",result)
                this.setState({
                    isLoading: false,
                    selectedAgent: result.data?result.data.agent:[],
                    marketImage:result.data?result.data.market:[],
                    countyToMarketSortByCode: countyToMarketSortByCode
                })
            })
    }

    onChangeState(event) {
        HTTP.Request("get", window.admin.countyListByState, { state: event })
            .then(result => {
                let countyImageArray = [];
                if (result.data && result.data.result && result.data.result.length) {
                    countyImageArray = result.data.result.map((i) => {
                    return { title: i.COUNTYNAME, _id: i._id };
                    })
                }
                const sortedCounties = countyImageArray.sort(
                    (countyA,countyB) =>  countyA.title > countyB.title ? 1 : -1
                );
                this.setState({
                    stateCounties: sortedCounties
                });
                const { change } = this.props;
                change('county', null);
            }).catch(err => {
                console.log(err);
            });
    };

    formSubmit(values) {

        this.setState({ isLoading: true });
        const { state_code, county, market, status } = values;
        const selectedState = countyToMarket.find(state => state.state_code === state_code);
        values.state = selectedState.state;
        values.state_code = state_code.toUpperCase();
        values.state_code = state_code.toUpperCase();
        delete values.updated_at
        HTTP.Request("post", window.admin.addMarket, values)
            .then(result => {
             console.log(result);
                if (result.success === true) {

                     toast(result.message, { type: "success" });
                     this.props.dispatch(push("/market-management-list"));
                } else {
                    toast(result.message, { type: "error" });

                }

                this.setState({ isLoading: false });
            })
            .catch(error => {
                toast(error.message, { type: "error" });
                this.setState({ isLoading: false });
            });
    }
}

//decorate form component
let MarketMang_Form = reduxForm({
    form: "MarkMang_Form"
})(AddMarket);

export default MarketMang_Form;
