import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

class OfferROW extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      showInfo: false,
    };
    /*bind this with methods*/
    // this.showModal = this.showModal.bind(this);
    this.showInfoModal = this.showInfoModal.bind(this);
    this.restore = this.restore.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  render() {
    const { offer, isRedirect } = this.props;
    console.log('isRedirect', isRedirect);
    return (
      <tr
        className="odd gradeX"
        style={{
          backgroundColor: offer.status == 'DECLINED' ? '#f7d0d0' : '',
        }}
      >
        <td>
          {isRedirect ? (
            offer &&
            offer.property &&
            offer.property.street_address.split(',')[0]
          ) : (
            <Link to={'/update-property/' + offer.property_id}>
              {offer.property.street_address.split(',')[0]}
            </Link>
          )}
          <br />
          <span>Buyer Company: </span>
          {offer && offer.buyer_company}
          <br />
          <p>
            <span>Buyer Name: </span>
            {offer && offer.buyer}
          </p>
          {offer.proof_of_fund && offer.proof_of_fund.url && (
            <p>
              <i>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={offer.proof_of_fund.url}
                >
                  Download proof of funds
                </a>
              </i>
            </p>
          )}
        </td>
        <td className="center" style={{ 'text-transform': 'capitalize' }}>
          {' '}
          {offer && offer.buyer}{' '}
        </td>
        <td>{offer && '$' + offer.price}</td>
        <td className="center">{offer && '$' + offer.emd_amount}</td>
        <td className="center">{offer && offer.status}</td>

        <td>
          <Moment format="MM/DD/YY">
            {moment(offer?.created_at).zone('-04:00')}
          </Moment>
        </td>
        <td className="text-center">
          {offer && offer.status === 'NEW' ? (
            <>
              <a
                id={offer._id}
                className="mr-10"
                title="Accept Offer"
                onClick={() => this.props.offerSetllementDialogFun(offer._id)}
              >
                <i className="fa fa-thumbs-up"></i>
              </a>
              <a
                id={offer._id}
                className="mr-10"
                title="Decline Offer"
                onClick={() => this.props.declineOffer(offer._id)}
              >
                <i className="fa fa-thumbs-down"></i>
              </a>
              <a
                id={offer._id}
                className="mr-10"
                title="Delete Offer"
                onClick={() => {
                  this.props.showOfferDeleteModal(offer._id);
                }}
              >
                <i className="fa fa-trash"></i>
              </a>
            </>
          ) : offer && offer.status === 'ACCEPTED' ? (
            <>
              <a
                id={offer._id}
                className="mr-10"
                title="Decline Offer"
                onClick={() => this.props.declineOffer(offer._id)}
              >
                <i className="fa fa-thumbs-down"></i>
              </a>
              <a
                id={offer._id}
                className="mr-10"
                title="Delete Offer"
                onClick={() => {
                  this.props.showOfferDeleteModal(offer._id);
                }}
              >
                <i className="fa fa-trash"></i>
              </a>
            </>
          ) : offer && offer.status === 'DECLINED' ? (
            <a
              id={offer._id}
              className="mr-10"
              title="Delete Offer"
              onClick={() => {
                this.props.showOfferDeleteModal(offer._id);
              }}
            >
              <i className="fa fa-trash"></i>
            </a>
          ) : (
            <>
              <a
                id={offer._id}
                className="mr-10"
                title="Accept Offer"
                onClick={() => this.props.offerSetllementDialogFun(offer._id)}
              >
                <i className="fa fa-thumbs-up"></i>
              </a>
              <a
                id={offer._id}
                className="mr-10"
                title="Decline Offer"
                onClick={() => this.props.declineOffer(offer._id)}
              >
                <i className="fa fa-thumbs-down"></i>
              </a>
              <a
                id={offer._id}
                className="mr-10"
                title="Delete Offer"
                onClick={() => {
                  this.props.showOfferDeleteModal(offer._id);
                }}
              >
                <i className="fa fa-trash"></i>
              </a>
            </>
          )}
        </td>
      </tr>
    );
  }

  showInfoModal() {
    /*show popup and confirm before delete*/
    this.setState({ showInfo: true });
  }

  restore() {
    this.setState({ isLoading: true, show: false });
    /*delete row by call prop func*/
    this.props.restore(this.props.revision._id, this.props.revision.revision);
  }

  closeModal() {
    this.setState({ show: false, showInfo: false });
  }
}

export default OfferROW;
