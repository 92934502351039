import React, { Component } from 'react';
import Modal from '../../../common/deleteModal';
import TT from "../../../common/tooltip";

class ROW extends Component {

  constructor(props){
    super(props);
    this.state={
        show: false
    }
    /*bind this with methods*/
    this.showModal = this.showModal.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    /*show popup and confirm before delete*/
    this.setState({ show: true });
  }

  deleteRow(){
      this.setState({ isLoading: true,show:false });
      /*delete row by call prop func*/
      this.props.delete(this.props.page);
  }

  closeModal() {
      this.setState({ show: false });
  }

  render() {
    const { page } = this.props;

    return (
      <tr className="odd gradeX">
        <td> {page} </td>
        <td>
          <TT tooltip="Remove"><a className=" btn btn-xs red-mint" onClick={this.showModal} title="View"><i className="fa fa-trash no-pointer"  ></i></a></TT>
          {this.state.show && <Modal show={true} func={this.deleteRow} closeParentModal={this.closeModal} /> }
        </td>
      </tr>
    );
  }
}

export default ROW;
