import React, { Component } from 'react';
import { connect } from 'react-redux';

/**COMPONENTS */
import Table from "../element/stagingTable";
import PageHeader from "../../common/pageheader";


/**PAGE LEVEL CSS */
import "../../../assets/css/profile.min.css";

class getOffer extends Component {
    render() {
        return (
            <div>
                <PageHeader pageTitle="Staging Get Offer" route="Staging Get Offer" />
                <div className="profile-content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="portlet light ">
                                <div className="portlet-title tabbable-line">
                                    <Table {...this.props} props={this.props}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default connect()(getOffer);

