import React, { Component } from "react";
import xlsx from 'xlsx'
import { Field, reduxForm } from "redux-form";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import FileInput from "../common/FileInput";
import { required, ValidateOnlyAlpha } from "../common/fieldValidations";
import { Modal, Button,Alert, Table } from 'react-bootstrap';
import test2 from "../../assets/sample.xlsx";
/**COMPONENT */
import RenderFiled from "../common/renderField";
import PageHeader from "../common/pageheader";
import Multiselect from "../common/multiselect";
import Loader from "../common/loader";
import DropdownComp from "../common/DropdownList";
import infoOf from "../common/tooltip-text";
import ROW from "./row";
import $ from "jquery";
import './upload.css';

/**CONSTANT DATA */
import { OPTIONS } from "../common/options";
import { json } from "body-parser";

class importMarket extends Component {


    constructor(props) {
        super(props);
        var wb;
        let i = 0;
        let j = 0;
        this.state = {
            arrayObj:[],
            showList : [],
            response : [],
            notUpload: [],
            show:false,
            issubmitting: false,
            isLoading: false,
            formAction: "ADD",
            status: true
        };
        /**event binding  */
        this.formSubmit = this.formSubmit.bind(this);
        this.bulkupload =  this.bulkupload.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.downloadScipt = this.downloadScipt.bind(this);
        this.downloadSciptError = this.downloadSciptError.bind(this);
        this.formatMarket = this.formatMarket.bind(this);
    }

    componentWillMount() {}

    render() {
        setTimeout(() => {
            $('table').on('scroll', function() {
                $("table > *").width($("table").width() + $("table").scrollLeft());
              });

        }, 2000);


        const pStyle = {
            marginTop: '12px',
          };
          const tHeight = {
            height: '250px',
            overflow:'auto'
          };
          const modalStyle = {
            marginLeft: '18%'
          }
        const { handleSubmit } = this.props;
        const { isLoading, formAction, status, arrayObj, response , notUpload, showList, issubmitting} = this.state;
        return (
            <div>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <PageHeader
                            route={
                                formAction === "ADD" ? "Import" : "Edit Role"
                            }
                            parent="Users"
                            parentRoute="/users"
                        />

                        <div className="tab-pane active">
                            <form onSubmit={handleSubmit(this.formSubmit)}>
                                <Field
                                    name="image"
                                    placeholder="Enter State"
                                    fieldName="State*"
                                    type="file"
                                    component={FileInput}
                                    validate={[required, ValidateOnlyAlpha]}
                                />
                                <br />
                                <div className="form-actions">
                                    <button
                                        type="submit"
                                        className="btn green uppercase"
                                        disabled={
                                            this.props.invalid ||
                                            this.props.submitting
                                        }
                                    >
                                        View File
                                    </button>
                                </div>
                            </form>
                            <div className={pStyle} >
                            <a href={test2} download = 'sample.xlsx'>Download Sample File</a>
                            </div>
                            <div>
                            {this.state.response.length > 0 ?
                            <a onClick={this.downloadScipt}>Download Success</a> : ''}
                              {this.state.notUpload.length > 0 ?
                            <a onClick={this.downloadSciptError}> / Download Failed </a> : ''}

                            </div>
                        </div>
                    </div>
                )}

<Modal
            show={this.state.show}
            onHide={this.closeModel}
            >
                <Modal.Header closeButton className='theme-bg'>
                    <Modal.Title>
                        List of Users  <span style= {modalStyle}> Total Records: {this.state.totalRecords}</span>
                    </Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit(this.bulkupload)}  >
                <Modal.Body>
                <Alert bsStyle="info">
                    <strong>NOTE : </strong>
                    There are  <strong>{this.state.property}</strong> Duplicate Records
                </Alert><br/>
                <div>
                <Table  striped bordered condensed hover className='tableScr'>
                                <thead>
                                    <tr>
                                        <th> firstname </th>
                                        <th> lastname </th>
                                        <th> email </th>
                                        <th> mobile </th>
                                        <th> user_type </th>
                                        <th> renovated_market</th>
                                        <th> renovated_county </th>
                                        <th> register_renovated</th>
                                        <th> register_wholesale </th>
                                        <th> wholesale_market </th>
                                        <th> wholesale_county </th>
                                        <th> communication</th>
                                        <th> source</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {showList.splice(0,10).map((cms, index) => {
                                        return (
                                            <ROW key={index} cms={cms} delete={this.delete} />
                                        )
                                    })}
                                </tbody>
                            </Table>
                </div>
                </Modal.Body>

                <Modal.Footer>
                    {/* <Button className='btn btn-default' onClick={this.closeModel}>Cancel</Button> */}
                    <button type="submit"
                    disabled={
                        this.state.issubmitting
                    }
                    className="btn green uppercase" >{"Submit"}</button>
               {/*      <Button className='btn red-mint' onClick={()=>this.deletefunc(elementID)} >Delete</Button> */}
                </Modal.Footer>
                </form>
            </Modal>
            </div>
        );
    }

    closeModel(){
        /**to close modal */
        this.setState({show:false});
    }

    bulkupload() {
        this.i =0;
        this.j = 0;
        this.setState({issubmitting : true});
        this.setState({ isLoading: true });

     HTTP.Request("post", window.admin.BulkUserInsert, this.state.arrayObj)
     .then(result => {
        if (result.success === true) {
            this.i = result.data.length;
            this.j = result.notUpload.length
        this.setState({
            response: result.data,
            notUpload: result.notUpload
        });
        this.setState({show:false});
        toast(result.message, {type:'success'})

        }
        this.setState({ isLoading: false });
        this.setState({issubmitting : false});

     }).catch(error => {
        this.setState({ isLoading: false });
        this.setState({issubmitting : false});
     })

    }

    downloadScipt() {

        if (this.i>0) {
            this.state.response.map((e)=> {
                this.i = 0;

                delete e.__v;
                delete e.password;
                delete e.trash;
                delete e.status;
                delete e.created_at;
                delete e.updated_at;

                if (e.user_type) {
                    e.user_type = e.user_type.join(',');

                } if (e.wholesale_market) {
                    const formattedMarket = this.formatMarket(e.wholesale_market);
                    e.wholesale_market = formattedMarket.markets;
                    e.wholesale_county = formattedMarket.counties;

                } if(e.renovated_market) {
                    const formattedMarket = this.formatMarket(e.renovated_market);
                    e.renovated_market = formattedMarket.markets;
                    e.renovated_county = formattedMarket.counties;
                } if (e.register_renovated === false) {
                    e.register_renovated = 'false';
                } else if (e.register_renovated === true){
                    e.register_renovated = 'true';
                } else {
                    e.register_renovated = '';
                }
                if (e.register_wholesale === false) {
                    e.register_wholesale = 'false';
                } else {
                    e.register_wholesale = 'true';
                }
            })
        }
        var ws = xlsx.utils.json_to_sheet(this.state.response);
        this.wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(this.wb, ws, "People");
        xlsx.writeFile(this.wb, "download.xlsx");
    }


    formatMarket(marketToFormat) {
        let result = {};
        let markets = [];
        let counties = [];
        if(Array.isArray(marketToFormat)) {
            marketToFormat.forEach(market => {
                if(market.market) {
                    markets.push(market.market);
                    if(counties.length === 0) {
                        counties = [market.counties.join(',')];
                    } else {
                        counties = [...counties,market.counties.join(',')];
                    }
                }
            });
            result.markets = markets.join(',');
            result.counties = counties.join(';');
        }
        return result;
    }

    /* This scipt is used to download the those data which is not uploaded due to error */
    downloadSciptError() {

        if (this.j>0) {
            this.state.response.map((e)=> {
                this.j = 0;
                if (e.register_wholesale === false) {
                    e.register_wholesale = 'false';
                } else {
                    e.register_wholesale = 'true';
                }
            })
        }
        var ws = xlsx.utils.json_to_sheet(this.state.notUpload);
        this.wb = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(this.wb, ws, "People");
        xlsx.writeFile(this.wb, "download.xlsx");
    }

    formSubmit(values) {
        let formData = new FormData();
        formData.append("file", values.image);
        this.setState({ isLoading: true });
        HTTP.Request("post", window.admin.InvesterUploads, formData)
            .then(result => {
                // this.props.dispatch(push("/market-management-list"));
                if (result.success) {
                this.setState({
                  isLoading: false,
                  show: true,
                  property: result.repeat,
                  arrayObj: Array.from(result.newdata),
                  showList: result.newdata,
                  totalRecords: result.totalrecord,
                });

                // toast(result.message, { type: "success" });
                // this.setState({ isLoading: false });
                } else {
                    toast(result.message, { type: "error" });
                    this.setState({ isLoading: false });
                }

            })
            .catch(error => {
                console.log(error);
                // toast(error.message, { type: "error" });
                // this.setState({ isLoading: false });
            });
    }
}

//decorate form component
let MarketMang_Form = reduxForm({
    form: "Markimport_Form"
})(importMarket);

export default MarketMang_Form;
