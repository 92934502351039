import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { push } from 'react-router-redux';
import {  toast } from 'react-toastify';
import HTTP from "../../services/http";
import {Tabs, Tab} from "react-bootstrap";

import { required, ValidateOnlyAlpha } from '../common/fieldValidations';

/**COMPONENT */
import RenderFiled from "../common/renderField";
import PageHeader from "../common/pageheader"; 
import Loader from "../common/loader";
import DropdownComp from "../common/DropdownList";
import Revisions from "./element/revisions";
import Editor from "../common/editor";
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';

/**CONSTANT DATA */
import {STATUS} from "../common/options";
import infoOf from "../common/tooltip-text";

class FAQcategory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading:false,
            formAction :"ADD",
            status:true,
            revisions:[]
        }

        /**event binding  */
        this.upsertCMS = this.upsertCMS.bind(this);
        this.getaCMS = this.getaCMS.bind(this);
    }

    componentWillMount(){
        this.getaCMS();
    }
    
    render() {
        const { handleSubmit } = this.props;
        const { isLoading, formAction, revisions} = this.state
        return (
            <div>

                {isLoading ? <Loader /> : <div><PageHeader route={formAction ==="ADD"?"Add FAQ Category":"Edit FAQ"} parent='FAQs Category' parentRoute="/faq-category-list" />
              
                <div className="tab-pane active" >
                    <Tabs defaultActiveKey={1} animation={false} id="profileTabs" >
                        <Tab eventKey={1} title={formAction ==="ADD"?"Add New FAQ":"Edit FAQ"}>
                            <form onSubmit={handleSubmit(this.upsertCMS)}  >                                             
                                <Field name="faq_category" fieldName="Category Name*" type="text" component={RenderFiled} validate={[required, ValidateOnlyAlpha]} />
                                <Field name="order" icon='fa fa-info-circle' tooltip={infoOf.order} fieldName="Order" type="number" component={RenderFiled} />  
                                <Field name="status"
                                    options={STATUS}
                                    label="Status"
                                    defaultValue={this.state.status ? "Active" : "Inactive"}
                                    textField="title"
                                    valueField="value"
                                    component={DropdownComp}
                                /><br /> 
                                
                                <div className="form-actions">
                                    <button type="submit" className="btn green uppercase" disabled={this.props.invalid || this.props.submitting}>{formAction === "ADD" ? "Add " : "Update"}</button>
                                </div>
                            </form>
                        </Tab>
                        <Tab eventKey={2} title="Revisions">
                            <Revisions revisions={revisions} />
                        </Tab>
                    </Tabs>
                        
                </div>
                </div>
                }

            </div>
        );
    }

    upsertCMS(data) {
        if(data.faq_category=='All' || data.faq_category =='all'){
            toast('Enter valid category', { type: "error" })
            return;
        }

        const { match } = this.props;
        this.setState({ isLoading: true });
        /*bind type of Post*/
        if(data.status === undefined) {
            data.status = true;
        }
        data.type = "FAQ_Category";
        data.title = data.faq_category;
        if (match.params.id) data.editID = match.params.id;

        let formData = new FormData();
        /*add file to request*/
        formData.append("file", this.state.file);
        formData.append("data", JSON.stringify(data));

        this.props.dispatch({
            type: "Admin-upsertFaqCategory",
            data: formData,
            success: (r) => {
                this.props.dispatch(push("/faq-category-list"));
                toast.success(r.message);
                this.setState({ isLoading: false });
                /*log audits for user*/
                this.props.dispatch({
                    type: ADMIN_TRACK_AUDIT_LOGS,
                    action: {
                        comment: "Modified the content of FAQ - " + r.data.title,
                        type: "audit"
                    }
                });
            },
            error: (e) => {
                if (e.errors) {
                    this.setState({ isLoading: false });
                    e.errors.map((error) => toast(error, { type: "error" }))
                }
            }
        });
    }

  
    getaCMS() {
     
        const { match, initialize } = this.props;
        /*extract plant id from request*/
        let cmsID = (match.params.id) ? match.params.id : null;
        if (cmsID) {
            this.setState({ isLoading: true, formAction: "EDIT" })
            HTTP.Request("get", window.admin.getaFaqCategory, { _id: cmsID })
            .then(result => {
                console.log("======result")
                this.setState({ isLoading: false, status: result.data.data.status, revisions: result.data.revisions?result.data.revisions:[],content:result.data.data.content })
                /*set data to form*/
                initialize(result.data.data);
            })
        }
    }

}

//decorate form component
let FAQ_category_Form = reduxForm({
    form: "FAQ_category_Form",
})(FAQcategory);


export default FAQ_category_Form;




















