import Http from '../services/http';

export function getCMSCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getCMS, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function upsertCMSCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertCMS, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function deleteCMSCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("put", window.admin.deleteCMS, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function getReviewCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getReviews, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function upsertReviewCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertReview, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function deleteReviewCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("put", window.admin.deleteReview, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function getBlogCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getBlogs, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function upsertBlogCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertBlog, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function deleteBlogCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("put", window.admin.deleteBlog, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function getPageCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getPages, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function exportData(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.exportCms, data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function deletePageCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("put", window.admin.deletePage, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function getCmsBlockCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getCmsBlocks, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function upsertCmsBlockCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertCmsBlock, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function deleteCmsBlockCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("put", window.admin.deleteCmsBlock, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function getSocialCmsCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getSocialCms, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function deleteCmsSocialCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("put", window.admin.deleteSocialCms, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function getSlideCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getSlides, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function upsertSlideCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertSlide, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function deleteSlideCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("put", window.admin.deleteSlide, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function getFaqCategoryCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getFaqCategory, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function upsertFaqCategoryCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertFaqCategory, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function deleteFaqCategoryCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("put", window.admin.deleteFaqCategory, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function getFaqCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getFaq, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function upsertFaqCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertFaq, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function deleteFaqCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("put", window.admin.deleteFaq, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function getAdsCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getAds, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function upsertAdsCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertAds, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function deleteAdsCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("put", window.admin.deleteAds, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function getBuyerCmsCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getBuyerCms, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function upsertBuyerCmsCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertBuyerCms, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function getInvestorCmsCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getInvestorCms, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function upsertInvestorCmsCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertInvestorCms, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}

export function getRealtorCmsCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("get", window.admin.getRealtorCms, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}


export function upsertRealtorCmsCall(data) {
	return new Promise((resolve, reject) => {
		Http.Request("post", window.admin.upsertRealtorCms, data.data)
			.then(response => { resolve(response) })
			.catch(error => { reject(error) });
	});
}