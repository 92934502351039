import React, { Component } from 'react';
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import Modal from '../../common/modal';
import TT from "../../common/tooltip";

class ROW extends Component {
    
    constructor(props){
        super(props);

        this.state={
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    
    render() {
        const {cms} = this.props;
        let newMarket="";
        if(Array.isArray(cms.market)){

        }
        return (
            <tr className="odd gradeX" >
                <td> {cms.firstname} </td>   
                <td className="center">{cms.email}</td>          
                <td className="center">{cms.mobile}</td>
                <td className="center">{Array.isArray(cms.market) ?cms.market.map((val)=>  `${val},`):""}</td>
                <td className="center" width="10%">
                    {" "}
                    <label
                        className={
                            cms.status
                                ? "label label-info"
                                : "label label-danger"
                        }
                    >
                        {cms.status ? "Active" : "Inactive"}
                    </label>{" "}
                </td>
                {/* <td><Moment format="MM/DD/YY">{cms.created_at}</Moment></td> */}

                <td>
                <TT tooltip="Remove"><a className=" btn btn-xs red-mint" onClick={this.showModal}><i className="fa fa-trash no-pointer"></i></a></TT>
                    {/* <TT tooltip="Edit"><Link to={'/realtor-signup-edit/' + cms._id} className=" btn btn-xs grey-mint" ><i className="fa fa-pencil-square-o"></i></Link></TT> */}
                    {this.state.show && <Modal show={true} func={this.deleteRow} closeParentModal={this.closeModal} title={cms.title} />}                    
                </td>
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow(){
        this.setState({ isLoading: true,show:false });
        /*delete row by call prop func*/
        this.props.delete(this.props.cms._id);        
    }

    closeModal() {
        this.setState({ show: false });
    }

}

export default ROW;

