import React, { Component } from 'react';
import Moment from "react-moment";
import moment from "moment-timezone";
class ROW extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    render() {
        const { log } = this.props;
        return (
            <tr className="odd gradeX" >
                <td> {log.username} </td>
                {/* <td className="center "> {log.password} </td> */}
                <td className="center capital"> {log.os} </td>
                <td className="center"> {log.ip} </td>
                <td>{log.browser ? log.browser.family : "N/A"}</td>
                {/* <td><Moment format="MM/DD/YY">{moment(log.created_at).zone("-04:00")}</Moment></td> */}
                {/* <td><Moment format="MM/DD/YY">{moment(log.created_at).zone("-04:00")}</Moment> {moment(log.created_at).zone("-04:00").format('h:mm:ss a')}</td> */}
                <td>{this.loginDSTTime(log.created_at)}</td>
                {/* <td><Moment format="MM/DD/YY HH:mm:ss A">{log.created_at}</Moment></td> */}
                <td className="center"> <label><b>{log.status ? "True" : "False"}</b></label> </td>
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow() {
        this.setState({ isLoading: true, show: false });
        /*delete row by call prop func*/
        this.props.delete(this.props.log._id);
    }

    closeModal() {
        this.setState({ show: false });
    }

    // LOGIN LOGS DST TIME 
    loginDSTTime = time => {
        let newTime = "";
        let d = moment(time);

        if (d.isDST()) {
            console.log("login logs dst time", d.isDST())
            newTime = moment(time)
                .format("MM/DD/YY h:mm:ss a");
        } else {
            console.log("login logs not dst time", d.isDST())
            newTime = moment(time)

                .format("MM/DD/YY h:mm:ss a");
        }
        return newTime;
    }


}

export default ROW;

