import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';



const SortableItem = sortableElement(props => {
    return(
        <div className="GallInnBx"  key={props.image.image.public_id}>
        <div onClick={() => props.removeImage(props.image)} 
        className='delete'>
        <FontAwesomeIcon 
        icon={faTimesCircle} 
        size='2x' />
    </div>
    <img 
        onError={() => props.onError(props.image.image.public_id)}
        src={props.image.image.secure_url} 
        alt='' 
    /> 
    </div>
    )
}

    // <div className="GallInnBx"  key={props.image.image.public_id}>
    // <div onClick={() => props.removeImage(props.image)} 
    //     className='delete'>
    //     <FontAwesomeIcon 
    //     icon={faTimesCircle} 
    //     size='2x' />
    // </div>
    // <img 
    //     onError={() => props.onError(props.image.image.public_id)}
    //     src={props.image.image.secure_url} 
    //     alt='' 
    // /> 
    // </div>
);



export default props =>
  props.images.map((image, index) =>
  <SortableItem key={`image-${index}`} 
    index={index} image={image} 
    onError={props.onError}
    removeImage={props.removeImage} />

  )