import {takeLatest} from 'redux-saga/effects'
import { login, forgotPassword, resetPassword, logout, trackLogs } from "./admin"; 
import { upsertCMS, getCMS, deleteCMS,upsertReview ,getReview,deletePage,deleteReview, getBlog, upsertBlog, deleteBlog,getPage,upsertCmsBlock,getCmsBlock,deleteCmsBlock,getSocialCms,deleteSocialCms,getSlide,deleteSlide,upsertSlide,getFaqCategory,deleteFaqCategory,upsertFaqCategory,getFaq,deleteFaq,upsertFaq,getAds,deleteAds,upsertAds,getBuyerCms,upsertBuyerCms,upsertInvestorCms,upsertRealtorCms,getInvestorCms,getRealtorCms} from "./cms"; 
import { getNotifications } from "./dashboard"; 
import {getTaxes} from "./taxes"
import {ADMIN_LOGOUT,GET_TAXES} from "../components/common/actions";


export default function* Sagas(){

	/*Admin User Actions*/
	yield takeLatest("Admin-login", login);
	yield takeLatest("Admin-Forgot-Password", forgotPassword)
	yield takeLatest("ADMIN_RESET_PASSWORD", resetPassword);
	yield takeLatest(ADMIN_LOGOUT, logout);
	
	/*CMS*/
	yield takeLatest("Admin-upsertCMS", upsertCMS);
	yield takeLatest("Admin-getCMS", getCMS);
	yield takeLatest("Admin-deleteCMS", deleteCMS);

	// Review CMS
	yield takeLatest("Admin-upsertReview", upsertReview);
	yield takeLatest("Admin-getReview", getReview);
	yield takeLatest("Admin-deleteReview", deleteReview);

	// Blog CMS
	yield takeLatest("Admin-upsertBlog", upsertBlog);
	yield takeLatest("Admin-getBlog", getBlog);
	yield takeLatest("Admin-deleteBlog", deleteBlog);

	// Page CMS
	yield takeLatest("Admin-getPage", getPage);
	yield takeLatest("Admin-deletePage", deletePage);

	// CMS Blocks
	yield takeLatest("Admin-upsertCmsBlock", upsertCmsBlock);
	yield takeLatest("Admin-getCmsBlock", getCmsBlock);
	yield takeLatest("Admin-deleteCmsBlock", deleteCmsBlock);

	// Slides
	yield takeLatest("Admin-upsertSlide", upsertSlide);
	yield takeLatest("Admin-getSlide", getSlide);
	yield takeLatest("Admin-deleteSlide", deleteSlide);

	// Faq Category
	yield takeLatest("Admin-upsertFaqCategory", upsertFaqCategory);
	yield takeLatest("Admin-getFaqCategory", getFaqCategory);
	yield takeLatest("Admin-deleteFaqCategory", deleteFaqCategory);

	// Faq 
	yield takeLatest("Admin-upsertFaq", upsertFaq);
	yield takeLatest("Admin-getFaq", getFaq);
	yield takeLatest("Admin-deleteFaq", deleteFaq);

	// Ads 
	yield takeLatest("Admin-upsertAds", upsertAds);
	yield takeLatest("Admin-getAds", getAds);
	yield takeLatest("Admin-deleteAds", deleteAds);

	// Buyer cms 
	yield takeLatest("Admin-upsertBuyerCms", upsertBuyerCms);
	yield takeLatest("Admin-getBuyerCms", getBuyerCms);

	// Investor cms 
	yield takeLatest("Admin-upsertInvestorCms", upsertInvestorCms);
	yield takeLatest("Admin-getInvestorCms", getInvestorCms);

	// Realtor cms 
	yield takeLatest("Admin-upsertRealtorCms", upsertRealtorCms);
	yield takeLatest("Admin-getRealtorCms", getRealtorCms);

	// Social CMS
	yield takeLatest("Admin-getSocialCms", getSocialCms);
	yield takeLatest("Admin-deleteSocialCms", deleteSocialCms);

	/*Notifications*/
	yield takeLatest("Dashboard-notifications", getNotifications);

	/*Logs*/
	yield takeLatest("Admin-trackAuditLogs", trackLogs);

	/**Taxes */
	yield takeLatest(GET_TAXES, getTaxes);
}