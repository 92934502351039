import React, { Component } from "react";
import { connect } from "react-redux";
import RenderField from "../common/renderField";
import { Tabs, Tab } from "react-bootstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import Loader from "../common/loader";
import PageHeader from "../common/pageheader";
import { slugify } from "../../libs/Helper";
import { push } from "react-router-redux";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import DropdownComp from "../common/DropdownList";
import DropdownCompV2 from "../common/dropdown_v2";

import ImageCropper from "../common/ImageCropper";

import { STATUS } from "../common/options";
import { required, ValidateOnlyAlpha } from "../common/fieldValidations";

class AddPropSec extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formAction: "ADD",
      isLoading: false,
      status: false,
      url: '',
      pagetype: ''
    };
    this.upsertProp = this.upsertProp.bind(this);
    this.getProp = this.getProp.bind(this);
  }

  componentDidMount() {
    this.getProp();
  }

  render() {
    const { handleSubmit } = this.props;
    const { isLoading, formAction, status, pagetype} = this.state;
    return (
      <div className="relative">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <PageHeader
              route={
                formAction === "ADD" ? "Add Buy Property CMS" : "Edit Buy Property CMS"
              }
              parent="Buy Property CMS"
              parentRoute="/buy-property-cms-list"
            />

            <div>
              <Tabs defaultActiveKey={1} animation={false} id="profileTabs">
                <Tab
                  eventKey={1}
                  title={
                    formAction === "ADD"
                      ? "Add Property CMS"
                       : "Edit Property CMS"
                  }
                >
                  <form onSubmit={handleSubmit(this.upsertProp)}>
                  
                    <Field
                      name="type"
                      textField="label"
                      valueField="value"
                      label="Property Type*"
                      component={DropdownCompV2}
                      placeholder="-select-"
                      validate={required}
                      options={[
                        { label: "Renovated Property", value: "renovated" },
                        { label: "Wholesale Properties", value: "wholesale" },
                        { label: "Buy Page", value: "buy" }

                      ]}
                    />
                    {/*  <Field name="type"  fieldName="Page type" type="text" component={RenderField} validate={[required]}/> */}
                   {this.props.initialValues}
                    <Field
                      name="title"
                      fieldName="Title*"
                      type="text"
                      component={RenderField}
                      validate={[required]}
                    />

                    <Field
                      name="url"
                      fieldName="Url*"
                      type="text"
                      readOnly = {
                       this.state.url ? true : false
                      }
                      component={RenderField}
                      validate={[required]}
                    />
                      
                    <Field
                      name="meta_title"
                      fieldName="Meta Title*"
                      type="text"
                      component={RenderField}
                      validate={[required]}
                    /> 
                   
                    <Field name="meta_description" textarea fieldName="Meta Description" component={RenderField}/>      
                    <Field
                      name="status"
                      options={STATUS}
                      label="Status"
                      defaultValue={this.state.status ? "Active" : "Inactive"}
                      textField="title"
                      valueField="value"
                      component={DropdownComp}
                    /> 

                    <br />

                    <div className="form-actions">
                      <button
                        type="submit"
                        className="btn green uppercase"
                        disabled={this.props.invalid || this.props.submitting}
                      >
                        {formAction === "ADD" ? "Add" : "Update"}
                      </button>
                    </div>
                  </form>
                </Tab>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    );
  }

  upsertProp(data) {
    const { match } = this.props;
    this.setState({ isLoading: true });
    if (match.params._id) data.editID = match.params._id;

    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    formData.append("file", data.image);

    HTTP.Request("post", window.admin.AddBuyCMSProperty, formData)
      .then(result => {
        console.log(result);
        this.setState({ isLoading: false });
        this.props.dispatch(push("/buy-property-cms-list"));
        toast(result.message, { type: "success" });
      })
      .catch(err => {
        this.setState({ isLoading: false });
        toast(err.message, { type: "error" });
      });
  }

  getProp() {
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let roleID = match.params._id ? match.params._id : null;

    if (roleID) {
      this.setState({ isLoading: true, formAction: "EDIT" });
      HTTP.Request("get", window.admin.GetOneBuyCMSProperty, {
        id: roleID
      }).then(result => {
        console.log("result", result);
        this.setState({
          isLoading: false,
          status: result.data.status,
          image: result.data.image,
          pagetype: result.data.type
        });

        /*set data to form*/
        console.log(result.data);
        if (result.data.url) {
          this.setState ({
            url: result.data.url
          })
        }
        initialize(result.data);
      });
    }
  }
}

let propSecForm = reduxForm({
  form: "propSecForm"
})(AddPropSec);

export default connect()(propSecForm);
