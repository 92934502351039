import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import TT from "../../common/tooltip";
const style = {
    width: "80px"
};
class ROW extends Component {
    render() {
        const { element } = this.props;

        let replied = <label className="label label-success">Replied</label>;
        let notReplied = (
            <label className="label label-danger">Not Replied</label>
        );
        return (
            <tr className="odd gradeX">
                <td> {element.address.address} </td>
                <td>
                    {" "}
                    {element.images ? (
                        <img
                            style={style}
                            src={element.images.before_image.secure_url}
                        />
                    ) : (
                        "N/A"
                    )}{" "}
                </td>
                <td>
                    {" "}
                    {element.images ? (
                        <img
                            style={style}
                            src={element.images.after_image ? element.images.after_image.secure_url :"NA"}
                        />
                    ) : (
                        "N/A"
                    )}{" "}
                </td>
                <td className="center">
                    {" "}
                    <label
                        className={
                            element.status
                                ? "label label-info"
                                : "label label-danger"
                        }
                    >
                        {element.status ? "Active" : "Inactive"}
                    </label>{" "}
                </td>
                <td>
                    <Moment format="MM/DD/YY">{element.created_at}</Moment>
                </td>
                <td>-</td>
            </tr>
        );
    }
}

export default ROW;
