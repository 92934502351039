import React, { Component } from 'react';
import Moment from "react-moment";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TT from "../../common/tooltip";
import moment from "moment-timezone";
import Modal from "../../common/modalTopArticles";

class ROW extends Component {  

    constructor(props) {
        super(props);
    
        this.state = {
            show: false
        };

          /*bind this with methods*/
    this.showModal = this.showModal.bind(this);
    this.deleteRow = this.deleteRow.bind(this);
    this.closeModal = this.closeModal.bind(this);
    }
    
    render() {
        const {admin,adminID} = this.props;
        return (
            <tr className="odd gradeX" >
                <td> {admin.sourceUrl} </td>
                <td> {admin.redirectedUrl} </td>
                <td><Moment format="MM/DD/YY">{moment(admin.created_at).zone("-04:00")}</Moment></td>

                {/* <td><Moment format="MM/DD/YY">{admin.created_at}</Moment></td>             */}
                {(adminID !== admin._id)?<td>                    
                    <TT tooltip="Edit"> <Link to={'/redirect-links/edit/' + admin._id} className=" btn btn-xs grey-mint"><i className="fa fa-pencil-square-o" ></i></Link> </TT>
                    <TT tooltip="Delete">
                        <a
                            onClick={this.showModal}
                            className="btn btn-xs red-mint"
                        >
                            <i className="fa fa-trash"></i>
                        </a>
                    </TT>
                    {this.state.show && (
              <Modal
                  show={true}
                  func={this.deleteRow}
                  closeParentModal={this.closeModal}
              />
            )}
                </td>:
                <td >- </td>
            }
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
      }
    
      deleteRow() {
        /*delete row by call prop func*/
        this.props.deleteRedirectLink(this.props.admin._id);
    }
    
    closeModal() {
        this.setState({ show: false });
    }
}
/*get props*/
const mapStatesToProps =(state) => {
    return ({
        adminID: state.admin && state.admin.user ? state.admin.user._id : null
    });
}



export default connect(mapStatesToProps)(ROW);
