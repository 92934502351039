export const normalizePhone = (value, previousValue) => {
    if (!value) {
      return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
       
    return onlyNums
  }

  export const normalizeLot = (value, previousValue) => {
    var onlyNums;
    if (!value) {
      return value
    }
    if(value > 43560){
       onlyNums = (value / 43560).toFixed(2)
    }
      onlyNums = value.replace(/[^\d]/g, '')
       
    return onlyNums
  }

  export const normalizePrice = (value, previousValue) => {
    if (!value) {
      return value
    }
    const onlyNums = value.replace(/,/g , ''); 
    const convertedInput = new Intl.NumberFormat().format(onlyNums);
    console.log('convertedInput', convertedInput);
    return convertedInput
  }  
  
  export const normalizeBuilt = (value, previousValue) => {
      if(!value){
          return value
      }
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums.slice(0, 4) 
  }

  export const normalizeHoa = (value, previousValue) => {
      if(!value){
          return value
      }

      const onlyNums = value.replace(/[^\d]/g,'')
      return onlyNums.slice(0,5)
  }