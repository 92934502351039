import React, { Component } from 'react';
import Moment from "react-moment";
import moment from "moment-timezone";
import HTTP from '../../../services/http';

import { connect } from "react-redux";
import { logsCount } from '../../common/actions';

class MongotoCrmROW extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            logsArray: [],
            noOfRecords: 0
        }
        /*bind this with methods*/

    }

    componentWillReceiveProps(nextProps) {
        let data = {
            page: nextProps.activePage,
            size: nextProps.sizePerPage
        }
        if (!nextProps.isFilter) {
            this.getmongoLogs(data);
        }
    }

    componentWillMount() {
        let data = {
            page: this.props.activePage,
            size: this.props.sizePerPage
        }
        if (!this.props.isFilter) {
            this.getmongoLogs(data)
        }
    }

    render() {
        let result = (this.props.filterData && this.props.filterData.length) ?
            this.props.filterData && this.props.filterData.map(log => {
                return (
                    <tr key={log._id}>
                        <td>
                            {moment(log.created_at).format("MM/DD/YY h:mm:ss a")}
                        </td>
                        <td>{log.Number_of_Agent_from_Mongo}</td>
                        <td>{log.Number_of_Agent_Updated}</td>
                    </tr>
                )
            }) :
            this.state.logsArray && this.state.logsArray.map(log => {
                return (
                    <tr key={log._id}>
                        <td>
                            {moment(log.created_at).format("MM/DD/YY h:mm:ss a")}
                        </td>
                        <td>{log.Number_of_Agent_from_Mongo}</td>
                        <td>{log.Number_of_Agent_Updated}</td>
                    </tr>
                )
            })


        return (
            <React.Fragment>
                {result}
            </React.Fragment>
        )
    }



    // get Mongo logs
    getmongoLogs(data) {

        //     console.log("cron time reponse", response)
        try {
            HTTP.Request("get", window.admin.mongotoCrmLog, data).then((response) => {
                console.log("mongo to crm logs", response)
                // this.state.logsArray.push(response.data)
                this.setState({
                    logsArray: response.data.logs,
                    noOfRecords: response.data.count,
                })

                this.props.dispatch({
                    type: logsCount,
                    params: response.data.count
                });

                // console.log(moment(value.created_at).format("DD MM YYYY hh:mm:ss:a")

                // })

            })
        }
        catch (e) {
            // console.log("erro is here", e)
        }
    }


}

export default connect()(MongotoCrmROW);
