import React from 'react';

export default function Detail(props) {
    const rowStyle = {
        backgroundColor: 'rgb(255, 230, 224)',
    }
    const preStyle = {
        border: 0
    }
    const stringified = props.payload ? JSON.stringify(JSON.parse(props.payload),null,2) : 'Empty' ;
    return <React.Fragment style={{...rowStyle}}>
        <tr style={{...rowStyle}}>
            <td>Url</td>
            <td colSpan="5">{props.url}</td>
        </tr>
        <tr style={{...rowStyle}}>
            <td>Payload</td>
            <td colSpan="5">
                <pre style={{...preStyle}}>{stringified}</pre>
            </td>
        </tr>
    </React.Fragment>
}
