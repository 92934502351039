import React, {Component} from 'react';
import RenderField from '../common/renderField';
import {connect} from 'react-redux'
import HTTP from "../../services/http";
import renderHTML from 'react-render-html';
import Loader from "../common/loader";
import moment from "moment"
import { toast } from "react-toastify";
import xlsx from 'xlsx';


 class FlyerPreview extends Component {
    someHTML1 = 'Home'
    constructor(props) {
        super(props);

        this.state = {
            mailingUsers:[],
            someHTML: '',
            isLoading : false
        
        }

        this.getEmailTemplate = this.getEmailTemplate.bind(this)
        this.sendEmail = this.sendEmail.bind(this)
        this.convert = this.convert.bind(this)
        this.USNumber = this.USNumber.bind(this)

    }

   

    componentDidMount() {
        ( async()=>{     
            await this.getEmailTemplate();
            await this.getUsers();
        })();
    }

    render() {
    const { someHTML,isLoading,mailingUsers } = this.state;
    const height = 'MarginTop: 500px';
        return (
            <div> 
            {isLoading &&<Loader />}
                <p className="warning-title">This flyer will be sent to <span className="increase-size">{mailingUsers.length}</span> users. {mailingUsers.length>0 ?(<i><a onClick={this.downloadUsers}>Click here</a> to download user records </i>):("")}</p>
                <h2 >Preview  </h2>
              
                {/* <div>  {renderHTML(someHTML)}</div>  */}
                <div style= {{minHeight: '500px', border: '1px solid'}} dangerouslySetInnerHTML={{__html:someHTML}}></div>


                <h2>  Preview SMS </h2>
            <div style= {{border: '1px solid'}} > 
              <div style = {{marginLeft : '15px'}}>
                    <p>{this.props.preview.flyerType}</p>
                    <p>Address: {this.props.preview.street_address?this.props.preview.street_address.split(',').slice(0, 1).join(','): ''} 
                    {this.props.preview.unit_no !== ' ' &&  this.props.preview.unit_no  ? ' #' + (this.props.preview.unit_no + ',').replace(/\s/g, ''): ''}
                    {this.props.preview.street_address?this.props.preview.street_address.split(',').slice(1, 3).join(','): ''}</p>
                    <p>Asking Price: ${this.props.preview.price? this.props.preview.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): this.props.preview.price } <br></br>
                     ARV: ${this.props.preview.arv ? this.props.preview.arv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.props.preview.arv} <br></br>
                     Spread: ${this.props.preview.spread ? this.props.preview.spread.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : this.props.preview.spread}</p>
                    <p>View details for the property on our website!</p>
                    <p><a href >https://www.housebuyersofamerica.com/buy/{this.props.preview.market.toLowerCase().split(' ').join('-')}/{this.props.preview.street_address.toLowerCase().split(' ').join('-')} </a></p>
                    <p>For more information, please contact {this.props.preview.recName} by phone {this.props.preview.recMobile? this.USNumber(this.props.preview.recMobile): this.props.preview.recMobile} or by email {this.props.preview.recEmail}.</p>
                    <p>Message/data rates apply. Message frequency varies. Send STOP to Opt Out. Reply HELP for help. Please call {this.props.preview.recFirstName} with any questions.</p>
                </div>
            </div>

            <div className=''>
            <button
            style = {{marginTop: '8px'}}
            className="btn green uppercase"          
            onClick={this.sendEmail}
            disabled={mailingUsers.length<=0}
            > Send Flyers
            </button>
            </div>

            </div>



        );
    }

    downloadUsers=()=>{
        let downloadList = this.state.mailingUsers.map((e)=> {
             if (e.user_type) {
                 e.user_type = e.user_type.map(type =>
                     type === 1
                         ? 'Buyer'
                         : type === 2
                             ? 'Investor'
                             : type === 3
                                 ? 'Realtor': 'Unknown'
                 );
                 e.user_type = e.user_type.join(',');
             }
 
             if (e.communication) {
                 e.communication =
                     e.communication === 1
                         ? 'SMS and Email'
                         : e.communication === 2
                             ? 'Email'
                             : e.communication === 3
                                 ? 'Unsusbcribe'
                                 : e.communication === 4
                                 ? 'SMS'
                                     : 'Unknown'
             }
 
             if (e.socialType) {
                 if (e.socialType === 'default') {
                     e.socialType = 'None'
                 }
             }
 
             if (e.wholesale_market) {
                 let wholesale_markets = [];
                 let wholesale_county = [];
                 if(Array.isArray(e.wholesale_market)) {
                     e.wholesale_market.forEach(market => {
                         if(market.market) {
                             wholesale_markets.push(market.market);
                             if(wholesale_county.length === 0) {
                                 wholesale_county = [...market.counties];
                             } else {
                                 wholesale_county = [...wholesale_county,';',...market.counties];
                             }
                         }
                     });
                     e.wholesale_market = wholesale_markets.join(',');
                     e.wholesale_county = wholesale_county.join(',');
                 }
             }
 
             if (e.renovated_county && !e.migrated) {
                 e.renovated_county = e.renovated_county.join(',');
             }
 
             if (e.renovated_market) {
                 let renovated_markets = [];
                 let renovated_county = [];
                 if(Array.isArray(e.renovated_market)) {
                     e.renovated_market.forEach(market => {
                         if(market.market) {
                             renovated_markets.push(market.market);
                             if(renovated_county.length === 0) {
                                 renovated_county = [...market.counties];
                             } else {
                                 renovated_county = [...renovated_county,';', ...market.counties];
                             }
                         }
                     });
                     e.renovated_market = renovated_markets.join(',');
                     e.renovated_county = renovated_county.join(',');
                 }
             }
 
             if (e.register_renovated === false) {
                 e.register_renovated = 'false';
             } else if (e.register_renovated === true) {
                 e.register_renovated = 'true';
             }
 
             if (e.register_wholesale === false) {
                 e.register_wholesale = 'false';
             } else if (e.register_wholesale === true) {
                 e.register_wholesale = 'true';
             }
 
             if (e.status === false) {
                 e.status = 'Inactive'
             } else if (e.status === true) {
                 e.status = 'Active'
             }
 
             if (e.trash === false) {
                 e.trash = 'false'
             } else if (e.trash === true) {
                 e.trash = 'true'
             }
             return e
         })
 
         var ws = xlsx.utils.json_to_sheet(downloadList,{header:['firstname','lastname','mobile','email','source',
         'socialType','status','user_type','communication','register_renovated','renovated_market',
         'renovated_county','register_wholesale','wholesale_market','wholesale_county',
         'created_at','updated_at']});
         console.log(ws);
         this.wb = xlsx.utils.book_new();
         xlsx.utils.book_append_sheet(this.wb, ws, "People");
         console.log(this.wb);
         xlsx.writeFile(this.wb, "Marketing_List.xlsx");
 
         this.setState({
             isLoading: false
         })
     }

    getUsers(){
        HTTP.Request("post", window.admin.getUserList,{...this.props.preview,campaignType:"wholesale"})
          .then(result => {
            this.setState({
               mailingUsers:result.data
            })
          })
    }

    getEmailTemplate() {
        console.log(this.props.preview)
        console.log(this.props.preview.slug)
        
        console.log(this.props.history);

       
        if (!this.props.preview.register_wholesale) {
         this.props.history.push('/wholesale-flyer-management')
        } 
    
        this.setState({
            isLoading : true
        })
        
        HTTP.Request("get", window.admin.flyerTemplate, {
            id: this.props.preview.register_wholesale
          })
          .then(result => {
            this.setState({ isLoading: false });
            let data = JSON.parse(result.data)
            // console.log('pure data', data);
            // console.log('complete data', data.versions[0].html_content)
            const unitNo =  this.props.preview.unit_no !== ' ' &&  this.props.preview.unit_no ? ' #' + this.props.preview.unit_no.replace(/\s/g, '') : '';
            const displayAddress = this.props.preview.street_address.split(',').slice(0, 1).join(',') +
            unitNo + ', ' + this.props.preview.street_address.split(',').slice(1, 3).join(',');
            const market = this.props.preview.market.toLowerCase().split(' ').join('-');
            this.setState ({
                someHTML: data.versions[0].html_content
                .replace('{{feature_image}}',  this.props.preview.feature_image.secure_url)
                // .replace('{{address}}', this.props.preview.street_address.split(',').slice(0,3).join(','))
                .replace('{{address}}', displayAddress)
                .replace(/{{address2}}/g,[this.props.preview?.city,this.props.preview?.state_code+" "+this.props.preview?.zipcode].join(" "))
                .replace('{{arv}}', this.props.preview.arv.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                .replace('{{remark}}', this.props.preview.remarks)
                .replace('${{buynow}}', this.props.preview.buynow ? '$'+ this.props.preview.buynow.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","): '')
                .replace('Buy Now', this.props.preview.buynow ? 'Buy Now': '')
                .replace('{{property_type}}', this.props.preview.property_type)
                .replace('{{spread}}', this.props.preview.spread.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                .replace(/{{recName}}/g, this.props.preview.recName)
                .replace('{{recMobile}}', this.USNumber(this.props.preview.recMobile))
                .replace(/{{recEmail}}/g, this.props.preview.recEmail)
                .replace('{{OHD}}', this.props.preview.OHD ? moment.utc(this.props.preview.OHD).format('MMMM Do') : '')

                // .replace('{{OHD}}', this.props.preview.OHD ? this.convert(this.props.preview.OHD): '')
                .replace('{{startTime}}', this.props.preview.starttime? this.props.preview.starttime: '')
                .replace(/{{gap}}/, this.props.preview.starttime? '—': '' )
                .replace('{{endTime}}', this.props.preview.endtime? this.props.preview.endtime: '')
                .replace('Open House', this.props.preview.OHD ? 'Open House': '')
                // .replace(/{{property_link}}/g, 'https://www.housebuyersofamerica.com/wholesale-detail/' + this.props.preview.slug)
                .replace(/{{property_link}}/g, `https://www.housebuyersofamerica.com/buy/${market}/${this.props.preview.street_address.toLowerCase().split(' ').join('-')}`)
                .replace('{{price}}', this.props.preview.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                .replace('{{#if buynow}}', '')
                .replace('{{/if}}', '')
                .replace('{{/if}}', '')
                .replace('{{#if OHD}}', '')
            })

            // this.someHTML1 = result.data.versions[0].html_content;
          })
          .catch(err => {
            //   this.setState({ isLoading: false });
            console.log(err);
          
          });
      }

        USNumber(str) {
        if(!str) {
            return "";
        }
        str = str.match(/(\d{3})(\d{3})(\d{4})/);
        return "(" + str[1] + ") " + str[2] + "-" + str[3];
        
        }

      convert(str) {
        console.log(str);
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
          console.log([ mnth, day, date.getFullYear(),].join("/"));
        return [ mnth, day, date.getFullYear(),].join("/");
      }

      sendEmail () {

        console.log('data to send', this.props.preview);
        this.setState({
            isLoading : true
        })
        HTTP.Request("post", window.admin.sendWholesaleFyer, {content: this.state.someHTML,  data: this.props.preview})
        .then(result => {
            console.log(result)
            if (result['success'] === true) {
                toast(result.message, { type: "success" });
                this.props.history.push('/wholesaleflyer');
            }
            else{
                toast(result.message + " : " +result?.error[0]?.message, { type: "error" });
            }
            this.setState({
                isLoading : false
            })

        })
        .catch(err => {
            console.log(err)
            this.setState({
                isLoading : false
            })

        });
      }
    
}

const mapStateToProp = state => {

    // console.log(state.RenovatedFyler.data.data.register_wholesale);
return({
     preview: state.WholesaleFlyer
})
   
    
}

export default connect(mapStateToProp)(FlyerPreview)

