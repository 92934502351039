import React, { Component } from 'react';
import Moment from "react-moment";
import { connect } from 'react-redux';
import Modal from '../../../common/modal';
import { Link } from 'react-router-dom';
import TT from "../../../common/tooltip";
import moment from "moment-timezone";


class ROW extends Component {
    constructor(props){
        super(props);
        this.state={
            show: false
        }
        /*bind this with methods*/
        this.showModal = this.showModal.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    render() {

        const {admin,adminID,adminRole} = this.props;
        var deleteRole='';
        console.log(adminRole);
        console.log(admin);
        console.log('adminId', adminID)
        if(adminRole=='Super Admin'){
            if(adminID !=admin._id){
                deleteRole=<TT tooltip="Remove"><a className=" btn btn-xs red-mint" onClick={this.showModal} title="View"><i className="fa fa-trash no-pointer"  ></i></a></TT>
            }
        }
        else if(adminRole=='Admin' && adminID !=admin._id){
            if(admin.roleTitle.title==='Super Admin' || admin.roleTitle.title==='Admin'){
                deleteRole="   "
            }else{
                deleteRole=<TT tooltip="Remove"><a className=" btn btn-xs red-mint" onClick={this.showModal} title="View"><i className="fa fa-trash no-pointer"  ></i></a></TT>
            }
        }else if(adminRole!='Super Admin' && adminRole !='Admin' ){
            if(adminID !=admin._id && admin.roleTitle.title!='Super Admin' && admin.roleTitle.title!='Admin'){
                deleteRole=" "
            }else{
                deleteRole=""
            }
        }

        /*console.log('admin',admin.roleTitle.title);

        console.log('adminRole',adminRole);*/

        var edit = "";

        if(adminRole == 'Super Admin')
        {
            edit = <TT tooltip="Edit"><Link to={'/user-management/edit/' + admin._id}>{admin.firstname + " " + admin.lastname}</Link></TT>
                    ;

        } else if(adminRole == 'Admin'){

            if( admin.roleTitle.title != 'Super Admin'){

                edit =  <TT tooltip="Edit"><Link to={'/user-management/edit/' + admin._id}>{admin.firstname + " " + admin.lastname}</Link></TT>
                    ;
            }

        } else if(admin.roleTitle.title == adminRole){

            edit = <TT tooltip="Edit"><Link to={'/user-management/edit/' + admin._id}>{admin.firstname + " " + admin.lastname}</Link></TT>
                   ;
        }
        else{
            edit = admin.firstname + " " + admin.lastname
        }

        return (
            <tr className="odd gradeX" >
                <td> {edit} </td>
                <td> {admin.email} </td>
                <td> {admin.mobile ? admin.mobile:"NA"} </td>
                <td> {admin.roleTitle.title} </td>
                <td><Moment format="MM/DD/YY">{moment(admin.updateded_at).zone("-04:00")}</Moment></td>
                <td><Moment format="MM/DD/YY">{moment(admin.created_at).zone("-04:00")}</Moment></td>

                {/* <td><Moment format="MM/DD/YY">{admin.created_at}</Moment></td>             */}
                <td className="center"> <label className={admin.status ? 'label label-info' : 'label label-danger'}>{admin.status ? "Active" : "Inactive"}</label> </td>
                <td>
                    {deleteRole}
                     {this.state.show && <Modal show={true} func={this.deleteRow} closeParentModal={this.closeModal} title={admin.title} />}
                </td>
            </tr>
        );
    }

    showModal() {
        /*show popup and confirm before delete*/
        this.setState({ show: true });
    }

    deleteRow(){
        this.setState({ isLoading: true,show:false });
        /*delete row by call prop func*/
        this.props.delete(this.props.admin._id);
    }

    closeModal() {
        this.setState({ show: false });
    }
}
/*get props*/
const mapStatesToProps =(state) => {
    //console.log('state.admin',state.admin);
    return ({
        adminID: state.admin && state.admin.user ? state.admin.user._id : null,
        adminRole: state.admin && state.admin.user ? state.admin.user.role.title : null,
    });
}

export default connect(mapStatesToProps)(ROW);
