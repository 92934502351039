import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import DropdownCompV2 from "../common/dropdown_v2";
import { required, emailValiadte, mobileValidate, requireArray } from "../common/fieldValidations";
import RenderFiled from "../common/renderField";
import Loader from "../common/loader";
import PageHeader from "../common/pageheader";
import HTTP from '../../services/http';
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
import infoOf from '../common/tooltip-text';
import TimePickers from "../common/timePicker"
import MaterialMulti from '../common/material_multi';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Multiselect from 'multiselect-dropdown-react';
import MenuItem from '@material-ui/core/MenuItem';
class Settings extends Component {
  result(adminUser) {
    // const { adminUser } = this.state
    console.log("they are params", adminUser);
  }
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      adminUser: [],
      userEmail: [],
      selected: [],
      selectedRole: []

      // disableCronTime: true,
      // cronTime: "",
      // message: ""
      // message: "You can change cron time after 24 hours"
    }
    /**event binding  */
    this.addEditSettings = this.addEditSettings.bind(this);
    this.getSettings = this.getSettings.bind(this);
    this.getFile = this.getFile.bind(this);

  }

  componentWillMount() {
    let selected = window.sessionStorage.getItem("selected");
    this.setState({ selected: selected });
    this.getAdminRole()
    // this.getCronTime();
    this.getSettings();
  }

  render() {
    // console.log("REnder admin", adminUser)
    const { handleSubmit, changeValue } = this.props;
    const { isLoading, adminUser, event, selected, selectedRole, userEmail } = this.state;
    console.log("REnder admin", selectedRole)
    return (
      <div>
        {isLoading && <Loader />}
        <div className="tab-pane active" >
          <PageHeader pageTitle="Settings" route="Settings" />
          <form onSubmit={handleSubmit(this.addEditSettings)}  >
            <Field name="sitename" icon='fa fa-info-circle' tooltip={infoOf.sitename} fieldName='Website Title*' type="text" placeholder='Site' component={RenderFiled} validate={[required]} />
            <Field name="sitesummary" icon='fa fa-info-circle' tooltip={infoOf.sitesummary} textarea fieldName="Site Summary" placeholder="Summary about your site" component={RenderFiled} />
            <Field name="map" icon='fa fa-info-circle' tooltip={infoOf.sitemap} textarea fieldName="Map" placeholder="Map" component={RenderFiled} />
            <label>Logo</label>
            <input type="file" onChange={this.getFile} accept="image/*" className='form-control' /><br />
            {this.state.image ? <img src={this.state.image.secure_url} width='120px' className="img-responsive img-thumbnail i-bot" alt="logo" /> : null}

            <Field name="email" icon='fa fa-info-circle' tooltip={infoOf.siteemail} fieldName='Contact Email*' type='email' placeholder='Email' component={RenderFiled} validate={[required, emailValiadte]} />
            <Field name="google_script" rows={8} textarea icon='fa fa-info-circle' tooltip={infoOf.siteemail} fieldName='Google/Bing Script*' type='text' placeholder='Google/Bing Script' component={RenderFiled} validate={[required]} />
            <Field name="mobile" fieldName='Mobile No.' type='number' placeholder='Mobile No.' component={RenderFiled} validate={[mobileValidate]} />
            <Field
              name="sms_service"
              placeholder="-select-"
              options={
                [{
                  label: "Active",
                  value: "true"
                },
                {
                  label: "Inactive",
                  value: "false"
                }

                ]
              }
              onChangeHoa={
                this.onChangeHoa
              }
              label="Choose Renovated SMS Service*"
              validate={
                required
              }
              textField="label"
              valueField="value"
              component={DropdownCompV2}
            />
            <Field
              name="wholesale_sms_service"
              placeholder="-select-"
              options={
                [{
                  label: "Active",
                  value: "true"
                },
                {
                  label: "Inactive",
                  value: "false"
                }

                ]
              }
              onChangeHoa={
                this.onChangeHoa
              }
              label="Choose Wholesale SMS Service*"
              validate={
                required
              }
              textField="label"
              valueField="value"
              component={DropdownCompV2}
            />
            <Field name="address" textarea fieldName='Address' type="text" placeholder='Address' component={RenderFiled} />


            <Field name="playstorelink" fieldName='Playstore Link' type="text" placeholder='Playstore Link' component={RenderFiled} />
            <Field name="applelink" fieldName='Apple iTune Link' type='text' placeholder='Apple iTune Link' component={RenderFiled} />
            <Field name="copyrights" fieldName='Copyrights Information' type='text' placeholder='copyrights' component={RenderFiled} />
            <Field name="cronjob_time" id='cronjob_time' fieldName="Disposition Consultant Sync with CRM Cron Job Time (EST)*"
              component={TimePickers} />



            <Field
              options={adminUser}
              selectedValues={selectedRole}
              // onChange={event => changeValue(event.target.value)}
              name='Cronjob Mailer'

              label="Cronjob Mailer*"

              component={MaterialMulti}
              validate={[requireArray]}
            />

            <div className="form-actions">
              <button type="submit" className="btn green uppercase" disabled={this.props.invalid || this.props.submitting}>Save Changes</button>
            </div>
          </form>
        </div>

      </div>
    );
  }



  // GET ADMIN ROLE 

  getAdminRole() {
    try {
      HTTP.Request("get", window.admin.getAdminRole)
        .then(result => {
          console.log("these imy roles array =>>>", result.roles)
          this.setState({ adminUser: result.roles, userEmail: result.roles })

        })
    } catch (e) {
      console.log("this is error ", e)
    }
  }



  // changeValue(e) {
  //   let value = e.target.value
  //   console.log("selected value", e.target.value)
  // }



  addEditSettings(data) {
    // this.setCronTime(data.cronjobtime)
    /**add _id of user in data */

    let obj = {
      meta_value: data,
      meta_key: "WEBSITE_SETTINGS"
    }
    console.log("addEditSettings obj", obj)
    let formData = new FormData();
    /*add file to request*/
    formData.append("file", this.state.file);
    formData.append("data", JSON.stringify(data));

    HTTP.Request("post", window.admin.addEditMetaData, obj)
      .then(result => {

        console.log("result ", result)
        toast(result.message, { type: "success" });

        /*log audits for user*/
        this.props.dispatch({
          type: ADMIN_TRACK_AUDIT_LOGS,
          action: {
            comment: "Updated Website Configuration/Settings",
            type: "audit"
          }
        });
      })
      .catch(err => toast(err.message))
  }

  getSettings() {
    let params = { meta_key: "WEBSITE_SETTINGS" }
    this.setState({ isLoading: true });
    HTTP.Request("get", window.admin.getMetaData, params)
      .then((result) => {
        console.log("result is ", result)

        if (result.data) {
          console.log("result data ", result.data)
          this.setState({
            selectedRole: result.data.meta_value["Cronjob Mailer"]
          })
        }

        this.setState({ isLoading: false }, () => {
          console.log("selected values ", this.state.selectedValues)
        });
        this.props.initialize(result.data.meta_value);
      })
      .catch(err => {
        this.setState({ isLoading: false });
        // toast(err.message,{type:"error"})
      })
  }

  getFile(e) {
    this.setState({ file: e.target.files[0] })
  }
}


let Settings_Form = reduxForm({
  form: "settings_form"
})(Settings);

export default Settings_Form;
