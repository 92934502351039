import React, { Component } from 'react';
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import TT from "../../common/tooltip";
import moment from "moment-timezone";

class ROW extends Component {

    render() {
        const {element} = this.props;

        return (
            <tr className="odd gradeX" >
                <td className='tdClass'><TT tooltip={element.isSynedToCRM}>{element.isSynedToCRM ?  <i className="fa fa-check-circle-o" style={{ color: '#58D68D', fontSize: 20}} /> :  <i className="fa fa-times-circle-o" style={{ color: '#C0392B', fontSize: 20}} />}</TT></td>
                <td className='tdClass'><TT tooltip="View"><Link to={'/get-staging-offer-view/' + element._id}>{element.firstname} </Link></TT></td>
                <td className='tdClass'> <TT tooltip={element.lastname}>{element.lastname}</TT></td>
                <td className='tdClass'><TT tooltip={element.email}> {element.email}</TT></td>
                <td className='tdClass'><TT tooltip={element.searchengine}> {element.searchengine}</TT> </td>
                <td><Moment format="MM/DD/YY">{moment(element.created_at).zone("-04:00")}</Moment></td>
                <td className='tdClass'> <TT tooltip={element.dataToCrm.emn_address1_line1}>{element.dataToCrm.emn_address1_line1}</TT></td>
                <td className='tdClass'> {element.dataToCrm.emn_unit} </td>
                <td className='tdClass'>  <TT tooltip={element.dataToCrm.emn_address1_city}>{element.dataToCrm.emn_address1_city}</TT></td>
                <td className='tdClass'> {element.dataToCrm.emn_address1_stateorprovince} </td>
                <td className='tdClass'> {element.dataToCrm.emn_address1_postalcode} </td>
                <td className='tdClass'> <TT tooltip={element.dataToCrm.emn_address1_county}>{element.dataToCrm.emn_address1_county}</TT></td>
                <td className='tdClass'> <TT tooltip={element.market}>{element.market}</TT> </td>
            </tr>
        );
    }

}



export default ROW;

