
import { Wholesale_Flyer_Info } from "../components/common/actions";

const initState = {
};

const _reducer = "_reducer";
export default function wholesaleFlyer(state = initState, data) {
    switch (data.type) {
        case Wholesale_Flyer_Info: {
            console.log('with in actions', data);
            return data.data
        }
        default: {
            return state;
        }
    }
}