import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import {SortableElement} from 'react-sortable-hoc';


export const GridItem = SortableElement(({ image ,removeImage,onError }) =>
    <div className="GallInnBx" >
    <div onClick={() => removeImage(image)} 
        className='delete'>
        <FontAwesomeIcon 
        icon={faTimesCircle} 
        size='2x' />
    </div>
    <img 
        onError={() => onError(image.image.public_id)}
        src={image.image.secure_url} 
        alt='' 
    /> 
    
  </div>
);

