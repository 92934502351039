import React, { Component } from "react";
import { Field, SubmissionError, FieldArray, reduxForm } from "redux-form";
import { toast } from "react-toastify";
import HTTP from "../../services/http";
import { push } from "react-router-redux";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
/**COMPONENTS */
import renderField from "../common/renderField";
import PageHeader from "../common/pageheader";
import FieldArrayProperty from "../common/FieldArrayProperty";
import { required, isValidAddress } from "../common/fieldValidations";
import Loader from "../common/loader";
import { ADMIN_TRACK_AUDIT_LOGS } from "../common/actions";
import infoOf from "../common/tooltip-text";
import FieldArrayfeature from "../common/FieldArrayfeature";
import LocationSearchInput from "../common/address";
import FileInput from "../common/FileInput";

class AddProperty extends Component {
	constructor() {
		super();
		this.state = {
			success: "",
			reset: false,
			isLoading: false,
			address: ""
		};
		this.addProperty = this.addProperty.bind(this);
	

	}

	componentWillMount() {

	 }



	addProperty(values) {
		this.setState({ isLoading: true });
		let formData = new FormData();
		let obj = {};
		let imageArrayF = [],
			imageArrayB = [];
		obj.address = values.address;
		obj.feature = values.feature;
		obj.features = values.features;
		obj.before_image = values.before_image;
		obj.after_image = values.after_image;
		if (
			values.before_image &&
			values.after_image &&
			(!values.addresses || values.addresses.length == 0)
		) {
			formData.append("data", JSON.stringify(values));
			formData.append("before_image", values.before_image);
			formData.append("after_image", values.after_image);
		}

		if (values.addresses && values.addresses.length > 0) {
			formData.append("data", JSON.stringify(values));
			formData.append("before_image", values.before_image);
			formData.append("after_image", values.after_image);
			values.addresses.forEach((item, index) => {
				formData.append(`before_image_${index}`, values.before_image);
				formData.append(`after_image_${index}`, values.after_image);
			});
		}

		HTTP.Request("post", window.admin.addProperty, formData)
			.then(result => {
				this.props.dispatch(push("/property-management-list"));
				toast(result.message, { type: "success" });
				this.setState({ isLoading: false });
			})
			.catch(error => {
				toast("something went wrong", { type: "error" });
				this.setState({ isLoading: false });
			});
	}

	render() {
		const { handleSubmit } = this.props;
		const { isLoading, formAction, status } = this.state;
		return (
			<div>
				{isLoading ? (
					<Loader />
				) : (
						<div>
							<PageHeader
								route={"Add New Property"}
								parent="Photo Gallery"
								parentRoute="/property-management-list"
							/>

							<div className="tab-pane active">
								<form onSubmit={handleSubmit(this.addProperty)}>
									<Field
										name="name"
										fieldName="Title*"
										type="text"
										component={renderField}
										validate={[required]}
									/>

									<div className="row">
										<div className="col-sm-6">
											<Field
												name="name"
												fieldName="Customer Name*"
												type="text"
												component={renderField}
												validate={[required]}
											/>
										</div>

									</div>

									<Field
										component={FileInput}
										type="file"
										label="Before Image"
										name="before_image"
										validate={required}
									/>
									<Field
										component={FileInput}
										type="file"
										label="After Image"
										name="after_image"
										validate={required}
									/>
									<Field
										fieldName="feature*"
										placeholder="Enter feature"
										icon="fa fa-info-circle"
										tooltip={infoOf.default_tax}
										component={renderField}
										type="text"
										name="feature"
										validate={required}
									/>
									<FieldArray
										placeholder1="Enter feature"
										formGroupClassName=""
										name="features"
										component={FieldArrayfeature}
									/>
									<FieldArray
										placeholder1="Enter Address"
										placeholder2="Enter feature"
										name="addresses"
										component={FieldArrayProperty}
									/>
									<br />
									<div className="form-actions">
										<button
											type="submit"
											className="btn green uppercase"
											disabled={
												this.props.invalid ||
												this.props.submitting
											}
										>
											Submit
                                    </button>
									</div>
								</form>
							</div>
						</div>
					)}
			</div>
		);
	}
}

let TaxesForm = reduxForm({
	form: "Property_form",
	asyncValidate: isValidAddress,
	asyncBlurFields: ["address"],
	shouldAsyncValidate: ({ trigger, blurredField, initialized, pristine }) => {
		if (blurredField !== "address") {
			return false;
		}
		switch (trigger) {
			case "blur":
				return true;
			case "submit":
				// submitting, so only async validate if form is dirty or was never initialized
				// conversely, DON'T async validate if the form is pristine just as it was
				// initialized
				return !pristine || !initialized;
			default:
				return false;
		}
	}
})(AddProperty);

export default TaxesForm;
