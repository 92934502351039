import flat from 'flat';

export function scrollToFirstError(errors) {
    const firstError = Object.keys(flat(errors))[0];
    const el = document.getElementById(`${firstError}_input`);
    const otherEl = document.querySelector(`[name="${firstError}"]`);
    if(el){
        el.focus();

    }else{
        if(otherEl){
            otherEl.focus();
        }
    }
  }

  