import React, { Component } from 'react';
import { connect } from 'react-redux';
import { exportData} from "../../api/cms";
import { toast } from 'react-toastify';

/**COMPONENTS */
import PageHeader from "../common/pageheader";
import DateFilter from '../common/filter';
import Switch from '@material-ui/core/Switch';
import xlsx from 'xlsx';
/**PAGE LEVEL CSS */
import "../../assets/css/profile.min.css";

class ExportCms extends Component {

  constructor(props) {
    super(props);

    this.state = {
      end_date: "",
      start_date: "",
      exportPages: true,
      exportBlogs: true,
      exportCityPages: true,
      exportPressReleases: true,
      exportCmsBlocks: true,
      loading:false,
    };

    this.resetFilters = this.resetFilters.bind(this);
    this.filterByDate = this.filterByDate.bind(this);


  }

  render() {
    const { end_date, start_date, exportBlogs, exportCityPages, isloading,exportCmsBlocks, exportPages, exportPressReleases } = this.state;
    return (
      <div>
        <PageHeader pageTitle="Export CMS" route="Export CMS" />
        <div className="profile-content min-height1000">
          <div className="">
            <div className="">
              <DateFilter submitText={"EXPORT"} resetFunction={this.resetFilters} filterByDate={this.filterByDate}></DateFilter>
            </div>
            <div style={{ paddingLeft: "15px" }} className='row col-12'>
              {isloading?"Exporting...." : ""}
              <div className='d-flex'>
                <Switch
                  checked={exportPages}
                  onChange={(e) => {
                    this.setState({
                      exportPages: !exportPages
                    })
                  }}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Pages
              </div>
              <div className='d-flex'>
                <Switch
                  checked={exportCityPages}
                  onChange={(e) => {
                    this.setState({
                      exportCityPages: !exportCityPages
                    })
                  }}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                City Pages

              </div>
              <div className='d-flex'>
                <Switch
                  checked={exportBlogs}
                  onChange={(e) => {
                    this.setState({
                      exportBlogs: !exportBlogs
                    })
                  }}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Blogs

              </div>
              <div className='d-flex'>
                <Switch
                  checked={exportPressReleases}
                  onChange={(e) => {
                    this.setState({
                      exportPressReleases: !exportPressReleases
                    })
                  }}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                Press Releases

              </div>
              <div className='d-flex'>
                <Switch
                  checked={exportCmsBlocks}
                  onChange={(e) => {
                    this.setState({
                      exportCmsBlocks: !exportCmsBlocks
                    })
                  }}
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
                CMS Blocks

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  filterByDate(data) {
    const { end_date, start_date } = data;
    this.state.start_date = start_date;
    this.state.end_date = end_date;
    this.setState({
      start_date: start_date,
      end_date: end_date,
    });
    this.exportData()
  }

  resetFilters() {
    this.setState({
      start_date: "",
      end_date: "",
    });
  }

  async exportData() {
    this.setState({
      isloading:true
    })
    if (this.state.exportPages || this.state.exportBlogs || this.state.exportCityPages || this.state.exportCmsBlocks || this.state.exportPressReleases) {
      let pageRes
      pageRes = await exportData({
        startDate:this.state.start_date,
        endDate:this.state.end_date,
        exportParms:{
          exportPages: this.state.exportPages,
          exportBlogs: this.state.exportBlogs,
          exportCityPages: this.state.exportCityPages,
          exportPressReleases: this.state.exportPressReleases,
          exportCmsBlocks: this.state.exportCmsBlocks,
        }
      });
      let workBook = xlsx.utils.book_new();
      if(pageRes.pages){
        const pageSheet = xlsx.utils.json_to_sheet(pageRes.pages);
        xlsx.utils.book_append_sheet(workBook, pageSheet, "Pages");
      }
      if(pageRes.cityPages){
        const cirtPagesSheet = xlsx.utils.json_to_sheet(pageRes.cityPages);
        xlsx.utils.book_append_sheet(workBook, cirtPagesSheet, "City Pages");
      }

      if(pageRes.blogs){
        const blogsSheet = xlsx.utils.json_to_sheet(pageRes.blogs);
        xlsx.utils.book_append_sheet(workBook, blogsSheet, "Blogs");
      }

      if(pageRes.pressReleases){
        const pressReleasesSheet = xlsx.utils.json_to_sheet(pageRes.pressReleases);
        xlsx.utils.book_append_sheet(workBook, pressReleasesSheet, "Press Releases");
      }

      if(pageRes.cmsBlocks){
        const cmsBlocksSheet = xlsx.utils.json_to_sheet(pageRes.cmsBlocks);
        xlsx.utils.book_append_sheet(workBook, cmsBlocksSheet, "CMS Blocks");
      }
      xlsx.writeFile(workBook, "CMS Export.xlsx");
      this.setState({
        isloading:false
      })
    }
    else{
      toast("Please select valid options to export", {
        type: 'error',
      });
    }
  }
}


export default connect()(ExportCms);

