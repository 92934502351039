import React, { Component } from 'react';
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  change,
} from 'redux-form';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import HTTP from '../../services/http';
import { Tabs, Tab } from 'react-bootstrap';
import {
  required,
  ValidateOnlyAlpha,
  faqOrderMax,
} from '../common/fieldValidations';
import { slugify } from '../../libs/Helper';
import ImageCropper from '../common/ImageCropper';
import FieldArrayfeature from '../common/FieldArrayfeature';
/**COMPONENT */
import RenderField from '../common/renderField';
import PageHeader from '../common/pageheader';
import Multiselect from '../common/multiselect';
import DropdownComp from '../common/DropdownList';
import Loader from '../common/loader';
import Revisions from './element/revisions';
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
/**CONSTANT DATA */
import { AdTYPE, AdPOSITION, stateList } from '../common/options';
import infoOf from '../common/tooltip-text';

class AddAd extends Component {
  constructor(props) {
    super(props);

    this.state = {
      revisions: [],
      content: '',
      isLoading: false,
      formAction: 'ADD',
      status: true,
      readOnly: true,
      _Position: AdPOSITION[0],
      _adtype: AdTYPE[0],
    };
    /**event binding  */
    this.upsertCMS = this.upsertCMS.bind(this);
    this.getaCMS = this.getaCMS.bind(this);
    this.getFile = this.getFile.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    this.getaCMS();
  }

  onChange(event) {
    const { change } = this.props;
    if (event.target.value) {
      if(this.state.formAction == "ADD"){
        change('slug', slugify(event.target.value));
      }
    }
  }

  render() {
    const { handleSubmit, internal, dispatch, obj } = this.props;
    const {
      isLoading,
      formAction,
      _adtype,
      _Position,
      status,
      readOnly,
    } = this.state;
    const title_value = obj.add ? `${obj.add}, ` : '';
    const title_city = obj.city ? `${obj.city}, ` : '';

    // dispatch(change('AddAd_Form', 'title', `${title_value}${title_city}${obj.state || ""}`))

    return (
      <div className="relative">
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <PageHeader
              route={
                formAction === 'ADD'
                  ? 'Add New Photo Gallery'
                  : 'Edit Photo Gallery'
              }
              parent="Photo Gallery"
              parentRoute="/ads"
            />

            <div className="tab-pane active">
              <Tabs defaultActiveKey={1} animation={false} id="profileTabs">
                <Tab
                  eventKey={1}
                  title={
                    formAction === 'ADD'
                      ? 'Add New Photo Gallery'
                      : 'Edit Photo Gallery'
                  }
                >
                  <form onSubmit={handleSubmit(this.upsertCMS)}>
                    <div className="row">
                      <div className="col-sm-6">
                        <Field
                          name="meta_title"
                          fieldName="Meta Title"
                          type="text"
                          component={RenderField}
                        />
                      </div>
                      <div className="col-sm-6">
                        <Field
                          name="meta_description"
                          fieldName="Meta Description"
                          type="text"
                          component={RenderField}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <Field
                          name="add"
                          fieldName="Address*"
                          type="text"
                          component={RenderField}
                          validate={[required]}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Field
                          name="city"
                          fieldName="City*"
                          type="text"
                          component={RenderField}
                          validate={[required]}
                        />
                      </div>
                      <div className="col-sm-4">
                        <Field
                          name="state"
                          options={stateList}
                          label="State"
                          defaultValue={'VA'}
                          textField="title"
                          valueField="value"
                          component={DropdownComp}
                        />
                      </div>
                    </div>

                    <Field
                      name="title"
                      fieldName="Title*"
                      type="text"
                      component={RenderField}
                      onChange={this.onChange}
                      readOnly={readOnly}
                      validate={[required]}
                    />
                    <Field
                      name="slug"
                      fieldName="Custom URL*"
                      type="text"
                      component={RenderField}
                      disabled={this.props.allowCustomURLEdit ? false : true}
                      validate={[required]}
                    />
                    <div className="row">
                      <div className="col-sm-6">
                        <label>Feature Image*</label>
                        <Field
                          component={ImageCropper}
                          id={'photo_feature'}
                          name="photo_feature"
                          minWidth={450}
                          minHeight={300}
                          dimensionsCheck={true}
                          ratioUpper={450}
                          ratioLower={300}
                          validate={formAction === 'ADD' ? [required] : []}
                        />
                        <br />
                        {this.state.photo_feature ? (
                          <img
                            src={this.state.photo_feature.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                      <div className="col-sm-6">
                        <Field
                          name="img_alt_featured"
                          fieldName="Featured Image Alternative Text"
                          type="text"
                          component={RenderField}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <Field
                          name="img_alt_before"
                          fieldName="Before Image Alternative Text"
                          type="text"
                          component={RenderField}
                        />
                      </div>
                      <div className="col-sm-6">
                        <Field
                          name="img_alt_after"
                          fieldName="After Image Alternative Text"
                          type="text"
                          component={RenderField}
                        />
                      </div> 
                    </div>
                    <Field
                      name="section_one"
                      fieldName="Heading One*"
                      type="text"
                      component={RenderField}
                      validate={[required]}
                    />
                    <div className="row">
                      <div className="col-sm-6">
                        <FieldArray
                          placeholder1="Enter Features Before"
                          formGroupClassName=""
                          name="heading_one_before_fea"
                          component={FieldArrayfeature}
                          feature={'Before Feature*'}
                        />
                      </div>
                      <div className="col-sm-6">
                        <FieldArray
                          placeholder1="Enter Features After"
                          formGroupClassName=""
                          name="heading_one_after_fea"
                          component={FieldArrayfeature}
                          feature={'After Feature*'}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <label>Image Before</label>
                        <Field
                          component={ImageCropper}
                          id={'HeadingOneBefore'}
                          name="image"
                          minWidth={450}
                          minHeight={300}
                          dimensionsCheck={true}
                          ratioUpper={450}
                          ratioLower={300}
                        />
                        <br />
                        {this.state.image ? (
                          <img
                            src={this.state.image.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                      <div className="col-sm-6">
                        <label>Image After</label>
                        <Field
                          component={ImageCropper}
                          id={'HeadingOneAfter'}
                          name="position"
                          minWidth={450}
                          minHeight={300}
                          dimensionsCheck={true}
                          imagetype={'gallery'}
                          ratioUpper={450}
                          ratioLower={300}
                        />
                        <br />
                        {this.state.position ? (
                          <img
                            src={this.state.position.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                    </div>

                    <Field
                      name="section_two"
                      fieldName="Heading Two*"
                      type="text"
                      component={RenderField}
                      validate={[required]}
                    />
                    <div className="row">
                      <div className="col-sm-6">
                        <FieldArray
                          placeholder1="Enter Features Before"
                          formGroupClassName=""
                          name="heading_two_before_fea"
                          component={FieldArrayfeature}
                          feature={'Before Feature*'}
                        />
                      </div>
                      <div className="col-sm-6">
                        <FieldArray
                          placeholder1="Enter Features After"
                          formGroupClassName=""
                          name="heading_two_after_fea"
                          component={FieldArrayfeature}
                          feature={'After Feature*'}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <label>Image Before</label>
                        <Field
                          component={ImageCropper}
                          id={'HeadingTwoBefore'}
                          name="featured_image"
                          minWidth={450}
                          minHeight={300}
                          dimensionsCheck={true}
                          ratioUpper={450}
                          ratioLower={300}
                        />
                        <br />
                        {this.state.featured_image ? (
                          <img
                            src={this.state.featured_image.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                      <div className="col-sm-6">
                        <label>Image After</label>
                        <Field
                          component={ImageCropper}
                          id={'HeadingTwoAfter'}
                          name="title_image"
                          minWidth={450}
                          minHeight={300}
                          dimensionsCheck={true}
                          ratioUpper={450}
                          ratioLower={300}
                        />
                        <br />
                        {this.state.title_image ? (
                          <img
                            src={this.state.title_image.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                    </div>

                    <Field
                      name="section_three"
                      fieldName="Heading Three*"
                      type="text"
                      component={RenderField}
                      validate={[required]}
                    />
                    <div className="row">
                      <div className="col-sm-6">
                        <FieldArray
                          placeholder1="Enter Features Before"
                          formGroupClassName=""
                          name="heading_three_before_fea"
                          component={FieldArrayfeature}
                          feature={'Before Feature*'}
                        />
                      </div>
                      <div className="col-sm-6">
                        <FieldArray
                          placeholder1="Enter Features After"
                          formGroupClassName=""
                          name="heading_three_after_fea"
                          component={FieldArrayfeature}
                          feature={'After Feature*'}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <label>Image Before</label>
                        <Field
                          component={ImageCropper}
                          id={'HeadingThreeBefore'}
                          name="adtype"
                          minWidth={450}
                          minHeight={300}
                          dimensionsCheck={true}
                          ratioUpper={450}
                          ratioLower={300}
                        />
                        <br />
                        {this.state.adtype ? (
                          <img
                            src={this.state.adtype.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                      <div className="col-sm-6">
                        <label>Image After</label>
                        <Field
                          component={ImageCropper}
                          id={'HeadingThreeAfter'}
                          minWidth={450}
                          minHeight={300}
                          name="address"
                          dimensionsCheck={true}
                          ratioUpper={450}
                          ratioLower={300}
                        />
                        <br />
                        {this.state.address ? (
                          <img
                            src={this.state.address.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                    </div>

                    <Field
                      name="section_four"
                      fieldName="Heading Four*"
                      type="text"
                      component={RenderField}
                    />
                    <div className="row">
                      <div className="col-sm-6">
                        <FieldArray
                          placeholder1="Enter Features Before"
                          formGroupClassName=""
                          name="heading_four_before_fea"
                          component={FieldArrayfeature}
                          feature={'Before Feature*'}
                        />
                      </div>
                      <div className="col-sm-6">
                        <FieldArray
                          placeholder1="Enter Features After"
                          formGroupClassName=""
                          name="heading_four_after_fea"
                          component={FieldArrayfeature}
                          feature={'After Feature*'}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <label>Image Before</label>
                        <Field
                          component={ImageCropper}
                          minWidth={450}
                          minHeight={300}
                          id={'HeadingFourBefore'}
                          name="headfourBe_image"
                          dimensionsCheck={true}
                          ratioUpper={450}
                          ratioLower={300}
                        />
                        <br />
                        {this.state.headfourBe_image ? (
                          <img
                            src={this.state.headfourBe_image.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                      <div className="col-sm-6">
                        <label>Image After</label>
                        <Field
                          component={ImageCropper}
                          minWidth={450}
                          minHeight={300}
                          id={'HeadingFourAfter'}
                          name="headfourAf_image"
                          dimensionsCheck={true}
                          ratioUpper={450}
                          ratioLower={300}
                        />
                        <br />
                        {this.state.headfourAf_image ? (
                          <img
                            src={this.state.headfourAf_image.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                    </div>

                    <Field
                      name="section_five"
                      fieldName="Heading Five*"
                      type="text"
                      component={RenderField}
                    />
                    <div className="row">
                      <div className="col-sm-6">
                        <FieldArray
                          placeholder1="Enter Features Before"
                          formGroupClassName=""
                          name="heading_five_before_fea"
                          component={FieldArrayfeature}
                          feature={'Before Feature*'}
                        />
                      </div>
                      <div className="col-sm-6">
                        <FieldArray
                          placeholder1="Enter Features After"
                          formGroupClassName=""
                          name="heading_five_after_fea"
                          component={FieldArrayfeature}
                          feature={'After Feature*'}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <label>Image Before</label>
                        <Field
                          component={ImageCropper}
                          minWidth={450}
                          minHeight={300}
                          id={'headingFiveBefore'}
                          name="headfiveBe_image"
                          dimensionsCheck={true}
                          ratioUpper={450}
                          ratioLower={300}
                        />
                        <br />
                        {this.state.headfiveBe_image ? (
                          <img
                            src={this.state.headfiveBe_image.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                      <div className="col-sm-6">
                        <label>Image After</label>
                        <Field
                          component={ImageCropper}
                          minWidth={450}
                          minHeight={300}
                          id={'HeadingFiveAfter'}
                          name="headfiveAf_image"
                          dimensionsCheck={true}
                          ratioUpper={450}
                          ratioLower={300}
                        />
                        <br />
                        {this.state.headfiveAf_image ? (
                          <img
                            src={this.state.headfiveAf_image.secure_url}
                            alt=""
                            width="120px"
                            className="img-responsive img-thumbnail i-bot"
                          />
                        ) : null}
                      </div>
                    </div>

                    <Field
                      name="status"
                      options={[
                        { label: 'Active', value: true },
                        { label: 'Inactive', value: false },
                      ]}
                      label="Status"
                      defaultValue={status ? 'Active' : 'Inactive'}
                      textField="label"
                      valueField="value"
                      component={DropdownComp}
                    />
                    <br />

                    <div className="form-actions">
                      <button
                        type="submit"
                        className="btn green uppercase"
                        disabled={this.props.invalid || this.props.submitting}
                      >
                        {formAction === 'ADD' ? 'Add' : 'Update'}
                      </button>
                    </div>
                  </form>
                </Tab>
                <Tab eventKey={2} title="Revisions">
                  <Revisions revisions={this.state.revisions} />
                </Tab>
              </Tabs>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentWillReceiveProps(nextProps, nextState) {
    const { formAction } = this.state;
    const { obj } = this.props;
    const {
      obj: { title, city, state, add },
    } = nextProps;
    const title_value = add ? `${add}, ` : '';
    const title_city = city ? `${city}, ` : '';
    // let temp =`${title_value}${title_city}${obj.state || ""}`;

    if (
      (obj.city && city && city !== obj.city) ||
      (obj.add && add && add !== obj.add) ||
      (obj.state && state && state !== obj.state)
    ) {
      nextProps.dispatch(
        change(
          'AddAd_Form',
          'title',
          `${title_value}${title_city}${state || ''}`,
        ),
      );
      nextProps.dispatch(
        change(
          'AddAd_Form',
          'slug',
          slugify(`${title_value}${title_city}${state || ''}`),
        ),
      );
    }

    // dispatch(change('AddAd_Form', 'title', `${title_value}${title_city}${obj.state || ""}`))
    // if(city !== nextProps.obj.city  && title !== nextProps.obj.title)
    // nextProps.dispatch(change('AddAd_Form', 'slug',slugify( nextProps.obj.title)))
  }
  upsertCMS(data) {
    const { match } = this.props;

    this.setState({ isLoading: true });
    /*bind type of Post*/
    if (data.status === undefined) {
      data.status = true;
    }
    data.type = 'ads';
    if (match.params._id) data.editID = match.params._id;
    data.showImage = 'NoImage';
    let formData = new FormData();
    /*add file to request*/
    formData.append('image', data.image);
    formData.append('photo_feature', data.photo_feature);
    formData.append('position', data.position);
    formData.append('featured_image', data.featured_image);
    formData.append('title_image', data.title_image);
    formData.append('adtype', data.adtype);
    formData.append('address', data.address);
    formData.append('headfiveBe_image', data.headfiveBe_image);
    formData.append('headfiveAf_image', data.headfiveAf_image);
    formData.append('headfourBe_image', data.headfourBe_image);
    formData.append('headfourAf_image', data.headfourAf_image);
    formData.append('data', JSON.stringify(data));

    HTTP.Request('post', window.admin.upsertAds, formData)
      .then((r) => {
        this.props.dispatch(push('/ads'));
        toast.success(r.message);
        this.setState({ isLoading: false });

        this.props.dispatch({
          type: ADMIN_TRACK_AUDIT_LOGS,
          action: {
            comment: 'Modified the content of Ad - ' + r.data.title,
            type: 'audit',
          },
        });
      })
      .catch((e) => {
        if (e.errors) {
          this.setState({ isLoading: false });
          e.errors.map((error) => toast(error, { type: 'error' }));
        }
      });
  }

  getFile(e) {
    this.setState({ file: e.target.files[0] });
  }

  getaCMS() {
    const { match, initialize } = this.props;
    /*extract plant id from request*/
    let cmsID = match.params._id ? match.params._id : null;

    if (cmsID) {
      this.setState({ isLoading: true, formAction: 'EDIT' });
      HTTP.Request('get', window.admin.getaAds, { _id: cmsID }).then(
        (result) => {
          this.setState({
            isLoading: false,
            status: result.data.data.status,
            content: result.data.data.content ? result.data.data.content : '',
            image: result.data.data.image ? result.data.data.image : '',
            title_image: result.data.data.title_image
              ? result.data.data.title_image
              : '',
            photo_feature: result.data.data.photo_feature
              ? result.data.data.photo_feature
              : '',
            featured_image: result.data.data.featured_image
              ? result.data.data.featured_image
              : '',
            position: result.data.data.position
              ? result.data.data.position
              : '',
            address: result.data.data.address ? result.data.data.address : '',
            adtype: result.data.data.adtype ? result.data.data.adtype : '',
            headfiveBe_image: result.data.data.headfiveBe_image
              ? result.data.data.headfiveBe_image
              : '',
            headfiveAf_image: result.data.data.headfiveAf_image
              ? result.data.data.headfiveAf_image
              : '',
            headfourBe_image: result.data.data.headfourBe_image
              ? result.data.data.headfourBe_image
              : '',
            headfourAf_image: result.data.data.headfourAf_image
              ? result.data.data.headfourAf_image
              : '',
          });
          if (result.data.adtype) this.adToggle(null, result.data.adtype);
          /*set data to form*/
          initialize(result.data.data);
        },
      );
    }
  }
}

//decorate form component
let AddAd_Form = reduxForm({
  form: 'AddAd_Form',
})(AddAd);
const selector = formValueSelector('AddAd_Form');
function mapstateToProps(state) {
  /**to get value of dropdown */
  const internal = selector(state, 'adtype');
  const obj = selector(state, 'add', 'city', 'state', 'title');
  var solvedPermissions = {};
  if (
    state.admin.permissions &&
    state.admin.permissions.indexOf('EDIT_CUSTOM_URL') !== -1
  ) {
    solvedPermissions.allowCustomURLEdit = true;
  }
  return { internal, obj, solvedPermissions};
}

export default connect(mapstateToProps)(AddAd_Form);
