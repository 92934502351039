
import { crmlogsCount } from "../components/common/actions";

const initState = {
};

const _reducer = "_reducer";
export default function crmlogs_count(state = initState, data) {
    switch (data.type) {
        case crmlogsCount: {
            console.log("data of crmlogsCount", data);
            return data.params
        }
        default: {
            return state;
        }
    }
}