import React, { Component } from 'react';
import RenderField from '../common/renderField';
import { connect } from 'react-redux'
import HTTP from "../../services/http";
import renderHTML from 'react-render-html';
import Loader from "../common/loader";
import { toast } from "react-toastify";
import xlsx from 'xlsx';
import { push } from 'react-router-redux';
import { ADMIN_TRACK_AUDIT_LOGS } from '../common/actions';
import Moment from "react-moment";


class BlogPreview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            someHTML: '',
            styling: `.blog-section {
                padding-top: 50px;
                min-height: 1000px;
            }
            
            .mid_sec {
                padding-bottom: 50px;
            }
            
            .blog_cont h3 a {
                color: #ff7e18;
                text-decoration: none;
            }
            
            .blog_cont .post_meta {
                padding-bottom: 5px;
                font-size: 18px;
                text-transform: uppercase;
                line-height: 24px;
                color: #6f7c82;
            }
            
            .common_content-1 {
                font-size: 16px !important;
                line-height: 27px !important;
                color: #6f7c82 !important;
                min-height: 1000px;
            }
            
            .common_content-1 a {
                color: #ff7e18 !important;
            }
            
            .common_content-1 h4 {
                line-height: 20px;
                font-size: 20px;
                color: #6f7c82;
                font-weight: bold;
            }

            img.fr-fic {
    margin: 5px auto;
    display: block;
    float: none;
    vertical-align: top;
}

img.fr-dib.fr-fil {
    margin-left: 0;
    text-align: left;
}

img.fr-dib.fr-fir {
    margin-right: 0;
    text-align: right;
}

            .common_content-1 p {
                font-size: 16px;
                line-height: 27px;
                color: #6f7c82;
                margin-bottom: 1rem !important;
                margin:0;
            }
            .common_content-1 h3 
            {
              font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
              line-height: 24px;
            font-size: 22px;
            margin:0;
              margin-bottom: 0.5rem;
            }
            .common_content-1 h2
            {
              font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
              margin:0;
              margin-bottom: 0.5rem;
            }

            .body-imp {
                font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
                /* font-family: Helvetica; */
                overflow-x: hidden;
                position: relative;
                padding:3px;
            }
            
            .social-icon-btn img {
                width: 23px;
            }
            
            .social_post_share {
                display: flex;
            }
            
            .social_post_share a {
                padding: 5px 0px;
                opacity: 0.5;
            }
            
            .post_details_heading {
                line-height: 36px;
                font-size: 34px;
                color: #292e31;
                padding-bottom: 0px;
                font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
                margin-bottom: 0;
            }
            
            .details_inner h1 {
                line-height: 36px;
                font-size: 34px;
                color: #292e31;
            }
            
            .post_meta-detail small {
                font-size: 14px;
                line-height: 18px;
                color: #6f7c82;
                font-style: italic;
            }
            
            .blog_cont img {
                padding-bottom: 10px;
                width: 100%;
                height: 100%;
            }
            
            h5.sidebar_title {
                background: #dcdcdb;
                padding: 20px 10px;
                border-left: 5px solid #a0a0a0;
                color: #000000;
                text-transform: uppercase;
                font-size: 14px;
            }
            
            .blog_sidebar li {
                font-size: 16px;
                line-height: 27px;
                color: #6f7c82;
                padding-bottom: 12px;
                border-bottom: 2px dotted #6f7c82;
                margin-bottom: 12px;
            }
            
            .blog_sidebar li a {
                color: #6f7c82;
            }
            
            .blog_cont.details_inner a {
                color: #ff7e18;
            }
            
            .getoffer_bot_section {
                text-align: center;
                background: #ff7e18;
                color: #ffffff;
                padding: 50px 50px;
            }
            
            .getoffer_bot_section h2 {
                line-height: 36px;
                font-size: 34px;
                padding-bottom: 20px;
                color: #ffffff;
            }
            
            .getoffer_bot_section p {
                font-size: 16px;
                line-height: 27px;
                padding-bottom: 30px;
            }
            
            .btn-primary:hover {
                color: #ffffff;
                background-color: #286090;
                border-color: #204d74;
            }
            
            .next_post,
            .prev_post {
                /* text-align: right; */
                padding-right: 15px;
                padding-top: 15px;
                padding-bottom: 15px;
            }
            
            .next_post {
                text-align: left;
            }
            
            .prev_post {
                text-align: right;
                /* border-right: 8px solid #dddddd; */
            }
            
            .prev-post-border {
                border-right: 8px solid #dddddd;
            }
            
            .custom_btn {
                background: #ffffff;
                background-color: rgb(255, 255, 255);
                border: none;
                border-top-color: currentcolor;
                border-right-color: currentcolor;
                border-bottom-color: currentcolor;
                border-left-color: currentcolor;
                border-radius: 0px;
                box-shadow: none;
                padding: 15px 20px;
                font-size: 18px;
                color: #6f7c82;
                width: 168px;
            }
            
            .nxt_prev {
                margin-top: 60px;
                margin-bottom: 70px;
            }
            
            .next_post a,
            .prev_post a {
                background-color: transparent;
                font-size: 14px;
                color: #6f7c82;
                font-weight: 400;
                padding: 0px;
                display: block;
            }
            
            
            /* css for listing applied */
            
            .common_content-1 li {
             color: #6f7c82;
            }
            
            
            /* css for listing end */
            
            .blog-home-bck-btn {
                color: #ff7e18;
                margin-bottom: 22px;
                display: block;
                margin-left: -45px;
            }
            
            @media only screen and (max-width: 1100px) {
                .blog-home-bck-btn {
                    margin-left: 0px;
                }
            }
            
            @media only screen and (max-width: 767px) {
                .post_details_heading {
                    line-height: 31px;
                    font-size: 23px;
                    margin-bottom: 13px;
                }
                .common_content-1 {
                    min-height: 3000px;
                }
                .blog-section {
                    min-height: 4000px;
                }
            }
            
            .share_label {
                margin-top: 4px;
            }
            
            @font-face {
                font-display: swap;
                font-family: 'Gotham';
                font-style: normal;
                font-weight: 500;
                src: local('?'), url('./assets/fonts/Gotham-Medium.woff2') format('woff2'), url('./assets/fonts/Gotham-Medium.woff') format('woff');
            }
            
            @font-face {
                font-display: swap;
                font-family: merriweather;
                src: local('?'), url('https://res.cloudinary.com/house-buyers-of-america/raw/upload/v1674047626/FE_assets_new/Merriweather-Regular.otf');
            }
            
            @font-face {
                font-display: swap;
                font-family: helvetica;
                src: local('?'), url('https://res.cloudinary.com/house-buyers-of-america/raw/upload/v1674047627/FE_assets_new/Helvetica-Normal.ttf');
            }

            img.fr-fic {
                margin: 5px auto;
                display: block;
                float: none;
                vertical-align: top;
            }
            
            img.fr-dib.fr-fil {
                margin-left: 0;
                text-align: left;
            }
            
            img.fr-dib.fr-fir {
                margin-right: 0;
                text-align: right;
            }

            img.fr-dii {
                display: inline-block;
                float: none;
                vertical-align: bottom;
                margin-left: 5px;
                margin-right: 5px;
                max-width: calc(100% - 10px);
            }
            `,
            isLoading: true,
            pageData: {},
            file: undefined,
            image:undefined,
            base64data:""
        }

        this.upsertCms = this.upsertCms.bind(this)
    }


    componentDidMount() {
        const scope = this
        var reader = new FileReader();
            reader.onloadend = function() {
                scope.setState({
                    base64data:reader.result, 
                });                
        }
        if(!!this.props.previewData && !!this.props.previewData.formFile){reader.readAsDataURL(this.props.previewData.formFile)}
        if (!!this.props.previewData) {
            this.setState({
                pageData: this.props.previewData.formData,
                file: this.props.previewData.formFile,
                image: !!this.props.previewData.image ? this.props.previewData.image : "" 
            })
        }
    }

    render() {
        const { pageData, styling,file,image,base64data } = this.state;
        return (
            <div style={{"margin":"auto"}}>
                <div className='body-imp' style={{ border: "1px solid",width:"80%" , padding:"15px",margin:"auto"}}>
                <h1 class="post_details_heading">{ pageData.title || ' ' }</h1>
                    <div className="blog_cont details_inner">
                        <div className="post_meta-detail">
                           {!!pageData.blog_post_date && <small><Moment format="MMM DD, YYYY">{pageData.blog_post_date}</Moment></small>}
                        </div>
                        {(!!base64data || (!!image && !!image.secure_url)) && <img fetchpriority="high" width="675" height="460" src={!!base64data ?  base64data : !!image && !!image.secure_url ? image.secure_url: ""} alt={!!image ? pageData.img_alt : ""} />}
                        <div className="common_content-1" dangerouslySetInnerHTML={{__html:pageData.content}}>
                        </div>
                    </div>
                </div>
                <style dangerouslySetInnerHTML={{ __html: styling }}></style>
                <button
                    style={{ marginTop: "8px" }}
                    className="btn green uppercase"
                    disabled={this.props.submitting}
                    onClick={(e => {
                        this.upsertCms(pageData)
                    })}
                >Submit
                </button>
                <button
                        style={{ marginTop: "8px" ,marginLeft:"5px"}}
                        className="btn green uppercase"
                        disabled={this.props.submitting}
                        onClick={(e => {
                            this.closeModel()
                        })}
                    >Back to edit
                </button>
            </div >
        );
    }


    upsertCms() {
        let formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('data', JSON.stringify(this.state.pageData));
        this.props.dispatch({
            type: 'Admin-upsertBlog',
            data: formData,
            success: (r) => {
                this.props.dispatch(push('/blogs'));
                toast.success(r.message);
                this.setState({ isLoading: false });
                /*log audits for user*/
                this.props.dispatch({
                    type: ADMIN_TRACK_AUDIT_LOGS,
                    action: {
                        comment: 'Modified the content of Blog - ' + r.data.title,
                        type: 'audit',
                    },
                });
            },
            error: (e) => {
                if (e.errors) {
                    this.setState({ isLoading: false });
                    e.errors.map((error) => toast(error, { type: 'error' }));
                }
            },
        });
    }
    closeModel(){
        /**to close modal */
        this.props.closeParentModal();
    }
}

const mapStateToProp = (state) => {

}
export default connect(mapStateToProp)(BlogPreview)